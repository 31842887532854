import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import emailIcon from "src/assets/images/login/email.svg";
import lockIcon from "src/assets/images/login/lock.svg";
import AuthLayout from "./AuthLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "react-apollo";
import { LOGIN_MUTATION } from "src/graphql/auth/mutation";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "src/shared/SessionHelper";
import { COOKIES } from "src/shared/Config";
import { addToast } from "src/utils/ToastUtil";
import { useHistory, useParams, Link } from "react-router-dom";
import { GET_MY_PROFILE } from "src/graphql/users/queries";
import {
  setAcceptTerms,
  setAuthToken,
  setUserDetails,
} from "src/redux/userSlice";
import { ENTERPRISE_USER_INFO } from "src/graphql/enterprise/queries";
import { QUERY_GET_COMPANY_SETTINGS } from "src/graphql/campaigns/postcard/queries";
import { REVIEW_SOURCE } from "src/shared/Constants";
import { PARTNER_USER_INFO } from "src/graphql/partner/queries";
import { CREATE_SERVICE_MONSTER_CONNECTION } from "src/graphql/direct_connect/mutations";
import { setToastMessage } from "src/redux/toastSlice";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isServiceMonster =
    history.location.pathname.includes("service-monster");
  const { userInfo } = useSelector((state) => state.user);
  const [companyId, setCompanyId] = useState(null);
  const { type } = useParams();

  const [createServiceMonsterConnection] = useMutation(
    CREATE_SERVICE_MONSTER_CONNECTION,
    {
      onCompleted: () => {
        dispatch(
          setToastMessage({
            type: "success",
            message:
              "Your Service Monster account has been successfully linked.",
          }),
        );
        history.push(`/companies/${companyId}/direct-connect-settings`);
      },
      onError: (err) => {
        dispatch(
          setToastMessage({
            type: "error",
            message:
              err && err.graphQLErrors && err.graphQLErrors[0]
                ? err.graphQLErrors[0].message
                : "Please try again.",
          }),
        );
      },
    },
  );

  const [getCompanySettings] = useLazyQuery(QUERY_GET_COMPANY_SETTINGS, {
    onCompleted: ({ companySettings }) => {
      if (companySettings?.reviewPlatform === REVIEW_SOURCE.PULSE_M) {
        setCookie(COOKIES.IS_PULSEM, true, process.env.REACT_APP_APP_DOMAIN);
      }
    },
  });

  const [getUser] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: async ({ getCompanyUser }) => {
      await dispatch(setAcceptTerms(getCompanyUser.acceptsTerms));
      await dispatch(setUserDetails({ ...userInfo, ...getCompanyUser }));
      if (isServiceMonster) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const requestId = urlParams.get("requestID");
        createServiceMonsterConnection({
          variables: {
            companyId,
            requestId,
          },
        });
      } else {
        history.push(
          `/${
            type === "company" ? "companies" : "enterprise"
          }/${companyId}/dashboard`,
        );
      }
    },
  });

  const [getEnterpriseDetails] = useLazyQuery(ENTERPRISE_USER_INFO, {
    onCompleted: async ({ getEnterpriseUser }) => {
      await dispatch(setUserDetails({ ...userInfo, ...getEnterpriseUser }));
      history.push(`key-metrics`);
    },
  });

  const [getPartnerDetails] = useLazyQuery(PARTNER_USER_INFO, {
    onCompleted: async ({ getPartnerUser }) => {
      await dispatch(setUserDetails({ ...userInfo, ...getPartnerUser }));
      history.push(`results`);
    },
  });

  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted: async (data) => {
      const { login } = data;
      if (login?.authenticated) {
        await dispatch(setUserDetails(login));
        await dispatch(setAuthToken(login.token));
        setCompanyId(login?.companyId);
        await setCookie(
          COOKIES.AUTH_TOKEN,
          login.token,
          process.env.REACT_APP_APP_DOMAIN,
        );

        if (login?.expired) {
          await dispatch(setAcceptTerms(true));
          history.push(`update-expired-password`);
        }
        if (type === "partner") {
          await getPartnerDetails({
            variables: {
              partnerUserId: login?.partnerUserId,
            },
          });
        } else if (type === "enterprise") {
          await getEnterpriseDetails({
            variables: {
              id: login.enterpriseUserId,
            },
          });
        } else {
          await getCompanySettings({
            variables: {
              companyId: login.companyId,
            },
          });
          await getUser({
            variables: {
              companyUserId: login.companyUserId,
            },
          });
        }
      } else {
        addToast({
          type: "error",
          message: login?.errors,
        });
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const {
    handleSubmit,
    handleChange,
    values: { email, password },
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Please enter an email address."),
      password: Yup.string().required("Please enter a password."),
    }),
    onSubmit: async ({ email, password }, { setSubmitting }) => {
      await login({ variables: { email, password, type } });
      setSubmitting(false);
    },
  });

  return (
    <AuthLayout title="Login In To Your Account">
      <Form className="login-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={emailIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Enter your Email"
                type="email"
                onChange={handleChange}
                name="email"
                value={email}
              />
            </InputGroup>
            {touched.email && errors.email ? (
              <div className="sg-text-danger my-2">{errors.email}</div>
            ) : null}
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={lockIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Enter your Password"
                type="password"
                onChange={handleChange}
                name="password"
                value={password}
              />
            </InputGroup>
            {touched.password && errors.password ? (
              <div className="sg-text-danger my-2">{errors.password}</div>
            ) : null}
            <Button
              variant="primary"
              type="submit"
              className="w-100 mt-5"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait..." : "Login"}
            </Button>
            <div className="text-center mt-3">
              <p>
                Forgot your password?{" "}
                <Link className="text-orange" to={`/${type}/forgot-password`}>
                  Reset it here
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default Login;
