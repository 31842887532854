import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import MiniCard from "src/shared/styleguide/card/MiniCard";
import Form from "src/shared/styleguide/form/Form";
import { useMutation, useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { GET_CREDITS } from "src/graphql/reviews/get_reviews/queries";
import BlockUi from "react-block-ui";
import { PURCHASE_CREDITS_MUTATION } from "src/graphql/reviews/get_reviews/mutations";
import { useDispatch } from "react-redux";
import { setToastMessage } from "src/redux/toastSlice";
import "./CallCredit.scss";

const CallCredit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedAmount, setSelectedAmount] = useState(5);

  const [purchaseCredits, { loading: isLoading }] = useMutation(
    PURCHASE_CREDITS_MUTATION,
    {
      variables: {
        creditCount: selectedAmount,
      },
      onCompleted: ({ purchaseCustomerCallCredits }) => {
        if (purchaseCustomerCallCredits?.error) {
          dispatch(
            setToastMessage({
              type: "error",
              message: purchaseCustomerCallCredits.error,
            }),
          );
        }
        dispatch(
          setToastMessage({
            type: "success",
            message: "Credits added",
          }),
        );
        refetch();
      },
      onError: (err) => {
        dispatch(
          setToastMessage({
            type: "error",
            message:
              err && err.graphQLErrors && err.graphQLErrors[0]
                ? err.graphQLErrors[0].message
                : "Something went wrong, please try again.",
          }),
        );
      },
    },
  );

  const { data, loading, refetch } = useQuery(GET_CREDITS, {
    variables: {
      companyId: id,
      creditType: "CustomerCallCredit",
    },
  });
  const { creditCost, credits, inProgress } = data?.getCredits || {};
  return (
    <BlockUi blocking={loading}>
      <Container className="mt-4 call-credit-container">
        <h1>CUSTOMER CALL CREDITS</h1>
        <p>
          Credits for your account are granted on the{" "}
          <span className="text-success">
            {data?.getCredits?.formattedDate} of each month.
          </span>
        </p>
        <Row>
          <Col sm={3}>
            <MiniCard
              cardBodyStyle={{
                marginTop: "-15px",
              }}
              cardStyle={{
                borderWidth: 1,
                minHeight: "100%",
              }}
              height="unset"
              popupInfoMsg=""
              title=""
            >
              <div className="centered">
                <h1>{credits || 0}</h1>
              </div>
              <div className="centered">
                <p>Available call credits</p>
              </div>
            </MiniCard>
          </Col>
          <Col sm={3}>
            <MiniCard
              cardBodyStyle={{
                marginTop: "-15px",
              }}
              cardStyle={{
                borderWidth: 1,
                minHeight: "100%",
              }}
              height="unset"
              popupInfoMsg=""
              title=""
            >
              <div className="centered">
                <h1>{inProgress || 0}</h1>
              </div>
              <div className="centered">
                <p>Customers to be called</p>
              </div>
            </MiniCard>
          </Col>
        </Row>
        <h1 className="mt-5">REQUEST ADDITIONAL CREDITS</h1>
        <p className="mb-2">
          You may purchase additional customer call credits to get verified
          customer reviews. Additional Customer <br /> Call credits cost{" "}
          <span className="text-success">${creditCost || 0}</span> per credit.
        </p>
        <Row className="mt-5">
          <Col sm={4}>
            <h1>SELECT AMOUNT OF CREDIT</h1>
            <Form.Group controlId="formBasicSelect">
              <Form.Select
                value={selectedAmount}
                onChange={({ value }) => setSelectedAmount(value)}
                options={[
                  { value: 5, label: "5" },
                  { value: 10, label: "10" },
                  { value: 15, label: "15" },
                  { value: 20, label: "20" },
                  { value: 25, label: "25" },
                  { value: 50, label: "50" },
                  { value: 100, label: "100" },
                  { value: 200, label: "200" },
                ]}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <h1> AMOUNT </h1>
            <div className="amount-card">${selectedAmount * creditCost}</div>
          </Col>
        </Row>
        <p>Your card will be billed for the amount shown above.</p>

        <Button className="mt-5" onClick={purchaseCredits} disabled={isLoading}>
          {isLoading
            ? "Please wait..."
            : `Purchase ${selectedAmount} Credits For $${
                selectedAmount * creditCost
              }`}
        </Button>
      </Container>
    </BlockUi>
  );
};

export default CallCredit;
