import { gql } from "apollo-boost";

export const QUERY_DIRECT_CONNECTION_STATUS = gql`
  query getDirectConnectSettings($id: ID!) {
    directConnectSettings(companyId: $id) {
      linkEstablished
    }
  }
`;

export const QUERY_LIST_BRANDING_CAMPAIGNS = gql`
  query getListCommuncationCampaigns(
    $categories: [String!]
    $channel: String
    $id: ID!
    $searchTerm: String
    $sortBy: String
    $sortOrder: String
    $status: String
  ) {
    listCommunicationCampaigns(
      categories: $categories
      channel: $channel
      companyId: $id
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      status: $status
    ) {
      branding {
        approved
        approvedAt
        approvedCredits
        category
        channel
        conversionRate
        createdAt
        deliveredCount
        deliveryRate
        followUpEmailEnabled
        frequency
        id
        iterationsCount
        locked
        monthlyTargetCount
        name
        nextMailingDate
        recommendedCredits
        resumeDate
        scheduledAt
        sentCount
        status
        upcomingMailingDate
        updatedAt
        customersReached
        unapprovedAt

      }
    }
  }
`;

export const QUERY_LIST_TARGETING_CAMPAIGNS = gql`
  query getListCommuncationCampaigns(
    $categories: [String!]
    $channel: String
    $id: ID!
    $page: Int
    $rpp: Int
    $searchTerm: String
    $sortBy: String
    $sortOrder: String
    $status: String
  ) {
    listCommunicationCampaigns(
      categories: $categories
      channel: $channel
      companyId: $id
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      status: $status
    ) {
      targeted {
        approved
        approvedAt
        monthlyTargetCount
        approvedCredits
        category
        channel
        conversionRate
        createdAt
        deliveredCount
        deliveryRate
        filter {
          explanation
          id
          name
        }
        followUpEmailEnabled
        frequency
        id
        iterationsCount
        locked
        name
        nextMailingDate
        recommendedCredits
        resumeDate
        scheduledAt
        sentCount
        status
        upcomingMailingDate
        unapprovedAt
        updatedAt
      }
      totalCount
    }
  }
`;

export const QUERY_CAMPAIGN_METADATA = gql`
  query campaignMetadata(
    $companyId: ID!
    $channel: String
    $categories: [String!]
    $searchTerm: String
  ) {
    campaignMetadata(
      companyId: $companyId
      channel: $channel
      categories: $categories
      searchTerm: $searchTerm
    )
  }
`;

export const QUERY_MEMBER_INSIGHT = gql`
  query getInsight($companyId: ID!) {
    memberAggregateInsight(companyId: $companyId) {
      ltv
      recommendedCredits
    }
  }
`;

export const QUERY_DISPLAY_BRANDING = gql`
  query displayBranding($companyId: ID!) {
    company(companyId: $companyId) {
      repeatMarketingSupported
    }
  }
`;

export const QUERY_CREDIT_SUMMARY = gql`
  query companyCreditSummary($companyId: ID!) {
    companyCreditSummary(companyId: $companyId)
    campaignCreditSummary(companyId: $companyId) {
      textCreditsRequired
      emailCreditsRequired
      postcardCreditsRequired
      textCreditsAvailable
      emailCreditsAvailable
      postcardCreditsAvailable
      accountBalance
      amountDue
    }
  }
`;

export const QUERY_UPCOMING_CAMPAIGNS = gql`
  query listUpcomingCampaigns(
    $channel: String
    $companyId: ID!
    $fromDate: String!
    $page: Int
    $rpp: Int
    $searchTerm: String
    $status: [String!]
    $toDate: String!
    $sortBy: String
    $sortOrder: String
  ) {
    listUpcomingCampaigns(
      channel: $channel
      companyId: $companyId
      fromDate: $fromDate
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
      status: $status
      toDate: $toDate
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      id
      mailingDate
      communicationCampaign {
        approvedCredits
        category
        channel
        conversionRate
        createdAt
        deliveredCount
        deliveryRate
        monthlyTargetCount
        filter {
          explanation
          id
          name
        }
        followUpEmailEnabled
        frequency
        id
        name
        nextMailingDate
        recommendedCredits
        resumeDate
        scheduledAt
        status
        upcomingMailingDate
        updatedAt
      }
    }
  }
`;


