import React, { useCallback, useEffect, useState } from "react";
import { addToast } from "src/utils/ToastUtil";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient, useMutation } from "react-apollo";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import downloadIcon from "src/assets/images/common/download.svg";
import Button from "src/shared/styleguide/button/Button";
import { Col, Row } from "react-bootstrap";
import SearchComponent from "src/components/search_component/SearchComponent";
import { useHistory } from "react-router";
import { ENTERPRISE_USERS } from "src/graphql/enterprise/queries";
import { DELETE_ENTERPRISE_USER } from "src/graphql/enterprise/mutations";
import CsvUtil from "src/utils/CsvUtil";
import NoRecordFound from "../NoRecordFound";
import swal from "sweetalert";
import { rearrangedArrayData } from "src/shared/Utils";
import "./Users.scss";
import { useSelector } from "react-redux";

export const HEADER_ITEMS = [
  { columnLabel: "ID", columnValue: "id", sortable: true },
  { columnLabel: "NAME", columnValue: "name", sortable: true },
  { columnLabel: "EMAIL", columnValue: "email", sortable: true },
  { columnLabel: "ROLE", columnValue: "role", sortable: true },
  { columnLabel: "STATUS", columnValue: "status", sortable: true },
  { columnLabel: "ACTIONS", columnValue: "actions", sortable: false },
];

const Users = () => {
  const perPage = 10;
  const history = useHistory();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { userInfo } = useSelector((state) => state.user);

  const [deleteUser] = useMutation(DELETE_ENTERPRISE_USER, {
    onCompleted: async () => {
      await getEnterpriseUsers({
        page: currentPage,
      });
      addToast({
        type: "success",
        message: "User deleted successfully",
      });
    },
    onError: (err) => {
      addToast({
        type: "error",
        message:
          err && err.graphQLErrors && err.graphQLErrors[0]
            ? err.graphQLErrors[0].message
            : "Something went wrong, please try again",
      });
      setLoading(false);
    },
  });
  const [dataProvider, setDataProvider] = useState([]);

  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const editEnterpriseUser = (id) => {
    history.push(`/enterprise/users/edit-user/${id}`);
  };

  const openModal = (id) => {
    swal({
      title: "Are you sure?",
      className: "custom-deactivation-template",
      text: "This will delete your complete user with the given enterprise. And this is an irreversible action.",
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Delete",
          value: "deleted",
        },
        cancel: "No, Cancel",
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
      cancelButtonText: "No, Cancel",
    })
      .then((confirm) => {
        if (confirm === "deleted") {
          setLoading(true);
          deleteUser({
            variables: { id },
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  const getEnterpriseUsersRow = (data, props = {}) => {
    const { id, firstName, lastName, email, isActive, isManager } = data;
    return [
      {
        node: id,
      },
      {
        node: `${firstName} ${lastName}`,
      },
      {
        node: email,
      },
      {
        node: isManager ? "Manager" : "User",
      },
      {
        node: isActive ? "Active" : "Inactive",
      },
      {
        node: (
          <Row className="row-container">
            {(userInfo?.isManager || userInfo?.enterpriseUserId === id) && (
              <Col xs={6}>
                <Button
                  className="action-button"
                  variant="outline-primary"
                  onClick={() => editEnterpriseUser(id)}
                >
                  Edit
                </Button>
              </Col>
            )}
            {userInfo?.isManager && (
              <Col xs={6}>
                <Button
                  className="action-button"
                  onClick={() => openModal(id)}
                  variant="outline-primary"
                >
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        ),
      },
    ];
  };

  const getEnterpriseUsers = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
    };

    if (props.searchTerm) {
      queryVariables["searchTerm"] = props.searchTerm.toLowerCase() || "";
    }
    client
      .query({
        query: ENTERPRISE_USERS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterpriseUsers) {
          const users = res.data.enterpriseUsers.data;
          const totalRecords = res.data.enterpriseUsers.totalCount;

          //setup customers
          setDataProvider(
            users.map((data) =>
              getEnterpriseUsersRow(data, {
                ...queryVariables,
              }),
            ),
          );

          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getEnterpriseUsers({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getEnterpriseUsers({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getEnterpriseUsers(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const downloadCSV = () => {
    client
      .query({
        query: ENTERPRISE_USERS,
        fetchPolicy: NO_CACHE,
        variables: { rpp: 0 },
      })
      .then(({ data: { enterpriseUsers } }) => {
        const order = [
          "id",
          "userDisplayName",
          "email",
          "isManager",
          "isActive",
        ];

        const labels = ["ID", "NAME", "EMAIL", "ROLE", "STATUS"];

        const rearrangedArray = rearrangedArrayData(
          enterpriseUsers?.data,
          order,
        );

        CsvUtil.downloadCSVFile(rearrangedArray, labels, "Users");
      });
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-between mb-4">
          <div className="page-title">Users</div>

          <Button
            className="px-5"
            onClick={() => history.push("/enterprise/users/add-user")}
            disabled={!userInfo?.isManager}
          >
            + Add User
          </Button>
        </Col>
      </Row>

      <Card
        title={
          <Row>
            <Col className="table-heading">All Users</Col>
            <Col className="d-flex justify-content-end align-items-center">
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
              <img
                className="ml-2 cursor-pointer"
                onClick={downloadCSV}
                src={downloadIcon}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <SortableTableHeader
                sortable={values.sortable}
                columnLabel={values.columnLabel}
                columnValue={values.columnValue}
                onSort={onSort}
                currentSortValue={filters.sortBy.value}
                currentSortOrder={filters.sortOrder.value}
              />
            ))}
            body={dataProvider}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </>
  );
};

export default Users;
