import React from "react";
import { gql } from "apollo-boost";
import { Row, Col } from "react-bootstrap";
import HorizontalBarChart from "src/shared/styleguide/charts/HorizontalBarChart";
import LinearPlotChart from "src/shared/styleguide/charts/LinearPlotChart";
import DonutChart from "src/shared/styleguide/charts/DonutChart";
import DonutChartHorizontal from "src/shared/styleguide/charts/DonutChartHorizontal";
import DonutChartVertical from "src/shared/styleguide/charts/DonutChartVertical";
import GaugeChart from "src/shared/styleguide/charts/GaugeChart";
import DonutChartResponsive from "src/shared/styleguide/charts/DonutChartResponsive";
import ResponsiveDonutChart from "src/shared/styleguide/charts/ResponsiveDonutChart";
import StyleGuideSection from "src/shared/styleguide/common/StyleGuideSection";
import ColumnChart from "src/shared/styleguide/charts/ColumnChart";
import CodePreview from "src/shared/styleguide/codepreview/CodePreview";
import {
  CardChart,
  CardChartWithGraphQL,
} from "src/shared/styleguide/charts/CardChart";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const linearPlotChartData = {
  min: 20,
  max: 100,
  current: 80,
};
const HorizontalBarChartData = [
  {
    labelTop: "Item 1",
    labelRight: 200,
    barValue: (200 / 800) * 100,
    variant: "warning",
  },
  {
    labelTop: "Item 2",
    labelRight: 400,
    barValue: (400 / 800) * 100,
    variant: "danger",
  },
  {
    labelTop: "Item 3",
    labelRight: 600,
    barValue: (600 / 800) * 100,
    variant: "info",
  },
  { labelTop: "Item 4", labelRight: 800, barValue: (800 / 800) * 100 },
];
const donutChartData = {
  name: "Postcard",
  data: [
    {
      name: "Sent",
      y: (4000 / 5000) * 100,
      count: 4000,
      color: "#2A68CB",
      infoMessage: "This shows the total number of communications sent.",
    },
    {
      name: "Not Sent",
      y: (1000 / 5000) * 100,
      count: 1000,
      color: "#EF3418",
      infoMessage:
        "This shows the total number of communications that could not be sent.",
    },
  ],
  chartTitle: `${(4000 / 5000) * 100}.00%`,
};
const DonutChartHorizontalData = {
  data: [
    {
      color: "#dc571a",
      count: 10,
      followup: 0,
      name: "Postcard",
      y: 10,
    },
    {
      color: "#4970d7",
      count: 20,
      name: "Text",
      y: 20,
    },
    {
      color: "#faa628",
      count: 30,
      name: "Email",
      y: 30,
    },
  ],
  title: "anything goes",
};

export const FormStyleGuide = () => {
  useDocumentTitle("Style Guide - Cards");
  return (
    <React.Fragment>
      <h3 className="text-dark font-weight-bold">Charts</h3>
      <div>
        Charts are graphical representation of data. Data can be visualized
        using different charts to draw different insights such as bar chart,
        line chart, pie charts etc.
      </div>

      <StyleGuideSection title="Bar Chart" description="">
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <ColumnChart
                    chartId="sampleColumnChart"
                    yAxisTitle="Most preferred social media"
                    chartData={[5, 3, 4, 4]}
                    xAxisCategories={[
                      "Instagram",
                      "Snapchat",
                      "Facebook",
                      "Tiktok",
                    ]}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import ColumnChart from "src/shared/styleguide/charts/ColumnChart";

<ColumnChart
  chartId="sampleColumnChart"
  yAxisTitle="Most preferred social media"
  chartData={[5, 3, 4, 4]}
  xAxisCategories={[
    "Instagram",
    "Snapchat",
    "Facebook",
    "Tiktok"
  ]}
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Donut Chart"
        description="Donut Chart was originally built for review results, and moved into the styleguide retroactively. 
        It should be noted that this particular flavor of donut is not inherently responsive or allow chart titles.
        This donut chart does consider drilldowns though, which was omitted from Responsive Donut Chart."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <DonutChart
                    chartData={donutChartData}
                    chartId="Chart"
                    className="some-optional-class"
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    pieSize={325}
                    popupInfoMsg="Generic pop up info message."
                    subTitle="A Subtitle Goes Here"
                    title="My Simple Chart Title"
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y}%)`;
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import DonutChart from "src/shared/styleguide/charts/DonutChart";

const donutChartData = {
  name: "Postcard",
  data: [
    {
      name: "Sent",
      y: (4000 / 5000) * 100,
      count: 4000,
      color: "#2A68CB",
      infoMessage: "This shows the total number of communications sent.",
    },
    {
      name: "Not Sent",
      y: (1000 / 5000) * 100,
      count: 1000,
      color: "#EF3418",
      infoMessage:
        "This shows the total number of communications that could not be sent.",
    },
  ],
};

<DonutChart
  chartData={donutChartData}
  chartId="Chart"
  className="some-optional-class"
  labelFormatter={(ctx) => (
    // use ctx.name and ctx.count
  )}
  pieSize={325}
  popupInfoMsg="Generic pop up info message."
  subTitle="A Subtitle Goes Here"
  title="My Simple Chart Title"
  tooltipFormatter={function() {
    const { point } = this;
    return // use point.name, point.count, point.y
  }}
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Responsive Donut Chart"
        description="**DEPRECATED** 
        
        __This chart has been deprecated.  It remains in style guide because we have 
        archived views for campaign results that reference it.  When those archives are removed, we should remove
        this from the style guide and delete related componentes.__
        
        Responsive Donut Chart optionally accepts a flag, legendVerbose, to trigger a responsive mode.  When
        the parent element's width drops below 660px, the legend shifts below the donut and center aligns to more
        comfortably accomodate narrow spaces. Responsive Donut Chart accepts chartTitle through data only, which displays text inside
        the center of the donut. It should be noted that this chart does not consider drilldown options.
        
        This chart relies on it's parent to display title, subtitle, etc.
        
        Adding a tooltipClassName value requires you to add styled DOM elements to tooltip."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <ResponsiveDonutChart
                    chartId="ResponsiveDonutChart"
                    chartData={donutChartData}
                    labelFormatter={(ctx) =>
                      `${ctx?.data?.name} (${ctx?.data?.count})`
                    }
                    legendVerbose={true}
                    pieSize={325}
                    // tooltipClassName="use-to-target-tooltip-styling"
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y}%)`;
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import ResponsiveDonutChart from "src/shared/styleguide/charts/ResponsiveDonutChart";

const donutChartData = {
  name: "Postcard",
  data: [
    {
      name: "Sent",
      y: (4000 / 5000) * 100,
      count: 4000,
      color: "#2A68CB",
      infoMessage: "This shows the total number of communications sent.",
    },
    {
      name: "Not Sent",
      y: (1000 / 5000) * 100,
      count: 1000,
      color: "#EF3418",
      infoMessage:
        "This shows the total number of communications that could not be sent.",
    },
  ],
  chartTitle: "80.0%"
};

<ResponsiveDonutChart
  chartId="ResponsiveDonutChart"
  chartData={donutChartData}
  labelFormatter={(ctx) => (
    // use ctx.name and ctx.count
  )}
  legendVerbose={true}
  pieSize={325}
  // tooltipClassName="use-to-target-tooltip-styling"
  tooltipFormatter={function() {
    const { point } = this;
    return "
      // use point.name, point.count, point.y
    ";
  }}
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Donut Chart Horizontal"
        description="Donut Chart Horizontal is a modified version of MiniDonutChart 
        built in the Reviews section of the app, designed to scale.  Keep in mind it is not 
        responsive, and does not have drilldowns. It is ideal for displaying simple 
        donut charts that require either a legend to the right or no legend at all."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={3}>
                  <DonutChartHorizontal
                    chartData={DonutChartHorizontalData.data}
                    chartId="SmallDonutChartHorizontal"
                    chartTitle="999"
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendIsPaginated={false}
                    pieSize={100}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
                <Col lg={4}>
                  <DonutChartHorizontal
                    chartData={DonutChartHorizontalData.data}
                    chartId="MediumDonutChartHorizontal"
                    chartTitle="2%"
                    chartTitleFontSize={30}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendIsPaginated={false}
                    legendX={25}
                    pieSize={200}
                    pieThickness={40}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
                <Col lg={5}>
                  <DonutChartHorizontal
                    chartData={DonutChartHorizontalData.data}
                    chartId="LargeDonutChartHorizontal"
                    chartTitle={DonutChartHorizontalData.title}
                    chartTitleFontSize={40}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendIsPaginated={false}
                    legendX={40}
                    pieSize={300}
                    pieThickness={10}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import ResponsiveDonutChart from "src/shared/styleguide/charts/ResponsiveDonutChart";

const DonutChartHorizontalData = {
  data: [
    {
      color: "#dc571a",
      count: 10,
      followup: 0,
      name: "Postcard",
      y: 10,
    },
    {
      color: "#4970d7",
      count: 20,
      name: "Text",
      y: 20,
    },
    {
      color: "#faa628",
      count: 30,
      name: "Email",
      y: 30,
    },
  ],
  title: "anything goes"
};

<DonutChartHorizontal
  chartData={DonutChartHorizontalData}
  chartId="SmallDonutChartHorizontal"
  chartTitle="999"
  labelFormatter={(ctx) => 
    // use ctx.name and ctx.count
  }
  legendIsPaginated={false}
  pieSize={100}
  tooltipFormatter={function() {
    const { point } = this;
    return "
      // use point.name, point.count, point.y
    ";
  }}
/>
`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Donut Chart Vertical"
        description="Donut Chart Vertical is a modified version of DonutChartHorizontal.  Keep in mind it is not 
        It is ideal for displaying simple donut charts that require either a legend below the chart or no legend at all."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={2}>
                  <DonutChartVertical
                    chartData={[
                      ...DonutChartHorizontalData.data,
                      ...DonutChartHorizontalData.data,
                    ]}
                    chartId="LargeDonutChartVertical"
                    chartTitle={"40.9%"}
                    chartTitleFontSize={12}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendFontSize={15}
                    legendItemMargin={20}
                    legendX={10}
                    legendY={20}
                    pieSize={64}
                    pieThickness={10}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
                <Col lg={4}>
                  <DonutChartVertical
                    chartData={DonutChartHorizontalData.data}
                    chartId="LargeDonutChartVertical"
                    chartTitle={"1.9%"}
                    chartTitleFontSize={32}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendFontSize={18}
                    legendItemMargin={12}
                    legendX={10}
                    legendY={20}
                    pieSize={200}
                    pieThickness={30}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <DonutChartVertical
                    chartData={[
                      ...DonutChartHorizontalData.data,
                      ...DonutChartHorizontalData.data,
                    ]}
                    chartId="LargeDonutChartVertical"
                    chartTitle={DonutChartHorizontalData.title}
                    chartTitleFontSize={40}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendFontSize={15}
                    legendX={16}
                    legendY={20}
                    pieSize={300}
                    pieThickness={4}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import ResponsiveDonutChart from "src/shared/styleguide/charts/ResponsiveDonutChart";

const DonutChartHorizontalData = {
  data: [
    {
      color: "#dc571a",
      count: 10,
      followup: 0,
      name: "Postcard",
      y: 10,
    },
    {
      color: "#4970d7",
      count: 20,
      name: "Text",
      y: 20,
    },
    {
      color: "#faa628",
      count: 30,
      name: "Email",
      y: 30,
    },
  ],
  title: "anything goes"
};

<DonutChartVertical
  chartData={DonutChartHorizontalData.data}
  chartId="LargeDonutChartVertical"
  chartTitle={"1.9%"}
  chartTitleFontSize={32}
  labelFormatter={(ctx) => 
    // use ctx.name and ctx.count
  }legendFontSize={18}
  legendIsPaginated={false}
  legendItemMargin={12}
  legendX={10}
  legendY={20}
  pieSize={200}
  pieThickness={30}
  tooltipFormatter={function() {
    const { point } = this;
    return "
      // use point.name, point.count, point.y
    ";
  }}
/>
`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Donut Chart Responsive"
        description="Donut Chart Responsive was built to replace Responsive Donut Chart because of the latter's
        poor title centering logic.  It takes the core of the improved centering logics in Donut Chart Horizontal
        and Donut Chart Vertical, and toggles that logic via the Highcharts responsive chart config variable.
        Additional parameters have been added for improved flexibility."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={7}>
                  <DonutChartResponsive
                    chartData={DonutChartHorizontalData.data}
                    chartId="LargeDonutChartVertical"
                    chartTitle={DonutChartHorizontalData.title}
                    chartTitleFontSize={22}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendFontSize={18}
                    legendIsPaginated={true}
                    legendItemMargin={12}
                    legendX={20}
                    legendY={20}
                    pieSize={230}
                    pieThickness={30}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
                <Col lg={5}>
                  <DonutChartResponsive
                    breakPoint={700}
                    chartData={DonutChartHorizontalData.data}
                    chartId="LargeDonutChartVertical"
                    chartTitle={DonutChartHorizontalData.title}
                    chartTitleFontSize={22}
                    labelFormatter={(ctx) => `${ctx.name} (${ctx.count})`}
                    legendFontSize={18}
                    legendIsPaginated={true}
                    legendItemMargin={12}
                    legendX={20}
                    legendY={20}
                    pieSize={230}
                    pieThickness={30}
                    tooltipFormatter={function () {
                      const { point } = this;
                      return `${point.name}: ${point.count} (${point.y})`;
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import ResponsiveDonutChart from "src/shared/styleguide/charts/ResponsiveDonutChart";

const DonutChartHorizontalData = {
  data: [
    {
      color: "#dc571a",
      count: 10,
      followup: 0,
      name: "Postcard",
      y: 10,
    },
    {
      color: "#4970d7",
      count: 20,
      name: "Text",
      y: 20,
    },
    {
      color: "#faa628",
      count: 30,
      name: "Email",
      y: 30,
    },
  ],
  title: "anything goes"
};

<DonutChartVertical
  chartData={DonutChartHorizontalData.data}
  chartId="LargeDonutChartVertical"
  chartTitle={DonutChartHorizontalData.title}
  chartTitleFontSize={22}
  labelFormatter={(ctx) => 
    // use ctx.name and ctx.count
  }legendFontSize={18}
  legendFontSize={18}
  legendIsPaginated={true}
  legendItemMargin={12}
  legendX={20}
  legendY={20}
  pieSize={230}
  pieThickness={30}
  tooltipFormatter={function() {
    const { point } = this;
    return "
      // use point.name, point.count, point.y
    ";
  }}
/>
`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Horizontal Bar Chart"
        description="Horizontal bar charts utilize Bootstrap progress bars to represent
        percentages of values. Traditional Bootstrap variables ('success', 'danger', 'warning', 'info') can be used to set bar
        color. If no color value, or an invalid color value, is provided, we use our
        $sg-chart-progress-bar-bg color variable."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <HorizontalBarChart
                    chartId="sampleHorizontalBarChart"
                    chartData={HorizontalBarChartData}
                    className="some-optional-class"
                    popupInfoMsg="Generic pop up info message."
                    subTitle="A Subtitle Goes Here"
                    title="My Simple Chart Title"
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import HorizontalBarChart from "src/shared/styleguide/charts/HorizontalBarChart";

const HorizontalBarChartData = [
  { labelTop: "Item 1", labelRight: 200, barValue: (200/800 * 100), variant: "warning" },
  { labelTop: "Item 2", labelRight: 400, barValue: (400/800 * 100), variant: "danger" },
  { labelTop: "Item 3", labelRight: 600, barValue: (600/800 * 100), variant: "info" },
  { labelTop: "Item 4", labelRight: 800, barValue: (800/800 * 100) },
];

<HorizontalBarChart
  chartId="sampleHorizontalBarChart"
  chartData={HorizontalBarChartData}
  className="some-optional-class"
  popupInfoMsg="Generic pop up info message."
  subTitle="A Subtitle Goes Here"
  title="My Simple Chart Title"
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection
        title="Linear Plot Chart"
        description="Linear Plot Chart depends on rc-slider, which is built as an input.  Through styling and intentionally omitting an onChange function, we've produced a chart that accepts three `chartData` values, `min`, `max`, and `current`.  Current displays with an always-on tooltip."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={8}>
                  <LinearPlotChart
                    chartData={linearPlotChartData}
                    chartId="LinearPlotChartExample"
                    title="RANGE FOR YOUR INDUSTRY"
                    description="Lifetime value of a customer increases as customers come back and do
                    multiple transactions."
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import LinearPlotChart from "src/shared/styleguide/charts/LinearPlotChart";

const linearPlotChartData = {
  min: 20,
  max: 100,
  current: 80,
};

<LinearPlotChart
  chartData={linearPlotChartData}
  chartId="LinearPlotChartExample"
  title="RANGE FOR YOUR INDUSTRY"
  description="Lifetime value of a customer increases as customers come back and do multiple transactions."
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection title="Chart inside a card" description="">
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <CardChart
                    popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
                    title="Overall Review Performance"
                    chartId="exampleLineChart"
                    chartConfig={{
                      credits: {
                        enabled: false,
                      },
                      title: null,
                      xAxis: {
                        categories: [],
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: null,
                        },
                      },
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        backgroundColor: "transparent",
                        borderWidth: 0,
                        shadow: false,
                      },
                      exporting: {
                        enabled: false,
                      },
                      plotOptions: {
                        column: {
                          stacking: "normal",
                          dataLabels: {
                            enabled: false,
                          },
                        },
                      },
                      tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormat:
                          "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
                      },
                      series: [
                        {
                          type: "line",
                          name: "Avg. Rating",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 50)),
                          color: "#5cb85c",
                          showInLegend: true,
                        },
                        {
                          type: "column",
                          name: "Positive reviews",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 20)),
                          color: "#dc5700",
                          showInLegend: true,
                        },
                        {
                          type: "column",
                          name: "Negative reviews",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 20)),
                          color: "#5d7ae1",
                          showInLegend: true,
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import { CardChart } from "src/shared/styleguide/charts/CardChart";

<CardChart
  popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
  title="Overall Review Performance"
  chartId="exampleLineChart"
  chartConfig={{
    credits: {
      enabled: false
    },
    title: null,
    xAxis: {
      categories: []
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false
    },
    exporting: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat:
        "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    series: [
      {
        type: "line",
        name: "Avg. Rating",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 50)),
        color: "#5cb85c",
        showInLegend: true
      },
      {
        type: "column",
        name: "Positive reviews",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 20)),
        color: "#dc5700",
        showInLegend: true
      },
      {
        type: "column",
        name: "Negative reviews",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 20)),
        color: "#5d7ae1",
        showInLegend: true
      }
    ]
  }}
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection title="Chart with no data" description="">
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <CardChart
                    popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
                    title="Overall Review Performance"
                    chartId="exampleLineChart"
                    noData
                    chartConfig={{
                      credits: {
                        enabled: false,
                      },
                      title: null,
                      xAxis: {
                        categories: [],
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: null,
                        },
                      },
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        backgroundColor: "transparent",
                        borderWidth: 0,
                        shadow: false,
                      },
                      exporting: {
                        enabled: false,
                      },
                      plotOptions: {
                        column: {
                          stacking: "normal",
                          dataLabels: {
                            enabled: false,
                          },
                        },
                      },
                      tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormat:
                          "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
                      },
                      series: [
                        {
                          type: "line",
                          name: "Avg. Rating",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 50)),
                          color: "#5cb85c",
                          showInLegend: true,
                        },
                        {
                          type: "column",
                          name: "Positive reviews",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 20)),
                          color: "#dc5700",
                          showInLegend: true,
                        },
                        {
                          type: "column",
                          name: "Negative reviews",
                          data: Array(10)
                            .fill()
                            .map(() => Math.round(Math.random() * 20)),
                          color: "#5d7ae1",
                          showInLegend: true,
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import { CardChart } from "src/shared/styleguide/charts/CardChart";

<CardChart
  popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
  title="Overall Review Performance"
  chartId="exampleLineChart"
  noData
  chartConfig={{
    credits: {
      enabled: false
    },
    title: null,
    xAxis: {
      categories: []
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false
    },
    exporting: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat:
        "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
    },
    series: [
      {
        type: "line",
        name: "Avg. Rating",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 50)),
        color: "#5cb85c",
        showInLegend: true
      },
      {
        type: "column",
        name: "Positive reviews",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 20)),
        color: "#dc5700",
        showInLegend: true
      },
      {
        type: "column",
        name: "Negative reviews",
        data: Array(10)
          .fill()
          .map(() => Math.round(Math.random() * 20)),
        color: "#5d7ae1",
        showInLegend: true
      }
    ]
  }}
/>`}
        />
      </StyleGuideSection>
      <StyleGuideSection
        title="GaugeChart"
        description="This chart is designed to resemble a classic odometer. The component accepts basic params directy, and can broadly accept advanced configuration via props."
      >
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={8}>
                  <GaugeChart
                    backgroundColor="rgba(126, 134, 157, 0.05)"
                    chartId="exampleGaugeChart"
                    title="RANGE FOR YOUR INDUSTRY"
                    chartData={{
                      dialPosition: 27,
                      tickPositions: [
                        {
                          value: 58,
                          description:
                            "(Medium, this is the middle range for your industry)",
                        },
                        {
                          value: 74,
                          description:
                            "(Highest, this is the upper range for your industry)",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import GaugeChart from "src/shared/styleguide/charts/GaugeChart";

<GaugeChart
  backgroundColor="rgba(126, 134, 157, 0.05)"
  chartId="exampleGaugeChart"
  title="RANGE FOR YOUR INDUSTRY"
  chartData={{
    dialPosition: 27,
    tickPositions: [
      {
        value: 58,
        description:
          "(Medium, this is the middle range for your industry)",
      },
      {
        value: 74,
        description:
          "(Highest, this is the upper range for your industry)",
      },
    ],
  }}
/>`}
        />
      </StyleGuideSection>

      <StyleGuideSection title="Chart with graphql integration" description="">
        <CodePreview
          elements={
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <CardChartWithGraphQL
                    popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
                    title="Overall Review Performance"
                    chartId="exampleLineChart"
                    gqlConfig={{
                      query: gql`
                        query sentimentScore {
                          reviewCounts(companyId: 46279, days: 1590) {
                            allReviews
                            negative
                            positive
                          }
                        }
                      `,
                      hasData: (data) =>
                        !!data &&
                        Object.prototype.hasOwnProperty.call(
                          data,
                          "reviewCounts",
                        ),
                      getSeries: (data) => ({
                        type: "line",
                        name: "Line series",
                        data: [
                          data.reviewCounts.allReviews,
                          data.reviewCounts.negative,
                          data.reviewCounts.positive,
                        ],
                        color: "#ff0",
                        showInLegend: true,
                      }),
                    }}
                    chartConfig={{
                      credits: {
                        enabled: false,
                      },
                      title: null,
                      xAxis: {
                        categories: [],
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: null,
                        },
                      },
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        backgroundColor: "transparent",
                        borderWidth: 0,
                        shadow: false,
                      },
                      exporting: {
                        enabled: false,
                      },
                      series: [{}],
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
          code={`import { CardChartWithGraphQL } from "src/shared/styleguide/charts/CardChart";

<CardChartWithGraphQL
  popupInfoMsg="This shows the number of positive &amp; negative reviews for the given date range, with an average rating trend line."
  title="Overall Review Performance"
  chartId="exampleLineChart"
  gqlConfig={{
    query: gql\`
      query sentimentScore {
        reviewCounts(companyId: 2163, days: 1590) {
          allReviews
          negative
          positive
        }
      }
    \`,
    hasData: data =>
      !!data &&
      Object.prototype.hasOwnProperty.call(
        data,
        "reviewCounts"
      ),
    getSeries: data => ({
      type: "line",
      name: "Line series",
      data: [
        data.reviewCounts.allReviews,
        data.reviewCounts.negative,
        data.reviewCounts.positive
      ],
      color: "#ff0",
      showInLegend: true
    })
  }}
  chartConfig={{
    credits: {
      enabled: false
    },
    title: null,
    xAxis: {
      categories: []
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false
    },
    exporting: {
      enabled: false
    },
    series: [{}]
  }}
/>`}
        />
      </StyleGuideSection>
    </React.Fragment>
  );
};

export default FormStyleGuide;
