import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient } from "react-apollo";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import downloadIcon from "src/assets/images/common/download.svg";
import recommendedIcon from "src/assets/images/enterprise/recommended.svg";
import questionIcon from "src/assets/images/enterprise/question-mark.svg";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import { ENTERPRISE_BRANDING_BUDGET } from "src/graphql/enterprise/queries";
import SearchComponent from "src/components/search_component/SearchComponent";
import { Col, Row } from "react-bootstrap";
import CsvUtil from "src/utils/CsvUtil";
import NoRecordFound from "./NoRecordFound";
import NumberUtil from "src/utils/NumberUtil";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { rearrangedArrayData } from "src/shared/Utils";

export const HEADER_ITEMS = [
  { columnLabel: "ACCOUNT NAME", columnValue: "company_name", sortable: true },
  { columnLabel: "CITY", columnValue: "city", sortable: true },
  { columnLabel: "STATE", columnValue: "state", sortable: true },
  { columnLabel: "ZIP", columnValue: "zip", sortable: true },
  { columnLabel: "APPROVED", columnValue: "approved_credits", sortable: true },
  {
    columnLabel: "RECOMMENDED",
    columnValue: "recommended_credits",
    sortable: true,
  },
];

const BrandingBudget = () => {
  useDocumentTitle("Branding Budget");
  const perPage = 10;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const [dataProvider, setDataProvider] = useState([]);

  const getBrandingBudgetRow = (data, props = {}) => {
    const {
      companyName,
      approvedCredits,
      city,
      state,
      zip,
      recommendedCredits,
    } = data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span>{companyName}</span>
          </div>
        ),
      },
      {
        node: city,
      },
      {
        node: state,
      },
      {
        node: zip,
      },
      {
        node: (
          <span
            className={`text-${
              recommendedCredits > approvedCredits ? "danger" : "success"
            }`}
          >
            {approvedCredits ? (
              NumberUtil.toHumanReadableNumber(approvedCredits)
            ) : (
              <PopupInfo
                placement="right"
                message="Approved budget not setup yet"
                icon={
                  <span>
                    <img src={questionIcon} height="24px" />
                  </span>
                }
              />
            )}
          </span>
        ),
      },
      {
        node: (
          <span className="text-success">
            {recommendedCredits && approvedCredits >= recommendedCredits ? (
              <PopupInfo
                message="Recommended is not shown as you have used 100% for it."
                icon={
                  <span>
                    <img src={recommendedIcon} />
                  </span>
                }
              />
            ) : recommendedCredits ? (
              NumberUtil.toHumanReadableNumber(recommendedCredits)
            ) : (
              <PopupInfo
                placement="bottom"
                message="Recommended budget not setup yet"
                icon={
                  <span>
                    <img src={questionIcon} height="24px" />
                  </span>
                }
              />
            )}
          </span>
        ),
      },
    ];
  };

  const getBrandingBudgetData = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
    };

    if (props.searchTerm || filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm || props.searchTerm.toLowerCase() || "";
    }
    client
      .query({
        query: ENTERPRISE_BRANDING_BUDGET,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterpriseBrandingBudget) {
          const brandingBudget = res.data.enterpriseBrandingBudget.data;
          const totalRecords = res.data.enterpriseBrandingBudget.totalCount;

          //setup customers
          setDataProvider(
            brandingBudget.map((data) =>
              getBrandingBudgetRow(data, {
                ...queryVariables,
              }),
            ),
          );

          //   setCustomerEmptyState(brandingBudget.length === 0);
          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getBrandingBudgetData({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getBrandingBudgetData({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy, filters.filterByType]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getBrandingBudgetData(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const downloadCSV = () => {
    const queryVariables = {
      rpp: 0,
      sortBy: filters.sortBy ? filters.sortBy.value : undefined,
      sortOrder: filters.sortOrder ? filters.sortOrder.value : undefined,
    };

    if (filters.searchTerm) {
      queryVariables["searchTerm"] = filters.searchTerm.toLowerCase() || "";
    }
    client
      .query({
        query: ENTERPRISE_BRANDING_BUDGET,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then(({ data: { enterpriseBrandingBudget } }) => {
        const order = [
          "companyName",
          "city",
          "state",
          "zip",
          "approvedCredits",
          "recommendedCredits",
        ];

        const labels = [
          "ACCOUNT NAME",
          "CITY",
          "STATE",
          "ZIP",
          "APPROVED",
          "RECOMMENDED",
        ];

        const rearrangedArray = rearrangedArrayData(
          enterpriseBrandingBudget?.data,
          order,
        );

        CsvUtil.downloadCSVFile(rearrangedArray, labels, "Branding Budget");
      });
  };

  return (
    <>
      <div className="page-title mb-4">Branding Budget</div>

      <Card
        title={
          <Row>
            <Col className="table-heading">Branding Budget Updates</Col>
            <Col className="d-flex justify-content-end align-items-center">
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
              <img
                className="ml-2 cursor-pointer"
                onClick={downloadCSV}
                src={downloadIcon}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <SortableTableHeader
                sortable={values.sortable}
                columnLabel={values.columnLabel}
                columnValue={values.columnValue}
                onSort={onSort}
                currentSortValue={filters.sortBy.value}
                currentSortOrder={filters.sortOrder.value}
              />
            ))}
            body={dataProvider}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </>
  );
};

export default BrandingBudget;
