import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "src/img/cl-logo.png";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { removeCookies } from "src/shared/SessionHelper";
import { constants } from "src/shared/Constants";
import { ToastContainer } from "react-toastify";
import Sidebar from "./Sidebar";
import BrandingBudget from "./BrandingBudget";
import Locations from "./locations/Locations";
import KeyMetrics from "./KeyMetrics";
import EnterpriseUser from "./enterprise_user";
import user from "src/assets/images/company_users/add_new_user.png";
import CampaignUsageReport from "./campaign_usage/CampaignUsageReport";
import EnterpriseSettings from "./settings/Enterprise";
import AddLocation from "./locations/AddLocation";
import { logout } from "src/redux/userSlice";
import "./index.scss";

const Enterprise = ({ history }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, userInfo } = useSelector((state) => state.user);
  if (!isAuthenticated) {
    removeCookies();
    sessionStorage.clear();
    history.push(`/enterprise/login`);
    return null;
  }

  if (userInfo?.expired) {
    return null;
  }
  return (
    <Container fluid>
      {/* Sidebar */}
      <Row>
        <Col md={2} className="navbar-container text-white vh-100 p-0">
          <Sidebar />
        </Col>

        {/* Main Content */}
        <Col md={10} className="enterprise-content-area vh-100 overflow-auto">
          {/* Top Profile Bar */}
          <Row className="bottom-shadow py-3">
            <Col className="d-flex justify-content-between align-items-center">
              <div>
                {/* <img
                  src={logo}
                  height="25"
                  alt="logo"
                  loading="lazy"
                /> */}
              </div>
              <Dropdown>
                <Dropdown.Toggle as="div" className="cursor-pointer">
                  <img
                    src={user}
                    className="rounded-circle mr-2"
                    height="25"
                    alt="profile pic"
                    loading="lazy"
                  />
                  {userInfo?.firstName || ""} {userInfo?.lastName || ""}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={async () => {
                      await dispatch(logout());
                      history.push("/enterprise/login");
                    }}
                  >
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-5 pl-5 pr-5">
              <Switch>
                <Redirect
                  exact
                  from="/enterprise"
                  to="/enterprise/key-metrics"
                />
                {/* <Route exact path="/enterprise" component={KeyMetrics} /> */}
                <Route
                  exact
                  path={`/enterprise/key-metrics`}
                  component={KeyMetrics}
                />
                <Route path={`/enterprise/users`} component={EnterpriseUser} />
                <Route path={`/enterprise/locations`} component={Locations} />
                <Route
                  path={`/enterprise/add-location`}
                  component={AddLocation}
                />
                <Route
                  path={`/enterprise/branding-budget`}
                  component={BrandingBudget}
                />
                <Route
                  path={`/enterprise/campaign-usage-report`}
                  component={CampaignUsageReport}
                />
                <Route
                  path={`/enterprise/settings`}
                  component={EnterpriseSettings}
                />
              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
    </Container>
  );
};

export default Enterprise;
