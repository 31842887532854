import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient, useQuery } from "react-apollo";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import { Row, Col } from "react-bootstrap";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "src/shared/styleguide/button/Button";
import SearchComponent from "src/components/search_component/SearchComponent";
import NoRecordFound from "src/components/enterprise/NoRecordFound.jsx";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { PARTNER_COMPANIES_LIST } from "src/graphql/partner/queries";
import "src/components/enterprise/KeyMetrics.scss";

export const HEADER_ITEMS = [
  {
    columnLabel: "Name",
    columnValue: "company_name",
    sortable: true,
    width: 350,
  },
  {
    columnLabel: "Address",
    columnValue: "address1",
    sortable: true,
  },
  {
    columnLabel: "City",
    columnValue: "city",
    sortable: true,
  },
  {
    columnLabel: "State",
    columnValue: "state",
    sortable: true,
  },
  {
    columnLabel: "Zip",
    columnValue: "zip",
    sortable: true,
  },
  {
    columnLabel: "Account Type",
    columnValue: "account_class",
    sortable: true,
  },
  {
    columnLabel: "Actions",
    columnValue: "",
    sortable: false,
  },
];

const Companies = () => {
  useDocumentTitle("Companies");
  const perPage = 10;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataProvider, setDataProvider] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const [companyStatus, setCompanyStatus] = useState("active");
  const companyTypes = [
    { name: "ACTIVE", value: "active" },
    { name: "INACTIVE", value: "inactive" },
  ];

  const getPostcardCampaignRow = (data) => {
    const { companyName, address1, city, state, zip, accountClass, companyId } =
      data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span className="text-primary">{companyName}</span>
          </div>
        ),
      },
      {
        node: address1,
      },
      {
        node: city,
      },
      {
        node: state,
      },
      {
        node: zip,
      },
      {
        node: <span className="text-capitalize">{accountClass}</span>,
      },
      {
        node: (
          <a
            href={`/companies/${companyId}/dashboard`}
            target="_blank"
            className="btn btn-primary"
          >
            Manage
          </a>
        ),
      },
    ];
  };

  const getCompaniesListsData = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      filter: props.filter || companyStatus,
      partnerAccount: true,
    };

    if (props.searchTerm || filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm || props.searchTerm.toLowerCase() || "";
    }

    client
      .query({
        query: PARTNER_COMPANIES_LIST,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listCompanies) {
          const companies = res.data.listCompanies.data;
          const totalRecords = res.data.listCompanies.totalCount;
          setDataProvider(companies);

          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getCompaniesListsData({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getCompaniesListsData({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy, companyStatus]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getCompaniesListsData(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      filter: companyStatus,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  return (
    <BlockUi>
      <div className="page-title mb-5">Companies</div>

      <Card
        title={
          <Row>
            <Col className="d-flex justify-content-end align-items-center key-metrics-filter">
              <ToggleButtonGroup
                type="radio"
                name="companyStatus"
                defaultValue="all"
                className="mr-2"
              >
                {companyTypes.map((radio, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      id={`radio-${index}`}
                      type="radio"
                      variant={
                        radio.value == companyStatus ? "primary" : "secondary"
                      }
                      name="radio"
                      value={radio.value}
                      checked={companyStatus === radio.value}
                      onChange={(e) => setCompanyStatus(e.currentTarget.value)}
                      className="cursor-pointer"
                    >
                      {radio.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <div style={{ minWidth: values?.width || "auto" }}>
                <SortableTableHeader
                  sortable={values.sortable}
                  columnLabel={values.columnLabel}
                  columnValue={values.columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />
              </div>
            ))}
            body={dataProvider.map((data) => getPostcardCampaignRow(data))}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </BlockUi>
  );
};

export default Companies;
