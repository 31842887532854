import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: false,
  message: "",
  type: "success",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToastMessage: (state, { payload }) => {
      state.showToast = true;
      state.message = payload.message;
      state.type = payload.type;
    },
    dismissToast: (state) => {
      state.showToast = false;
    },
  },
});

export const { setToastMessage, dismissToast } = toastSlice.actions;
export default toastSlice.reducer;
