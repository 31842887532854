import React from "react";

const BillingCardStatus = ({ card }) => {
  const cardStatus = card && card.status ? card.status : "";
  if (cardStatus === "current") {
    return (
      <div className="sg-text-success">
        <span style={{ position: "relative", top: -1 }} className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 18 18"
          >
            <g className="cls-1">
              <circle
                fill="currentColor"
                className="cls-3"
                cx="9"
                cy="9"
                r="9"
              />
              <circle fill="#fff" className="cls-4" cx="9" cy="9" r="8.25" />
            </g>
            <path
              fill="currentColor"
              d="M.108,6.961a.348.348,0,0,1,0-.5l.5-.5a.348.348,0,0,1,.5,0l.036.036L3.133,8.114a.174.174,0,0,0,.252,0L8.211,3.108h.036a.348.348,0,0,1,.5,0l.5.5a.348.348,0,0,1,0,.5h0L3.493,10.094a.348.348,0,0,1-.5,0L.18,7.069.108,6.961Z"
              transform="translate(4.5 2.25)"
            />
          </svg>
        </span>
        Your card is up to date
      </div>
    );
  }
  if (cardStatus === "hold") {
    return (
      <div className="sg-text-danger">
        <span style={{ position: "relative", top: -1 }} className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 18 18"
          >
            <g>
              <g>
                <circle fill="currentColor" cx="9" cy="9" r="9" />
                <circle fill="#fff" cx="9" cy="9" r="8" />
              </g>
              <g transform="translate(8.205 3.664)">
                <path
                  fill="currentColor"
                  d="M224.216,179.453V184.8a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032v-5.351a1.032,1.032,0,1,0-2.063,0Z"
                  transform="translate(-224.216 -178.421)"
                />
                <path
                  fill="currentColor"
                  d="M225.248,360.921a1.034,1.034,0,0,0-1.032,1.032h0a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032h0A1.041,1.041,0,0,0,225.248,360.921Z"
                  transform="translate(-224.216 -351.912)"
                />
              </g>
            </g>
          </svg>
        </span>
        Your card is on hold
      </div>
    );
  }
  if (cardStatus === "canceled") {
    return (
      <div className="sg-text-danger">
        <span style={{ position: "relative", top: -1 }} className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 18 18"
          >
            <g>
              <g>
                <circle fill="currentColor" cx="9" cy="9" r="9" />
                <circle fill="#fff" cx="9" cy="9" r="8" />
              </g>
              <g transform="translate(8.205 3.664)">
                <path
                  fill="currentColor"
                  d="M224.216,179.453V184.8a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032v-5.351a1.032,1.032,0,1,0-2.063,0Z"
                  transform="translate(-224.216 -178.421)"
                />
                <path
                  fill="currentColor"
                  d="M225.248,360.921a1.034,1.034,0,0,0-1.032,1.032h0a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032h0A1.041,1.041,0,0,0,225.248,360.921Z"
                  transform="translate(-224.216 -351.912)"
                />
              </g>
            </g>
          </svg>
        </span>
        Your card is cancelled
      </div>
    );
  }
  if (cardStatus === "") {
    return (
      <div className="sg-text-danger">
        <span style={{ position: "relative", top: -1 }} className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 18 18"
          >
            <g>
              <g>
                <circle fill="currentColor" cx="9" cy="9" r="9" />
                <circle fill="#fff" cx="9" cy="9" r="8" />
              </g>
              <g transform="translate(8.205 3.664)">
                <path
                  fill="currentColor"
                  d="M224.216,179.453V184.8a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032v-5.351a1.032,1.032,0,1,0-2.063,0Z"
                  transform="translate(-224.216 -178.421)"
                />
                <path
                  fill="currentColor"
                  d="M225.248,360.921a1.034,1.034,0,0,0-1.032,1.032h0a1.034,1.034,0,0,0,1.032,1.032,1.071,1.071,0,0,0,1.032-1.032h0A1.041,1.041,0,0,0,225.248,360.921Z"
                  transform="translate(-224.216 -351.912)"
                />
              </g>
            </g>
          </svg>
        </span>
        Your card status is not clear
      </div>
    );
  }
  return null;
};

export default BillingCardStatus;
