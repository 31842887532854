import { gql } from "apollo-boost";

export const PARTNER_USER_INFO = gql`
  query ($partnerUserId: ID!, $companyId: ID) {
    getPartnerUser(partnerUserId: $partnerUserId, companyId: $companyId) {
      id
      companyUserId
      email
      firstName
      isActive
      isOwner
      lastName
      partnerAccountId
      userDisplayName
    }
  }
`;

export const GET_PARTNER_DETAILS = gql`
  query partner {
    partnerAccount {
      address1
      address2
      city
      companyId
      country
      internalId
      isActive
      partnerAccountId
      partnerAccountName
      partnerId
      phone
      state
      website
      zip
    }
  }
`;

export const PARTNER_COMPANIES_LIST = gql`
  query listCompanies(
    $sortBy: String
    $rpp: Int
    $page: Int
    $sortOrder: String
    $searchTerm: String
    $filter: String
    $partnerAccount: Boolean
  ) {
    listCompanies(
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
      filter: $filter
      partnerAccount: $partnerAccount
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        address1
        address2
        city
        companyId
        companyName
        accountClass
        id
        partnerCompanyId
        state
        website
        zip
      }
      totalCount
    }
  }
`;
export const LIST_PARTNER_USERS = gql`
  query listUsers(
    $sortBy: String
    $rpp: Int
    $page: Int
    $sortOrder: String
    $searchTerm: String
  ) {
    listPartnerUsers(
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        companyUserId
        email
        firstName
        id
        isActive
        isOwner
        lastName
        partnerAccountId
        userDisplayName
      }
      totalCount
    }
  }
`;

export const PARTNER_RESULTS_QUERY = gql`
  query {
    partnerResults {
      activeLocationsCount
      appointmentsCount
      averageReadingTime
      customersCount
      customersLast12Count
      distributionByMedium
      distributionByRating
      distributionBySource
      distributionBySyndicationType
      recentReviewList
      id
      inactiveLocationsCount
      negativeReviewsCount
      partnerAccountId
      partnerId
      positiveReviewsCount
      publishedReviewsCount
      sentimentScore
    }
  }
`;
