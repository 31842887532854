import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { removeCookies } from "src/shared/SessionHelper";
import { constants } from "src/shared/Constants";
import { ToastContainer } from "react-toastify";
import user from "src/assets/images/company_users/add_new_user.png";
import logoutIcon from "src/assets/images/header/logout-1.png";
import { logout } from "src/redux/userSlice";
import Companies from "./Companies";
import Results from "./Results";
import Sidebar from "./Sidebar";
import Terms from "./Terms";
import EditPartner from "./EditPartner";
import Users from "./Users";
import "src/components/enterprise/index.scss";

const Partner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, userInfo } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    removeCookies();
    sessionStorage.clear();
    history.push(`/partner/login`);
    return null;
  }

  if (userInfo?.expired) {
    return null;
  }
  if (!userInfo?.partnerUserId) {
    return <p>No Page Found</p>;
  }
  return (
    <Container fluid>
      {/* Sidebar */}
      <Row>
        <Col md={2} className="navbar-container text-white vh-100 p-0">
          <Sidebar />
        </Col>

        {/* Main Content */}
        <Col md={10} className="enterprise-content-area vh-100 overflow-auto">
          {/* Top Profile Bar */}
          <Row className="bottom-shadow py-3">
            <Col className="d-flex justify-content-between align-items-center">
              <div></div>
              <Dropdown>
                <Dropdown.Toggle as="div" className="cursor-pointer">
                  <img
                    src={user}
                    className="rounded-circle mr-2"
                    height="25"
                    alt="profile pic"
                    loading="lazy"
                  />
                  {userInfo?.firstName || ""} {userInfo?.lastName || ""}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={async () => {
                      await dispatch(logout());
                      history.push("/partner/login");
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img src={logoutIcon} /> &nbsp; Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-5 pl-5 pr-5">
              <Switch>
                <Redirect exact from="/partner" to="/partner/results" />
                <Route exact path={`/partner/results`} component={Results} />
                <Route path={`/partner/companies`} component={Companies} />
                <Route path={`/partner/edit`} component={EditPartner} />
                <Route path={`/partner/users`} component={Users} />
                <Route path={`/partner/terms`} component={Terms} />
              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer autoClose={constants.TOASTER_TIMER} limit={4} />
    </Container>
  );
};

export default Partner;
