import React, { useEffect, useRef } from "react";
import "src/components/settings/widgets/review_widgets/StaticButtonsBody.scss";
import LightBlueCard from "src/components/settings/widgets/review_widgets/LightBlueCard.jsx";
import {
  copyDynamicButtonOne,
  copyReviewWidgetToClipboard,
} from "src/utils/CopyToClipboardUtil.js";
import axios from "axios";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const DynamicButtonsBody = ({ handleToast, widgetSettings }) => {
  const companyId = useGetCompanyIdFromUrl();
  const dynamicBtnEl = useRef(null);
  const dynamicReviewsEl = useRef(null);
  const [copiedOne, setCopiedOne] = React.useState(false);
  const [copiedTwo, setCopiedTwo] = React.useState(false);

  const handleCompanyName = () => {
    if (widgetSettings.data && !widgetSettings.loading) {
      return widgetSettings.data.company.companyName;
    }

    return "";
  };

  const handleDynamicButtonClick = (callback) => {
    handleToast();
    callback({
      companyId,
      abbreviatedName: widgetSettings.data.company.abbreviatedName,
      companyName: widgetSettings.data.company.companyName,
    });
    setCopiedOne(true);
    setTimeout(() => setCopiedOne(false), 3000);
  };

  const handleAbbreviatedName = () => {
    if (
      !widgetSettings.loading &&
      widgetSettings.data &&
      widgetSettings.data.company
    ) {
      return widgetSettings.data.company.abbreviatedName;
    }

    return "";
  };

  const handleDynamicReviewButtonClick = (callback) => {
    if (
      !widgetSettings.loading &&
      widgetSettings.data &&
      widgetSettings.data.companySettings
    ) {
      const { reviewCount, widgetWidth, widgetColor } =
        widgetSettings.data.companySettings;

      handleToast();
      callback({
        companyId,
        widgetColor,
        abbreviatedName: handleAbbreviatedName(),
        widgetWidth,
        reviewCount,
      });
      setCopiedTwo(true);
      setTimeout(() => setCopiedTwo(false), 3000);
    }
  };

  const dynamicBtnHelper = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_SECURE_URL}/widgets/widget-view/?company_id=${companyId}&widget_type=dynamic_reviews_button`,
      );

      if (dynamicBtnEl && res.data) {
        dynamicBtnEl.current.innerHTML = res.data;
        let dynamicBtnChild = dynamicBtnEl.current.children[0];
        dynamicBtnChild.removeAttribute("href");
        dynamicBtnChild.style.cursor = "auto";
      }
    } catch (e) {}
  };

  const dynamicReviewsElHelper = async () => {
    if (
      widgetSettings.data &&
      !widgetSettings.loading &&
      widgetSettings.data.companySettings
    ) {
      const { widgetWidth, reviewCount, widgetColor } =
        widgetSettings.data.companySettings;
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_SECURE_URL}/widgets/widget-view/?company_id=${companyId}&widget_type=review_widget&widget_width=${widgetWidth}&review_count=${reviewCount}&widget_color=${widgetColor}`,
        );

        if (dynamicReviewsEl && res.data) {
          dynamicReviewsEl.current.innerHTML = res.data;

          const widgetChildEl = dynamicReviewsEl.current.children[0];
          if (widgetChildEl) {
            let aTags = widgetChildEl.querySelectorAll("a");
            if (aTags) {
              Array.from(aTags).forEach((aTag) => {
                aTag.removeAttribute("href");
                aTag.style.cursor = "auto";
              });
            }
          }
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    dynamicBtnHelper();
    dynamicReviewsElHelper();
  }, [widgetSettings.data]);

  return (
    <div>
      <LightBlueCard />
      <div
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        className="buttons-container"
      >
        <div style={{ marginRight: "31px" }} className="button-outer-container">
          <div className="button-container">
            <div ref={dynamicBtnEl} />
          </div>
          <div
            onClick={() => handleDynamicButtonClick(copyDynamicButtonOne)}
            className="copy-code"
          >
            {copiedOne ? "Copied" : "Copy Code"}
          </div>
        </div>

        <div className="button-outer-container-dynamic">
          <div className="button-container-tall">
            <div ref={dynamicReviewsEl} />
          </div>
          <div
            onClick={() =>
              handleDynamicReviewButtonClick(copyReviewWidgetToClipboard)
            }
            className="copy-code"
          >
            {copiedTwo ? "Copied" : "Copy Code"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicButtonsBody;
