import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient } from "react-apollo";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import downloadIcon from "src/assets/images/common/download.svg";
import successIcon from "src/assets/images/enterprise/location-success.svg";
import errorIcon from "src/assets/images/enterprise/location-error.svg";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import { ENTERPRISE_LOCATIONS } from "src/graphql/enterprise/queries";
import SearchComponent from "src/components/search_component/SearchComponent";
import { Col, Row } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import { stringConvertor, rearrangedArrayData } from "src/shared/Utils";
import LocationDetailView from "./LocationDetailView";
import NoRecordFound from "src/components/enterprise/NoRecordFound";
import CsvUtil from "src/utils/CsvUtil";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

export const HEADER_ITEMS = [
  { columnLabel: "ID", columnValue: "company_id", sortable: true },
  { columnLabel: "ACCOUNT NAME", columnValue: "company_name", sortable: true },
  { columnLabel: "LOCATION STATUS", columnValue: "status", sortable: true },
  {
    columnLabel: "DC STATUS",
    columnValue: "direct_connect_status",
    sortable: true,
  },
  {
    columnLabel: "LAST REVIEW DATE",
    columnValue: "last_review_date",
    sortable: true,
  },
  {
    columnLabel: "BILLING METHOD",
    columnValue: "payment_Method",
    sortable: true,
  },
  {
    columnLabel: "",
  },
];

const Locations = ({ history }) => {
  useDocumentTitle("Locations");
  const perPage = 10;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [detailsShown, setDetailsShown] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const [dataProvider, setDataProvider] = useState([]);

  const getBrandingBudgetRow = (data, props = {}) => {
    const {
      companyName,
      status,
      lastReviewDate,
      directConnectDisabled,
      paymentMethod,
      companyId,
    } = data;

    return [
      {
        node: <span>{companyId}</span>,
      },
      {
        node: (
          <a href={`/companies/${companyId}/dashboard`} target="_blank">
            {companyName}
          </a>
        ),
      },
      {
        node: stringConvertor(status),
      },
      {
        node: <img src={directConnectDisabled ? errorIcon : successIcon} />,
      },
      {
        node: <span>{lastReviewDate || "N/A"}</span>,
      },
      {
        node:
          paymentMethod === "ach" ? (
            <span className="text-uppercase">{paymentMethod}</span>
          ) : (
            <span>{stringConvertor(paymentMethod)}</span>
          ),
      },
      {
        node: (
          <img
            onClick={() => {
              setCompanyDetails(data);
              setDetailsShown(true);
            }}
            src={ellipsisV}
            alt="view detail"
            className="cursor-pointer"
          />
        ),
      },
    ];
  };

  const getBrandingBudgetData = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
    };

    if (props.searchTerm || filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm || props.searchTerm.toLowerCase() || "";
    }

    client
      .query({
        query: ENTERPRISE_LOCATIONS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterpriseLocations) {
          const brandingBudget = res.data.enterpriseLocations.data;
          const totalRecords = res.data.enterpriseLocations.totalCount;

          //setup customers
          setDataProvider(
            brandingBudget.map((data) =>
              getBrandingBudgetRow(data, {
                ...queryVariables,
              }),
            ),
          );

          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getBrandingBudgetData({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getBrandingBudgetData({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getBrandingBudgetData(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const downloadCSV = () => {
    const queryVariables = {
      rpp: 0,
      sortBy: filters.sortBy ? filters.sortBy.value : undefined,
      sortOrder: filters.sortOrder ? filters.sortOrder.value : undefined,
    };

    if (filters.searchTerm) {
      queryVariables["searchTerm"] = filters.searchTerm.toLowerCase() || "";
    }

    client
      .query({
        query: ENTERPRISE_LOCATIONS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then(({ data: { enterpriseLocations } }) => {
        const order = [
          "companyId",
          "companyName",
          "status",
          "directConnectDisabled",
          "lastReviewDate",
          "paymentMethod",
        ];

        const labels = [
          "ID",
          "ACCOUNT NAME",
          "LOCATION STATUS",
          "DC STATUS",
          "LAST REVIEW DATE",
          "BILLING METHOD",
        ];

        const rearrangedArray = rearrangedArrayData(
          enterpriseLocations?.data,
          order,
        );

        CsvUtil.downloadCSVFile(rearrangedArray, labels, "Locations");
      });
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-between mb-4">
          <div className="page-title">Locations</div>

          <Button className="px-5" onClick={() => history.push("add-location")}>
            + Add Location
          </Button>
        </Col>
      </Row>

      <Card
        title={
          <Row>
            <Col>
              <div className="table-heading">All Locations</div>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
              <img
                className="ml-2 cursor-pointer"
                onClick={downloadCSV}
                src={downloadIcon}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          {detailsShown && (
            <LocationDetailView
              details={companyDetails}
              handleClose={() => setDetailsShown(false)}
            />
          )}
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <SortableTableHeader
                sortable={values.sortable}
                columnLabel={values.columnLabel}
                columnValue={values.columnValue}
                onSort={onSort}
                currentSortValue={filters.sortBy.value}
                currentSortOrder={filters.sortOrder.value}
              />
            ))}
            body={dataProvider}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </>
  );
};

export default Locations;
