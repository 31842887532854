import React from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import { Row, Col } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import Form from "src/shared/styleguide/form/Form";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import { SUPPORT_PHONE } from "src/constants/Billing";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UPDATE_ENTERPRISE_MUTATION } from "src/graphql/enterprise/mutations";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { addToast } from "src/utils/ToastUtil";
import { GET_ENTERPRISE_DETAILS } from "src/graphql/enterprise/queries";

const BillingEmail = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").nullable(),
    }),
    onSubmit: () => {
      saveChanges();
    },
  });

  // call apis
  const [updateBillingAddress, { loading }] = useMutation(
    UPDATE_ENTERPRISE_MUTATION,
  );

  useQuery(GET_ENTERPRISE_DETAILS, {
    onCompleted: ({ getEnterprise }) => {
      formik.setFieldValue("email", getEnterprise.billingEmail || "");
    },
  });

  const saveChanges = async () => {
    try {
      await updateBillingAddress({
        variables: {
          billingEmail: formik.values.email,
        },
      });
      addToast({
        type: "success",
        message: "Billing email address updated.",
      });
    } catch (err) {
      addToast({
        type: "error",
        message: "Billing email address not updated.",
      });
    }
  };

  // render
  return (
    <div style={{ boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.08)" }}>
      <SettingsCard
        loading={false}
        settingsName="Billing Email"
        settingsDescription="Enter the email address at which you want to get billing related notification/ monthly receipt emails below."
        settingsForm={
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="email">
                    <Form.Label className="text-dark">Email Address</Form.Label>
                    <Form.Control
                      name="email"
                      value={formik.values.email}
                      placeholder="Enter email address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      This is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  {formik.touched.email && formik.errors.email ? (
                    <div className="sg-text-danger">{formik.errors.email}</div>
                  ) : null}
                </Col>
              </Row>

              {/* action button */}
              <Row className="mt-1">
                <Col xs={12}>
                  <Button
                    disabled={!formik.isValid || loading}
                    onClick={saveChanges}
                  >
                    {loading ? "Please wait..." : "Update"}
                  </Button>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12}>
                  <PopupIcon
                    iconSize={16}
                    props={{
                      className: "mb-1 mr-1",
                    }}
                  />
                  <span className="font-italic">
                    To terminate your account, call {SUPPORT_PHONE}
                  </span>
                </Col>
              </Row>
            </div>
          </form>
        }
      />
    </div>
  );
};

export default BillingEmail;
