import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "src/shared/styleguide/form/Form";
import Card from "src/shared/styleguide/card/Card";
import informationIcon from "src/assets/images/alerts/information_gray.svg";
import Button from "src/shared/styleguide/button/Button";
import Table from "src/shared/styleguide/table/Table";
import { useMutation, useQuery } from "react-apollo";
import { addToast } from "src/utils/ToastUtil";
import {
  GET_NOTIFICATION_EMAIL_USER_LIST,
  GET_NOTIFICATION_SETTINGS_DATA,
} from "src/graphql/enterprise/queries";
import { UPDATE_NOTIFICATIONS_SETTINGS } from "src/graphql/enterprise/mutations";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const Notifications = () => {
  useDocumentTitle("Notifications");
  // state
  const [activeNotificationUser, setActiveNotificationUser] = useState();
  const [enterpriseNotificationSettings, setEnterpriseNotificationSettings] =
    useState([]);

  // queries

  const [updateNotificationSettings, { loading }] = useMutation(
    UPDATE_NOTIFICATIONS_SETTINGS,
    {
      onCompleted: () => {
        addToast({
          type: "success",
          message: "Notification settings updated successfully",
        });
      },
      onError: (err) => {
        addToast({
          type: "error",
          message:
            err && err.graphQLErrors && err.graphQLErrors[0]
              ? err.graphQLErrors[0].message
              : "Something went wrong please try again",
        });
      },
    },
  );

  useQuery(GET_NOTIFICATION_SETTINGS_DATA, {
    onCompleted: ({ enterpriseNotificationSettings }) => {
      setEnterpriseNotificationSettings(enterpriseNotificationSettings);
    },
  });

  const { data: { enterpriseUsers } = {} } = useQuery(
    GET_NOTIFICATION_EMAIL_USER_LIST,
    {
      onCompleted: ({ enterpriseUsers: { notificationUser } }) => {
        setActiveNotificationUser(notificationUser?.id);
      },
    },
  );

  const handleSwitchToggle = (id, name, value) => {
    let updatedValue = enterpriseNotificationSettings.map((row) =>
      row.id === id ? { ...row, [name]: value } : row,
    );

    setEnterpriseNotificationSettings(updatedValue);
  };

  return (
    <>
      <div className="page-title">Settings</div>
      <Card className="mt-4 mb-4">
        <Row>
          <Col>
            <h1 className="form-heading">Contact Person</h1>
            <div class="customer-card-subtitle">
              Select a user to whom you want the alert/notification emails to go
              to.
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="mt-5">
            <Form.Group controlId="name">
              <Form.Label className="text-dark">
                Send Notifications To{" "}
                <img className="ml-2" src={informationIcon} />
              </Form.Label>
              <Form.Select
                options={enterpriseUsers?.data?.map(
                  ({ email, id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName} (${email})`,
                  }),
                )}
                value={activeNotificationUser}
                onChange={(e) => setActiveNotificationUser(e.value)}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <div>
              <h1 className="form-heading">Notification Settings</h1>
              <div class="customer-card-subtitle">
                Select the categories for which you want to receive
                notifications. You can choose if a notification needs to be
                delivered to single person managing all the locations, to
                individual <br /> locations or both. Toggle the switches to
                change your preferences.
              </div>
            </div>
          </Col>
          <Col className="mt-4">
            <Table
              head={[
                "NOTIFICATION CATEGORY",
                "SEND TO ENTERPRISE",
                "SEND TO LOCATION",
              ]}
              body={enterpriseNotificationSettings
                ?.filter((value) => value.categoryName)
                ?.map((row) => [
                  {
                    node: row.categoryName,
                  },
                  {
                    node: (
                      <Form.Group controlId={`enterprise_${row.categoryName}`}>
                        <Form.Check
                          custom
                          checked={row.deliverToEnterpriseUser}
                          type="switch"
                          onChange={() =>
                            handleSwitchToggle(
                              row.id,
                              "deliverToEnterpriseUser",
                              !row.deliverToEnterpriseUser,
                            )
                          }
                          label=""
                          className="text-dark custom-switch-lg"
                        />
                      </Form.Group>
                    ),
                  },
                  {
                    node: (
                      <Form.Group controlId={`location_${row.categoryName}`}>
                        <Form.Check
                          custom
                          type="switch"
                          checked={row.deliverToCompanyUser}
                          onChange={() =>
                            handleSwitchToggle(
                              row.id,
                              "deliverToCompanyUser",
                              !row.deliverToCompanyUser,
                            )
                          }
                          label=""
                          className="text-dark custom-switch-lg"
                        />
                      </Form.Group>
                    ),
                  },
                ])}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-between border-top">
            <Button variant="" className="mt-3">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                const enterpriseEmailSettings =
                  enterpriseNotificationSettings.map(
                    ({
                      id,
                      deliverToEnterpriseUser,
                      deliverToCompanyUser,
                    }) => ({
                      id,
                      deliverToEnterpriseUser,
                      deliverToCompanyUser,
                    }),
                  );
                updateNotificationSettings({
                  variables: {
                    enterpriseEmailSettings,
                    enterpriseSetting: {
                      notificationUserId: activeNotificationUser,
                    },
                  },
                });
              }}
              className="mt-3 px-5"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Notifications;
