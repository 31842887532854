import React, { Component } from "react";
import "rc-time-picker/assets/index.css";
import Switch from "react-switch";
import TimePicker from "rc-time-picker";
import { GET_BUSINESS_HOURS } from "./graphql_queries/queries";
import { UPDATE_BUSINESS_HOURS } from "./graphql_queries/mutations";
import { gql } from "apollo-boost";
import { COOKIES, APPOLO_FETCH_POLICY } from "../../../shared/Config";
import { MAP_DAYS } from "../../../shared/Constants";
import { loadCookie } from "../../../shared/SessionHelper";
import moment from "moment";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import ProfileSettingTitleBar from "./ProfileSettingTitleBar";
import { addToast } from "src/utils/ToastUtil";
import { connect } from "react-redux";

const DEFAULT_START_TIME = "9:00 am";
const DEFAULT_END_TIME = "17:00 pm";

class BusinessHours extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      blocking: false,
      token: loadCookie(COOKIES.AUTH_TOKEN),
      companyId: this.props.companyId,
      businessHours: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.businessHours();
  }

  /**
   * Extracts available business hours for a company.
   */
  businessHours() {
    this.toggleBlocking();
    this.props.client
      .query({
        query: gql`
          ${GET_BUSINESS_HOURS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: { id: parseInt(this.state.companyId) },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        this.toggleBlocking();
        if (response && response.data && response.data.businessHours) {
          this.setState({ businessHours: response.data.businessHours });
        } else {
          this.setState({ businessHours: this.getDefaultBusinessHours() });
        }
      })
      .catch((error) => {
        this.setState({ businessHours: this.getDefaultBusinessHours() });
        this.toggleBlocking();
        console.log("Error in getting business hours: ", error);
      });
  }

  getDefaultBusinessHours() {
    const businessHours = [];

    for (let index = 0; index < 7; index++) {
      businessHours.push({
        openTime: null,
        closeTime: null,
        closed: true,
        day: index,
      });
    }

    return businessHours;
  }

  /**
   * Provides the moment object for open and close time.
   * @param time - Time to be converted in moment.
   * @returns - Moment Object.
   */
  getOpenCloseTime(time) {
    if (time && typeof time === "string") {
      return this.getMomentTimeFromString(time);
    } else {
      return time ? moment(time) : "";
    }
  }

  /**
   * Converts the moment time from the string Values.
   * @param time - Time to be converted.
   * @returns {moment.Moment} -> Converted moment object.
   */
  getMomentTimeFromString(time) {
    var currentMoment = moment();
    let timeSplit = time.split(":");
    if (timeSplit.length > 1) {
      let min = timeSplit[1].toLowerCase().replace("am", "").replace("pm", "");
      currentMoment.set({
        hour: timeSplit[0],
        minute: min,
        second: 0,
        millisecond: 0,
      });
    }
    return currentMoment;
  }

  /**
   * Update the switch toggler on change event.
   * @param index - Index of business hours to be changed.
   */
  updateSwitchValue(index) {
    let businessHours = this.state.businessHours;
    let businessHour = businessHours[index];
    businessHour.closed = !businessHour.closed;
    if (businessHour.closed) {
      businessHour.openTime = null;
      businessHour.closeTime = null;
    } else {
      businessHour.openTime = this.getMomentTimeFromString(DEFAULT_START_TIME);
      businessHour.closeTime = this.getMomentTimeFromString(DEFAULT_END_TIME);
    }

    businessHour.closeTimeError = false;
    businessHour.openTimeError = false;
    this.setState({ businessHours });
  }

  /**
   * Business hours submit handler.
   */
  submit = () => {
    if (this.validateData("both")) {
      this.updateBusinessHours();
    }
  };

  /**
   * Updates business hours for a company.
   */
  updateBusinessHours() {
    this.toggleBlocking();
    this.props.client
      .mutate({
        mutation: gql`
          ${UPDATE_BUSINESS_HOURS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: this.processParams(),
      })
      .then(() => {
        addToast({
          type: "success",
          message: "Company business hours updated successfully.",
        });
        this.toggleBlocking();
      })
      .catch((error) => {
        addToast({
          type: "error",
          message: "Failed to update company business hours.",
        });
        this.toggleBlocking();
        console.log("Error in update Business Hours: ", error);
      });
  }

  /**
   * Capturing the parameters for updating the business hours.
   * @returns - Params Json.
   */
  processParams() {
    let businessHours = this.state.businessHours;
    let params = {
      companyId: parseInt(this.state.companyId, 10),
    };

    for (let index = 0; index < businessHours.length; index++) {
      let businessHour = businessHours[index];
      params["open" + index] = !businessHour.closed;
      params["openTime" + index] = businessHour.openTime
        ? this.getOpenCloseTime(businessHour.openTime).format()
        : null;
      params["closeTime" + index] = businessHour.closeTime
        ? this.getOpenCloseTime(businessHour.closeTime).format()
        : null;
    }

    return params;
  }

  /**
   * Vaidates the business hours form data.
   * @param type - Type of the time [Open/Close/Both]
   * @param value - Value to be updated.
   * @returns - True if all the values valid otherwise false.
   */
  validateData(type, value) {
    let valid = true;
    let businessHours = this.state.businessHours;
    for (let index = 0; index < businessHours.length; index++) {
      let isValid = this.validateTime(businessHours[index], type);
      if (!isValid) {
        valid = false;
      }
    }
    this.setState({ businessHours });
    return valid;
  }

  /**
   * Validates open and close time.
   * @param businessHour - Business hour obejct.
   * @param type - Type of the time [Open/Close/Both]
   * @returns {boolean} - True if all the values valid otherwise false.
   */
  validateTime(businessHour, type) {
    let valid = true;
    let openTime = this.getOpenCloseTime(businessHour.openTime);
    let closeTime = this.getOpenCloseTime(businessHour.closeTime);
    // Validating Open time.
    if (type === "openTime" || type === "both") {
      if (!businessHour.closed && !businessHour.openTime) {
        businessHour.openTimeError = true;
        businessHour.openTimeErrorMessage = "Field Required";
        valid = false;
      } else if (businessHour.openTime && openTime.isAfter(closeTime)) {
        businessHour.openTimeError = true;
        businessHour.closeTimeError = false;
        businessHour.openTimeErrorMessage =
          "Open time should be less than close time.";
        valid = false;
      } else {
        // Resetting to default
        businessHour.openTimeError = false;
        businessHour.openTimeErrorMessage = "";
        businessHour.closeTimeError = false;
      }
    }

    // Validating Close time.
    if (type === "closeTime" || type === "both") {
      if (!businessHour.closed && !businessHour.closeTime) {
        businessHour.closeTimeError = true;
        businessHour.closeTimeErrorMessage = "Field Required";
        valid = false;
      } else if (businessHour.closeTime && closeTime.isBefore(openTime)) {
        businessHour.closeTimeError = true;
        businessHour.openTimeError = false;
        businessHour.closeTimeErrorMessage =
          "Close time should be greater than open time.";
        valid = false;
      } else {
        // Resetting to default
        businessHour.closeTimeError = false;
        businessHour.openTimeError = false;
        businessHour.closeTimeErrorMessage = "";
      }
    }

    return valid;
  }

  /**
   * Change handler for Open Time.
   * @param value - Value to be updated.
   * @param index - Index of current business hour.
   */
  openTimeChanged = (value, index) => {
    let businessHours = this.state.businessHours;
    businessHours[index].openTime = value;
    this.setState({ businessHours });
  };

  /**
   * Close handler for time picker.
   * @param type - Type of the time [Open/Close/Both]
   * @param index - Index of current business hour.
   */
  timePickerClosed = (type, index) => {
    let businessHours = this.state.businessHours;
    this.validateTime(businessHours[index], type);
    this.setState({ businessHours });
  };

  /**
   * Change handler for Close Time.
   * @param value - Value to be updated.
   * @param index - Index of current business hour.
   */
  closeTimeChanged = (value, index) => {
    let businessHours = this.state.businessHours;
    businessHours[index].closeTime = value;
    this.setState({ businessHours });
  };

  /**
   * Toggled the clocking of the UI.
   */
  toggleBlocking() {
    this.setState({ blocking: !this.state.blocking });
  }

  /**
   * Set visibility texting toast pop up.
   * @param visible -> boolean value, true if popup needs to be visible.
   */
  enabledToast = (visible) => {
    this.setState({ showToast: visible });
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
        <div id="business-hour">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-12 ml-sm-2">
                  <ProfileSettingTitleBar
                    title={"Business Hours"}
                    subTitle={
                      "This section contains your company working days and hours"
                    }
                  />
                </div>
              </div>
              <div className="row offset-sm-1">
                <div className="col-12 col-lg-11 col-sm-12">
                  <div className="row mt-4">
                    <div className="col-12 pl-0">
                      {/* Rendering business hours */}
                      {this.state.businessHours.map((businessHour, index) => (
                        <div
                          className="row week-days-row mt-4 ml-0"
                          key={index}
                        >
                          <div className="col-lg-3 col-md-4">
                            <div className="d-inline-flex">
                              <Switch
                                onColor="#f47821"
                                checked={!businessHour.closed}
                                onChange={(checked, event, id) =>
                                  this.updateSwitchValue(index)
                                }
                                offColor="#e2e2e2"
                                uncheckedIcon={false}
                                checkedIcon={false}
                              />
                              <div className="week-day">
                                {" "}
                                {MAP_DAYS[businessHour.day]}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-8">
                            <div className="row">
                              <div className="col-5 pr-0">
                                <TimePicker
                                  id={`openTime.${index}`}
                                  name={`openTime.${index}`}
                                  inputClassName="time-picker"
                                  inputReadOnly
                                  placeholder="09:00"
                                  value={this.getOpenCloseTime(
                                    businessHour.openTime,
                                  )}
                                  onChange={(value) =>
                                    this.openTimeChanged(value, index)
                                  }
                                  onClose={(open) =>
                                    this.timePickerClosed("openTime", index)
                                  }
                                  showSecond={false}
                                  disabled={businessHour.closed}
                                />
                                {businessHour.openTimeError && (
                                  <div className="error-message">
                                    {" "}
                                    {businessHour.openTimeErrorMessage}
                                  </div>
                                )}
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                <div className="time-seprater">-</div>
                              </div>
                              <div className="col-5 pl-0">
                                <TimePicker
                                  id={`closeTime.${index}`}
                                  name={`closeTime.${index}`}
                                  inputClassName="time-picker"
                                  inputReadOnly
                                  placeholder="17:00"
                                  value={this.getOpenCloseTime(
                                    businessHour.closeTime,
                                  )}
                                  onChange={(value) =>
                                    this.closeTimeChanged(value, index)
                                  }
                                  onClose={(open) =>
                                    this.timePickerClosed("closeTime", index)
                                  }
                                  showSecond={false}
                                  disabled={
                                    businessHour.closed ||
                                    !businessHour.openTime
                                  }
                                />
                                {businessHour.closeTimeError && (
                                  <div className="error-message">
                                    {" "}
                                    {businessHour.closeTimeErrorMessage}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row mt-4 ml-0 mb-5">
                    <button
                      type="submit"
                      onClick={this.submit}
                      className="btn btn-primary"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  companyId: user.userInfo.companyId,
});
export default connect(mapStateToProps)(BusinessHours);
