import React, { Component } from "react";
import { connect } from "react-redux";
import "./NotFoundPage.scss";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="error-page jumbotron">
        <h2 className="headline text-orange"> 404</h2>

        <div className="error-content">
          <h3>
            <i className="fa fa-warning text-orange"></i> Oops! Page not found.
          </h3>
          <p>
            We could not find the page you were looking for. Meanwhile, you may{" "}
            <a href={`/companies/${this.props.userInfo.companyId}/dashboard`}>
              return to dashboard
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});
export default connect(mapStateToProps)(NotFoundPage);
