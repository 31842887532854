import React from "react";
import { render } from "react-dom";
import "babel-polyfill";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./index.scss";
import App from "./App";
import "reactjs-popup/dist/index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    // integrations: [
    //   Sentry.reactRouterV5BrowserTracingIntegration({ history, matchPath }),
    // ],
    tracesSampleRate: 1.0,
  });
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
