/**
 * Constants Helper for the application.
 */

export const constants = {
  // Add constants here.
  MONTE_CARLO: "#6EC5B8",
  APPROX_LYNCH: "#5d798f",
  TOASTER_TIMER: 6000,
  PHONE_NUMBER_VALIDATION_MSG: "Enter valid phone number.",
  ZIP_CODE_VALIDATION_MSG: "Enter valid ZIP code.",
  WEBSITE_VALIDATION_MSG: "Enter valid website url.",
  PASSWORD_VALIDATION_MSG: "Password should not be empty.",
  CONFIRM_PASSWORD_VALIDATION_MSG:
    "Entered password is not match with password",
  EMPTY_FIELD_MSG: "Field should not be empty.",
  PHONE_EXTENSION_MSG:
    "The phone extension should be numbers with maximum length of six digits.",
  SWITCH_ON: "ON",
  SWITCH_OFF: "OFF",
  EMAIL_ID_VALIDATION_MSG: "Please enter valid email address.",
  VALID_URL: "Please enter a valid URL.",
  DIGITS_ONLY: "Please enter only digits.",
  NUMBERS_ONLY: "Please enter numbers only.",
  REQUIRED_FIELD: "This field is required.",
  VALID_PHONE_MSG: "Please specify a valid phone number",
  KIOSK_COPY_CLIP_MSG: "The Mobile Kiosk URL has been copied",
  BLAST_CAMPAIGN: "blast",
  BRANDING_CAMPAIGN: "branding",
  DRIP_CAMPAIGN: "drip",
  CONFIRM_BTN_TEXT: "Save",
  CANCEL_BTN_TEXT: "Don't Save",
  SETTINGS_TAB: "settings",
  CUSTOMERS_TAB: "customers",
  POSTCARD_FRONT_TAB: "postcard-front",
  POSTCARD_BACK_TAB: "postcard-back",
  APPROVE_TAB: "approve",
  SAVE_CHANGES_MESSAGE: "Save changes?",
  BRANDING: "branding",
  RECENT_REVIEW: "recent",
  FEATURED_REVIEW: "featured",
  POSTCARD_FEATURED_REVIEW: "featured_postcard_reviews",
  POSTCARD_RECENT_REVIEW: "recent_postcard_reviews",
  WEBHOOK_URL_FOR_PAYSIMPLE: "https://api.customerlobby.com/webhooks/paysimple",
  NEIGHBOURS: "neighbourhood",
  FILTERS: "filters",
  CSV_UPLOAD: "csv_upload",
};

export const urlConstants = {
  DASHBOARD: "/company/results",
  COMPANY_SETTINGS: "/company/edit",
  USER_SETTINGS: "/company/users",
  MODIFY_USER: "/company/users/:id/edit",
  INSIGHTS: "/company/insights",
  REVIEW_SETTINGS: "/company/review-settings",
  INVITATION_SETTINGS: "/company/invitation-settings",
  REVIEW_CATEGORY_SETTINGS: "/company/categories-settings",
  REVIEW_PUBLISHING_SETTINGS: "/company/publishing-settings",
  GET_REVIEWS: "/company/more-reviews",
  CUSTOMER_CALL_CREADITS: "company/customer-call-credits",
  MANAGE_SETTINGS: "/company/manage-review",
  EDIT_REVIEW: "/company/:review_id/Edit",
  TRACK_REVIEWS: "/company/track-reviews",
  TRACK_CUSTOMER_CALLS: "/company/customer-calls",
  TRACK_EMAIL_INVITATIONS: "/company/email-invitations",
  TRACK_KIOSK_REVIEWS: "/company/kiosk-reviews",
  CUSTOMERS: "/company/customers",
  CUSTOMER_DETAILS: "/company/customers/:contact_id/edit",
  FEEDBACK_REVIEWS: "/company/customer-suggestions",
  HELP: "/company/request-support",
  MANAGE_CAMPAIGN: "/company/campaigns/manage",
  CAMPAIGN_RESULTS_SUMMARY: "/company/campaigns-results-summary",
  BRANDING_CAMPAIGN_SETTINGS: "/company/campaigns/:id/results",
  EDIT_CAMPAIGNS_SETTINGS: "/company/campaigns/:id/edit",
  NEW_CAMPAIGNS_SETTINGS: "/company/campaigns/new",
  MANAGE_CAMPIAGN_PAGE: "/company/campaigns/manage",
  CUSTOMERS_URL: "/company/customers",
  CUSTOMERS_SEGEMENT_URL: "/company/customers/segment/",
};

// Feature Flag Constants
export const ffConstants = {
  // Operations
  read: "read",
  write: "write",

  // Texting Flag
  texting: "Texting",

  // Appointment Flag
  appointment: "Appointment",
  customAppointment: "CustomAppointment",
  customerlobbyAppointment: "CustomerlobbyAppointment",

  // Campaign Flags
  campaign: "Campaign",
  emailCampaign: "EmailCampaign",
  postcardCampaign: "PostcardCampaign",
  textCampaign: "TextCampaign",
  prospectMarkting: "ProspectMarketing",
  magnetMailer: "MagnetMailer",

  // Message Flags
  message: "Message",
  autoResponse: "AutoResponse",
  autoResponseAll: "AutoResponseAll",
  autoResponseAfterHours: "AutoResponseAfterHours",
  messageTemplates: "MessageTemplates",
  quickReply: "QuickReply",
  mobileApp: "MobileApp",

  // Review Flags
  review: "Review",
  autoEmailInvitation: "AutoEmailInvitation",
  autoQueueCustomerCalls: "AutoQueueCustomerCalls",
  ignoreAutoInvitesForCommercial: "IgnoreAutoInvitesForCommercial",
  invitationDelay: "InvitationDelay",
  smartCallQueuing: "SmartCallQueuing",
  smartInvite: "SmartInvite",
  prospectMarketing: "ProspectMarketing",
  prospect: "ProspectMarketing",
};

export const msgConstants = {
  SUCCESS_MSG_FOR_SMART_INVITES_UPDATE: "Smart invites updated successfully.",
  FAILURE_MSG_FOR_SMART_INVITES_UPDATE: "Unable to update smart invites.",
  SUCCESS_MSG_FOR_CUSTOM_EMAIL_TEMPLATE_UPDATE:
    "Email template updated successfully.",
  FAILURE_MSG_FOR_CUSTOM_EMAIL_TEMPLATE_UPDATE:
    "Failed to update email template.",
  SUCCESS_MSG_FOR_AUTO_INVITATION_SETTINGS_UPDATE:
    "Auto invitation settings updated successfully.",
  FAILURE_MSG_FOR_AUTO_INVITATION_SETTINGS_UPDATE:
    "Unable to update auto invitation settings.",
  SUCCESS_MSG_FOR_SMART_CALL_QUEUING_UPDATE:
    "Smart call Queuing updated successfully.",
  FAILURE_MSG_FOR_SMART_CALL_QUEUING_UPDATE:
    "Unable to update smart call queuing.",
  SUCCESS_MSG_FOR_COMPANY_SETTINGS_UPDATE:
    "Company details updated successfully.",
  FAILURE_MSG_FOR_COMPANY_SETTINGS_UPDATE: "Unable to update company details.",
  SUCCESS_MSG_FOR_COMPANY_LOGO_UPDATE: "Logo Updated Successfully.",
  FAILURE_MSG_FOR_COMPANY_LOGO_UPDATE:
    "Unable to upload the file, please try again.",
  SUCCESS_MSG_FOR_USER_INFO_UPDATE: "User updated sucessfully",
  FAILURE_MSG_FOR_USER_INFO_UPDATE: "Unable to update user details.",
  SUCCESS_MSG_FOR_PUBLISHING_SETTINGS_UPDATE:
    "Pusblish settings updated sucessfully.",
  FAILURE_MSG_FOR_PUBLISHING_SETTINGS_UPDATE:
    "Failed to update publish settings.",
  SUCCESS_MSG_FOR_PRIVATE_FEEDBACK_TOPIC_UPDATE:
    "Private feedback settings updated successfully.",
  FAILURE_MSG_FOR_PRIVATE_FEEDBACK_TOPIC_UPDATE:
    "Unable to update private feedback settings, please try again.",
  SUCCESS_MSG_FOR_REVIEW_TOPIC_FEEDBACK_UPDATE:
    "Review topic settings updated successfully.",
  FAILURE_MSG_FOR_REVIEW_TOPIC_FEEDBACK_UPDATE:
    "Unable to update review topic settings, please try again.",
  DATA_COPY_TO_CLIPBOARD: "Data has been copied to clipboard.",
  SUCCESS_MSG_FOR_EMAIL_INVITAION_UPDATE:
    "Email invitation updated successfully",
  FAILURE_MSG_FOR_EMAIL_INVITAION_UPDATE:
    "Unable to update email invitation, please try again.",
  SUCCESS_MSG_FOR_SYNDICATION_STATUS_UPDATE:
    "Syndication reviews status updated successfully.",
  FAILURE_MSG_FOR_SYNDICATION_STATUS_UPDATE:
    "Unable to update syndication reviews status, please try again.",
  SUCCESS_MSG_FOR_COMPANY_PLACE_DELETE: "Company place Deleted sucessfully",
  FAILURE_MSG_FOR_COMPANY_PLACE_DELETE:
    "Failed to delete company place, please try again",
  SUCCESS_MSG_FOR_MATCH_COMPANY_PLACE: "Company place updated sucessfully",
  FAILURE_MSG_FOR_MATCH_COMPANY_PLACE:
    "Failed to update place details, please try again",
  SUCCESS_MSG_FOR_EMAIL_INVITES: "Email Invites sent successfully.",
  FAILURE_MSG_FOR_EMAIL_INVITES:
    "Unable to send calls invites, please try again.",
  SUCCESS_MSG_FOR_CALLS_INVITES: "Calls invites sent successfully.",
  FAILURE_MSG_FOR_CALLS_INVITES:
    "Unable to send calls invites, please try again.",
  SUCCESS_MSG_FOR_PURCHASE_CREDIT: "Purchase credit successfully.",
  FAILURE_MSG_FOR_PURCHASE_CREDIT:
    "Failed to purchase credits, please try again.",
  SUCCESS_MSG_FOR_PURCHASE_CREDIT_CARD: "Purchase credit successfully.",
  FAILURE_MSG_FOR_PURCHASE_CREDIT_CARD:
    "Failed to purchase credit, please try again.",
  SUCCESS_MSG_FOR_FEATURED_REVIEW_REQUEST:
    "Review moved to featured successfully.",
  FAILURE_MSG_FOR_FEATURED_REVIEW_REQUEST:
    "Failed to update featured review, please try again.",
  SUCCESS_MSG_FOR_ADD_COMMET_ON_REVIEWS: "Comment added successfully.",
  FAILURE_MSG_FOR_ADD_COMMET_ON_REVIEWS:
    "Failed to add comments, Please try again.",
  SUCCESS_MSG_FOR_CHALLENGE_REVIEWS: "The review challenge request is created.",
  FAILURE_MSG_FOR_CHALLENGE_REVIEWS:
    "Failed to request a challenge for review, please try again.",
  SUCCESS_MSG_FOR_REVIEW_WITHDRAWAL_REQUEST:
    "Withdrawal request added successfully.",
  FAILURE_MSG_FOR_REVIEW_WITHDRAWAL_REQUEST:
    "Failed to add withdrawal request, please try again.",
  EMAIL_INVITES_NOT_AVAILABLE: "Email invitations not available.",
  SUCCESS_MSG_EMAIL_RESEND_ACTION: "Email updated and invitation resent.",
  FAILUER_MSG_EMAIL_RESEND_ACTION: "Failed to send the email invite.",
  SUCCESS_MSG_FOR_SEND_EMAIL_INVITE: "Email invite send successFully.",
  FAILURE_MSG_FOR_SEND_EMAIL_INVITE: "Fail to send email invite.",
  SUCCESS_MSG_FOR_UPDATE_CUSTOMER_DETAILS:
    "Customer details updated successfully.",
  FAILURE_MSG_FOR_UPDATE_CUSTOMER_DETAILS:
    "Failed to update customer details, please try again.",
  SUCCESS_MSG_FOR_GLOBAL_CAMPAIGN_SETTINGS:
    "Global campaign settings updated successfully.",
  FAILURE_MSG_FOR_GLOBAL_CAMPAIGN_SETTINGS:
    "Failed to update global campaign settings, please try again.",
  REVIEWS_NOT_AVAILABLE: "Reviews not available.",
  CUSTOM_INVITATION_NOT_AVAILABLE: "Custom invitations not available.",
  FAILURE_MSG_FOR_ENOUGH_BUDGET: "Your already have enough budget.",
  SUCCESS_MSG_FOR_BUDGET_UPDATE: "Your monthly budget has been updated",
  FAILURE_MSG_FOR_RECOMMENDED_CREDIT: "Failed to update credit.",
  SUCCESS_MSG_FOR_CREATE_CUSTOM_FILTER:
    "Your custom filter is created successfully.",
  FAILURE_MSG_FOR_CREATE_CUSTOM_FILTER: "Failed to create the custom_filter",
  SUCCESS_MSG_FOR_SAVE_CUSTOMER_SEGMENT:
    "Customer segment has been created successfully.",
  FAILURE_MSG_FOR_SAVE_CUSTOMER_SEGMENT: "Failed to save the customer segment.",
  SUCCESS_MSG_FOR_UPDATE_CUSTOMER_SEGMENT:
    "Customer segment has been updated successfully.",
  FAILURE_MSG_FOR_UPDATE_CUSTOMER_SEGMENT:
    "Failed to update the customer segment.",
  SUCCESS_MSG_FOR_ADD_BULK_CUSTOMERS: "CSV file uploaded successfully.",
  FAILURE_MSG_FOR_ADD_BULK_CUSTOMERS: "Failed to upload CSV.",
  FAILURE_MSG_FOR_PROCESS_BULK_CUSTOMERS: "Failed to process CSV.",
  FAILURE_MSG_FOR_PROCESS_BULK_CONTACTS: "Failed to process CSV.",
  POPOVER_TRANSACTION_RECORD_MSG: "Transaction records not avaiable.",
  POPOVER_POSTCARD_RECORD_MSG: "Postcard records not avaiable.",
  SUCCESS_MSG_FOR_CREATE_SUPPORT_REQUEST: "Request Added successfully.",
  FAILURE_MSG_FOR_CREATE_SUPPORT_REQUEST: "Failed to create support request.",
  SUCCESS_MSG_FOR_ENABLE_CAMPAIGN: "Campaign created successfully.",
  FAILUER_MSG_FOR_ENABLE_CAMPAIGN:
    "Failed to enabled new camapign, please try again.",
  SUCCESS_MSG_FOR_CLONE_CAMPAIGN: "Your campaign has been cloned successfully.",
  FAILUER_MSG_FOR_CLONE_CAMPAIGN:
    "Failed to clone the campaign please try again later.",
  SUCCESS_MSG_FOR_DELETE_CAMPAIGN:
    "Your campaign setup has been deleted successfully.",
  FAILUER_MSG_FOR_DELETE_CAMPAIGN:
    "Failed to delete campaign please try again later.",
  SUCCESS_MSG_FOR_CAMPAIGN_PURCHASE_CREDIT:
    "Your credit purchase was successful.",
  FAILURE_MSG_FOR_CAMPAIGN_PURCHASE_CREDIT:
    "Something went wrong, please try this again later",
  SUCCESS_MSG_FOR_CAMPAIGN_SETUP:
    "Your campaign has been created successfully.",
  FAILURE_MSG_FOR_CAMPAIGN_SETUP: "Failed to create a campaign.",
  SUCEESS_MSG_FOR_DELETE_CAMPAPIGN: "Your campaign setup have been deleted.",
  FAILURE_MSG_FOR_DELETE_CAMPAIGN_SETUP:
    "Failed to delete campaign setup, please try this again later",
  SUCCESS_MSG_FOR_UPDATE_CAMPAIGN_SETUP:
    "Your campaign has been updated successfully.",
  FAILURE_MSG_FOR_UPDATE_CAMPAIGN_SETUP: "Failed to update campaign settings.",
  SUCCESS_MSG_FOR_FEATURED_REVIEWS_SELECTION:
    "Featured reviews are selected successfully",
  FAILURE_MSG_FOR_FEATURED_REVIEWS_SELECTION:
    "Failed to update featured reviews, please try again",
  SUCCESS_MSG_FOR_PlAN_UPGRADE: "Plan is upgraded successfully.",
  FAILURE_MSG_FOR_PlAN_UPGRADE: "Failed to upgrade plan, please try again.",
  SUCCESS_MSG_FOR_UPDATE_BILLING_NOTIFICATION:
    "Notification email changed successfully.",
  FAILURE_MSG_FOR_UPDATE_BILLING_NOTIFICATION:
    "Failed to update notification email, please try again.",
  SUCCESS_MSG_CREATE_DIRECT_CONNECT_SETTINGS: "Direct connect system Enabled",
  FAILUER_MSG_FOR_CREATE_DIRECT_CONNECT_SETTINGS:
    "Failed to register the billing system. Please try again.",
  SUCCESS_MSG_FOR_DISCONNECT_SETTINGS:
    "Direct connect settings successfully disconnected.",
  FAILUER_MSG_FOR_DISCONNECT_SETTINGS:
    "Failed to disconnect the direct connect settings, Please try again later.",
  SUCCESS_MSG_FOR_CREATE_COMPANY_BILLING_SUBSCRIPTION:
    "Your subscription is created successfully.",
  FAILURE_MSG_FOR_CREATE_COMPANY_BILLING_SUBSCRIPTION:
    "Failed to create subscription, Please try again.",
  SUCCESS_MSG_FOR_UPDATE_COMPANY_BILLING_SUBSCRIPTION:
    "Your subscription is updated successfully.",
  FAILURE_MSG_FOR_UPDATE_COMPANY_BILLING_SUBSCRIPTION:
    "Failed to update subscription, Please try again.",
  MSG_FIELD_REQUIRED: "Required field",
};

export const apiURLConstants = {
  JSON_EXT: ".json",
  COMPANIES: "/companies/",
  COMPANY_SETTINGS: "/company_settings.json",
  CUSTOM_EMAIL_TEMPLATES: "/custom_email_templates/",
  GET_CUSTOM_EMAIL_TEMPLATES: "/custom_email_templates.json",
  SMART_INVITE_SETTINGS: "/smart_invite_settings.json",
  REVIEW_PUBLISHING_SETTINGS: "/company/publishing_settings",
  CATEGORIES: "/categories.json",
  STATE_OPTIONS: "/state-options.json",
  COMPANY_USERS: "/company_users/",
  COMPANY_USER_BY_ID: "/company_users.json?company_id=",
  JSON_BY_COMAPNY_ID: ".json?company_id=",
  COMPANY_PLACES: "/company_places/",
  COMPANY_PLACES_URL: "/company_places.json",
};

export const configConstant = {
  S3_URL: "https://s3.amazonaws.com/",
};

export const BillingSystemsConstants = {
  SERVICE_TITAN: "ServiceTitan",
  SUCCESSWARE21ASP: "Successware21Asp",
  QUICKBOOK_ONLINE: "QuickbooksOnline",
  KICKSERV: "Kickserv",
  SERVICEM8: "ServiceM8",
  XERO: "Xero",
  FRESHBOOKS: "FreshBooks",
  NETSUITE: "Netsuite",
  FIELD_AWARE: "FieldAware",
  GINGR: "Gingr",
  BASE_CRM: "BaseCrm",
  BALLPARK: "Ballpark",
  FIELD_EDGE: "FieldEdge",
  APPOINTMENT_PLUS: "AppointmentPlus",
  FIELD_LOCATE: "FieldLocate",
  PROTRACTOR: "Protractor",
  INVOICE2GO: "Invoice2go",
  ISN: "InspectionSupportNetwork",
  MARKETSHARP: "MarketSharp",
  CAMELOT: "Camelot",
  QUICKBOOKS: "Quickbooks",
  SHOPIFY: "Shopify",
  ZOHO: "Zoho",
  SERVICE_MONSTER: "ServiceMonster",
  PAYSIMPLE: "PaySimple",
  TOOLBOX: "Toolbox",
  LESS_ACCOUNTING: "LessAccounting",
};

export const HEADER_MENU = {
  results: "results",
  texting: "texting",
  customers: "customers",
  reviews: "reviews",
  campaigns: "campaigns",
  appointments: "appointments",
  insights: "insights",
  settings: "settings",
  users: "users",
  appointments: "appointments",
  help_center: "help-center",
};

export const COMPANY_SETTINGS_SIDEBAR = {
  profileSetting: "profileSetting",
  user: "user",
  users: "users",
  billing: "billing",
  directConnect: "directConnect",
  review: "review",
  texting: "message-settings",
  campaign: "campaign",
  appointment: "appointment",
  syndication: "syndication",
  widgets: "widgets",
  app: "app",
};

export const REVIEW_SETTINGS_TAB = {
  categories: "categories",
  publishing: "publishing-notifications",
  invites: "invites",
  social_media: "social-media",
};

export const PROFILE_SETTINGS_TAB = {
  companyInformation: 0,
  contactDetails: 1,
  businessHours: 2,
};

export const PROFILE_SETTINGS_ROUTES = {
  companyInformation: "settings",
  contactDetails: "contact-details",
  businessHours: "business-hours",
};

export const EMAIL_CAMPAIGN_STEPS = {
  campaignDetails: "Campaign Details",
  designEmail: "Design Email",
  reviewAndSend: "Review And Send",
};

export const APPS_INTEGRATION_SETTINGS_TAB = {
  apps: 0,
  integrations: 1,
};

export const APPS_INTEGRATION_SETTINGS_ROUTES = {
  apps: "apps",
  integrations: "integrations",
};

export const APP_INTEGRATIONS = {
  pulseM: "pulse_m",
};

export const MAP_DAYS = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
};

export const CACHE_AND_NETWORK = "cache-and-network";
export const NETWORK_ONLY = "network-only";
export const NO_CACHE = "no-cache";

export const PHONE_NUMER_REGULAR_EXPRESSION =
  /^(\+?1-?)?(\([2-9]\d{2}\)\s?|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;
export const PHONE_NUMBER_WITH_LETTER_EXPRESSION =
  /\(*[0-9]{3}\)*[\s.A-Za-z0-9\-]{7,9}/;

export const TOAST_TYPES = {
  warning: "warning",
  info: "info",
  error: "error",
  success: "success",
};

export const MANAGE_TAB_VIEW_BY = {
  ALL_REVIEW: "All Review",
  PENDING: "Pending",
  POSITIVE: "Positive",
  NEGATIVE: "Negative",
  FEATURED: "Featured",
};
export const PRIVATE_FEEDBACK_DISPOSITIONS = {
  all_feedback: "all_feedback",
  general_feedback: "general_feedback",
  unhappy_feedback: "unhappy_feedback",
};

export const SOURCES = {
  CUSTOMER_CALLS: "CustomerCalls",
  HANDWRITTEN: "Handwritten",
  REVIEW_ME: "Reviewme",
  CUSTOM_INVITE: "CustomInvites",
  KIOSK: "Kiosk",
  EMAIL: "Email",
  KIOSK_HANDWRITTEN: "Handwritten/Kiosk",
  HANDWRITTEN_KIOSK: "HandwrittenKiosk",
};

export const CREDIT_TYPE = {
  CALL_CREDIT: "CustomerCallCredit",
  HANDWRITTEN_CREDIT: "HandwrittenReviewCredit",
};

export const MANAGE_REVIEW_TABS = {
  results: {
    eventKey: 0,
    title: "Results",
    pageHeader: "Reviews",
    documentTitle: "Review Results | Customer Lobby",
    path: "review-results",
  },
  manage: {
    eventKey: 1,
    title: "Manage",
    pageHeader: "Reviews",
    documentTitle: "Manage Reviews | Customer Lobby",
    path: "manage-reviews",
  },
  track: {
    eventKey: 2,
    title: "Track",
    pageHeader: "Reviews",
    documentTitle: "Track Reviews | Customer Lobby",
    path: "track-reviews",
  },
  privateFeedback: {
    eventKey: 3,
    title: "Private Feedback",
    pageHeader: "Reviews",
    documentTitle: "Private Feedback | Customer Lobby",
    path: "private-feedback",
  },
  getReviews: {
    eventKey: 4,
    title: "Get Reviews",
    pageHeader: "Reviews",
    documentTitle: "Get Reviews | Customer Lobby",
    path: "get-reviews",
  },
};
export const PLATFORM_TYPE = {
  CUSTOMER_LOBBY: "customerlobby",
  GOOGLE: "google",
};

export const COMPANY_USER = "CompanyUser";
export const ENTERPRISE_USER = "EnterpriseUser";

export const REVIEW_STATUS = {
  PENDING: "pending",
  PUBLISH: "publish",
};

export const DIRECTION = {
  DOWN: "DOWN",
  UP: "UP",
};

export const COMPANY_SETTINGS_ROUTES = {
  profileSetting: "settings",
  texting: "message-settings",
  campaign: "campaigns-settings",
  appointments: "appointment-settings",
  app: "apps",
  integration: "integrations",
  users: "users",
  widgets: "widgets",
  syndication: "syndication-settings",
  review: "review-settings",
  billing: "billing",
  directConnect: "direct-connect-settings",
  messaging: "messages",
  campaigns: "campaigns",
  dashboard: "dashboard",
};

export const REVIEW_DETAILS_DISPLAY_TABS = {
  comment: {
    eventKey: 0,
    title: "Comment",
  },
  challenge: {
    eventKey: 1,
    title: "Challenge",
  },
  withdrawalRequest: {
    eventKey: 2,
    title: "Withdrawal Request",
  },
};
export const NEW_USER_NO_RECORD = {
  HEADING: "No results yet",
  MESSAGE: "You haven’t received any Private Feedback. Click Get Reviews",
};

export const NO_RECORD_FOUND = {
  HEADING: "No results found",
  MESSAGE:
    "We couldn’t find any results. Try adjusting your filters to display results.",
};

export const POTRAIT_ORIENTATION = {
  HEADING: "PLEASE ROTATE YOUR DEVICE FOR OPTIMAL DISPLAY.",
  MESSAGE:
    "This content is better experienced with your device in landscape mode.",
};

export const REVIEW_SOURCE_DISPLAY = {
  web: "Web",
  custom: "Custom Invites",
  email: "Email",
  handwritten: "Handwritten",
  call: "Customer calls",
  kiosk_mobile_phone: "Kiosk",
  kiosk_email: "Kiosk",
  reviewme_sms: "Text",
  reviewme_email: "Web",
  reviewme_instant: "Review me",
  web_text: "Web Text",
  csv: "Web",
};

export const CONTACT_SOURCE_DISPLAY = {
  csv_upload: "Upload",
  custom: "Custom",
  direct_connect: "Direct Connect",
  handwritten: "Handwritten",
  manual_input: "Manual",
  ncoa: "Recently Moved (NCOA)",
  reviewme: "Review Me",
  unknown: "Unknown",
  web: "Web",
};

export const CAMPAIGNS_TABS = {
  results: {
    eventKey: 0,
    title: "Results",
    pageHeader: "Campaigns",
    documentTitle: "Campaign Results | Customer Lobby",
    path: "results",
  },
  manage: {
    eventKey: 1,
    title: "Manage",
    pageHeader: "Campaigns",
    documentTitle: "Manage Campaigns | Customer Lobby",
    path: "manage",
  },
};
export const PROSPECT_TABS = {
  manage: {
    eventKey: 0,
    title: "Manage",
    pageHeader: "Prospect Marketing Summary",
    documentTitle: "Manage Prospect Marketing | Customer Lobby",
    path: "manage",
  },
};

export const OVERVIEW_RESULT_CHART_COLORS = {
  sentAndDeliveredColors: ["#5cb85c", "#6dc76d", "#82cf82", "#97d897"],
  undeliveredAndUnsubscribedColors: [
    "#dc4800",
    "#e06226",
    "#eb8251",
    "#ed9b75",
  ],
};

export const NOT_AVAILABLE = "N/A";

export const NUMBER_TEXT = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
};

export const REPEAT_INTERVAL_OPTIONS = [
  {
    value: 12,
    label: "Annually",
  },
  {
    value: 6,
    label: "Every 6 Months",
  },
  {
    value: 4,
    label: "Every 4 Months",
  },
  {
    value: 3,
    label: "Every 3 Months",
  },
  {
    value: 2,
    label: "Every 2 Months",
  },
  {
    value: 1,
    label: "Every Month",
  },
];
export const MAILING_OPTIONS = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 12,
    label: "12",
  },
];

export const REPEAT_INTERVAL_OPTIONS_EMAIL = [
  {
    value: 12,
    unit: "months",
    label: "Annually",
  },
  {
    value: 0.2,
    unit: "days",
    label: "Daily",
  },
  {
    value: 6,
    unit: "months",
    label: "Every 6 Months",
  },
  {
    value: 4,
    unit: "months",
    label: "Every 4 Months",
  },
  {
    value: 3,
    unit: "months",
    label: "Every 3 Months",
  },
  {
    value: 2,
    unit: "months",
    label: "Every 2 Months",
  },
  {
    value: 1,
    unit: "months",
    label: "Every Month",
  },
  {
    value: 0.5,
    unit: "weeks",
    label: "Weekly",
  },
];
export const REPEAT_INTERVAL_OPTIONS_POSTCARD = [
  {
    value: 0,
    label: "Once",
  },
  {
    value: 12,
    label: "Annually",
  },
  {
    value: 6,
    label: "Every 6 Months",
  },
  {
    value: 4,
    label: "Every 4 Months",
  },
  {
    value: 3,
    label: "Every 3 Months",
  },
  {
    value: 2,
    label: "Every 2 Months",
  },
  {
    value: 1,
    label: "Every Month",
  },
];

export const DATE_FORMATS = {
  date_time: "YYYY-M-D HH:mm",
  lll: "lll",
  ll: "ll",
  date_year: "DDD MM, YYYY",
};

export const DEFAULT_FONT = `"proxima-nova", Arial, Helvetica, sans-serif`;

export const COLOR_PALETTE = {
  alizarin: "#EF3418",
  blackRockBlue: "#313541",
  burntOrange: "#FF652B",
  carrotOrange: "#F47821",
  ceruleanBlue: "#2A68CB",
  darkBlue: "#5d7ae1",
  darkRed: "#FF0000",
  darkTangerine: "#FAA61F",
  fernGreen: "#5CB85C",
  goldenPoppy: "#f3bd00",
  gondolaGrey: "#323130",
  hoki: "#627589",
  veryLightGrey: "#cacaca",
  silvergrey: "#cccccc",
  oliveBrown: "#40a40b",
  pinkClosest: "#828ef2",
  jungleGreen: "#25B05B",
  lightBlue: "#94c5f0",
  lightGrey: "#d6d3d3",
  lightNavy: "#4371D6",
  lightSlateGrey: "#7f869b",
  lynchBlue: "#667587",
  mayaBlue: "#72c7f9",
  neonCarrot: "#FF9734",
  paleNavy: "#AFC6E3",
  persimmon: "#DC4807",
  red: "#ff0000",
  royalBlue: "#4970d7",
  silver: "#BBBBBB",
  tenne: "#dc5700",
  vividBlue: "#3BC2F4",
  white: "#ffffff",
  darkModrateBlue: "#3b4557",
};

export const MANAGE_CUSTOMER_TABS = {
  customers: {
    eventKey: 0,
    title: "Customers",
    pageHeader: "Customers",
    documentTitle: "Customers | Customer Lobby",
    path: "customers",
  },
  segments: {
    eventKey: 1,
    title: "Segments",
    pageHeader: "Segments",
    documentTitle: "Segments | Customer Lobby",
    path: "segments",
  },
};

export const CUSTOMER_TAB = {
  eventKey: 0,
  title: "Customer",
  pageHeader: "Edit Customer Details",
  documentTitle: "Customer | Customer Lobby",
  path: "customers",
};

export const SEGMENT_TAB = {
  eventKey: 0,
  title: "Segment",
  pageHeader: "Create Segment",
  documentTitle: "Segment | Customer Lobby",
  path: "segments",
};

export const MANAGE_CAMPAIGN_TABS = {
  results: {
    eventKey: 0,
    title: "Results",
    pageHeader: "Campaign",
    documentTitle: "Campaigns Results | Customer Lobby",
    path: "campaigns/results",
  },
  manage: {
    eventKey: 1,
    title: "Manage",
    pageHeader: "Campaign",
    documentTitle: "Campaigns Manage | Customer Lobby",
    path: "campaigns/manage",
  },
};

export const REVIEW_SOURCE = {
  CUSTOMER_LOBBY: "customerlobby",
  PULSE_M: "pulse_m",
};

export const REVIEW_CATEGORY = {
  FEATURED: "featured",
  RECENT: "recent",
};

export const CAMPAIGN_TYPE = {
  BLAST: "blast",
  OVERTIME: "drip",
};

export const SUBSCRIPTION_TYPE = [
  {
    label: "Customer Lobby",
    value: "customer_lobby",
  },
  {
    label: "PulseM",
    value: "pulse_m",
  },
  {
    label: "Customer Lobby & PulseM",
    value: "customer_lobby,pulse_m",
  },
];

export const FREQUENCY_OPTIONS = [
  { value: "weeks_0", label: "Once" },
  { value: "weeks_1", label: "Weekly" },
  { value: "weeks_2", label: "Bi-Weekly" },
  { value: "months_1", label: "Every Month" },
  // { value: "months_2", label: "Every Two Months" },
  // { value: "months_3", label: "Every 3 months" },
  // { value: "months_4", label: "Every 4 months" },
  // { value: "months_5", label: "Every 6 months" },
  // { value: "years_1", label: "Annually" },
];

export const PROSPECT_CAMPAIGN_TYPE = [
  { value: "blast", label: "One-Time" },
  { value: "repeat", label: "Repeat" },
];

export const PROSPECT_JOURNEY_TITLE = {
  NEIGHBOURS: "Enhance Your Reach in Familiar Neighborhoods",
  FILTERS: "Expand Into New Markets",
  CSV_UPLOAD: "Utilize Your Own Contact List",
};

export const CALCULATOR_FILTERS = {
  pet_owner: "Pet Owner",
  home_build_year: "Home Year Built",
  home_value: "Home Value",
  home_owner: "Home Owner",
  home_attributes: "Home Attributes",
};
