import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "src/shared/styleguide/card/Card";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApolloClient, useMutation } from "react-apollo";
import {
  CREATE_ENTERPRISE_USER,
  UPDATE_ENTERPRISE_USER,
} from "src/graphql/enterprise/mutations";
import { GET_ENTERPRISE_USER } from "src/graphql/enterprise/queries";
import { NO_CACHE } from "src/shared/Constants";
import { addToast } from "src/utils/ToastUtil";
import informationIcon from "src/assets/images/alerts/information_gray.svg";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  role: "manager",
  status: "active",
};

const passwordValidation = Yup.string()
  .min(16, "Password must be at least 16 characters long")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .matches(/\d/, "Password must contain at least one number")
  .matches(
    /[!@#$%^&*(),.?":{}|<>]/,
    "Password must contain at least one special character",
  );

const commonFields = {
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Please enter an email address."),
};

const addUserValidationSchema = Yup.object().shape({
  ...commonFields,
  password: passwordValidation.required("Password is a required field"),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "The password and confirmation do not match",
    )
    .required("Please re-enter the password."),
});

const editUserValidationSchema = Yup.object().shape({
  ...commonFields,
  password: passwordValidation,
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "The password and confirmation do not match",
  ),
});

const AddEditUser = ({ history, match }) => {
  const [addNewUser] = useMutation(CREATE_ENTERPRISE_USER);
  const [updateUser] = useMutation(UPDATE_ENTERPRISE_USER);
  const client = useApolloClient();

  const { userInfo } = useSelector((state) => state.user);

  const isEditTab = window.location.href.includes("edit-user");
  const validationSchema = isEditTab
    ? editUserValidationSchema
    : addUserValidationSchema;
  const { id } = useParams();

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (isEditTab) {
        await updateUser({
          variables: values,
        });
        addToast({
          message: `User successfully updated !`,
          persist: false,
          type: "success",
          toastId: "sg-success-demo",
        });
      } else {
        await addNewUser({
          variables: values,
        });
        history.goBack();
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (id) {
      getEnterpriseUser();
    }
    if (!userInfo?.isManager && !isEditTab) {
      history.push(`/enterprise/users`);
    }
  }, []);

  const getEnterpriseUser = () => {
    client
      .query({
        query: GET_ENTERPRISE_USER,
        fetchPolicy: NO_CACHE,
        variables: { id },
      })
      .then((res) => {
        if (res && res.data && res.data.getEnterpriseUser) {
          setDefaultValues(res.data.getEnterpriseUser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDefaultValues = (res) => {
    Object.keys(res).map((item) => {
      if (item === "isActive") {
        setFieldValue("status", res["isActive"] ? "active" : "inactive");
      } else if (item === "isManager") {
        setFieldValue("role", res["isManager"] ? "manager" : "user");
      } else {
        setFieldValue(item, res[item]);
      }
    });
  };

  const onCancel = () => {
    history.goBack();
  };

  const rolesOptions = [
    userInfo?.isManager && { value: "manager", label: "Manager" },
    { value: "user", label: "User" },
  ].filter(Boolean);

  return (
    <>
      <div className="page-title">{isEditTab ? "Edit" : "Add"} User</div>
      <Card className="mt-4 mb-4">
        <Row>
          <Col>
            <h1 className="form-heading mb-4">Enterprise User</h1>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
              />
              {touched.firstName && errors.firstName ? (
                <div className="sg-text-danger my-2">{errors.firstName}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="lastName">
              <Form.Label className="text-dark">Last Name</Form.Label>
              <Form.Control
                placeholder="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
              />
              {touched.lastName && errors.lastName ? (
                <div className="sg-text-danger my-2">{errors.lastName}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label className="text-dark">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              {touched.email && errors.email ? (
                <div className="sg-text-danger my-2">{errors.email}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Label className="text-dark">Status</Form.Label>
              <Form.Select
                value={values.status}
                onChange={(e) => setFieldValue("status", e.value)}
                placeholder="Status"
                options={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label className="text-dark">Role</Form.Label>
              <Form.Select
                value={values.role}
                onChange={(e) => setFieldValue("role", e.value)}
                placeholder="Role"
                options={rolesOptions}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <h1 className="form-heading mt-5 mb-4">Password</h1>

            <Form.Group controlId="password">
              <Form.Label className="text-dark">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
              />
              {touched.password && errors.password ? (
                <div className="sg-text-danger my-2">{errors.password}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="passwordConfirmation">
              <Form.Label className="text-dark">Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={values.passwordConfirmation}
                onChange={handleChange}
              />
              {touched.passwordConfirmation && errors.passwordConfirmation ? (
                <div className="sg-text-danger my-2">
                  {errors.passwordConfirmation}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4} className="enterprise-form-text">
            {!isEditTab && (
              <>
                <img className="information-icon" src={informationIcon} />
                <p className="d-flex align-items-center font-weight-bold">
                  ADDING A NEW USER
                </p>{" "}
                <p>Each User is required to have a unique email address.</p>
                <p>
                  Select Manager as the role of a user to enable them
                  <br /> to have the ability to add edit other users, and to{" "}
                  <br /> access and control individual locations
                </p>
              </>
            )}
          </Col>
        </Row>
        <hr className="footer-nav-separator" />
        <Row>
          <Col>
            <div onClick={() => onCancel()}>Cancel</div>
          </Col>
          <Col md="auto">
            <Button
              variant="primary"
              size="lg"
              disable={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting ? "Please wait..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AddEditUser;
