import React, { useState } from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import TabsComponent from "src/shared/tabs/TabsComponent";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import FacebookConnectSettings from "./FacebookConnectSettings";
import TwitterConnectSettings from "./TwitterConnectSettings";
import { useLocation, useHistory } from "react-router-dom";
import "src/components/company_users/DeactivationModal.scss";
import swal from "sweetalert";
import "./SocialMediaSettings.scss";

const SocialMediaSettings = () => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [loading, setLoading] = useState(false);

  const disconnectModal = (callback, content) => {
    swal({
      title: "Are you sure?",
      className: "custom-deactivation-template",
      text: content,
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Disconnect",
          value: "DISCONNECT",
        },
        cancel: true,
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
    })
      .then((confirm) => {
        if (confirm === "DISCONNECT") {
          callback();
        }
      })
      .catch(() => {});
  };

  // variables
  const tabs = [
    {
      eventKey: 0,
      title: "Twitter",
      path: "social-media/twitter",
      documentTitle: "Twitter",
      component: (
        <TwitterConnectSettings
          loading={loading}
          setLoading={setLoading}
          disconnectModal={disconnectModal}
        />
      ),
    },
    {
      eventKey: 1,
      title: "Facebook",
      path: "social-media/facebook",
      documentTitle: "Facebook",
      component: (
        <FacebookConnectSettings
          loading={loading}
          setLoading={setLoading}
          disconnectModal={disconnectModal}
        />
      ),
    },
  ];

  // state
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const history = useHistory();
  const location = useLocation();

  // methods
  const onTabChange = (tabIndex) => {
    const selectedTab = tabs[tabIndex];
    history.push(
      `/companies/${showCompanyId}/review-settings/${selectedTab.path}`,
    );
    document.title = selectedTab.documentTitle;
    setSelectedTab(selectedTab);
  };

  // effects
  React.useEffect(() => {
    const selectedTab = tabs.find((tab) =>
      location.pathname.endsWith(tab.path),
    );
    if (selectedTab) {
      document.title = selectedTab.documentTitle;
      setSelectedTab(selectedTab);
    }
  }, [location]);

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Connected Accounts"
      settingsDescription="Automatically tweet or post your new review by linking your Twitter and Facebook account to your Customer Lobby account."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.234"
          height="36.363"
          viewBox="0 0 38.234 36.363"
        >
          <g transform="translate(1.194 1.201)">
            <g transform="translate(-.993 -1)">
              <g>
                <path
                  d="M13.1 191.082l-4 4.006a4.25 4.25 0 1 1-6.011-6.009l8.014-8.014a4.249 4.249 0 0 1 6.009 0 1.417 1.417 0 1 0 2-2 7.082 7.082 0 0 0-10.016 0l-8.014 8.014A7.083 7.083 0 0 0 11.1 197.092l4.006-4.006a1.417 1.417 0 1 0-2-2z"
                  transform="translate(.993 -165.167)"
                />
                <path
                  d="M209.182 1.074a7.084 7.084 0 0 0-10.018 0l-4.807 4.807a1.417 1.417 0 0 0 2 2l4.807-4.807a4.25 4.25 0 0 1 6.011 6.009l-8.811 8.817a4.249 4.249 0 0 1-6.009 0 1.417 1.417 0 1 0-2 2 7.082 7.082 0 0 0 10.016 0l8.815-8.815a7.082 7.082 0 0 0-.004-10.011z"
                  transform="translate(-177.257 1)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <div className="mt-3">
          <TabsComponent
            unmountOnExit={false}
            onTabChange={onTabChange}
            tabs={tabs}
            selectedTab={selectedTab.eventKey}
          />
        </div>
      }
    />
  );
};

export default SocialMediaSettings;
