import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "src/shared/styleguide/form/Form";
import Card from "src/shared/styleguide/card/Card";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import Button from "src/shared/styleguide/button/Button";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { CREATE_LOCATION_MUTATION } from "src/graphql/enterprise/mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_STATES,
} from "src/components/company/settings/graphql_queries/queries";
import { PHONE_NUMBER_WITH_LETTER_EXPRESSION } from "src/shared/Constants";
import { numberToPhoneNumberConversion } from "src/shared/Utils";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { GET_PARTNER_DETAILS } from "src/graphql/partner/queries";
import BlockUi from "react-block-ui";
import { UPDATE_PARTNER_DETAILS } from "../../graphql/partner/mutations";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../redux/toastSlice";

const validationSchema = Yup.object().shape({
  partnerAccountName: Yup.string().required("Name is required field"),
  website: Yup.string()
    .matches(
      /^(https?:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Enter a valid URL (must start with http://, https://, or www.)",
    )
    .required("Website is required field"),
  phone: Yup.string()
    .required("Phone is required field")
    .matches(PHONE_NUMBER_WITH_LETTER_EXPRESSION, "Invalid phone"),
  address1: Yup.string().required("Street is required field"),
  city: Yup.string().required("City is required field"),
  zip: Yup.string().required("ZIP is required field").max(7),
});

const EditPartner = () => {
  useDocumentTitle("Edit Partner");
  const { data: { partnerAccount } = {}, loading } =
    useQuery(GET_PARTNER_DETAILS);
  const dispatch = useDispatch();
  const [updatePartnerAccount] = useMutation(UPDATE_PARTNER_DETAILS, {
    onCompleted: () => {
      dispatch(
        setToastMessage({
          type: "success",
          message: "Settings updated successfully",
        }),
      );
    },
  });
  const {
    address1,
    address2,
    city,
    country,
    partnerAccountName,
    phone,
    state,
    website,
    zip,
  } = partnerAccount || {};

  const initialValues = {
    partnerAccountName,
    website,
    phone,
    address1,
    address2,
    city,
    state,
    zip,
    country,
  };

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await updatePartnerAccount({
        variables: values,
      });
      setSubmitting(false);
    },
  });

  const [getStates, { data: statesList }] = useLazyQuery(GET_STATES, {
    onCompleted: ({ listStates }) => {
      // set the default value for state
      setFieldValue("state", values?.state || listStates[0]?.name);
    },
  });

  const { data: countryList } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    getStates({
      variables: {
        country: values.country,
      },
    });
  }, [values.country]);

  return (
    <BlockUi blocking={loading}>
      <div className="page-title">Your Information</div>
      <Card className="mt-4 mb-4">
        <Row>
          <Col md={8}>
            <Form.Group controlId="companyName">
              <div className="d-flex">
                <Form.Label className="text-dark" required>
                  Name
                </Form.Label>
                <PopupInfo
                  message="Name"
                  icon={
                    <span>
                      <PopupIcon
                        iconSize={16}
                        props={{
                          className: "mb-1 ml-2",
                        }}
                      />
                    </span>
                  }
                />
              </div>
              <Form.Control
                placeholder="Name"
                onChange={handleChange}
                name="partnerAccountName"
                value={values.partnerAccountName}
              />
              {touched.partnerAccountName && errors.partnerAccountName ? (
                <div className="sg-text-danger my-2">
                  {errors.partnerAccountName}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="street">
              <Form.Label className="text-dark" required>
                Street
              </Form.Label>
              <Form.Control
                placeholder="Street"
                onChange={handleChange}
                name="address1"
                value={values.address1}
              />
              {touched.address1 && errors.address1 ? (
                <div className="sg-text-danger my-2">{errors.address1}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="suite">
              <Form.Label className="text-dark">Suite</Form.Label>
              <Form.Control
                placeholder="Suite"
                onChange={handleChange}
                name="address2"
                value={values.address2}
              />
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label className="text-dark" required>
                City
              </Form.Label>
              <Form.Control
                placeholder="City"
                onChange={handleChange}
                name="city"
                value={values.city}
              />
              {touched.city && errors.city ? (
                <div className="sg-text-danger my-2">{errors.city}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label className="text-dark" required>
                State
              </Form.Label>
              <Form.Select
                onChange={(e) => setFieldValue("state", e.value)}
                value={values.state}
                options={statesList?.listStates?.map((state) => ({
                  value: state.code,
                  label: state.name,
                }))}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="zip">
              <Form.Label className="text-dark" required>
                ZIP
              </Form.Label>
              <Form.Control
                placeholder="ZIP"
                onChange={handleChange}
                name="zip"
                value={values.zip}
              />
              {touched.zip && errors.zip ? (
                <div className="sg-text-danger my-2">{errors.zip}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="country">
              <Form.Label className="text-dark">Country</Form.Label>
              <Form.Select
                onChange={(e) => setFieldValue("country", e.value)}
                value={values.country}
                options={countryList?.listCountries?.countries.map(
                  (countryVal) => ({
                    value: countryVal,
                    label: countryVal,
                  }),
                )}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label className="text-dark" required>
                Phone
              </Form.Label>
              <Form.Control
                placeholder="Phone"
                onChange={handleChange}
                name="phone"
                value={numberToPhoneNumberConversion(values.phone || "")}
              />
              {touched.phone && errors.phone ? (
                <div className="sg-text-danger my-2">{errors.phone}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="website">
              <Form.Label className="text-dark" required>
                Website
              </Form.Label>
              <Form.Control
                placeholder="Website"
                onChange={handleChange}
                name="website"
                value={values.website}
              />
              {touched.website && errors.website ? (
                <div className="sg-text-danger my-2">{errors.website}</div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-between border-top">
            <Button variant="" className="mt-3">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="mt-3 px-5"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? "Please wait..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Card>
    </BlockUi>
  );
};

export default EditPartner;
