import { gql } from "apollo-boost";

export const GET_USER_DETAILS = gql`
  query GetAuth($token: String!) {
    getAuthentication(token: $token) {
      authenticated
      companyId
      companyUserId
      enterpriseId
      enterpriseUserId
      firstName
      lastName
      expired
      partnerAccountId
      partnerUserId
    }
  }
`;
