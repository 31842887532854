import React from "react";
import { useQuery } from "react-apollo";
import BlockUi from "react-block-ui";
import { Col, Row } from "react-bootstrap";
import Card from "src/shared/styleguide/card/Card";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import { PARTNER_RESULTS_QUERY } from "src/graphql/partner/queries";
import { formatDate } from "src/shared/Utils";
import PieChart from "./PieChart";

export const HEADER_ITEMS = [
  {
    columnLabel: "Review Date	",
    columnValue: "first_name",
    sortable: false,
    width: 350,
  },
  {
    columnLabel: "Summary",
    columnValue: "email",
    sortable: false,
  },
  {
    columnLabel: "Stars",
    columnValue: "is_active",
    sortable: false,
  },
  {
    columnLabel: "City",
    columnValue: "is_owner",
    sortable: false,
  },
  {
    columnLabel: "State",
    columnValue: "",
    sortable: false,
  },
];

const Results = () => {
  const { data: { partnerResults } = {}, loading } = useQuery(
    PARTNER_RESULTS_QUERY,
  );

  const distributionByRating = partnerResults?.distributionByRating
    .map(({ rating, count }) => ({ name: rating, y: count }))
    .sort((a, b) => b.name - a.name);
  const distributionBySource = partnerResults?.distributionBySource.map(
    ({ source, count }) => ({ name: source, y: count }),
  );

  const getReviewsRow = (data) => {
    const {
      review_summary,
      total_score,
      reviewer_location_state,
      reviewer_location_city,
      created_at,
    } = data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span className="text-primary">{formatDate(created_at, "ll")}</span>
          </div>
        ),
      },
      {
        node: review_summary,
      },
      {
        node: total_score,
      },
      {
        node: reviewer_location_city,
      },

      {
        node: reviewer_location_state,
      },
    ];
  };

  return (
    <Card className="mb-4">
      <BlockUi loader={<React.Fragment />} blocking={loading}>
        <Row>
          <Col>
            <h1>Sentiment Score</h1>
            <p>{partnerResults?.sentimentScore || 0} on a scale of 100</p>
          </Col>
          <Col>
            <h1>Avg. Read Time</h1>
            <p>{partnerResults?.averageReadingTime || 0} seconds</p>
          </Col>
          <Col>
            <h1>Review Metrics</h1>
            <p>{partnerResults?.publishedReviewsCount || 0} published</p>
          </Col>
        </Row>
        <h1>Traffic to Review Pages By Source (All Time)</h1>
        <Row>
          <Col sm={5}>
            <h2>Review Rating Distribution</h2>
            <div>
              <PieChart
                colors={[
                  "#f8955f",
                  "#90e56a",
                  "#eac419",
                  "#6d8ef2",
                  "#ef4374",
                  "#3eccef",
                ]}
                data={distributionByRating}
                labelFormatter={function () {
                  return `${this.name} Star (${this.y})`;
                }}
              />
            </div>
          </Col>
          <Col sm={7}>
            <h2>Review Source Distribution</h2>
            <div>
              <PieChart
                colors={[
                  "#f8955f",
                  "#90e56a",
                  "#eac419",
                  "#6d8ef2",
                  "#ef4374",
                  "#3eccef",
                  "#3f4f23",
                ]}
                data={distributionBySource}
                labelFormatter={function () {
                  const names = {
                    web: "Web",
                    custom: "Custom",
                    email: "Email",
                    handwritten: "Handwritten",
                    call: "Call",
                    kiosk_mobile_phone: "Kiosk Phone",
                    kiosk_email: "Kiosk Email",
                  };

                  return `${names[this.name]}  (${this.y})`;
                }}
              />
            </div>
          </Col>
        </Row>
        <h1>Most Popular Reviews (last 60 days)</h1>

        <Table
          className="customer-table mb-0"
          loading={loading}
          head={HEADER_ITEMS.map((values) => (
            <div style={{ minWidth: values?.width || "auto" }}>
              <SortableTableHeader
                sortable={values.sortable}
                columnLabel={values.columnLabel}
                columnValue={values.columnValue}
                // onSort={onSort}
                // currentSortValue={filters.sortBy.value}
                // currentSortOrder={filters.sortOrder.value}
              />
            </div>
          ))}
          body={partnerResults?.recentReviewList
            ?.splice(0, 10)
            ?.map((data) => getReviewsRow(data))}
          // hasPagination={true}
          // pageCount={pageCount}
          // onPagination={onPagination}
          // currentPage={currentPage}
          // noData={<NoRecordFound />}
        />
      </BlockUi>
    </Card>
  );
};

export default Results;
