import React from "react";
import ResultsNotFound from "src/shared/errorpages/ResultsNotFound";

const NoRecordFound = () => {
  // render
  return (
    <ResultsNotFound
      svgIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="prefix__folder"
          width="64.019"
          height="54.25"
          viewBox="0 0 64.019 54.25"
        >
          <path
            id="prefix__Path_2314"
            fill="#dee3e6"
            d="M108.092 75.943v33.936a5.272 5.272 0 0 1-5.272 5.272H51.172V75.943a5.271 5.271 0 0 1 5.272-5.272h46.376a5.271 5.271 0 0 1 5.272 5.272zM83.345 102.61a9.186 9.186 0 0 0 5.588-8.336 9.529 9.529 0 0 0-19.042-.032 9.043 9.043 0 0 0 5.588 8.333v5.488a1.917 1.917 0 0 0 1.917 1.918h4.031a1.917 1.917 0 0 0 1.917-1.918zm0 0"
            data-name="Path 2314"
            transform="translate(-44.073 -60.901)"
          />
          <path
            id="prefix__Path_2315"
            d="M205.151 184.01a9.185 9.185 0 0 1-5.588 8.336v1.854H191.7v-1.892a9.043 9.043 0 0 1-5.588-8.333 9.529 9.529 0 0 1 19.042.032zm0 0"
            fill="#fff"
            data-name="Path 2315"
            transform="translate(-160.291 -150.637)"
          />
          <path
            id="prefix__Path_2316"
            d="M234.256 314.289v3.6a1.917 1.917 0 0 1-1.917 1.918h-4.031a1.917 1.917 0 0 1-1.917-1.918v-3.6zm0 0"
            fill="#fff"
            data-name="Path 2316"
            transform="translate(-194.985 -270.723)"
          />
          <path
            id="prefix__Path_2317"
            fill="#c3c9ce"
            d="M23.665 2.178l6.389 7.831H12.37a5.271 5.271 0 0 0-5.27 5.272v39.207H5.272A5.272 5.272 0 0 1 0 49.217V5.51A5.272 5.272 0 0 1 5.272.238h14.309a5.273 5.273 0 0 1 4.084 1.94zm0 0"
            data-name="Path 2317"
            transform="translate(0 -.238)"
          />
          <g
            id="prefix__Group_2114"
            data-name="Group 2114"
            transform="translate(16.047 14.325)"
          >
            <path
              id="prefix__Path_2318"
              d="M248.958 109.286v-4.676a1.11 1.11 0 0 0-2.22 0v4.677a1.11 1.11 0 1 0 2.22 0zm0 0"
              fill="#fff"
              data-name="Path 2318"
              transform="translate(-228.556 -103.5)"
            />
            <path
              id="prefix__Path_2319"
              d="M116.782 229.77a1.11 1.11 0 1 0 0 2.22h4.866a1.11 1.11 0 0 0 0-2.22zm0 0"
              fill="#fff"
              data-name="Path 2319"
              transform="translate(-115.672 -212.253)"
            />
            <path
              id="prefix__Path_2320"
              d="M348.707 229.77h-4.867a1.11 1.11 0 1 0 0 2.22h4.866a1.11 1.11 0 1 0 0-2.22zm0 0"
              fill="#fff"
              data-name="Path 2320"
              transform="translate(-311.232 -212.253)"
            />
            <path
              id="prefix__Path_2321"
              d="M158.695 145.961a1.11 1.11 0 0 0 .765-1.915l-3.465-3.292a1.11 1.11 0 0 0-1.529 1.609l3.465 3.292a1.1 1.1 0 0 0 .764.306zm0 0"
              fill="#fff"
              data-name="Path 2321"
              transform="translate(-148.786 -135.324)"
            />
            <path
              id="prefix__Path_2322"
              d="M315.49 145.96a1.105 1.105 0 0 0 .764-.305l3.465-3.292a1.11 1.11 0 0 0-1.529-1.609l-3.465 3.292a1.11 1.11 0 0 0 .765 1.915zm0 0"
              fill="#fff"
              data-name="Path 2322"
              transform="translate(-286.814 -135.322)"
            />
          </g>
        </svg>
      }
      marginTop={80}
      marginBottom={80}
      iconSize={140}
      primaryMsg="No Results Found"
      secondaryMsg={
        <div className="mx-auto">
          We couldn’t find any results. Try adjusting your filters to display
          results.
        </div>
      }
    />
  );
};

export default NoRecordFound;
