import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateFeedbackTileComponentV2 from "./PrivateFeedbackTileComponentV2";
import NoDataFoundComponent from "src/shared/no-data/NoDataFoundComponent";
import ModalPopupRight from "src/shared/modal/ModalPopupRight";
import PrivateFeedbackReviewComponent from "../private-feedback-review/PrivateFeedbackReviewComponent";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import { LIST_PRIVATE_FEEDBACKS } from "../graphql_queries/queries";
import { resetPagination } from "src/shared/Utils";
import { NO_RECORD_FOUND } from "src/shared/Constants";
import { query } from "src/shared/GraphQLHandler";
import { contactPageUrl } from "src/shared/Utils.js";
import TableMeta from "src/shared/styleguide/table/TableMeta";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import "./PrivateFeedbackListComponent.scss";

const PAGE_SIZE = 10;

/**
 * Component to render the private feedback list.
 * @param props - Props to be used from parent.
 * @returns {XML} -> Return the PrivateFeedbackListComponent
 */
const PrivateFeedbackListComponent = (props) => {
  const history = useHistory();
  let [listLoading, setListLoading] = useState(true);

  const companyId = useGetCompanyIdFromUrl();

  const getPageCount = (pages) => {
    return Math.ceil(pages / PAGE_SIZE);
  };

  let [pageCount, setPageCount] = useState(() => getPageCount());
  let [currentPage, setCurrentPage] = useState(0);

  /**
   * Method invoked to get private feedback data
   */
  const getPrivateFeedbackData = (currentPage) => {
    let variables = {
      companyId: companyId,
      dateFrom: props.filters.dateRange.value,
      dateBucket: props.filters.dateRange.bucket,
      disposition: props.filters.viewBy.value,
      sortBy: props.filters.sortBy.value,
      page: currentPage,
      rpp: PAGE_SIZE,
      searchTerm: props.filters.searchTerm,
    };

    query(
      props.client,
      LIST_PRIVATE_FEEDBACKS,
      function (error, response) {
        if (response && response.data && response.data.listPrivateFeedbacks) {
          setPrivateFeedbackListData(response.data.listPrivateFeedbacks);
          setCurrentPage(currentPage - 1);
          resetPagination(currentPage - 1);
          setListLoading(false);
        } else {
          console.log("Error in getting Private Feedback list: ", error);
        }
      },
      variables,
    );
  };

  // Setting up states.
  let [privateFeedbackListData, setPrivateFeedbackListData] = useState([]);
  let [selectedReview, setSelectedReview] = useState("");
  let [reviewDetailDisplay, setReviewDetailDisplay] = useState(false);

  /**
   * Filters change handler.
   */
  useEffect(() => {
    setPageCount(getPageCount(props.filters.viewBy.count));
    getPrivateFeedbackData(1);
    setCurrentPage(0);
  }, [
    props.filters.viewBy,
    props.filters.sortBy,
    listLoading,
    props.filters.searchTerm,
  ]);

  /**
   * Method invoked to display review modal popup
   * @param {*} review selected review
   */
  const updateReviewDetailDisplay = (review, e = null) => {
    if (e && e.target.className === "name") {
      return history.push(contactPageUrl(review.contact.contactId, companyId), {
        prevPage: document.location.pathname,
      });
    }

    setSelectedReview(review);
    setReviewDetailDisplay(!reviewDetailDisplay);
  };

  /**
   * Method invoked on pagination change
   * @param {*} event event
   */
  const onPagination = (event) => {
    setCurrentPage(event.selected);
    getPrivateFeedbackData(event.selected + 1);
  };

  const areFiltersApplied = () => {
    return (
      props.filters.searchTerm ||
      props.filters.viewBy.value !== "all_feedback" ||
      (props.filters.dateRange.label &&
        !/30 days/i.test(props.filters.dateRange.label))
    );
  };

  const resetFilters = () => {
    props.setFilters({
      ...props.filters,
      searchTerm: "",
    });
    props.setReset(Date.now());
  };

  // render
  return (
    <div id="PrivateFeedbackList" className="row">
      <div className="col-12">
        {/* Modal popup for private feedback details */}
        {reviewDetailDisplay ? (
          <ModalPopupRight
            isModalOpen={reviewDetailDisplay}
            modalClose={updateReviewDetailDisplay}
            modalHeader="Private Feedback Details"
            modalBody={
              <PrivateFeedbackReviewComponent
                review={selectedReview}
                companyId={companyId}
                client={props.client}
              />
            }
          />
        ) : null}
        <div className="row">
          <div className="col-12 mt-4">
            <TableMeta
              label={props.filters.dateRange.label}
              searchTerm={props.filters && props.filters.searchTerm}
              filtersApplied={areFiltersApplied()}
              resetFilters={resetFilters}
            />
          </div>
        </div>
        {privateFeedbackListData && privateFeedbackListData.length === 0 ? (
          <NoDataFoundComponent
            heading={NO_RECORD_FOUND.HEADING}
            message={NO_RECORD_FOUND.MESSAGE}
          />
        ) : (
          <PrivateFeedbackTileComponentV2
            searchTerm={props.filters.searchTerm}
            clickEvent={updateReviewDetailDisplay}
            reviewData={privateFeedbackListData}
          />
        )}
        {pageCount > 1 ? (
          <PaginatorComponent
            pageCount={pageCount}
            pageChangeHandler={onPagination}
            currentPage={currentPage}
          />
        ) : (
          <div className="p-3"></div>
        )}
      </div>
    </div>
  );
};
export default PrivateFeedbackListComponent;
