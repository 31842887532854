import React from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import emailIcon from "src/assets/images/login/email.svg";
import AuthLayout from "./AuthLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RESET_PASSWORD_MUTATION } from "src/graphql/auth/mutation";
import { useMutation } from "react-apollo";
import { addToast } from "src/utils/ToastUtil";
import { useParams, Link } from "react-router-dom";

const ForgotPassword = () => {
  const { type } = useParams();
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: ({ resetPassword }) => {
      if (!resetPassword.emailNotFound) {
        addToast({
          type: "success",
          message:
            "We have sent you an email with instructions on how to reset your password",
        });
      } else {
        addToast({
          type: "error",
          message: "This email address does not exist",
        });
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const {
    handleSubmit,
    handleChange,
    values: { email },
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Please enter an email address."),
    }),
    onSubmit: async ({ email }, { setSubmitting }) => {
      await resetPassword({ variables: { email, type } });
      setSubmitting(false);
    },
  });

  return (
    <AuthLayout title="Reset Your Password">
      <Form className="auth-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <label className="text-center">
              Enter your email address here to receive further instructions
            </label>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <img src={emailIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Enter your Email"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </InputGroup>
            {touched.email && errors.email ? (
              <div className="sg-text-danger my-2">{errors.email}</div>
            ) : null}

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="w-100 mt-5"
            >
              {isSubmitting ? "Please wait..." : "Reset Password"}
            </Button>
            <div className="text-center mt-3">
              <p>
                Already have an account?{" "}
                <Link className="text-orange" to={`/${type}/login`}>
                  Login here
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default ForgotPassword;
