import React from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import { Row, Col } from "react-bootstrap";
import Form from "src/shared/styleguide/form/Form";
import CalendarUtil from "src/utils/CalendarUtil";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import {
  QUERY_LIST_COUNTRIES,
  QUERY_LIST_ALL_STATES,
} from "src/graphql/settings/billing/queries";
import PropTypes from "prop-types";
import { ENTERPRISE_USER, SUBSCRIPTION_TYPE } from "src/shared/Constants";
import CardUtil from "src/utils/CardUtil";
import { addToast } from "src/utils/ToastUtil";
import { useSelector } from "react-redux";
import {
  UPDATE_ENTERPRISE_SUBSCRIPTION,
  CREATE_ENTERPRISE_SUBSCRIPTION,
} from "src/graphql/enterprise/mutations";
import "src/components/settings/billing/Billing.scss";

const BillingUpdateModal = ({
  show,
  setShow,
  subscription,
  fetchPreferences,
  pulseMSubscriptionDetails,
}) => {
  // variables
  const {
    userInfo: { enterpriseUserId },
  } = useSelector((state) => state.user);

  const listOfMonths = [
    { label: "1 - Jan", value: 1 },
    { label: "2 - Feb", value: 2 },
    { label: "3 - Mar", value: 3 },
    { label: "4 - Apr", value: 4 },
    { label: "5 - May", value: 5 },
    { label: "6 - Jun", value: 6 },
    { label: "7 - Jul", value: 7 },
    { label: "8 - Aug", value: 8 },
    { label: "9 - Sep", value: 9 },
    { label: "10 - Oct", value: 10 },
    { label: "11 - Nov", value: 11 },
    { label: "12 - Dec", value: 12 },
  ];
  const today = new Date();
  const currentYear = today.getFullYear();
  const listOfYears = Array(10)
    .fill(0)
    .map((_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i,
    }));

  // state
  const [cardType, setCardType] = React.useState("");
  const formik = useFormik({
    initialValues: {
      addNewCard: true,
      useCustomerLobby: false,
      usePulseM: false,
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      cardNumber: "",
      expirationDateMonth: today.getMonth() + 1,
      expirationDateYear: currentYear,
      cvv: "",
      subscriptionType: "",
      subscription:
        pulseMSubscriptionDetails?.status === "current" &&
        subscription?.status === "current"
          ? true
          : false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter first name").nullable(),
      lastName: Yup.string().required("Please enter last name").nullable(),
      email: Yup.string()
        .required("Please enter email address")
        .email("Please enter valid email address")
        .nullable(),
      address1: Yup.string().required("Please enter street address").nullable(),
      city: Yup.string().required("Please enter city").nullable(),
      zip: Yup.string()
        .typeError("Please enter ZIP code")
        .required("Please enter ZIP code")
        .nullable(),
      state: Yup.string("Please select state")
        .required("Please select state")
        .nullable(),
      country: Yup.string("Please select country")
        .required("Please select country")
        .nullable(),
      cardNumber: Yup.number().when("addNewCard", {
        is: true,
        then: Yup.number()
          .typeError("Please enter valid credit card number")
          .required("Please enter credit card number")
          .test(
            "cardNumber",
            "Please enter valid card number",
            (cardNumber) => {
              // reset
              setCardType("");

              // card validation
              const validationResult = CardUtil.validateCard(cardNumber);
              if (validationResult.card && validationResult.card.type) {
                setCardType(validationResult.card.type);
              }

              if (cardNumber && cardNumber.toString().length < 15) {
                throw new Yup.ValidationError(
                  "Please enter at least 15 characters",
                  cardNumber,
                  "cardNumber",
                );
              }

              if (validationResult.isPotentiallyValid) {
                return true;
              }
              return false;
            },
          ),
      }),
      cvv: Yup.string().when("addNewCard", {
        is: true,
        then: Yup.string().required("Please enter CVV"),
      }),
      subscriptionType: Yup.string().when("subscription", {
        is: true,
        then: Yup.string().required("Please select the subscription type"),
      }),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });
  const client = useApolloClient();
  const [listOfStates, setListOfStates] = React.useState([]);
  const [clCardDetails, setClCardDetails] = React.useState();

  // call apis
  const [updateSubscriptions, { loading: saving }] = useMutation(
    UPDATE_ENTERPRISE_SUBSCRIPTION,
  );
  const [addNewSubscriptions] = useMutation(CREATE_ENTERPRISE_SUBSCRIPTION);
  const { data: listOfCountries } = useQuery(QUERY_LIST_COUNTRIES);

  // methods
  const initForm = (type, flag) => {
    if (type !== "addNewCard") {
      formik.resetForm({
        values: {
          addNewCard: false,
          useCustomerLobby: type === "customer_lobby",
          usePulseM: type === "pulse_m",
          firstName:
            type === "customer_lobby"
              ? subscription.billing.firstName
              : pulseMSubscriptionDetails.billing.firstName,
          lastName:
            type === "customer_lobby"
              ? subscription.billing.lastName
              : pulseMSubscriptionDetails.billing.lastName,
          email:
            type === "customer_lobby"
              ? subscription.billing.email
              : pulseMSubscriptionDetails.billing.email,
          address1:
            type === "customer_lobby"
              ? subscription.billing.address1
              : pulseMSubscriptionDetails.billing.address1,
          address2:
            type === "customer_lobby"
              ? subscription.billing.address2
              : pulseMSubscriptionDetails.billing.address2,
          city:
            type === "customer_lobby"
              ? subscription.billing.city
              : pulseMSubscriptionDetails.billing.city,
          state:
            type === "customer_lobby"
              ? subscription.billing.state
              : pulseMSubscriptionDetails.billing.state,
          zip:
            type === "customer_lobby"
              ? subscription.billing.zip
              : pulseMSubscriptionDetails.billing.zip,
          country:
            type === "customer_lobby"
              ? subscription.billing.country
              : pulseMSubscriptionDetails.billing.country,
          subscriptionType: formik.values.subscriptionType,
          subscription:
            pulseMSubscriptionDetails?.status === "current" &&
            subscription?.status === "current"
              ? true
              : false,
        },
      });
    } else {
      formik.resetForm({
        values: {
          addNewCard: true,
          useCustomerLobby: false,
          usePulseM: false,
          firstName: "",
          lastName: "",
          email: "",
          address1: "",
          address2: "",
          city: "",
          state: "CA",
          zip: "",
          country: "US",
          cardNumber: "",
          expirationDateMonth: today.getMonth() + 1,
          expirationDateYear: currentYear,
          cvv: "",
          subscriptionType:
            pulseMSubscriptionDetails?.status === "current" &&
            subscription?.status === "current"
              ? formik.values.subscriptionType
              : flag
              ? "customer_lobby"
              : "pulse_m",
          subscription:
            pulseMSubscriptionDetails?.status === "current" &&
            subscription?.status === "current"
              ? true
              : false,
        },
      });
    }
  };

  const populateData = (data) => {
    if (data && data.statesUS && data.statesCA) {
      setListOfStates(
        [...data.statesUS, ...data.statesCA].map((state) => ({
          label: state.name,
          value: state.code,
        })),
      );
    }
  };

  const hideModal = () => {
    setShow(false);
  };

  const getListOfStates = () => {
    client
      .query({
        query: QUERY_LIST_ALL_STATES,
      })
      .then((res) => {
        if (res && res.data) {
          populateData(res.data);
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  const saveChanges = ({
    addNewCard,
    firstName,
    lastName,
    email,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    cardNumber,
    expirationDateMonth,
    expirationDateYear,
    cvv,
    subscriptionType,
  }) => {
    let selectedSubscription = [];
    subscriptionType.includes(",")
      ? formik.values.useCustomerLobby
        ? selectedSubscription.push("customer_lobby")
        : formik.values.usePulseM
        ? selectedSubscription.push("pulse_m")
        : subscriptionType.split(",").map((type) => {
            selectedSubscription.push(type);
          })
      : selectedSubscription.push(subscriptionType);
    let pulseMSubscription = subscriptionType.includes("pulse_m")
      ? {
          pulseMAccountLevelId: pulseMSubscriptionDetails.pulseMAccountLevelId,
        }
      : null;
    const queryVariables = {
      accountLevelTransition: {
        userId: enterpriseUserId,
        userType: ENTERPRISE_USER,
      },
      billing: {
        firstName,
        lastName,
        email,
        address1,
        address2,
        city,
        state,
        zip: zip,
        country,
        type: selectedSubscription,
      },
    };
    if (subscriptionType.includes("pulse_m")) {
      queryVariables["pulseMSubscription"] = pulseMSubscription;
    }
    if (addNewCard === true) {
      queryVariables.card = {
        brand: cardType,
        number: cardNumber,
        month: expirationDateMonth.toString(),
        year: expirationDateYear.toString(),
        verificationValue: cvv.toString(),
      };
    }

    if (subscription) {
      updateSubscriptions({
        variables: queryVariables,
      })
        .then(() => {
          hideModal();
          if (fetchPreferences) {
            fetchPreferences();
          }
          addToast({
            type: "success",
            message: "Credit card details saved.",
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            message:
              err && err.graphQLErrors && err.graphQLErrors[0]
                ? err.graphQLErrors[0].message
                : "Credit card details not saved.",
          });
          hideModal();
        });
    } else {
      addNewSubscriptions({
        variables: queryVariables,
      })
        .then(() => {
          hideModal();
          if (fetchPreferences) {
            fetchPreferences();
          }
          addToast({
            type: "success",
            message: "Credit card details saved.",
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            message:
              err && err.graphQLErrors && err.graphQLErrors[0]
                ? err.graphQLErrors[0].message
                : "Credit card details not saved.",
          });
          hideModal();
        });
    }
  };

  const getSubscriptionType = () => {
    if (subscription?.status === "current") {
      initForm("addNewCard", true);
      setClCardDetails(true);
    } else if (pulseMSubscriptionDetails?.status === "current") {
      initForm("addNewCard", false);
      setClCardDetails(false);
    } else {
      initForm("addNewCard", true);
      setClCardDetails(true);
    }
  };

  // effects
  React.useState(() => {
    getListOfStates();
    getSubscriptionType();
  }, []);

  React.useEffect(() => {
    if (
      subscription?.status === "current" &&
      pulseMSubscriptionDetails?.status === "current"
    ) {
      initForm("addNewCard");
    }
  }, [formik.values.subscriptionType]);

  // render
  return (
    <Modal
      show={show}
      modalSize="md"
      header="Update Credit Card Details"
      onHide={hideModal}
      body={
        <form onSubmit={formik.handleSubmit}>
          {pulseMSubscriptionDetails?.status === "current" &&
          subscription?.status === "current" ? (
            <Row>
              <Col lg={12}>
                <Form.Group controlId="subscriptionType">
                  <Form.Label className="text-dark">
                    Subscription Type <small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Select
                    name="subscriptionType"
                    value={formik.values.subscriptionType}
                    onBlur={formik.handleBlur("subscriptionType")}
                    onChange={(opt) => {
                      formik.setFieldValue("subscriptionType", opt.value);
                    }}
                    placeholder="Select subscription type"
                    options={SUBSCRIPTION_TYPE}
                    searchable={false}
                    clearable={false}
                    isInvalid={
                      formik.touched.subscriptionType &&
                      formik.errors.subscriptionType
                    }
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {formik.errors.subscriptionType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
          <Row className="card-details-block">
            {subscription?.lastFour && subscription?.expirationDate ? (
              <Col lg={12}>
                <Form.Group controlId="useCustomerLobby">
                  <Form.Check
                    custom
                    checked={
                      formik.values.subscriptionType === "pulse_m"
                        ? formik.values.usePulseM
                        : formik.values.useCustomerLobby
                    }
                    onChange={() => {
                      initForm(
                        formik.values.subscriptionType === "pulse_m"
                          ? "pulse_m"
                          : "customer_lobby",
                      );
                    }}
                    type="radio"
                    label={
                      <div
                        className="h5"
                        style={{ position: "relative", top: 5 }}
                      >
                        Use{" "}
                        {formik.values.subscriptionType ===
                        "customer_lobby,pulse_m"
                          ? "Customer Lobby"
                          : ""}{" "}
                        Visa ending in{" "}
                        {formik.values.subscriptionType === "pulse_m" ||
                        !clCardDetails
                          ? pulseMSubscriptionDetails?.lastFour
                          : subscription?.lastFour}{" "}
                        Expiring on{" "}
                        {formik?.values?.subscriptionType &&
                          CalendarUtil.fromStrToStr(
                            formik.values.subscriptionType === "pulse_m" ||
                              !clCardDetails
                              ? pulseMSubscriptionDetails?.expirationDate
                              : subscription?.expirationDate,
                            "yyyy-MM-dd",
                            "MM/yyyy",
                          )}
                      </div>
                    }
                    className="text-dark"
                  />
                </Form.Group>
              </Col>
            ) : null}

            {formik.values.subscriptionType === "customer_lobby,pulse_m" ? (
              <Col lg={12}>
                <Form.Group controlId="usePulseM">
                  <Form.Check
                    custom
                    checked={formik.values.usePulseM}
                    onChange={() => {
                      initForm("pulse_m");
                    }}
                    type="radio"
                    label={
                      <div
                        className="h5"
                        style={{ position: "relative", top: 5 }}
                      >
                        Use PulseM Visa ending in{" "}
                        {pulseMSubscriptionDetails?.lastFour} Expiring on{" "}
                        {CalendarUtil.fromStrToStr(
                          pulseMSubscriptionDetails?.expirationDate,
                          "yyyy-MM-dd",
                          "MM/yyyy",
                        )}
                      </div>
                    }
                    className="text-dark"
                  />
                </Form.Group>
              </Col>
            ) : null}

            <Col lg={12}>
              <Form.Group controlId="addNewCard">
                <Form.Check
                  custom
                  checked={formik.values.addNewCard}
                  onChange={() => {
                    initForm("addNewCard", true);
                  }}
                  type="radio"
                  label={
                    <div
                      className="h5"
                      style={{ position: "relative", top: 5 }}
                    >
                      Add New Credit Card
                    </div>
                  }
                  className="text-dark"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* header */}
          <Row>
            <Col lg={12}>
              <div className="mb-2 text-dark font-weight-bold">
                Credit Card Holder Details
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="text-dark">
                  First Name <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  name="firstName"
                  isInvalid={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  placeholder="Enter first name"
                  onChange={formik.handleChange}
                  disabled={!formik.values.addNewCard}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="text-dark">
                  Last Name <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  name="lastName"
                  isInvalid={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  placeholder="Enter last name"
                  onChange={formik.handleChange}
                  disabled={!formik.values.addNewCard}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="email">
                <Form.Label className="text-dark">
                  Email Address <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  name="email"
                  isInvalid={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Enter email address"
                  onChange={formik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="address1">
                <Form.Label className="text-dark">
                  Street Address <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  name="address1"
                  isInvalid={formik.touched.address1 && formik.errors.address1}
                  onBlur={formik.handleBlur}
                  value={formik.values.address1}
                  placeholder="Enter street address"
                  onChange={formik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address1}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="address2">
                <Form.Label className="text-dark">Suite # </Form.Label>
                <Form.Control
                  name="address2"
                  value={formik.values.address2}
                  placeholder="Enter suite #"
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="city">
                <Form.Label
                  className="text-dark"
                  style={{ marginBottom: "0.4rem" }}
                  required
                >
                  City
                </Form.Label>
                <Form.Control
                  name="city"
                  isInvalid={formik.touched.city && formik.errors.city}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  placeholder="Enter city"
                  onChange={formik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="zip">
                <Form.Label className="text-dark">
                  ZIP/Postal code <small className="text-danger">*</small>
                </Form.Label>
                <Form.Control
                  name="zip"
                  isInvalid={formik.touched.zip && formik.errors.zip}
                  onBlur={formik.handleBlur}
                  value={formik.values.zip}
                  placeholder="Enter ZIP/Postal code"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "zip",
                      e.target.value.replace(/[^a-z0-9 ]/gi, ""),
                    );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.zip}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="state">
                <Form.Label className="text-dark">
                  State <small className="text-danger">*</small>
                </Form.Label>
                <Form.Select
                  name="state"
                  value={formik.values.state}
                  onChange={(opt) => {
                    formik.setFieldValue("state", opt.value);
                  }}
                  placeholder="Select state"
                  options={listOfStates}
                  menuBuffer={30}
                  searchable={false}
                  clearable={false}
                  isInvalid={formik.touched.state && formik.errors.state}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group controlId="country">
                <Form.Label className="text-dark">
                  Country <small className="text-danger">*</small>
                </Form.Label>
                <Form.Select
                  name="country"
                  value={formik.values.country}
                  onChange={(opt) => {
                    formik.setFieldValue("country", opt.value);
                  }}
                  placeholder="Select country"
                  options={
                    listOfCountries && listOfCountries.listCountries
                      ? listOfCountries.listCountries.countries.map(
                          (country) => ({
                            value: country,
                            label: country,
                          }),
                        )
                      : []
                  }
                  menuBuffer={30}
                  searchable={false}
                  clearable={false}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {formik.values.addNewCard ? (
            <React.Fragment>
              {/* new card */}
              <Row>
                <Col lg={12}>
                  <div className="mb-3 text-dark font-weight-bold">
                    Credit Card Details
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <ul className="card_logos pl-0">
                    <li
                      className={classnames("visa", {
                        off: cardType && cardType !== "visa",
                      })}
                    />
                    <li
                      className={classnames("mastercard", {
                        off: cardType && cardType !== "mastercard",
                      })}
                    />
                    <li
                      className={classnames("amex", {
                        off: cardType && cardType !== "amex",
                      })}
                    />
                    <li
                      className={classnames("discover", {
                        off: cardType && cardType !== "discover",
                      })}
                    />
                  </ul>
                </Col>
              </Row>

              <Row>
                <Col lg={8} className="pr-0">
                  <Form.Group controlId="cardNumber">
                    <Form.Label className="text-dark">
                      Credit Card Number{" "}
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      name="cardNumber"
                      isInvalid={
                        formik.touched.cardNumber && formik.errors.cardNumber
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.cardNumber}
                      placeholder="Enter credit card number"
                      onChange={formik.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.cardNumber ? formik.errors.cardNumber : ""}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* month */}
                <Col lg={3} className="pr-0">
                  <Form.Group
                    controlId="expirationDateMonth"
                    className="menu-up"
                  >
                    <Form.Label className="text-dark">
                      Expiry Date <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Select
                      name="expirationDateMonth"
                      value={formik.values.expirationDateMonth}
                      isInvalid={!formik.values.expirationDateMonth}
                      onChange={(opt) => {
                        formik.setFieldValue("expirationDateMonth", opt.value);
                      }}
                      placeholder="Month"
                      options={listOfMonths}
                      menuBuffer={30}
                      searchable={false}
                      clearable={false}
                    />
                  </Form.Group>
                </Col>

                {/* year */}
                <Col lg={3} className="pr-0">
                  <Form.Group
                    controlId="expirationDateYear"
                    className="menu-up"
                  >
                    <Form.Label className="text-dark">&nbsp;</Form.Label>
                    <Form.Select
                      name="expirationDateYear"
                      value={formik.values.expirationDateYear}
                      isInvalid={!formik.errors.expirationDateYear}
                      onChange={(opt) => {
                        formik.setFieldValue("expirationDateYear", opt.value);
                      }}
                      placeholder="Year"
                      options={listOfYears}
                      menuBuffer={30}
                      searchable={false}
                      clearable={false}
                      className="expiry-date"
                    />
                  </Form.Group>
                </Col>

                {/* cvv */}
                <Col lg={3} className="pr-0">
                  <Form.Group controlId="cvv">
                    <Form.Label className="text-dark">
                      CVV <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      name="cvv"
                      style={{ maxWidth: 68 }}
                      isInvalid={formik.touched.cvv && formik.errors.cvv}
                      onBlur={formik.handleBlur}
                      value={formik.values.cvv}
                      type="number"
                      placeholder="000"
                      onChange={formik.handleChange}
                      className="billing_cvv_input"
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.cvv}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
        </form>
      }
      footer={
        <React.Fragment>
          <Button variant="outline-secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            onClick={formik.submitForm}
            disabled={!formik.isValid || saving || !formik.values.firstName}
          >
            {saving ? "Updating" : "Update"}
          </Button>
        </React.Fragment>
      }
    />
  );
};

BillingUpdateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  pulseMSubscriptionDetails: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
  fetchPreferences: PropTypes.func,
};

export default BillingUpdateModal;
