import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import CustomerDetailsHeader from "src/shared/customer-details-sidebar/CustomerDetailsHeader";
import Card from "src/shared/styleguide/card/Card";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import MapPinIcon from "src/assets/images/customer/icon_map_pin.svg";
import errorIcon from "src/assets/images/enterprise/error.svg";
import statusIcon from "src/assets/images/enterprise/status.svg";
import successIcon from "src/assets/images/enterprise/success.svg";
import questionMarkIcon from "src/assets/images/enterprise/question-mark.svg";
import subscribedIcon from "src/assets/images/enterprise/subscribed.svg";
import { Col, Row } from "react-bootstrap";
import "./LocationDetailView.scss";

const InfoStrip = ({ img, info }) => {
  if (!info) return null;

  return (
    <div className="d-flex align-items-center info-text-container">
      {img}
      <div className="info-txt">{info}</div>
    </div>
  );
};

const LocationDetailView = ({ handleClose, details }) => {
  const formatAddress = () => {
    let address = "";
    if (details.address1) address += `${details.address1}, `;
    if (details.address2) address += `${details.address2}, `;
    if (details.city) address += `${details.city}, `;
    if (details.state) address += `${details.state}, `;
    if (details.zip) address += `${details.zip}, `;

    if (!address) return address;

    return address.slice(0, address.length - 2);
  };

  const getFeatureFlagStatusByType = (type) => {
    const data = details.companyFeatureFlags.filter(
      (value) => value.component === type,
    );

    return data[0]?.enabled || false;
  };

  const isCampaignEnabled = details?.companyFeatureFlags?.filter(
    (value) => value.component === "Campaign" && value.operation === "write",
  )[0]?.enabled;
  const isPostCardEnabled = getFeatureFlagStatusByType("PostcardCampaign");
  const isTextCampaignEnabled = getFeatureFlagStatusByType("TextCampaign");
  const isEmailCampaignCardEnabled =
    getFeatureFlagStatusByType("EmailCampaign");
  const isMagnetMailerCardEnabled = getFeatureFlagStatusByType("MagnetMailer");
  const isProspectMarketingCardEnabled =
    getFeatureFlagStatusByType("ProspectMarketing");

  const isCustomerLobbyEnabled = details.reviewPlatform === "customerlobby";
  return (
    <div className="location-detail-view-container">
      <CustomerDetailsHeader
        leftComponent="Details"
        rightComponent={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.001"
            height="16"
            viewBox="0 0 16.001 16"
            className="cursor-pointer"
            onClick={handleClose}
          >
            <g>
              <path
                d="M9.415 8l6.292-6.292A1 1 0 0 0 14.293.294L8 6.586 1.708.294A1 1 0 0 0 .293 1.709L6.586 8 .293 14.293a1 1 0 1 0 1.415 1.415L8 9.416l6.292 6.292a1 1 0 0 0 1.415-1.415z"
                transform="translate(0 -.001)"
              />
            </g>
          </svg>
        }
      />
      <div className="customer-details-content p-2">
        {/* 
        style param feels like inline style, and should be considered for 
        removal.  But we do use it per standard when utilizing this library 
        throughout the app. 
      */}
        {/* 
        TODO Scrollbars component accepts an autoHeightMin param that may 
        allow us to remove style param 
      */}
        {/*
        autoHeight?: boolean | undefined;
        autoHeightMin?: number | string | undefined; 
      */}
        <Row>
          <Scrollbars style={{ minHeight: "calc(100vh - 250px)" }}>
            <Col sm={12}>
              <Card>
                <div className="customer-info-container">
                  <div className="d-flex align-items-center">
                    <UserInitials
                      size="3.2rem"
                      fontSize="1rem"
                      initialsOverride={details?.companyId}
                    />
                    <div className="d-flex flex-column customer-name-container">
                      <a rel="noopener noreferrer" className="customer-name">
                        {details?.companyName}
                      </a>
                    </div>
                  </div>

                  <InfoStrip
                    img={
                      <PopupInfo
                        message={"Location"}
                        icon={<img src={MapPinIcon} alt="Location" />}
                      />
                    }
                    info={`Location: ${formatAddress()}`}
                  />
                  <InfoStrip
                    img={
                      <PopupInfo
                        message={"Status"}
                        icon={<img src={statusIcon} alt="Status" />}
                      />
                    }
                    info={`Status: ${details.status}`}
                  />
                </div>
              </Card>
            </Col>

            <Col className="mt-2" sm={12}>
              <TextingAccordion
                label={
                  <div className="d-flex align-items-center">
                    <div className="mr-2 tab-header">Review Platform</div>
                    <PopupInfo message="Review Platform" />
                  </div>
                }
              >
                <Row className="d-flex justify-content-between">
                  <Col className="d-flex flex-column align-items-center">
                    <span className="review-platform-item-heading">
                      Customer Lobby
                    </span>
                    <img
                      src={
                        isCustomerLobbyEnabled ? successIcon : questionMarkIcon
                      }
                      className="mt-2"
                    />
                    <span
                      className={isCustomerLobbyEnabled ? "text-success" : ""}
                    >
                      {isCustomerLobbyEnabled ? "Enabled" : "Not Enabled"}
                    </span>
                  </Col>
                  <div className="vr" />
                  <Col className="d-flex flex-column align-items-center">
                    <span className="review-platform-item-heading">pulseM</span>
                    <img
                      src={
                        isCustomerLobbyEnabled ? questionMarkIcon : successIcon
                      }
                      className="mt-2"
                    />
                    <span
                      className={isCustomerLobbyEnabled ? "" : "text-success"}
                    >
                      {isCustomerLobbyEnabled ? "Not Enabled" : "Enabled"}
                    </span>
                  </Col>
                </Row>
              </TextingAccordion>
            </Col>
            <Col className="mt-2" sm={12}>
              <TextingAccordion
                label={
                  <div className="d-flex align-items-center">
                    <div className="mr-2 tab-header">
                      Customer Campaign Status
                    </div>
                    <PopupInfo message="Customer Campaign Status" />
                  </div>
                }
              >
                <div className="campaign-status">
                  <div>
                    <img
                      src={
                        isPostCardEnabled && isCampaignEnabled
                          ? successIcon
                          : errorIcon
                      }
                    />
                    Postcard <br /> Campaign
                  </div>
                  <div>
                    <img
                      src={
                        isTextCampaignEnabled && isCampaignEnabled
                          ? successIcon
                          : errorIcon
                      }
                    />
                    Text <br /> Campaign
                  </div>
                  <div>
                    <img
                      src={
                        isEmailCampaignCardEnabled && isCampaignEnabled
                          ? successIcon
                          : errorIcon
                      }
                    />
                    Email
                    <br /> Campaign
                  </div>
                  <div>
                    <img
                      src={
                        isMagnetMailerCardEnabled && isCampaignEnabled
                          ? successIcon
                          : errorIcon
                      }
                    />
                    Magnet <br /> Mailer
                  </div>
                </div>
              </TextingAccordion>
            </Col>
            <Col className="mt-2 mb-5">
              <Card
                popupInfoMsg="Prospect Marketing Product"
                title="Prospect Marketing Product"
              >
                <img
                  src={
                    isProspectMarketingCardEnabled ? subscribedIcon : errorIcon
                  }
                  className="mr-2"
                />
                {isProspectMarketingCardEnabled ? "Subscribed" : "Unsubscribed"}
              </Card>
            </Col>
          </Scrollbars>
        </Row>
      </div>
    </div>
  );
};

export default LocationDetailView;
