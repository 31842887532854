import React, { useEffect, useState } from "react";
import ReviewsSettingInfo from "./ReviewsSettingInfo";
import RedCircleX from "../../../../img/red_circle_x.png";
import GreenCircleChecked from "../../../../img/green_circle_checked.png";
import information from "../../../../img/information.svg";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { getReviewStatusesHelper } from "./review_statuses_query";
import { useSelector } from "react-redux";

const ReviewStatusCard = () => {
  const showCompanyId = useGetCompanyIdFromUrl();
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [reviewStatuses, setReviewStatuses] = useState({
    reviewsPage: false,
    smartInvite: false,
    autoInvite: false,
    directConnect: false,
    googleAuthentication: false,
  });

  const queryHelper = async () => {
    let res = await getReviewStatusesHelper({ companyId: showCompanyId });
    if (!res || !res.data || !res.data.data || !res.data.data.getReviewStatuses)
      return null;

    setReviewStatuses(res.data.data.getReviewStatuses);
  };

  const imageHelper = (counter) => {
    if (counter) return GreenCircleChecked;

    return RedCircleX;
  };

  useEffect(() => {
    queryHelper();
  }, []);

  let {
    reviewsPage,
    smartInvite,
    autoInvite,
    directConnect,
    googleAuthentication,
  } = reviewStatuses;

  return (
    <div className="review-status-container">
      <div className="filter-title mb-2">Review Status</div>
      <div className="gray-line-separator"></div>
      <ReviewsSettingInfo
        imgOne={imageHelper(reviewsPage)}
        imgTwo={information}
        content={"Review Page"}
        hoverContent={
          reviewsPage
            ? "Your review page is up and running"
            : "Your review page is not up and running"
        }
        link={`/companies/${showCompanyId}/review-settings/publishing-notifications`}
      />
      {featureFlags.smartInviteWrite ? (
        <ReviewsSettingInfo
          imgOne={imageHelper(smartInvite)}
          imgTwo={information}
          content={"Smart Invite"}
          hoverContent={
            smartInvite
              ? "Smart Invitations are enabled"
              : "Smart Invitations are not enabled"
          }
          link={`/companies/${showCompanyId}/review-settings/invites`}
          top={smartInvite ? "-40px" : "-60px"}
        />
      ) : null}
      <ReviewsSettingInfo
        imgOne={imageHelper(autoInvite)}
        imgTwo={information}
        content={"Auto Invite"}
        hoverContent={
          autoInvite
            ? "Invitations to write reviews are being sent automatically"
            : "Inviitations to write reviews are not being sent automatically"
        }
        link={`/companies/${showCompanyId}/review-settings/invites`}
        top={autoInvite ? "-60px" : "-82px"}
      />
      <ReviewsSettingInfo
        imgOne={imageHelper(directConnect)}
        imgTwo={information}
        content={"Direct Connect"}
        hoverContent={
          directConnect
            ? "Direct Connect is up and running"
            : "Direct Connect is not up and running"
        }
        link={`/companies/${showCompanyId}/direct-connect-settings`}
      />
      <ReviewsSettingInfo
        imgOne={imageHelper(googleAuthentication)}
        imgTwo={information}
        content={"Google Authentication"}
        hoverContent={
          googleAuthentication
            ? "Google authentication is enabled for review invites sent via the mobile app."
            : "Google authentication is not enabled for review invites sent via the mobile app."
        }
        link={`/companies/${showCompanyId}/review-settings/invites`}
        top={"-82px"}
        marginBottom={true}
      />
    </div>
  );
};

export default ReviewStatusCard;
