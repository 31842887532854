import React, { useEffect, useRef, useState, useContext } from "react";
import "src/components/settings/widgets/review_widgets/StaticButtonsBody.scss";
import LightBlueCard from "src/components/settings/widgets/review_widgets/LightBlueCard.jsx";
import { copyAppointmentAndDynamicButtonV2 } from "src/utils/CopyToClipboardUtil.js";
import RequestAnAppointment from "src/components/settings/widgets/review_widgets/RequestAnAppointment.jsx";
import axios from "axios";
import { useApolloClient } from "@apollo/react-hooks";
import { QUERY_APPOINTMENT_URL } from "src/graphql/appointments/queries";
import SettingsContext from "src/shared/context/SettingsContext";
import { NO_CACHE } from "src/shared/Constants";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const AppointmentsAndReviewsBody = ({ handleToast, widgetSettings }) => {
  const companyId = useGetCompanyIdFromUrl();
  const dynamicBtnEl = useRef(null);
  const client = useApolloClient();
  const settingsContext = useContext(SettingsContext);
  const [appointmentLink, setAppointmentLink] = useState("");
  const [copied, setCopied] = React.useState(false);

  const handleCompanyName = () => {
    if (widgetSettings.data && !widgetSettings.loading) {
      return widgetSettings.data.company.companyName;
    }

    return "";
  };

  const dynamicBtnHelper = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_SECURE_URL}/widgets/widget-view/?company_id=${companyId}&widget_type=dynamic_reviews_button`,
      );

      if (dynamicBtnEl && res.data) {
        dynamicBtnEl.current.innerHTML = res.data;
        let dynamicBtnChild = dynamicBtnEl.current.children[0];
        dynamicBtnChild.removeAttribute("href");
        dynamicBtnChild.style.cursor = "auto";
      }
    } catch (e) {}
  };

  /**
   * Fetch the appointment link
   */
  const fetchAppointmentLink = () => {
    client
      .query({
        query: QUERY_APPOINTMENT_URL,
        variables: {
          companyId: companyId,
        },
        fetchPolicy: NO_CACHE,
      })
      .then((res) => {
        if (res.data && res.data.appointment) {
          setAppointmentLink(
            settingsContext.showAppointment
              ? `${res.data.appointment.url}?s=widget`
              : res.data.appointment.url,
          );
        }
      })
      .catch((err) => {
        // do nothing
      });
  };

  useEffect(() => {
    dynamicBtnHelper();
    fetchAppointmentLink();
  }, []);

  const handleDynamicButtonClick = (callback) => {
    handleToast();
    callback({
      companyId,
      abbreviatedName: widgetSettings.data.company.abbreviatedName,
      companyName: widgetSettings.data.company.companyName,
      appointmentLink: appointmentLink,
    });
    setCopiedTemporarily();
  };

  const setCopiedTemporarily = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div>
      <LightBlueCard />
      <div
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        className="buttons-container"
      >
        <div style={{ marginBottom: "0px" }} className="button-outer-container">
          <div
            style={{ paddingTop: "22px", paddingBottom: "15px" }}
            className="button-container-appointments"
          >
            <RequestAnAppointment width={"220px"} />
            <div ref={dynamicBtnEl} />
          </div>
          <div
            onClick={() =>
              handleDynamicButtonClick(copyAppointmentAndDynamicButtonV2)
            }
            className="copy-code"
          >
            {copied ? "Copied" : "Copy Code"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsAndReviewsBody;
