import React from "react";
import classnames from "classnames";
import GoogleLogin from "react-google-login";
import PropTypes from "prop-types";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import Button from "src/shared/styleguide/button/Button";
import { MUTATION_GOOGLE_TOKENS } from "src/graphql/settings/reviews/mutations";
import BlockUi from "react-block-ui";
import { useMutation } from "@apollo/react-hooks";

const GoogleConnectButton = ({
  connected = false,
  setConnected,
  setTokens,
}) => {
  // variables
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showCompanyId = useGetCompanyIdFromUrl();
  // state
  const [loading, setLoading] = React.useState(false);

  // call apis
  const [getToken] = useMutation(MUTATION_GOOGLE_TOKENS);

  // methods
  const responseGoogle = (res) => {
    setLoading(true);
    if (res && res.code) {
      getToken({
        variables: {
          companyId: showCompanyId,
          clientId: GOOGLE_CLIENT_ID,
          code: res.code,
        },
      })
        .then((tokenRes) => {
          if (
            tokenRes &&
            tokenRes.data &&
            tokenRes.data.googleTokensGenerator
          ) {
            setConnected(true);
            setTokens(tokenRes.data.googleTokensGenerator);
          } else {
            setConnected(false);
          }
        })
        .catch(() => {
          setConnected(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // render
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      accessType="offline"
      responseType="code"
      prompt="consent"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
      render={(renderProps) => (
        <BlockUi
          loader={<React.Fragment />}
          blocking={renderProps.disabled || loading}
        >
          <span
            onClick={connected ? undefined : renderProps.onClick}
            className={classnames("d-inline-flex", {
              "cursor-pointer": !connected,
            })}
            style={{
              borderRadius: 1,
              border: "1px solid #4285f4",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.25)",
            }}
          >
            <span className="p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 48 48"
              >
                <g>
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </g>
              </svg>
            </span>

            <span
              style={{
                backgroundColor: "#4285f4",
                color: "white",
                minWidth: 136,
              }}
              className="p-2 d-flex flex-grow-1 justify-content-center align-items-center"
            >
              {connected ? "Connected" : "Sign in with Google"}
            </span>
          </span>
          {connected ? (
            <Button
              variant="outline-secondary"
              onClick={() => setConnected(false)}
              size="lg"
              className="ml-3 p-2"
            >
              Disconnect
            </Button>
          ) : null}
        </BlockUi>
      )}
    />
  );
};

GoogleConnectButton.propTypes = {
  connected: PropTypes.bool,
  setConnected: PropTypes.func.isRequired,
  setTokens: PropTypes.func.isRequired,
};

export default GoogleConnectButton;
