import { gql } from "apollo-boost";

export const CREATE_LOCATION_MUTATION = gql`
  mutation createLocation(
    $companyName: String!
    $companyDescription: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $country: String
    $zip: String
    $website: String
    $categoryIds: Int
    $isActive: Boolean
    $companyUser: CompanyUserInput
  ) {
    createLocation(
      companyName: $companyName
      companyDescription: $companyDescription
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      country: $country
      zip: $zip
      website: $website
      categoryIds: $categoryIds
      isActive: $isActive
      companyUser: $companyUser
    )
  }
`;

export const UPDATE_ENTERPRISE_MUTATION = gql`
  mutation updateEnterprise(
    $name: String
    $description: String
    $phoneNumber: String
    $website: String
    $address1: String
    $address2: String
    $state: String
    $city: String
    $zipcode: String
    $country: String
    $billingEmail: String
    $categoryIds: [Int!]
    $enableReviewsPage: Boolean
  ) {
    updateEnterprise(
      name: $name
      description: $description
      phoneNumber: $phoneNumber
      website: $website
      address1: $address1
      address2: $address2
      state: $state
      city: $city
      zipcode: $zipcode
      country: $country
      billingEmail: $billingEmail
      categoryIds: $categoryIds
      enableReviewsPage: $enableReviewsPage
    ) {
      name
      description
      phoneNumber
      website
      enableReviewsPage
      reviewPageUrl
      address1
      address2
      city
      state
      zipcode
      country
      billingEmail
      categoryIds
    }
  }
`;

export const CREATE_ENTERPRISE_USER = gql`
  mutation createEnterpriseUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $role: String!
    $status: String!
  ) {
    createEnterpriseUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      role: $role
      status: $status
    ) {
      id
      firstName
      lastName
      email
      isActive
      isManager
      userDisplayName
    }
  }
`;

export const DELETE_ENTERPRISE_USER = gql`
  mutation deleteEnterpriseUser($id: ID!) {
    deleteEnterpriseUser(id: $id)
  }
`;

export const UPDATE_ENTERPRISE_USER = gql`
  mutation updateEnterpriseUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $passwordConfirmation: String
    $role: String
    $status: String
  ) {
    updateEnterpriseUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      role: $role
      status: $status
    ) {
      id
      firstName
      lastName
      email
      isActive
      isManager
      userDisplayName
    }
  }
`;
export const UPDATE_NOTIFICATIONS_SETTINGS = gql`
  mutation updateEnterpriseSetting(
    $enterpriseEmailSettings: [EnterpriseEmailSettingInput!]!
    $enterpriseSetting: EnterpriseSettingInput
  ) {
    updateEnterpriseSettings(
      enterpriseEmailSettings: $enterpriseEmailSettings
      enterpriseSetting: $enterpriseSetting
    ) {
      id
      categoryName
      deliverToCompanyUser
      deliverToEnterpriseUser
    }
  }
`;

export const CREATE_ENTERPRISE_SUBSCRIPTION = gql`
  mutation CreateEnterpriseSubscription(
    $billing: BillingInput
    $card: CardInput
    $accountLevelTransition: AccountLevelTransitionInput
  ) {
    createEnterpriseSubscription(
      billing: $billing
      card: $card
      accountLevelTransition: $accountLevelTransition
    ) {
      billing {
        address1
        address2
        city
        country
        countryLabel
        email
        firstName
        lastName
        state
        stateLabel
        zip
      }
      cardType
      enterpriseId
      expirationDate
      lastFour
      status
    }
  }
`;

export const UPDATE_ENTERPRISE_SUBSCRIPTION = gql`
  mutation UpdateEnterpriseSubscription(
    $billing: BillingInput
    $card: CardInput
    $accountLevelTransition: AccountLevelTransitionInput
  ) {
    updateEnterpriseSubscription(
      billing: $billing
      card: $card
      accountLevelTransition: $accountLevelTransition
    ) {
      billing {
        address1
        address2
        city
        country
        countryLabel
        email
        firstName
        lastName
        state
        stateLabel
        zip
      }
      cardType
      enterpriseId
      expirationDate
      lastFour
      status
    }
  }
`;
