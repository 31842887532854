import { gql } from "apollo-boost";

const QUERY_INVITATIONS_SUMMARY_FILTER = gql`
  query invitationsSummaryFilters(
    $companyId: ID!
    $searchTerm: String
    $missingEmailAddress: Boolean
    $missingPhoneNumber: Boolean
  ) {
    invitationsSummaryFilters(
      companyId: $companyId
      searchTerm: $searchTerm
      missingPhoneNumber: $missingPhoneNumber
      missingEmailAddress: $missingEmailAddress
    ) {
      allCustomers
      lastInvitedViaEmail
      lastInvitedViaCall
      eligibleForInvitation
      neverResponded
    }
  }
`;

const QUERY_GET_CUSTOMER_CALL_CREDITS = gql`
  query getCredits($companyId: ID!) {
    getCredits(companyId: $companyId, creditType: "CustomerCallCredit") {
      credits
      formattedCount
      formattedDate
      formattedGrantDate
      inProgress
      nextGrantDate
    }
  }
`;

const QUERY_INVITATIONS_SUMMARY = gql`
  query invitationsSummary(
    $companyId: ID!
    $searchTerm: String
    $viewBy: String
    $missingPhoneNumber: Boolean
    $missingEmailAddress: Boolean
    $page: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
  ) {
    invitationsSummary(
      companyId: $companyId
      searchTerm: $searchTerm
      viewBy: $viewBy
      missingPhoneNumber: $missingPhoneNumber
      missingEmailAddress: $missingEmailAddress
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      contacts {
        contactId
        callQueuedOn
        callable
        contactDisplayName
        createdAt
        daysLeftToContact
        eligibleToContactOn
        email
        emailable
        lastContactedAt
        lastContactedMethod
        lastName
        lastServiceDate
        formattedPhoneAlt
        formattedPhoneDaytime
        formattedPhoneMobile
        source
        wantsEmail
        wantsSms
        firstName
        lastName
        sourceLabel
        lastReviewSource
      }
      total
      page
      limit
    }
  }
`;

const COMPANY_URLS = gql`
  query companyUrls($companyId: ID!) {
    companyUrls(companyId: $companyId) {
      customInvitationUrl
      customInvitationPdfUrl
    }
  }
`;

export const GET_CREDITS = gql`
  query getCredits($companyId: ID!, $creditType: String!) {
    getCredits(companyId: $companyId, creditType: $creditType) {
      credits
      nextGrantDate
      formattedGrantDate
      formattedDate
      formattedCount
      creditCost
      inProgress
    }
  }
`;

export {
  COMPANY_URLS,
  QUERY_INVITATIONS_SUMMARY_FILTER,
  QUERY_INVITATIONS_SUMMARY,
  QUERY_GET_CUSTOMER_CALL_CREDITS,
};
