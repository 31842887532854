import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GreenCircle from "src/img/green_circle_checked.png";
import RedCircle from "src/img/red_circle_x.png";
import SearchInputWithDropdown from "src/shared/search-input-dropdown/SearchInputWithDropdown";
import SidebarMenuDropdownGrouped from "src/shared/sidebar-menu/SidebarMenuDropdownGrouped";
import SidebarMenuMultiDropdown from "src/shared/sidebar-menu/SidebarMenuMultiDropdown";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import {
  FILTER_BY_TYPE,
  SEARCH_BY_TYPE_OPTIONS,
  SORT_BY,
} from "../customerConstants.js";
import { CustomersContext } from "./CustomersContext.js";
import BrowserUtil from "src/utils/BrowserUtil";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl.js";

const CustomerSidebar = ({
  directConnectLastRunDate,
  directConnectSettings,
}) => {
  const {
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    searchType,
    setSearchType,
    setDetailsShown,
    disabledFilters,
    setDisabledFilters,
  } = useContext(CustomersContext);
  const companyId = useGetCompanyIdFromUrl();
  // state
  const [filterByType, setFilterByType] = useState(FILTER_BY_TYPE);

  // methods
  const updateFilters = (items, filterName) => {
    let newItems = [...items];
    //console.log(newItems.map(function(item) { return item["value"]; }))
    if (items.length > 1 && items.slice(-1)[0].value === "all") {
      newItems = [items.pop()];
    } else if (items.length >= 1) {
      newItems = items.filter((item) => item.value !== "all");
    } else if (items.length === 0) {
      newItems = [{ label: "All", value: "all" }];
    }
    setDetailsShown(false);
    setFilters({ ...filters, [`${filterName}`]: newItems });
  };

  const disableFilterByTypes = (arg) => {
    let filterType = [...filterByType];
    filterType.map((item) => {
      if (item.label !== "All") {
        let disabledItem = disabledFilters.find((filter) =>
          filter.hasOwnProperty(item.value),
        );
        item.disabled = disabledItem ? !disabledItem[item.value] : true;
      }
    });
    setFilterByType(filterType);
  };

  // useEffect
  useEffect(() => {
    setDetailsShown(false);
    setFilters({ ...filters, searchTerm: searchTerm });
  }, [searchTerm]);

  useEffect(() => {
    setDetailsShown(false);
    if (filters.searchType.value !== searchType.value) {
      setFilters({ ...filters, searchType: searchType, searchTerm: "" });
    }
  }, [searchType]);

  useEffect(() => {
    if (disabledFilters && disabledFilters.length > 0) {
      disableFilterByTypes(filterByType);
    }
  }, [disabledFilters]);

  // render
  return (
    <div className="customers-sidebar-view">
      {/* search */}
      <div className="search-title ">Search</div>

      {/* search Input with dropdown */}
      <SearchInputWithDropdown
        searchTerm={searchTerm}
        searchType={searchType}
        setSearchTerm={setSearchTerm}
        setSearchType={setSearchType}
        searchTypeOptions={SEARCH_BY_TYPE_OPTIONS}
        maxLength={BrowserUtil.isIPad() ? 40 : 50}
      />

      <div className="mt-2">
        {/* sort by */}
        <SidebarMenuDropdownGrouped
          title="Sort By"
          value={filters.sortBy.value}
          name="sort-by"
          isClearable={false}
          onChange={(item) => {
            setDetailsShown(false);
            setFilters({
              ...filters,
              sortBy: { value: item.value },
              sortOrder: { value: "desc" },
            });
          }}
          options={SORT_BY}
          isOptionDisabled={(option) => option.disabled}
          inputProps={{ readOnly: true }}
        />

        {/* Show customers */}
        <SidebarMenuMultiDropdown
          title="Show Customers"
          disabled={false}
          inputProps={{ readOnly: true }}
          name="filter-by-type"
          options={filterByType}
          onChange={(value) => updateFilters(value, "filterByType")}
          placeholder="All"
          value={filters.filterByType}
        />
      </div>

      {/* Direct Connect */}
      <div className="credit-info">
        <div className="title">Status</div>
        <hr className="my-1" />
        <div
          style={{ marginTop: "0.9rem", marginBottom: "8rem" }}
          className="d-flex align-items-center"
        >
          <img
            className="checkmark-img"
            src={directConnectSettings ? GreenCircle : RedCircle}
            alt="checkmark"
          />
          <Link
            to={`/companies/${companyId}/direct-connect-settings`}
            target="_blank"
            className="direct-connect-link"
          >
            Direct Connect
          </Link>
          {directConnectLastRunDate && directConnectSettings && (
            <PopupInfo
              tooltipClassName="tooltip-w-213"
              message={`Last Update Date: ${directConnectLastRunDate}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerSidebar;
