import React from "react";
import visaIcon from "src/assets/images/settings/visa_card_logo.png";
import mastercardIcon from "src/assets/images/settings/mastercard_card_logo.png";
import discoverCardIcon from "src/assets/images/settings/discover_card_logo.png";
import amexCardIcon from "src/assets/images/settings/amex_card_logo.png";

const BillingCardStatus = ({ card }) => {
  const cardType = card && card.cardType ? card.cardType : "";
  switch (cardType) {
    case "visa":
      return <img src={visaIcon} alt={cardType} />;
    case "mastercard":
      return <img src={mastercardIcon} alt={cardType} />;
    case "amex":
      return <img src={amexCardIcon} alt={cardType} />;
    case "discover":
      return <img src={discoverCardIcon} alt={cardType} />;
    default:
      return null;
  }
};

export default BillingCardStatus;
