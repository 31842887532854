import React from "react";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { QUERY_PUBLISHING_NOTIFICATION } from "src/graphql/settings/reviews/queries";
import { MUTATION_POSTING_FREQUENCY_SETTINGS } from "src/graphql/settings/reviews/mutations";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import SettingsCard from "../SettingsCard";
import { addToast } from "src/utils/ToastUtil";

const PostingFrequencySettings = () => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const formik = useFormik({
    initialValues: {
      reviewsDailyMax: 1,
      reviewsIntervalDays: 1,
    },
    validationSchema: Yup.object().shape({
      reviewsDailyMax: Yup.number()
        .typeError("Invalid number")
        .required("Required field")
        .positive("Field must be greater than 0"),
      reviewsIntervalDays: Yup.number()
        .typeError("Invalid number")
        .required("Required field")
        .positive("Field must be greater than 0"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  // call apis
  const { loading } = useQuery(QUERY_PUBLISHING_NOTIFICATION, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    variables: {
      companyId: showCompanyId,
    },
    onCompleted(data) {
      populateData(data);
    },
  });
  const [saveSettings] = useMutation(MUTATION_POSTING_FREQUENCY_SETTINGS);

  // methods
  const populateData = (data) => {
    if (data && data.getCompanyInformation) {
      const { setting } = data.getCompanyInformation;
      formik.resetForm({
        values: {
          reviewsDailyMax: setting.reviewsDailyMax,
          reviewsIntervalDays: setting.reviewsIntervalDays,
        },
      });
    }
  };
  const saveChanges = ({ reviewsDailyMax, reviewsIntervalDays }) => {
    saveSettings({
      variables: {
        companyId: showCompanyId,
        reviewsDailyMax,
        reviewsIntervalDays,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Publishing/Notifications settings updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Publishing/Notifications settings not saved.",
        });
      });
  };

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Posting Frequency"
      settingsDescription="Select the number of reviews you would like to post to Facebook / Twitter per day and specify any intervals. By default we post 3 reviews every day."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 35 35"
        >
          <g>
            <g>
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(25.02 12.988)"
              />
              <path d="M29.531 2.734h-1.777V1.367a1.367 1.367 0 0 0-2.734 0v1.367H18.8V1.367a1.367 1.367 0 0 0-2.734 0v1.367H9.912V1.367a1.367 1.367 0 0 0-2.734 0v1.367H5.469A5.475 5.475 0 0 0 0 8.2v21.331A5.475 5.475 0 0 0 5.469 35h10.459a1.367 1.367 0 0 0 0-2.734H5.469a2.737 2.737 0 0 1-2.734-2.734V8.2a2.737 2.737 0 0 1 2.734-2.731h1.709v1.367a1.367 1.367 0 0 0 2.734 0V5.469h6.152v1.367a1.367 1.367 0 0 0 2.734 0V5.469h6.222v1.367a1.367 1.367 0 0 0 2.734 0V5.469h1.777A2.737 2.737 0 0 1 32.266 8.2V16A1.367 1.367 0 0 0 35 16V8.2a5.475 5.475 0 0 0-5.469-5.466z" />
              <path
                d="M278.271 270a8.271 8.271 0 1 0 8.271 8.271 8.281 8.281 0 0 0-8.271-8.271zm0 13.809a5.537 5.537 0 1 1 5.537-5.537 5.543 5.543 0 0 1-5.537 5.537z"
                transform="translate(-251.543 -251.543)"
              />
              <path
                d="M374.35 332.8h-.615v-1.436a1.367 1.367 0 0 0-2.734 0v2.8a1.367 1.367 0 0 0 1.367 1.367h1.982a1.367 1.367 0 0 0 0-2.734z"
                transform="translate(-345.639 -307.441)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(19.072 12.988)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                transform="translate(13.125 18.936)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(7.178 12.988)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(7.178 18.936)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(7.178 24.883)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(13.125 24.883)"
              />
              <circle
                cx="1.367"
                cy="1.367"
                r="1.367"
                transform="translate(13.125 12.988)"
              />
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <form onSubmit={formik.handleSubmit}>
          {/* 1. Review(s) posted per day */}
          <div className="d-flex flex-row mt-4 align-items-center">
            <div className="d-flex flex-row align-items-center">
              <span className="text-dark mt-1 mr-2">
                Review(s) posted per day
                <span className="ml-2 text-danger">*</span>
              </span>
            </div>
            <Form.Group
              style={{ marginLeft: 12, marginRight: 12, width: 80 }}
              className="mb-0"
              controlId="input1"
            >
              <Form.Control
                type="number"
                name="reviewsDailyMax"
                onChange={formik.handleChange}
                value={formik.values.reviewsDailyMax}
                className="text-dark"
              />
            </Form.Group>
          </div>
          <div className="text-danger">
            {formik.errors.reviewsDailyMax ? (
              <div>{formik.errors.reviewsDailyMax}</div>
            ) : null}
          </div>

          {/* 2. Review(s) are posted every */}
          <div className="d-flex flex-row mt-4 align-items-center">
            <div className="d-flex flex-row align-items-center">
              <span className="text-dark mt-1 mr-2">
                Review(s) are posted every
                <span className="ml-2 text-danger">*</span>
              </span>
            </div>
            <Form.Group
              style={{ marginLeft: 12, marginRight: 12, width: 80 }}
              className="mb-0"
              controlId="intpu2"
            >
              <Form.Control
                type="number"
                name="reviewsIntervalDays"
                onChange={formik.handleChange}
                value={formik.values.reviewsIntervalDays}
                className="text-dark"
              />
            </Form.Group>
            <span className="text-dark">day(s)</span>
          </div>
          <div className="text-danger">
            {formik.errors.reviewsIntervalDays ? (
              <div>{formik.errors.reviewsIntervalDays}</div>
            ) : null}
          </div>

          <div className="mt-3">
            <Button
              type="submit"
              variant="primary"
              className="mr-2"
              disabled={!formik.dirty || !formik.isValid}
            >
              Save Changes
            </Button>
          </div>
        </form>
      }
    />
  );
};

export default PostingFrequencySettings;
