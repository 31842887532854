import React from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import { Row, Col } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import BillingEmail from "./BillingEmail";
import { useQuery } from "@apollo/react-hooks";
import BillingCardStatus from "src/components/settings/billing/BillingCardStatus";
import BillingCardIcon from "src/components/settings/billing/BillingCardIcon";
import CalendarUtil from "src/utils/CalendarUtil";
import BillingUpdateModal from "./BillingUpdateModal";
import { NETWORK_ONLY } from "src/shared/Constants";
import { ENTERPRISE_SUBSCRIPTION } from "src/graphql/enterprise/queries";

const BillingPreferences = () => {
  // state
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [subscription, setSubscription] = React.useState();
  const [pulseMSubscription, setPulseMSubscription] = React.useState();
  const [clCardDetails, setClCardDetails] = React.useState(true);

  // call apis
  const { refetch: fetchPreferences } = useQuery(ENTERPRISE_SUBSCRIPTION, {
    fetchPolicy: NETWORK_ONLY,
    onCompleted: ({ enterpriseSubscription }) => {
      populateData(enterpriseSubscription);
    },
  });

  // method
  const populateData = (data) => {
    if (data) {
      setSubscription(data);

      if (data?.status === "current") {
        setClCardDetails(true);
      }
    }

    if (data && data.pulseMSubscription) {
      setPulseMSubscription(data.pulseMSubscription);
      if (
        data?.subscriptionDetails?.status !== "current" &&
        data.pulseMSubscription.status === "current"
      ) {
        setClCardDetails(false);
      }
    }
  };

  const getExpiryDate = () => {
    if (subscription?.status === "current") {
      return subscription.expirationDate;
    } else if (pulseMSubscription?.status === "current") {
      return pulseMSubscription.expirationDate;
    } else {
      return subscription.expirationDate;
    }
  };

  const getLastFour = () => {
    if (subscription?.status === "current") {
      return subscription.lastFour;
    } else if (pulseMSubscription?.status === "current") {
      return pulseMSubscription.lastFour;
    } else {
      return subscription.lastFour;
    }
  };

  const getCardDetails = () => {
    if (subscription?.status === "current") {
      return subscription;
    } else if (pulseMSubscription?.status === "current") {
      return pulseMSubscription;
    } else {
      return subscription;
    }
  };

  // render
  return (
    <React.Fragment>
      {/* modal */}
      {!subscription && (
        <Button
          onClick={() => setShowUpdateModal(true)}
          className="mt-3 py-2 add-billing-btn"
        >
          Add Billing Information
        </Button>
      )}
      {showUpdateModal ? (
        <BillingUpdateModal
          fetchPreferences={() =>
            fetchPreferences().then((res) => {
              populateData(res.data.enterpriseSubscription);
            })
          }
          subscription={subscription}
          pulseMSubscriptionDetails={pulseMSubscription}
          show={showUpdateModal}
          setShow={setShowUpdateModal}
        />
      ) : null}
      {subscription ? (
        <React.Fragment>
          <SettingsCard
            loading={false}
            cardStyle={{
              marginBottom: 32,
              padding: 0,
            }}
            settingsForm={
              <div>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="h3 mb-3 text-dark">
                      Credit Card Holder Details
                    </div>

                    <Row className="mb-3">
                      {/* first name */}
                      <Col sm={12} md={6}>
                        <div>First Name</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.firstName
                            : pulseMSubscription.billing.firstName) || "-"}
                        </div>
                      </Col>
                      {/* last name */}
                      <Col sm={12} md={6}>
                        <div>Last Name</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.lastName
                            : pulseMSubscription.billing.lastName) || "-"}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      {/* email address */}
                      <Col sm={12} md={6}>
                        <div>Email Address</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.email
                            : pulseMSubscription.billing.email) || "-"}
                        </div>
                      </Col>
                      {/* street address */}
                      <Col sm={12} md={6}>
                        <div>Street Address</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.address1
                            : pulseMSubscription.billing.address1) || "-"}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      {/* suite */}
                      <Col sm={12} md={6}>
                        <div>Suite#</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.address2
                            : pulseMSubscription.billing.address2) || "-"}
                        </div>
                      </Col>
                      {/* city */}
                      <Col sm={12} md={6}>
                        <div>City</div>
                        <div className="text-dark  truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.city
                            : pulseMSubscription.billing.city) || "-"}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      {/* ZIP */}
                      <Col sm={12} md={6}>
                        <div>ZIP/Postal code</div>
                        <div className="text-dark  truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.zip
                            : pulseMSubscription.billing.zip) || "-"}
                        </div>
                      </Col>
                      {/* state */}
                      <Col sm={12} md={6}>
                        <div>State</div>
                        <div className="text-dark  truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.stateLabel
                            : pulseMSubscription.billing.stateLabel) || "-"}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      {/* country */}
                      <Col sm={12} md={6}>
                        <div>Country</div>
                        <div className="text-dark truncate-overflow">
                          {(clCardDetails
                            ? subscription.billing.countryLabel
                            : pulseMSubscription.billing.countryLabel) || "-"}
                        </div>
                      </Col>
                      <Col sm={12} md={6}></Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="h3 mb-3 text-dark">Credit Card Details</div>

                    {/* card number */}
                    <Row className="mb-3">
                      <Col xs={12}>
                        <div>Card Number</div>
                        <div className="text-dark">
                          <span
                            className="mr-2"
                            style={{ position: "relative", top: 3 }}
                          >
                            ****
                          </span>
                          <span
                            className="mr-2"
                            style={{ position: "relative", top: 3 }}
                          >
                            ****
                          </span>
                          <span
                            className="mr-2"
                            style={{ position: "relative", top: 3 }}
                          >
                            ****
                          </span>
                          <span className="mr-3">{getLastFour()}</span>
                          <BillingCardIcon card={getCardDetails()} />
                        </div>
                      </Col>
                    </Row>

                    {/* expiry date */}
                    <Row className="mb-3">
                      <Col xs={12}>
                        <div>Expiry Date</div>
                        <div className="text-dark">
                          {getExpiryDate()
                            ? CalendarUtil.fromStrToStr(
                                getExpiryDate(),
                                "yyyy-MM-dd",
                                "MM/yyyy",
                              )
                            : "N/A"}
                        </div>
                      </Col>
                    </Row>

                    {/* card status */}
                    <Row className="mb-3">
                      <Col xs={12}>
                        <BillingCardStatus card={getCardDetails()} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Button
                      onClick={() => {
                        setShowUpdateModal(true);
                      }}
                    >
                      Update Details
                    </Button>
                  </Col>
                </Row>
              </div>
            }
          />
        </React.Fragment>
      ) : null}

      <BillingEmail />
    </React.Fragment>
  );
};

export default BillingPreferences;
