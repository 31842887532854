import React from "react";
import Button from "src/shared/styleguide/button/Button";
import { QUERY_FACEBOOK_PROFILE } from "src/graphql/settings/reviews/queries";
import {
  MUTATION_CREATE_FACEBOOK_PROFILE,
  MUTATION_FACEBOOK_PAGE_STATUS,
  MUTATION_RESET_FACEBOOK,
} from "src/graphql/settings/reviews/mutations";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import FullPageOverlay from "src/shared/styleguide/overlay/FullPageOverlay";
import FacebookProfileInformation from "./FacebookProfileInformation";
import FacebookConnectButton from "./FacebookConnectButton";
import {
  REVIEW_SETTINGS_FETCH_POLICY,
  CLOSE_WINDOW_AFTER,
} from "src/constants/ReviewSettings";
import { addToast } from "src/utils/ToastUtil";

const FacebookConnectionSettings = ({ loading, disconnectModal }) => {
  const showCompanyId = useGetCompanyIdFromUrl();
  // state
  const client = useApolloClient();
  const location = useLocation();
  const [authCode, setAuthCode] = React.useState();
  const [hasAuthCode, setHasAuthCode] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [connected, setConnected] = React.useState(false);

  // call apis
  const { loading: loadingProfile, data: facebookData } = useQuery(
    QUERY_FACEBOOK_PROFILE,
    {
      fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
      variables: {
        companyId: showCompanyId,
      },
      onCompleted(data) {
        populateData(data);
      },
    },
  );
  const [createProfile] = useMutation(MUTATION_CREATE_FACEBOOK_PROFILE);
  const [resetProfile] = useMutation(MUTATION_RESET_FACEBOOK);
  const [setStatus] = useMutation(MUTATION_FACEBOOK_PAGE_STATUS);

  // methods
  const refetch = () => {
    return client.query({
      query: QUERY_FACEBOOK_PROFILE,
      fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
      variables: {
        companyId: showCompanyId,
      },
      onCompleted(data) {
        populateData(data);
      },
    });
  };

  const populateData = (data) => {
    if (
      data &&
      data.socialNetworkProfiles &&
      data.socialNetworkProfiles.length > 0
    ) {
      setConnected(true);
    } else {
      setConnected(false);
    }
  };
  const authHandler = ({ authCode }) => {
    if (authCode) {
      setReset(false);
      createProfile({
        variables: {
          companyId: showCompanyId,
          code: authCode,
        },
      })
        .then(() => {
          refetch().then((res) => {
            populateData(res ? res.data : null);
          });
          addToast({
            type: "success",
            message: "Facebook is connected",
          });
        })
        .catch(() => {
          addToast({
            type: "error",
            message: "Facebook not connected.",
          });
        });
    }
  };
  const disconnect = () => {
    setReset(true);
    resetProfile({
      variables: {
        companyId: showCompanyId,
      },
    })
      .then((res) => {
        refetch().then((res) => {
          populateData(res ? res.data : null);
        });
      })
      .catch((err) => {});
  };
  const closePopup = (timeout = CLOSE_WINDOW_AFTER) => {
    setTimeout(() => {
      window.close();
    }, timeout);
  };

  // effects
  React.useEffect(() => {
    window.addEventListener(
      "message",
      ({ data }) => {
        if (data && data.data && data.data.authCode) {
          setAuthCode(data.data.authCode);
        }
      },
      false,
    );

    const params = new URLSearchParams(location.search);
    if (params.has("code")) {
      const authCode = params.get("code");
      setHasAuthCode(true);
      window.opener.postMessage(
        {
          type: "authorized",
          data: {
            authCode,
          },
        },
        window.origin,
      );
      closePopup();
    } else if (params.has("error")) {
      setHasAuthCode(true);
      closePopup(0);
    }
  }, []);

  React.useEffect(() => {
    if (authCode) {
      authHandler({ authCode });
    }
  }, [authCode]);

  // checks
  if (loading || loadingProfile) {
    return <React.Fragment />;
  }

  if (hasAuthCode) {
    return (
      <FullPageOverlay>
        <h2>Logging in...</h2>
      </FullPageOverlay>
    );
  }

  // render
  return (
    <div className="mt-4">
      {!connected || reset ? (
        <div className="py-4">
          <FacebookConnectButton />
        </div>
      ) : (
        <React.Fragment>
          <FacebookProfileInformation
            setStatus={setStatus}
            facebookProfiles={
              facebookData ? facebookData.socialNetworkProfiles : [[]]
            }
          />
          <div className="my-3">
            <p>
              Are the wrong Facebook pages showing above? Click the button below
              to reset your Customer Lobby Facebook settings.
            </p>
            <Button
              onClick={() =>
                disconnectModal(
                  disconnect,
                  "This will disconnect your Customer Lobby account from Facebook. And no more posts will be made via Customer Lobby.",
                )
              }
            >
              Disconnect
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

FacebookConnectionSettings.propTypes = {
  toast: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  disconnectModal: PropTypes.func.isRequired,
};

export default FacebookConnectionSettings;
