
export const CAMPAIGN_TYPE = {
    ALL: "all",
    BRANDING: "branding",
    DRIP: "drip",
    BLAST: "blast",
};

export const DEFAULT_SELECTIONS = [
    {
        key: CAMPAIGN_TYPE.ALL,
        value: 'All',
        isChecked: true
    },
    {
        key: CAMPAIGN_TYPE.BRANDING,
        value: 'Branding',
        isChecked: false
    },
    {
        key: CAMPAIGN_TYPE.DRIP,
        value: 'Over Time',
        isChecked: false
    },
    {
        key: CAMPAIGN_TYPE.BLAST,
        value: 'Blast',
        isChecked: false
    },
]
