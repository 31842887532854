import moment from "moment";
import format from "date-fns/format";
import * as momentTz from "moment-timezone";
import { FREQUENCY_OPTIONS } from "./Constants.js";
/**
 * Constants Helper for the application.
 */
export function contactPageUrl(contactId, companyId, isOldNavigation = true) {
  return `/companies/${companyId}/customers/${contactId}`;
}

/**
 * Constants Helper for the application.
 */
export function usdCurrencyFormatter(number, precision) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

/**
 * Provide number formatter
 * @param {*} number
 * @param {*} precision
 */
export function numberFormatter(number, precision) {
  return number.toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

/**
 * Provide min value
 * @param {*} value1
 * @param {*} value2
 */
export function getMin(value1, value2) {
  return value1 < value2 ? value1 : value2;
}

/**
 * Provide max value
 * @param {*} value1
 * @param {*} value2
 */
export function getMax(value1, value2) {
  return value1 > value2 ? value1 : value2;
}
/**
 * Provide round number
 * @param {*} number
 */
export function roundNumber(number) {
  return Math.round(number);
}

/**
 * Validate email format
 * @param {*} value
 */
export function matchEmailFormat(value) {
  return value.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
}

/**
 * Validate ZIP code
 * @param {*} value
 */
export function matchZipCode(value) {
  return value.match(/^([0-9]{5}|[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/);
}

/**
 * Validate website url
 * @param {*} value
 */
export function matchWebsiteUrl(value) {
  return value.match(/^(ftp|http|https):\/\/[^ "]+$/);
}

/**
 * Validate mobile number
 * @param {*} value
 */
export function validatePhoneNumber(value) {
  return value.match(/^\d{10}$/);
}

/**
 * Validate phone extension
 * @param {*} value
 */
export function validatePhoneExt(value) {
  return value.match(/^\d[0-9]{0,5}$/);
}

/**
 * Validate digits only
 * @param {*} value
 */
export function matchDigits(value) {
  return value.match(/^[0-9]*$/);
}

/**
 * Validate floating digits number
 * @param {*} value
 */
export function matchFloatDigitsNumber(value) {
  return !isNaN(value);
}

/**
 * Validate landline phone number
 * @param {*} value
 */
export function validateLandLinePhoneNumber(value) {
  return value.match(/^\d{3}-\d{3}-\d{4}$/);
}

/**
 * Date formattter (Jan 1, 2018) format
 */
export function dateFormatter(value) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = new Date(value);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  return monthNames[monthIndex] + " " + day + ", " + year;
}

export function formatCreatedAtDate(createdAt) {
  if (!createdAt) return;

  const today = new Date(createdAt);
  const month = today.toLocaleString("default", { month: "long" });
  const year = String(today.getFullYear());
  const day = String(today.getDate()).padStart(2, "0");

  return `${month} ${day}, ${year}`;
}
/**
 * Day formatter
 * @param {*} value
 */
export function dayFormatter(value) {
  let date = new Date(value);
  var day = date.getDate();
  var sufixes = ["th", "st", "nd", "rd"],
    finalDay = day % 100;
  return (
    day + (sufixes[(finalDay - 20) % 10] || sufixes[finalDay] || sufixes[0])
  );
}

export const formatUtcToTimezone = (date, timezone, timezoneAbbrevation) => {
  const d = new Date(date);
  const now = new Date(Date.now());
  const nowInCompanyTimeZone = new Date(
    now.toLocaleString("en-US", {
      timeZone: timezone,
    }),
  );

  const hrsDiff = nowInCompanyTimeZone.getHours() - now.getHours();
  d.setHours(d.getHours() + hrsDiff);

  const dateInTZ = new Date(d);
  // return dateInTZ;
  return `${formatDate(date, "ll")}`;
};

/**
 * Provide error message
 * @param {*} error
 * @param {*} errorMsg
 */
export function errorMsg(error, errorMsg) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    if (Array.isArray(error.response.data.errors)) {
      return error.response.data.errors[0];
    } else {
      return error.response.data.errors;
    }
  } else {
    return errorMsg;
  }
}

/**
 * Provide year
 * @param {*} startYear
 */
export function getYears(startYear) {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 2000;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

/**
 * Provide email options
 */
export function getEmailOptions() {
  return [
    {
      firstName: "",
      lastName: "",
      email: "",
      isFirstNameValid: true,
      isEmailValid: true,
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      isFirstNameValid: true,
      isEmailValid: true,
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      isFirstNameValid: true,
      isEmailValid: true,
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      isFirstNameValid: true,
      isEmailValid: true,
    },
  ];
}

/**
 * Provide call option
 */
export function getCallOptions() {
  return [
    {
      firstName: "",
      lastName: "",
      daytimePhone: "",
      phoneExt: "",
      mobilePhone: "",
      isFirstNameValid: true,
      isDaytimePhoneValid: true,
      isPhoneExtValid: true,
      isMobilePhoneValid: true,
    },
    {
      firstName: "",
      lastName: "",
      daytimePhone: "",
      phoneExt: "",
      mobilePhone: "",
      isFirstNameValid: true,
      isDaytimePhoneValid: true,
      isPhoneExtValid: true,
      isMobilePhoneValid: true,
    },
    {
      firstName: "",
      lastName: "",
      daytimePhone: "",
      phoneExt: "",
      mobilePhone: "",
      isFirstNameValid: true,
      isDaytimePhoneValid: true,
      isPhoneExtValid: true,
      isMobilePhoneValid: true,
    },
    {
      firstName: "",
      lastName: "",
      daytimePhone: "",
      phoneExt: "",
      mobilePhone: "",
      isFirstNameValid: true,
      isDaytimePhoneValid: true,
      isPhoneExtValid: true,
      isMobilePhoneValid: true,
    },
  ];
}

/**
 * Provide object Empty status
 */
export function isEmpty(object) {
  for (var key in object) {
    if (object.hasOwnProperty(key)) return true;
  }
  return false;
}

/**
 * Provide formated phone number
 * @param value- number for phone number conversion
 */
export function formatPhoneNumber(value) {
  var phoneNumber = ("" + value).replace(/\D/g, "");
  var finalNumber = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !finalNumber
    ? null
    : finalNumber[1] + "-" + finalNumber[2] + "-" + finalNumber[3];
}

/**
 * Provide formated phone number at all string lengths.
 * To be used for live formatting of input.
 * @param value- number for phone number conversion
 */
export function formatPhoneNumberLive(value) {
  const phoneNumber = ("" + value).replace(/\D/g, "");
  const formatted =
    phoneNumber.length <= 3
      ? phoneNumber
      : phoneNumber.length < 7
      ? `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
          3,
          phoneNumber.length,
        )}`
      : phoneNumber.length < 11
      ? `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
          3,
          6,
        )}-${phoneNumber.substring(6, phoneNumber.length)}`
      : `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
          3,
          6,
        )}-${phoneNumber.substring(6, 10)}`;

  return formatted;
}

/**
 * Provide ten digit phone number without letters
 * or special characters
 * @param value- number for phone number conversion
 */
export function sanitizePhoneNumber(value) {
  return ("" + value.substring(0, 12)).replace(/\D/g, "");
}

/**
 * Provinde number to phone number
 */
export function numberToPhoneNumberConversion(number) {
  let phoneNumber = number.replace(/[^\d]+/g, "");
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

/**
 * Check null condition
 * @param value
 */
export function isNull(value) {
  if (typeof value !== "undefined" && null !== value) {
    return true;
  }
  return false;
}

/**
 * Provide percentage value
 * @param value - value consion for percentatge
 * @returns percentage value
 */
export function getPercentage(value) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  value = value * 100;
  return value < 1 && value > 0 ? "< 1" : Math.round(value);
}

/**
 * Provide formatated date using moment.js
 * @param {*} date - which want to convert
 * @param {*} dateFormat - format in which format we want(3rd Jan, 2018)
 */
export function formatDate(date, dateFormat) {
  if (date) {
    return moment(date).format(dateFormat);
  }
  return null;
}

function stringConvertorHelper(str) {
  let newStr;
  switch (str) {
    case "zip_code":
      newStr = "ZIP_code";
      break;
    default:
      newStr = str;
      break;
  }

  return newStr;
}

/**
 * String convertor
 * @param str - String for conversion(e.g all_customer)
 * @returns converted string capatalize format (All Customer)
 */
export function stringConvertor(str) {
  const groomedStr = stringConvertorHelper(str);
  var conversionString = groomedStr.split("_");
  for (let i = 0; i < conversionString.length; i++) {
    conversionString[i] =
      conversionString[i].charAt(0).toUpperCase() +
      conversionString[i].slice(1);
  }
  return conversionString.join(" ");
}

/**
 * Provide number into delimiter(comma separated) format
 * @param {*} number
 */
export function numberToDelimiterConversion(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Provide the number to percentage conversion of number
 * @param {*} number
 * @param {*} precision
 */
export function numberToPercentageConversion(number, precision) {
  return Number(number / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: precision,
  });
}

/**
 * Validate US Mobile number
 * @param {*} value
 */
export function usMobileNumberValidation(value) {
  return value.match(
    /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
  );
}

/**
 * Validate US phone number
 * @param {*} value
 */
export function usPhoneNumberValidation(value) {
  return (
    ("" + value).match(
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
    ) || !value
  );
}

/**
 * Validate phone extension
 * @param {*} value
 */
export function validateNumbersOnly(value) {
  return value.match(/^\d[0-9]$/);
}

/**
 * String convertor
 * @param str - String for conversion(e.g upper_position)
 * @returns converted string capatalize format (upper-position)
 */
export function stringConvertorwithHyphon(str) {
  var conversionString = str.split("_");
  for (let i = 0; i < conversionString.length; i++) {
    conversionString[i] =
      conversionString[i].charAt(0) + conversionString[i].slice(1);
  }
  return conversionString.join("-");
}

/**
 * Date formattter (January 1) format
 */
export function dateFormatterWithMonthOnly(value) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date(value);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  return monthNames[monthIndex] + " " + day;
}

/**
 * Date time formattter (Jan 1, 2020 00:00 AM) format
 */
export const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Date(dateTimeString).toLocaleString("en-US", options);
};

/**
 *  Date formattter (January-2009) format
 * @param value
 */
export function dateFormatterWithMonthAndYear(value) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date(value);
  var day = date.getUTCDate();
  var monthIndex = date.getUTCMonth();
  var year = date.getUTCFullYear();
  return monthNames[monthIndex] + " - " + year;
}

/**
 * Get dates with interval
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} days
 * @returns the array of dates
 */
export function addDays(startDate, endDate, days) {
  let calculatedDate = new Date(startDate);
  let selectedDays = [];
  while (calculatedDate < new Date(endDate)) {
    calculatedDate.setDate(calculatedDate.getDate() + days);
    if (calculatedDate < new Date(endDate)) {
      selectedDays.push(moment(calculatedDate).format("Do MMM YYYY"));
    }
  }
  return selectedDays;
}

/**
 * Convert string into title case
 * @param str
 */
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * Truncate string to specified limit.
 * @param value - String to be truncated.
 * @param limit - Limit to be truncated.
 * @returns {*} - the truncated string.
 */
export function truncateString(value, limit) {
  if (value && value.length > limit) {
    return value.slice(0, limit) + "...";
  } else {
    return value;
  }
}

/**
 * Provide months
 */
export function getSubscriptionMonths() {
  return [
    { month: "1-Jan", value: 1 },
    { month: "2-Feb", value: 2 },
    { month: "3-Mar", value: 3 },
    { month: "4-Apr", value: 4 },
    { month: "5-May", value: 5 },
    { month: "6-Jun", value: 6 },
    { month: "7-Jul", value: 7 },
    { month: "8-Aug", value: 8 },
    { month: "9-Sept", value: 9 },
    { month: "10-Oct", value: 10 },
    { month: "11-Nov", value: 11 },
    { month: "12-Dec", value: 12 },
  ];
}

export function replaceAll(str, search, replacement) {
  return str.replace(new RegExp(search, "g"), replacement);
}

/**
 * String convertor
 * @param str - String for conversion(e.g All Customer)
 * @returns converted string capatalize format (AllCustomer)
 */
export function stringToCapitaliseConvertor(str) {
  return str
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
    .replace(/\s/g, "");
}

export function generateTimeTicks(step) {
  let currentStep = 0;
  const totalTicks = 1440 / step;
  let generatedTicks = [];
  for (let index = 0; index < totalTicks; index++) {
    let currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    if (index > 0) {
      currentStep = currentStep + step;
      currentTime.setMinutes(currentStep);
    }

    let hours =
      currentTime.getHours() < 10
        ? `0${currentTime.getHours()}`
        : currentTime.getHours();
    const AM_PM = hours < 12 ? "AM" : "PM";
    let mins =
      currentTime.getMinutes() < 10
        ? `0${currentTime.getMinutes()}`
        : currentTime.getMinutes();
    generatedTicks.push({
      label: `${hours}:${mins} ${AM_PM}`,
      value: `${hours}:${mins}`,
    });
  }

  return generatedTicks;
}

/**
 * Converts Name into initials
 * @param {*} fullname fullname
 */
export function getNameInitials(fullname) {
  if (fullname) {
    return fullname
      .split(" ")
      .map((name) => name[0])
      .join("");
  } else {
    return "N/A";
  }
}

export function resetPagination(n) {
  let pageNumbers = document.querySelectorAll(".page-counts");
  if (Array.from(pageNumbers).length === 0) return null;
  Array.from(pageNumbers).map((page) => {
    if (Array.from(page.classList).includes("active-page")) {
      page.classList.remove("active-page");
    }
    if (page.innerText === (n + 1).toString()) {
      page.classList.add("active-page");
      page.classList.add("d-flex");
      page.classList.add("justify-content-center");
    }
  });
}

/**
 * String selector for proper grammar
 * @param str - The word following needed "a" or "an"
 * @returns a lowercase "a" or "an" depending on the first letter of the following word
 */
export const letterACleanup = (str) => {
  const firstLetter = str.charAt(0);
  switch ((firstLetter || "").toLowerCase()) {
    case "a":
    case "e":
    case "i":
    case "o":
    case "u":
      return "an";
  }
  return "a";
};

export const getObjectLength = (obj) => (obj ? Object.keys(obj).length : 0);

export const convertStringToArray = (str, sign = "_") => {
  if (typeof str === "string") {
    const value = str.split(sign);
    return value;
  }
  return str;
};

export const minimumDate = (timeZone, date = Date.now(), days = 0) => {
  // 35 minutes from now
  let nowPlus35 = new Date(date + 35 * 60 * 1000);
  if (days > 0) {
    const oneWeekInMillis = days * 24 * 60 * 60 * 1000;
    const currentDate = new Date(date);
    nowPlus35 = new Date(currentDate.getTime() + oneWeekInMillis);
  }

  const inCompanyTimeZone = nowPlus35.toLocaleString("en-US", {
    timeZone,
  });
  const minDate = new Date(inCompanyTimeZone);
  return minDate;
};

export const getDifferenceInDays = (date1, date2, timeZone) => {
  const date1Tz = momentTz.tz(date1, timeZone);
  const date2Tz = momentTz.tz(date2, timeZone);

  if (date2Tz.isBefore(date1Tz)) {
    return 0; // Return 0 if date2 is before date1
  }

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date2Tz - date1Tz);

  // Convert back to days and return
  return Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
};

// Function for exclude the date from datepicker
export const isDateSelectable = (date) => {
  const day = date.getDate();

  return day !== 3 && day !== 18 && date.getDay() !== 6 && date.getDay() !== 0;
};

export const getLatLongByZip = async (zip) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
  );
  const { results, status } = await response.json();

  if (status === "OK") {
    const location = results[0].geometry.location;
    return {
      zip,
      lat: location.lat,
      lng: location.lng,
    };
  }
  return false;
};

export const arrayToSelectOptions = (data) => {
  if (data) {
    return data.map((val) => ({
      value: val,
      label: val,
    }));
  }
  return [];
};

export const extractMetaDataValue = (metaObject, field) => {
  if (field === "pet_owner") {
    if (metaObject?.hasOwnProperty("pet_owner")) {
      return metaObject.pet_owner.operator === "gt" ? "yes" : "no";
    } else {
      return false;
    }
  }

  return metaObject[field]?.value;
};

export const getLabelByFrequencyValue = (frequencyValue) => {
  const frequencyString = `${frequencyValue?.units}_${frequencyValue?.value}`;

  const frequencyOption = FREQUENCY_OPTIONS.find(
    (option) => option.value === frequencyString,
  );

  return frequencyOption ? frequencyOption.label : "";
};

// Replace undefined, null, and empty strings with fillValue
export const fillArray = (array, fillValue) => {
  const filledArray = array.map((item) =>
    item === undefined || item === null || item === "" ? fillValue : item,
  );
  // Ensure the array length is 2
  while (filledArray.length < 2) {
    filledArray.push(fillValue);
  }

  return filledArray;
};

export const milesToKilometers = (miles) => {
  return miles * 1.60934;
};

export const rearrangedArrayData = (originalArray, keyOrder) => {
  const rearrangedArray = originalArray.map((item) => {
    const reorderedObject = {};
    keyOrder.forEach((key) => {
      if (key in item) {
        reorderedObject[key] = item[key] === null ? "" : item[key];
      }
    });
    return reorderedObject;
  });
  return rearrangedArray;
};
