import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "src/shared/styleguide/form/Form";
import Card from "src/shared/styleguide/card/Card";
import informationIcon from "src/assets/images/alerts/information_gray.svg";
import Button from "src/shared/styleguide/button/Button";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { GET_ENTERPRISE_DETAILS } from "src/graphql/enterprise/queries";
import { UPDATE_ENTERPRISE_MUTATION } from "src/graphql/enterprise/mutations";
import { addToast } from "src/utils/ToastUtil";
import { useFormik } from "formik";
import BlockUi from "react-block-ui";
import * as Yup from "yup";
import {
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_STATES,
} from "src/components/company/settings/graphql_queries/queries";
import { PHONE_NUMBER_WITH_LETTER_EXPRESSION } from "src/shared/Constants";
import { numberToPhoneNumberConversion } from "src/shared/Utils";
import { Switch, Route } from "react-router-dom";
import Billing from "./billing/Billing";
import Notifications from "./Notifications";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required field"),
  website: Yup.string().url().required("Website is required field"),
  phoneNumber: Yup.string()
    .required("Phone is required field")
    .matches(PHONE_NUMBER_WITH_LETTER_EXPRESSION, "Invalid phone"),
  address1: Yup.string().required("Street is required field"),
  city: Yup.string().required("City is required field"),
  zipcode: Yup.string().required("ZIP is required field").max(7),
  billingEmail: Yup.string().email(),
});

const Enterprise = () => {
  useDocumentTitle("Enterprise");
  const { data: enterpriseDetails, loading } = useQuery(GET_ENTERPRISE_DETAILS);
  const [updateEnterpriseDetails] = useMutation(UPDATE_ENTERPRISE_MUTATION, {
    onCompleted: () => {
      addToast({
        type: "success",
        message: "Enterprise settings updated successfully",
      });
    },
    onError: () => {
      addToast({
        type: "error",
        message: "Something went wrong please try again",
      });
    },
  });
  const {
    name,
    description,
    reviewPageUrl,
    enableReviewsPage,
    phoneNumber,
    zipcode,
    address1,
    address2,
    city,
    website,
    state,
    country,
    categoryIds,
    billingEmail,
  } = enterpriseDetails?.getEnterprise || {};
  const initialValues = {
    name,
    description,
    categoryIds: categoryIds?.[0],
    reviewPageUrl,
    enableReviewsPage,
    phoneNumber: phoneNumber || "",
    address1,
    address2,
    city,
    state,
    zipcode,
    country,
    website,
    billingEmail,
  };

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await updateEnterpriseDetails({
        variables: values,
      });
      setSubmitting(false);
    },
  });

  const [getStates, { data: statesList }] = useLazyQuery(GET_STATES, {
    onCompleted: ({ listStates }) => {
      // set the default value for state
      // setFieldValue("state", listStates[0]?.name);
    },
  });

  const { data } = useQuery(GET_CATEGORIES, {
    onCompleted: ({ categories }) => {
      // set the default value for categoryIds field
      if (!values?.categoryIds) {
        setFieldValue("categoryIds", parseInt(categories[0]?.id));
      }
    },
  });
  const { data: countryList } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    getStates({
      variables: {
        country: values.country,
      },
    });
  }, [values.country]);

  return (
    <BlockUi blocking={loading}>
      <div className="page-title">Enterprise Settings</div>
      <Card className="mt-4 mb-4">
        <Row>
          <Col>
            <h1 className="form-heading mb-4">General</h1>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Form.Group controlId="name">
              <Form.Label className="text-dark" required>
                Name
              </Form.Label>
              <Form.Control
                placeholder="Name"
                onChange={handleChange}
                name="name"
                value={values.name}
              />
              {touched.name && errors.name ? (
                <div className="sg-text-danger my-2">{errors.name}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label className="text-dark">Description</Form.Label>
              <Form.Control
                placeholder="Description"
                onChange={handleChange}
                name="description"
                value={values.description}
              />
            </Form.Group>

            <Form.Group controlId="Industry">
              <Form.Label className="text-dark">Industry</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFieldValue("categoryIds", parseInt(e.value))
                }
                value={values.categoryIds}
                options={data?.categories?.map((categoryValue) => ({
                  value: categoryValue.id,
                  label: categoryValue.name,
                }))}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="enableReviewPage">
              <Form.Label className="text-dark">Enable Review Page?</Form.Label>
              <Form.Select
                onChange={(e) => setFieldValue("enableReviewsPage", e.value)}
                value={values.enableReviewsPage}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="reviewPageUrl">
              <Form.Label className="text-dark">Review Page URL</Form.Label>
              <a href={reviewPageUrl} target="_blank">
                {reviewPageUrl}
              </a>
            </Form.Group>

            <h1 className="form-heading mt-5 mb-4">Contact</h1>

            <Form.Group controlId="website">
              <Form.Label className="text-dark" required>
                Website
              </Form.Label>
              <Form.Control
                placeholder="Website"
                onChange={handleChange}
                name="website"
                value={values.website}
              />
              {touched.website && errors.website ? (
                <div className="sg-text-danger my-2">{errors.website}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label className="text-dark" required>
                Phone
              </Form.Label>
              <Form.Control
                placeholder="Phone"
                onChange={handleChange}
                name="phoneNumber"
                value={numberToPhoneNumberConversion(values.phoneNumber)}
              />
              {touched.phoneNumber && errors.phoneNumber ? (
                <div className="sg-text-danger my-2">{errors.phoneNumber}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="billingEmail">
              <Form.Label className="text-dark">Billing Email</Form.Label>
              <Form.Control
                placeholder="Billing Email"
                onChange={handleChange}
                name="billingEmail"
                value={values.billingEmail}
              />
              {touched.billingEmail && errors.billingEmail ? (
                <div className="sg-text-danger my-2">{errors.billingEmail}</div>
              ) : null}
            </Form.Group>

            <h1 className="form-heading mt-5 mb-4">Address</h1>

            <Form.Group controlId="street">
              <Form.Label className="text-dark" required>
                Street
              </Form.Label>
              <Form.Control
                placeholder="Street"
                onChange={handleChange}
                name="address1"
                value={values.address1}
              />
              {touched.address1 && errors.address1 ? (
                <div className="sg-text-danger my-2">{errors.address1}</div>
              ) : null}
            </Form.Group>

            <Form.Row>
              <Col xs={6}>
                <Form.Group controlId="suite">
                  <Form.Label className="text-dark">Suite</Form.Label>
                  <Form.Control
                    placeholder="Suite"
                    onChange={handleChange}
                    name="address2"
                    value={values.address2}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="city">
                  <Form.Label className="text-dark" required>
                    City
                  </Form.Label>
                  <Form.Control
                    placeholder="City"
                    onChange={handleChange}
                    name="city"
                    value={values.city}
                  />
                  {touched.city && errors.city ? (
                    <div className="sg-text-danger my-2">{errors.city}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="state">
                  <Form.Label className="text-dark">State</Form.Label>
                  <Form.Select
                    onChange={(e) => setFieldValue("state", e.value)}
                    value={values.state}
                    options={statesList?.listStates?.map((state) => ({
                      value: state.code,
                      label: state.name,
                    }))}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="zip">
                  <Form.Label className="text-dark" required>
                    ZIP
                  </Form.Label>
                  <Form.Control
                    placeholder="Zip"
                    onChange={handleChange}
                    name="zipcode"
                    value={values.zipcode}
                  />
                  {touched.zipcode && errors.zipcode ? (
                    <div className="sg-text-danger my-2">{errors.zipcode}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="country">
                  <Form.Label className="text-dark" required>
                    Country
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setFieldValue("country", e.value)}
                    value={values.country}
                    options={countryList?.listCountries?.countries.map(
                      (countryVal) => ({
                        value: countryVal,
                        label: countryVal,
                      }),
                    )}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
          <Col md={4} className="enterprise-form-text">
            <img className="information-icon" src={informationIcon} />
            <p className="d-flex align-items-center font-weight-bold">
              Enterprise Settings
            </p>{" "}
            <p>
              Please enter the Industry your business is associated
              <br /> with. All locations associates with your account will
              <br /> be automatically assigned the Industry you select.
            </p>
            <p>
              Enter the phone number of the person handling this
              <br /> account. We will need this phone number to get in <br />
              touch with you, if there is a problem with account.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between border-top">
            <Button variant="" className="mt-3">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="mt-3 px-5"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? "Please wait..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Card>
    </BlockUi>
  );
};

const EnterpriseSettings = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/`} exact component={Enterprise} />
      <Route path={`${match.path}/notifications`} component={Notifications} />
      <Route path={`${match.path}/billing`} component={Billing} />
    </Switch>
  );
};

export default EnterpriseSettings;
