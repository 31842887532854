import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "./CustomerDetailsHeader.scss";

const MessagingHeader = ({
  leftComponent,
  rightComponent = null,
  leftComponentSpan = 6,
  rightComponentSpan = 6,
}) => {
  // render
  return (
    <div className="bg-white px-4 d-flex align-items-center customer-details-header">
      <Row className="flex-grow">
        <Col
          className="text-dark h3 mb-0  font-weight-bold align-items-center custom-header"
          xs={leftComponentSpan}
        >
          {leftComponent}
        </Col>
        <Col
          className="d-flex justify-content-end align-items-center"
          xs={rightComponentSpan}
        >
          {rightComponent}
        </Col>
      </Row>
    </div>
  );
};

MessagingHeader.propTypes = {
  leftComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node,
};

export default MessagingHeader;
