import store from "src/redux/store";

export default class CsvUtil {
  /**
   * Returns a promise that resolves when CSV file has exact
   * header match and has at least one row; rejects otherwise
   * @param {object} csvFile File object
   * @param {array} headers array of strings of allowed CSV column headers; default = []
   * @param {number} CHUNK_SIZE size of the file to be read; default = 2kb
   */
  static isValidContactFile(csvFile, headers = [], CHUNK_SIZE = 2000) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      // error handler
      fileReader.onerror = () => {
        reject();
      };

      // success handler
      fileReader.onload = (evt) => {
        let matched = true;
        if (evt && evt.target && evt.target.result) {
          const csvData = evt.target.result.split("\n");

          // check if there's at least one row
          if (csvData.length <= 1) {
            matched = false;
          } else {
            const line0 = csvData[0].trim();
            const line1 = csvData[1].trim();
            const csvHeaders = line0
              .split(",")
              .map((header) => header.toLowerCase())
              .filter((header) => !!header);
            const inputHeaders = headers.map((header) => header.toLowerCase());

            // match each csv column header
            csvHeaders.forEach((header) => {
              if (!inputHeaders.includes(header)) {
                matched = false;
              }
            });

            // match number of columns
            if (csvHeaders.length !== inputHeaders.length) {
              matched = false;
            }

            // check for row
            if (!line1) {
              return reject(new Error("data"));
            }
          }
        }

        // resolve or reject promise
        if (matched) {
          resolve();
        } else {
          return reject(new Error("header"));
        }
      };

      fileReader.readAsText(csvFile.slice(0, CHUNK_SIZE));
    });
  }

  static downloadCSVFile(data, labels = null, fileName = "data") {
    const { enterpriseInfo } = store.getState()["user"];

    const updatedArr = data.map((obj) => {
      const keys = Object.keys(obj); // Get all keys of the object
      // const lastKey = keys[keys.length - 1]; // Get the last key
      // if (lastKey) delete obj[lastKey]; // Delete the last key
      return obj;
    });

    const header = labels || Object.keys(updatedArr[0]);
    // Convert JSON data to CSV format
    const csvHeaders = header.join(",") + "\n";
    const csvRows = updatedArr.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`) // Escape values for CSV
        .join(","),
    );
    const csvContent = csvHeaders + csvRows.join("\n");

    // Create a Blob for the CSV file
    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const name = enterpriseInfo?.name;
    // Create a download link and trigger it
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${name} - ${fileName}.csv`;
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  }
}
