import { configureStore } from "@reduxjs/toolkit";
import prospectSlice from "src/redux/prospectSlice";
import individualResultSlice from "src/redux/individualResultSlice";
import magnetMailerSlice from "src/redux/magnetMailerSlice";
import featureFlagsSlice from "src/redux/featureFlagsSlice";
import themeSlice from "src/redux/themeSlice";
import companySlice from "src/redux/companySlice";
import userSlice from "src/redux/userSlice";
import toastSlice from "src/redux/toastSlice";

export const store = configureStore({
  reducer: {
    featureFlags: featureFlagsSlice,
    prospect: prospectSlice,
    individualResults: individualResultSlice,
    magnetMailer: magnetMailerSlice,
    theme: themeSlice,
    company: companySlice,
    user: userSlice,
    toast: toastSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
