import React, { useRef, useEffect } from "react";
import Button from "src/shared/styleguide/button/Button";
import Form from "src/shared/styleguide/form/Form";
import { Row, Col } from "reactstrap";
import "./UsageFilterModal.scss";
import { Accordion } from "react-bootstrap";
import { CAMPAIGN_TYPE } from "../CampaignUsageConstants";

const UsageFilterModal = (
  {
    isFilterDisabled,
    campaginNameOptions,
    selectedCampaigns,
    setMultiSelection,
    campaignsType,
    closeModal,
    onCampaignTypeChange,
    onApply
  }) => {

  const myDivRef = useRef(null);
  const disabled = isFilterDisabled()

  // Function to handle the close logic
  const handleClickOutside = (event) => {
    // Check if the click happened outside the referenced div
    if (myDivRef.current && !myDivRef.current.contains(event.target)) {
      if (!event.target.className?.includes("campaign-filter-modal")) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    // Add click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const onFilterApplied = () => {
    const result = selectedCampaigns.map(item => item.value).join("|");
    const categories = campaignsType[0].isChecked
      ? []
      : campaignsType.filter(item => item.key !== CAMPAIGN_TYPE.ALL && item.isChecked).map(item => item.key);
    onApply(categories, result);
  };

  const isValid = () => campaignsType.some(item => item.isChecked);

  return (
    <div ref={myDivRef} className="campaign-filter-modal">
      <h1 className="filter-pane-header mb-0">
        Filter
        <span className="pull-right cursor-pointer" onClick={closeModal}>
          <svg
            version="1.1"
            viewBox="0 0 100 100"
            style={{
              width: 16,
              height: 16,
              strokeWidth: 12,
              stroke: "#d6d3d3",
            }}
          >
            <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
          </svg>
        </span>
      </h1>

      <Row className="filter-pane-content p-4">
        <Col sm={12} className="mb-0">
          <h4>Type of Campaign</h4>

          {campaignsType.map((item) => {
            return (
              <Form.Group
                key={item.key}
                controlId={`${item.key}`}>
                <Form.Check
                  custom
                  checked={item.isChecked}
                  onChange={() => onCampaignTypeChange(item.key, item.isChecked)}
                  type="checkbox"
                  label={item.value}
                  className="text-dark"
                />
              </Form.Group>
            )
          })}

          <Accordion>
            <Row>
              <div className={`chevron`}></div>
              <div className="search-container">
                Campaign Name
              </div>
            </Row>

            <Accordion.Collapse eventKey="0" in={true}>
              <Form.MultiSelect
                clearable={true}
                onChange={(items) => setMultiSelection(items)}
                options={campaginNameOptions}
                placeholder="Select Campaign Names"
                searchable={true}
                value={selectedCampaigns}
              />
            </Accordion.Collapse>
          </Accordion>
        </Col>

        <span className="validation-text">{isValid() ? "" : "* Please select atleast one Type of Campaign"}</span>
        <Col className="filter-pane-footer">
          <Button
            onClick={() => closeModal()}
            variant="outline-secondary mr-2">Cancel</Button>
          <Button
            disabled={disabled}
            onClick={() => onFilterApplied()}
            variant="primary">Apply</Button>
        </Col>
      </Row>
    </div >
  );
};

export default UsageFilterModal;
