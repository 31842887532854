import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { GET_GLOBAL_CAMPAIGN_SETTINGS } from "./graphql_queries/queries";
import { UPDATE_GLOBAL_CAMPAIGN_SETTINGS } from "./graphql_queries/mutations";
import { gql } from "apollo-boost";
import { COOKIES, APPOLO_FETCH_POLICY } from "../../../shared/Config";
import { loadCookie } from "../../../shared/SessionHelper";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import ProfileSettingTitleBar from "./ProfileSettingTitleBar";
import StyleGuideForm from "src/shared/styleguide/form/Form.scss";
import SettingsContext from "src/shared/context/SettingsContext";
import { addToast } from "src/utils/ToastUtil";
import "./CompanySettings.scss";
import { connect } from "react-redux";

/**
 * Campaign settings component.
 */

class CampaignsSettings extends Component {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      blocking: false,
      token: loadCookie(COOKIES.AUTH_TOKEN),
      companyId: this.props.companyId,
      globalCampaignSetting: null,
    };
  }

  /**
   * React lifecycle hook.
   */
  UNSAFE_componentWillMount() {
    this.globalCampaignSetting();
  }

  /**
   * Gets global campaigns settings.
   */
  globalCampaignSetting() {
    this.toggleBlocking();
    this.props.client
      .query({
        query: gql`
          ${GET_GLOBAL_CAMPAIGN_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: { id: parseInt(this.state.companyId) },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        this.toggleBlocking();
        if (response && response.data && response.data.globalCampaignSetting)
          this.setState({
            globalCampaignSetting: response.data.globalCampaignSetting,
          });
      })
      .catch(() => {
        this.toggleBlocking();
      });
  }

  /**
   * Updates global campaign settings.
   * @param data -> Params to be provided.
   */
  updateGlobalCampaignSetting(data) {
    this.toggleBlocking();
    this.props.client
      .mutate({
        mutation: gql`
          ${UPDATE_GLOBAL_CAMPAIGN_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: this.processParams(data),
      })
      .then(() => {
        this.context.setFollowUpEnabled(true);
        addToast({
          type: "success",
          message: "Campaign settings updated successfully.",
        });
        this.toggleBlocking();
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Failed to update campaign settings.",
        });
        this.toggleBlocking();
      });
  }

  /**
   * Process the parameters.
   * @param data
   * @returns {{companyId: Number}}
   */
  processParams(data) {
    let params = {
      companyId: parseInt(this.state.companyId, 10),
    };
    params.email = data.email;
    params.emailFirstName = data.emailFirstName;
    params.emailLastName = data.emailLastName;
    return params;
  }

  toggleBlocking() {
    this.setState({ blocking: !this.state.blocking });
  }

  /**
   * Set visibility texting toast pop up.
   * @param visible -> boolean value, true if popup needs to be visible.
   */
  enabledToast = (visible) => {
    this.setState({ showToast: visible });
  };

  render() {
    const { globalCampaignSetting } = this.state;
    const { featureFlags } = this.props;
    return featureFlags.campaignRead ? (
      <Formik
        initialValues={{
          emailFirstName: globalCampaignSetting
            ? globalCampaignSetting.emailFirstName
            : "",
          emailLastName: globalCampaignSetting
            ? globalCampaignSetting.emailLastName
            : "",
          email: globalCampaignSetting ? globalCampaignSetting.email : "",
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          emailFirstName: Yup.string().required("Field required"),
          emailLastName: Yup.string().required("Field required"),
          email: Yup.string().email("Invalid Email").required("Field required"),
        })}
        onSubmit={(fields) => {
          this.updateGlobalCampaignSetting(fields);
        }}
        render={({ errors, status, touched }) => (
          <Form>
            <div id="campaigns-setting">
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <ProfileSettingTitleBar
                          title={"Campaigns Setting"}
                          subTitle={
                            "This section contains your campaign settings."
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-11 offset-sm-1">
                        <div className="row">
                          <div className="col-12 d-inline-flex">
                            <div className="followUp">
                              Follow-up Email <br />
                              <div className="followUpSubtitle">
                                After your customer receives the post card, we
                                send a follow up email on your behalf. From whom
                                should we send the email?
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <label className="input-label required-field">
                              First Name
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="emailFirstName"
                              className={
                                "form-control" +
                                (errors.emailFirstName && touched.emailFirstName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="First Name"
                              type="text"
                              maxLength="125"
                            />
                            <ErrorMessage
                              name="emailFirstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <label className="input-label required-field mt-1">
                              Last Name
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="emailLastName"
                              className={
                                "form-control" +
                                (errors.emailLastName && touched.emailLastName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Last Name"
                              type="text"
                              maxLength="125"
                            />
                            <ErrorMessage
                              name="emailLastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <label className="input-label required-field">
                              Email Address
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Email"
                              type="text"
                              maxLength="125"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="row mt-4 mb-5">
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </Form>
        )}
      />
    ) : null;
  }
}

// Map state and dispatch to props
const mapStateToProps = (state) => ({
  featureFlags: state.featureFlags.flags,
  companyId: state.user.userInfo.companyId,
});

// Connect the component to Redux
export default connect(mapStateToProps)(CampaignsSettings);
