import React from "react";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import { useApolloClient } from "@apollo/react-hooks";
import { ENTERPRISE_RECEIPTS } from "src/graphql/enterprise/queries";
import { NETWORK_ONLY } from "src/shared/Constants";
import BlockUi from "react-block-ui";
import Badge from "src/shared/styleguide/badge/Badge";
import format from "date-fns/format";
import downloadIcon from "src/assets/images/enterprise/download.svg";
import NoRecordFound from "src/components/enterprise/NoRecordFound";

const BillingTransactions = () => {
  // variables

  const perPage = 10;
  const headerItems = [
    {
      columnLabel: "MONTH",
      columnValue: "date",
      sortable: true,
    },
    { columnLabel: "STATUS", columnValue: "status", sortable: false },
    { columnLabel: "ACTION", columnValue: "", sortable: false },
  ];

  // state
  const client = useApolloClient();
  const [sortBy, setSortBy] = React.useState("date");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // methods
  const getBadgeType = (transaction) => {
    const status = transaction.status.toLowerCase();
    if (status === "sent") {
      return "success";
    }
    if (status === "generated") {
      return "info";
    }
    if (status === "in_progress") {
      return "warning";
    }
    return "default";
  };

  const populateData = (data) => {
    if (data) {
      setTransactions(
        data.map((transaction) => getTransactionRow(transaction)),
      );
    }
  };

  const getTransactionRow = (transaction) => {
    return [
      {
        node: (
          <div className="d-flex align-items-center py-1 billing-table-cell">
            {format(new Date(transaction.date), "MMMM - yyyy")}
          </div>
        ),
      },
      {
        node: (
          <div className="d-flex align-items-center py-1 billing-table-cell">
            <Badge type={getBadgeType(transaction)}>
              {transaction.status.replace("_", " ")}
            </Badge>
          </div>
        ),
      },
      {
        node: (
          <div className="d-flex align-items-center py-1 billing-table-cell">
            <a href={transaction.url} target="_blank">
              <img src={downloadIcon} />
            </a>
          </div>
        ),
      },
    ];
  };

  const onSort = (columnValue, sortDirection) => {
    setSortBy(columnValue);
    setSortOrder(sortDirection);
    setCurrentPage(0);
    getTransactions({
      page: 0,
      sortBy: columnValue,
      sortOrder: sortDirection,
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getTransactions({
      page: selected,
    });
  };

  const getTransactions = (props = {}) => {
    setLoading(true);
    const queryVariables = {
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy : sortBy ? sortBy : undefined,
      sortOrder: props.sortOrder
        ? props.sortOrder
        : sortOrder
        ? sortOrder
        : undefined,
    };
    client
      .query({
        query: ENTERPRISE_RECEIPTS,
        fetchPolicy: NETWORK_ONLY,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterpriseReceipts) {
          const { enterpriseReceipts } = res.data;

          populateData(enterpriseReceipts.data);
          setTotalItems(enterpriseReceipts.total);
          setPageCount(Math.ceil(enterpriseReceipts.total / perPage));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // effects
  React.useEffect(() => {
    getTransactions();
  }, []);

  // render
  return (
    <SettingsCard
      cardStyle={{
        padding: 0,
      }}
      cardBodyStyle={{
        paddingBottom: 4,
      }}
      loading={false}
      settingsName="Past Statements"
      settingsDescription={
        <span className="billing-transactions-description">
          View the status of your past statements and download a copy for your
          own records.
        </span>
      }
      settingsForm={
        <div className="mt-4">
          <BlockUi blocking={loading}>
            <Table
              showPageInformation={true}
              perPage={perPage}
              totalItems={totalItems}
              className="get-reviews-table mb-0"
              head={[
                <SortableTableHeader
                  sortable={headerItems[0].sortable}
                  columnLabel={headerItems[0].columnLabel}
                  columnValue={headerItems[0].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy}
                  currentSortOrder={sortOrder}
                />,
                <SortableTableHeader
                  sortable={headerItems[1].sortable}
                  columnLabel={headerItems[1].columnLabel}
                  columnValue={headerItems[1].columnValue}
                  onSort={onSort}
                  currentSortValue={sortBy}
                  currentSortOrder={sortOrder}
                />,
                <div style={{ width: 120 }}>
                  <SortableTableHeader
                    sortable={headerItems[2].sortable}
                    columnLabel={headerItems[2].columnLabel}
                    columnValue={headerItems[2].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />
                </div>,
              ]}
              body={transactions}
              hasPagination={true}
              pageCount={pageCount}
              onPagination={onPagination}
              currentPage={currentPage}
              noData={<NoRecordFound />}
            />
          </BlockUi>
        </div>
      }
    />
  );
};

export default BillingTransactions;
