import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Badge.scss";

const Badge = ({ type = "", children }) => {
  // render
  return (
    <span
      className={classnames("sg-badge", {
        "sg-badge-success": type === "success",
        "sg-badge-warning": type === "warning",
        "sg-badge-default-light": type === "default-light",
        "sg-badge-default": type === "default" || type === "",
        "sg-badge-danger": type === "danger",
        "sg-badge-info": type === "info",
      })}
    >
      {children}
    </span>
  );
};

Badge.propTypes = {
  type: PropTypes.string,
};

export default Badge;
