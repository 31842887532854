import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CompanyUsersIndex from "src/components/company_users/CompanyUsersIndex";
import "src/containers/CompanyUsersScreen.scss";
import IndexHeaderImg from "src/assets/images/company_users/company_users_index.png";
import AddNewUserImg from "src/assets/images/company_users/add_new_user.png";
import CompanyUsersHeader from "src/components/company_users/CompanyUsersHeader";
import AddOrEditCompanyUser from "src/components/company_users/AddOrEditCompanyUser";
import { GET_COMPANY_USER } from "src/graphql/users/queries";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import swal from "sweetalert";
import "src/components/company_users/DeactivationModal.scss";
import { withApollo } from "react-apollo";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { useSelector } from "react-redux";

const CompanyUsersScreen = ({ client }) => {
  useDocumentTitle("Users | Customer Lobby");
  const { userInfo } = useSelector((state) => state.user);

  const companyId = useGetCompanyIdFromUrl();
  const [successMessage, setSuccessMessage] = useState("");
  const [queriedUser, setQueriedUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyId,
    source: "",
    password: "",
    passwordConfirmation: "",
    avatarUrl: "",
    isManager: true,
    isActive: true,
    formattedUserDate: "",
    imageOriginalFileName: "",
    base64Image: "",
  });
  const [display, setDisplay] = useState("none");
  const [path, setPath] = useState("users");
  const [headerState, setHeaderState] = useState({
    users: {
      img: IndexHeaderImg,
      label: "Manage Users",
      content: "Add new users to the company or manage existing users.",
      createdOn: false,
    },
    add: {
      img: AddNewUserImg,
      label: "Add New User",
      content: "Add a new user to the company.",
      createdOn: false,
    },
    edit: {
      img: AddNewUserImg,
      label: "Edit User Details",
      content: "Edit an existing user's details.",
      createdOn: true,
    },
  });

  const location = useLocation();

  const handleEditUser = () => {
    const splitLocation = location.pathname.split("/");
    const currPath = splitLocation[splitLocation.length - 1];

    if (currPath === "edit") {
      const userToEdit = splitLocation[splitLocation.length - 2];
      // user changes often so want to always get from server
      client
        .query({
          query: GET_COMPANY_USER,
          variables: { companyUserId: userToEdit },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data && res.data.getCompanyUser && !res.loading)
            setQueriedUser((q) => {
              return { ...q, ...res.data.getCompanyUser };
            });
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    const splitLocation = location.pathname.split("/");
    const currPath = splitLocation[splitLocation.length - 1];
    setPath(currPath);
    handleEditUser();
  }, [location]);

  const handleHeaderImage = () => {
    return IndexHeaderImg;
  };

  const openModal = (callback, text, value, content) => {
    swal({
      title: "Are you sure?",
      className: "custom-deactivation-template",
      text: content,
      icon: "error",
      buttons: {
        save: {
          text,
          value,
        },
        cancel: true,
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
    })
      .then((confirm) => {
        if (confirm === value) {
          callback();
        }
      })
      .catch(() => {});
  };

  const renderComponent = () => {
    if (path === "users")
      return (
        <CompanyUsersIndex
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          setDisplay={setDisplay}
          display={display}
          setQueriedUser={setQueriedUser}
          openModal={openModal}
        />
      );
    return (
      <AddOrEditCompanyUser
        setSuccessMessage={setSuccessMessage}
        path={path}
        setDisplay={setDisplay}
        queriedUser={queriedUser}
        openModal={openModal}
      />
    );
  };

  return (
    <div className="company-users-container container-fluid mb-4">
      <div
        style={{ width: path === "users" ? "100%" : "83.333333%" }}
        className="d-flex flex-column"
      >
        <CompanyUsersHeader
          source={headerState[path]?.img}
          label={headerState[path]?.label}
          content={headerState[path]?.content}
          companyUserIsManager={Number(userInfo?.isManager)}
          showBtn={path === "users"}
          createdOn={headerState[path]?.createdOn}
          setQueriedUser={setQueriedUser}
          createdAt={queriedUser.formattedUserDate}
          path={path}
        />
        <div className="container-fluid d-flex mt-4">
          <div className="placeholder-block mr-4" />
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default withApollo(CompanyUsersScreen);
