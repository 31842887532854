import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient } from "react-apollo";
import ellipsisV from "src/styles/assets/ellipsis-v.svg";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import downloadIcon from "src/assets/images/common/download.svg";
import { ENTERPRISE_CAMPAIGN_USER_REPORT } from "src/graphql/enterprise/queries";
import filterIcon from "src/assets/images/enterprise/filter.svg";
import ReadMoreNotes from "src/shared/styleguide/common/ReadMoreNotes";
import { Row, Col } from "react-bootstrap";
import CsvUtil from "src/utils/CsvUtil";
import SearchComponent from "src/components/search_component/SearchComponent";
import UsageFilterModal from "./components/UsageFilterModal";
import {
  ENTERPRISE_RECENT_CAMPAIGNS,
  ENTERPRISE_UNIQUE_CAMPAIGN_NAMES,
} from "src/graphql/enterprise/queries";
import NoRecordFound from "../NoRecordFound";
import { rearrangedArrayData, truncateString } from "src/shared/Utils";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { CAMPAIGN_TYPE, DEFAULT_SELECTIONS } from "./CampaignUsageConstants";
import RecentCampaigns from "./components/RecentCampaigns";
import format from "date-fns/format";
import "./CampaignUsageReport.scss";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";

export const HEADER_ITEMS = [
  {
    columnLabel: "ID",
    columnValue: "companyId",
    sortable: true,
  },
  {
    columnLabel: "ACCOUNT NAME",
    columnValue: "company_name",
    sortable: true,
  },
  {
    columnLabel: "NUMBER OF CAMPAIGN CREATED",
    columnValue: "campaignCount",
    sortable: true,
  },
  {
    columnLabel: "",
    columnValue: "",
    sortable: false,
  },
];

const INITIAL_FILTER = {
  companySearchTerm: "",
  campaignSearchTerm: "",
  categories: [],
  sortBy: {
    value: "",
  },
  sortOrder: {
    value: "",
  },
};

const CampaignUsageReport = () => {
  useDocumentTitle("Location Campaign Usage");
  const perPage = 10;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataProvider, setDataProvider] = useState([]);
  const [recentCampaigns, setRecentCampaigns] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [campaignsType, setCampaignType] = useState(DEFAULT_SELECTIONS);
  const [campaginNameOptions, setCampaignNameOptions] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [isFilterApplied, setFilterApplied] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTER);

  useEffect(() => {
    setCurrentPage(0);
    getCampaignUsage({
      page: 0,
    });
  }, [filters.sortBy]);

  useEffect(() => {
    searchCampaignNames(filters.categories, filters.companySearchTerm);
  }, [filters.companySearchTerm]);

  const getRecentCampaigns = (companyId) => {
    const now = new Date();
    const then = new Date();
    then.setDate(now.getDate() + 29);
    setModelLoading(true);
    setRecentCampaigns("");
    client
      .query({
        query: ENTERPRISE_RECENT_CAMPAIGNS,
        fetchPolicy: NO_CACHE,
        variables: {
          companyId,
          fromDate: format(now, "dd/MM/yyyy"),
          toDate: format(then, "dd/MM/yyyy"),
          rpp: 10,
          page: 1,
          sortBy: "mailing_date",
          sortOrder: "asc",
          status: ["scheduled", "scheduled_in_progress"],
        },
      })
      .then(({ data: { listUpcomingCampaigns } }) => {
        setRecentCampaigns(listUpcomingCampaigns);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setModelLoading(false);
      });
  };

  const onUsageFilterApplied = (categories, campaignSearchTerm) => {
    setFilters({
      ...filters,
      categories: categories,
      campaignSearchTerm: campaignSearchTerm,
    });
    setCurrentPage(0);
    getCampaignUsage({
      page: 0,
      categories,
      campaignSearchTerm,
    });
    setShowFilterModal(false);
    setFilterApplied(true);
  };

  const getCampaignUsageRow = (data) => {
    const { companyName, campaignCount, companyId } = data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span>{companyId}</span>
          </div>
        ),
      },
      {
        node: companyName,
      },
      {
        node: campaignCount,
      },
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <ReadMoreNotes
              icon={
                <div onClick={() => getRecentCampaigns(companyId)}>
                  <img className="ml-1" src={ellipsisV} />
                </div>
              }
              title="Recent Campaigns"
              className="enterprise-recent-campaigns"
              placement="left-start"
              threshold={400}
              minWidth={660}
              rootClose={true}
              content={
                <RecentCampaigns
                  recentCampaigns={recentCampaigns}
                  modelLoading={modelLoading}
                />
              }
            />
          </div>
        ),
      },
    ];
  };

  const getCampaignUsage = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy?.value || filters.sortBy?.value || "",
      sortOrder: props.sortOrder?.value || filters.sortOrder?.value || "",
      page: (props.page ?? currentPage) + 1,
      category: props.categories || filters.categories || [],
      companySearchTerm:
        props.companySearchTerm || filters.companySearchTerm || "",
      campaignSearchTerm:
        props.campaignSearchTerm || filters.campaignSearchTerm || "",
    };

    client
      .query({
        query: ENTERPRISE_CAMPAIGN_USER_REPORT,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterpriseCampaignUsage) {
          const campaignUsage = res.data.enterpriseCampaignUsage.data;
          const totalRecords = res.data.enterpriseCampaignUsage.totalCount;
          setDataProvider(campaignUsage);
          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getCampaignUsage({
      page: selected,
    });
  };

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getCampaignUsage({ page: 0, ...params });
  };

  const debounced = useCallback(debounce(getSearchResults, 500), []);

  const searchRecord = (companySearchTerm) => {
    setFilters({ ...filters, companySearchTerm });
    debounced({
      categories: filters.categories,
      companySearchTerm: companySearchTerm,
      campaignSearchTerm: filters.campaignSearchTerm,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const downloadCSV = () => {
    const queryVariables = {
      rpp: 0,
      sortBy: filters.sortBy?.value || "",
      sortOrder: filters.sortOrder?.value || "",
      page: currentPage + 1,
      category: filters.categories || [],
      companySearchTerm: filters.companySearchTerm || "",
      campaignSearchTerm: filters.campaignSearchTerm || "",
    };

    client
      .query({
        query: ENTERPRISE_CAMPAIGN_USER_REPORT,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then(({ data: { enterpriseCampaignUsage } }) => {
        const order = ["companyId", "companyName", "campaignCount"];

        const labels = ["ID", "ACCOUNT NAME", "NUMBER OF CAMPAIGN CREATED"];

        const rearrangedArray = rearrangedArrayData(
          enterpriseCampaignUsage?.data,
          order,
        );

        CsvUtil.downloadCSVFile(
          rearrangedArray,
          labels,
          "Campaign Usage Report",
        );
      });
  };

  const handleFilterSelection = (value) => {
    if (value) {
      setShowFilterModal(true);
    } else if (isFilterApplied) {
      setShowFilterModal(false);
    } else {
      DEFAULT_SELECTIONS[0].isChecked = true
      setCampaignType(DEFAULT_SELECTIONS);
      setSelectedCampaigns([]);
      setShowFilterModal(false);
    }
  };

  const onResetFilter = () => {
    DEFAULT_SELECTIONS[0].isChecked = true
    setFilters(Object.assign(filters, INITIAL_FILTER));
    setCurrentPage(0);
    setCampaignType(DEFAULT_SELECTIONS);
    setSelectedCampaigns([]);
    setFilterApplied(false);
    // close the search input box
    const searchClose = document.querySelector(".search-close-icon");
    if (searchClose) {
      searchClose.click();
    } else {
      getCampaignUsage();
    }
  };

  const onCampaignTypeChange = (key, isChecked) => {
    const updatedCampaigns = campaignsType.map((item) => {
      if (item.key === key) {
        return { ...item, isChecked: !isChecked };
      }

      // If 'All' is selected, uncheck all other types
      if (key === CAMPAIGN_TYPE.ALL && !isChecked) {
        return { ...item, isChecked: false };
      }

      return item;
    });

    // If a specific type (not 'All') is selected, uncheck 'All'
    if (key !== CAMPAIGN_TYPE.ALL) {
      updatedCampaigns[0].isChecked = false;
    }
    const categories = updatedCampaigns[0].isChecked
      ? []
      : updatedCampaigns
        .filter((item) => item.key !== CAMPAIGN_TYPE.ALL && item.isChecked)
        .map((item) => item.key);
    setCampaignType(updatedCampaigns);
    setSelectedCampaigns([]);
    searchCampaignNames(categories);
  };

  // fetching campaign names
  const searchCampaignNames = debounce((category = [], companySearchTerm) => {
    client
      .query({
        query: ENTERPRISE_UNIQUE_CAMPAIGN_NAMES,
        fetchPolicy: NO_CACHE,
        variables: { category, companySearchTerm, campaignSearchTerm: "" },
      })
      .then(({ data: { enterpriseUniqCampaignNames } }) => {
        const res = enterpriseUniqCampaignNames.map((item) => {
          return { label: <PopupInfo icon={<span>{truncateString(item.name, 22)}</span>} message={item.name} />, value: item.name };
        });
        setCampaignNameOptions(res);
      });
  }, 500);

  const setMultiSelection = (items) => {
    setSelectedCampaigns([...items]);
  };

  const isFilterDisabled = () => {
    const index = campaignsType.findIndex((item) => item.isChecked === true);
    return Boolean(selectedCampaigns.length == 0 || index == -1);
  };

  return (
    <>
      <div className="page-title">Location Campaign Usage</div>

      <Card
        className="mt-4"
        title={
          <Row>
            <Col xs={5} className="table-heading">
              Campaign Usage Report (Total No. of Locations)
            </Col>
            {isFilterApplied && (
              <span
                className="pl-2 meta-label sg-text-info cursor-pointer meta-reset reset-text"
                onClick={() => onResetFilter()}
              >
                Reset Filters
              </span>
            )}
            <Col className="d-flex justify-content-end align-items-center">
              <SearchComponent
                handleOnChange={(companySearchTerm) =>
                  searchRecord(companySearchTerm)
                }
              />
              <div className="filter-button">
                {isFilterApplied && <span className="filter-badge" />}
                <img width="15" src={filterIcon} className="mr-1" />
                <span
                  className="cursor-pointer"
                  onClick={() => handleFilterSelection(true)}
                >
                  FILTER
                </span>
                {showFilterModal && (
                  <UsageFilterModal
                    campaignsType={campaignsType}
                    campaginNameOptions={campaginNameOptions}
                    selectedCampaigns={selectedCampaigns}
                    isFilterDisabled={() => isFilterDisabled()}
                    closeModal={() => handleFilterSelection(false)}
                    setMultiSelection={(items) => setMultiSelection(items)}
                    onCampaignTypeChange={(key, isChecked) =>
                      onCampaignTypeChange(key, isChecked)
                    }
                    onApply={(categories, campaignSearchTerm) =>
                      onUsageFilterApplied(categories, campaignSearchTerm)
                    }
                  />
                )}
              </div>
              <img
                className="ml-2 cursor-pointer"
                onClick={downloadCSV}
                height="15"
                width="30"
                src={downloadIcon}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <SortableTableHeader
                sortable={values.sortable}
                columnLabel={values.columnLabel}
                columnValue={values.columnValue}
                onSort={onSort}
                currentSortValue={filters.sortBy.value}
                currentSortOrder={filters.sortOrder.value}
              />
            ))}
            body={dataProvider.map((data) => getCampaignUsageRow(data))}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </>
  );
};

export default CampaignUsageReport;
