import React from "react";
import PropTypes from "prop-types";

const AuthBlock = ({
  redirectTo = null,
  show = false,
  children = null,
  fallback = null,
}) => {
  // effects
  React.useEffect(() => {
    if (!show && redirectTo) {
      window.location = redirectTo;
    }
  });

  // checks
  if (!show) {
    return fallback;
  }

  // render
  return children;
};

AuthBlock.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

const ManagerAuthBlock = ({ manager, ...props }) => (
  <AuthBlock show={manager} {...props} />
);
ManagerAuthBlock.propTypes = {
  manager: PropTypes.bool.isRequired,
};

export default AuthBlock;
export { ManagerAuthBlock };
