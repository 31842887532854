import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import {
  QUERY_COMPANY_PLACES,
  MUTATION_UPDATE_PLACE,
} from "src/graphql/settings/syndication/queries";
import { Table, Row, Col } from "react-bootstrap";
import EllipsisV from "src/styles/assets/ellipsis-v.svg";
import Button from "src/shared/styleguide/button/Button";
import errorAlertImg from "src/assets/images/alerts/error.svg";
import successAlertImg from "src/assets/images/alerts/success.svg";
import cityGridImg from "src/assets/images/settings/citygrid_logo_grey_words.jpg";
import infoUSAImg from "src/assets/images/settings/InfoUSA_logo.jpg";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import CompanyInformation from "./CompanyInformation";
import Modal from "src/shared/styleguide/modal/Modal";
import NumberUtil from "src/utils/NumberUtil";
import PropTypes from "prop-types";
import { addToast } from "src/utils/ToastUtil";

const CompanyPlacesTable = ({ oldPlace }) => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [showModal, setShowModal] = useState();
  const [matched, setMatched] = useState(false);
  const [hiddenPlaces, setHiddenPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState();
  const [countPlaces, setCountPlaces] = useState({
    new: 0,
    synchronized: 0,
    rejected: 0,
  });

  // call apis
  const { loading: loadingPlaces, data: dataPlaces } = useQuery(
    QUERY_COMPANY_PLACES,
    {
      variables: {
        companyId: showCompanyId,
      },
      onCompleted: (data) => {
        populateData(data);
      },
    },
  );
  const [updateStatus] = useMutation(MUTATION_UPDATE_PLACE);

  // methods:
  const populateData = (data) => {
    if (data && data.companyPlaces) {
      setCountPlaces({
        new: data.companyPlaces.filter((place) => place.status === "new")
          .length,
        synchronized: data.companyPlaces.filter(
          (place) => place.status === "synchronized",
        ).length,
        rejected: data.companyPlaces.filter(
          (place) => place.status === "rejected",
        ).length,
      });
    }
  };
  const hideThisPlace = (place) => {
    updateStatus({
      variables: {
        id: place.id,
        companyId: showCompanyId,
        status: "rejected",
      },
    })
      .then(() => {
        setHiddenPlaces([...hiddenPlaces, place.id]);
        addToast({
          type: "success",
          message: "You have successfully deleted the address.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Unable to delete address.",
        });
      });
  };

  const matchThisPlace = (place) => {
    updateStatus({
      variables: {
        id: place.id,
        companyId: showCompanyId,
        status: "synchronized",
      },
    })
      .then(() => {
        setMatched(true);
        addToast({
          type: "success",
          message: "You have successfully matched the address.",
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          message: "Unable to match address.",
        });
      });
  };

  // checks
  if (loadingPlaces) {
    return null;
  }

  // render
  return (
    <React.Fragment>
      {countPlaces.synchronized > 0 || matched ? (
        <div>
          <span
            style={{
              color: "#1bc652",
              borderRadius: 6,
              border: "solid 1px #1bc652",
              opacity: 0.9,
              fontSize: "0.9rem",
            }}
            className="p-2 px-4"
          >
            {" "}
            <img
              style={{
                width: 16,
                height: 16,
              }}
              src={successAlertImg}
              alt="Error"
              className="mr-2"
            />
            Congratulations! Your data on Citygrid and Customer Lobby are an
            exact match.
          </span>
        </div>
      ) : countPlaces.new > 0 ? (
        <React.Fragment>
          <Modal
            onHide={() => setShowModal(false)}
            header="Match to this place"
            body={
              <React.Fragment>
                <p className="my-4">
                  Are you sure you want to update your Customer Lobby Account
                  information to match this location?
                </p>
                <Row className="mb-3">
                  <Col xl={6}>
                    <CompanyInformation label="Old Place" place={oldPlace} />
                  </Col>
                  <Col xl={6}>
                    <CompanyInformation
                      label="New Place"
                      place={selectedPlace}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }
            footer={
              <React.Fragment>
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    matchThisPlace(selectedPlace);
                    setShowModal(false);
                  }}
                >
                  Update
                </Button>
              </React.Fragment>
            }
            show={selectedPlace && showModal}
            handleClose={() => {}}
          />
          <Table className="table-company-places mt-3">
            <thead>
              <tr>
                <th>ADDRESS</th>
                <th>SOURCE</th>
                <th>SYNC STATUS</th>
                <th>MATCH PROBABILITY</th>
                <th>{/* actions */}</th>
              </tr>
            </thead>
            <tbody>
              {dataPlaces.companyPlaces
                .filter(
                  (place) =>
                    place.status === "new" && !hiddenPlaces.includes(place.id),
                )
                .map((place) => (
                  <tr key={place.id}>
                    <td>
                      <Button
                        variant="link"
                        style={{
                          color: "#428bca",
                        }}
                        className="text-left"
                      >
                        {place.name}, {place.city}, {place.state},{" "}
                        {place.zipcode}
                      </Button>
                    </td>
                    <td className="text-capitalize">{place.source}</td>
                    <td className="text-capitalize">{place.status}</td>
                    <td>{NumberUtil.toPercentage(place.matchScore, 1)}</td>
                    <td style={{ width: 8 }}>
                      <DropdownButton
                        alignRight
                        variant="link"
                        direction={false}
                        title={<img src={EllipsisV} alt="Ellipsis" />}
                      >
                        <Dropdown.Item
                          href="#/"
                          onClick={() => hideThisPlace(place)}
                        >
                          Hide this place
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedPlace(place);
                            setShowModal(true);
                          }}
                          href="#/"
                        >
                          Match to this place
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </React.Fragment>
      ) : (
        <div
          style={{
            maxWidth: 640,
            borderRadius: 6,
            border: "solid 1px #f42134",
          }}
          className="p-4"
        >
          <div className="d-flex flex-row align-items-start">
            <img src={errorAlertImg} alt="Error" className="mt-1 mr-2" />
            <div style={{ color: "#f42134" }}>
              Uh oh, we did not find any information of your business in City
              Grid. You can fix this by registering your business at
            </div>
          </div>

          <div className="mt-4">
            <img src={cityGridImg} alt="Citygrid" />
            <span className="mx-3"> and or</span>
            <img src={infoUSAImg} alt="InfoUSA" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

CompanyPlacesTable.propTypes = {
  oldPlace: PropTypes.object.isRequired,
};

export default CompanyPlacesTable;
