import React from "react";
import PropTypes from "prop-types";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import "./CustomerSegmentsContainer.scss";
import newCustomerImage from "src/assets/images/customer/create_customer.svg";
import newSegmentImage from "src/assets/images/segments/create_segment.svg";
import { useHistory } from "react-router-dom";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const CreateNewButton = ({ setShowAddCustomersModal }) => {
  const history = useHistory();
  const showCompanyId = useGetCompanyIdFromUrl();
  return (
    <div className="new-customer-btn pull-right">
      <DropdownButton title="+ New ">
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            setShowAddCustomersModal(true);
          }}
        >
          <img src={newCustomerImage} alt="Add Customers" /> Add Customers
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/companies/${showCompanyId}/segments/new`, {
              prevPage: document.location.pathname,
            });
          }}
        >
          <img src={newSegmentImage} alt="Create Segment" /> Create Segment
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

CreateNewButton.propTypes = {
  setShowAddCustomersModal: PropTypes.func,
};

export default CreateNewButton;
