import React, { Component } from "react";
import "./CompanySettings.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { COOKIES, APPOLO_FETCH_POLICY } from "../../../shared/Config";
import { PHONE_NUMBER_WITH_LETTER_EXPRESSION } from "../../../shared/Constants";
import { loadCookie } from "../../../shared/SessionHelper";
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_COMPANY_SETTINGS,
} from "./graphql_queries/queries";
import { UPDATE_COMPANY_SETTINGS } from "./graphql_queries/mutations";
import { gql } from "apollo-boost";
import { numberToPhoneNumberConversion } from "../../../shared/Utils";
import ProfileSettingTitleBar from "./ProfileSettingTitleBar";
import StyleGuideForm from "src/shared/styleguide/form/Form.scss";
import { addToast } from "src/utils/ToastUtil";
import { connect } from "react-redux";

/**
 * Contact details component
 */
class ContactDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      blocking: false,
      token: loadCookie(COOKIES.AUTH_TOKEN),
      companyId: this.props.companyId,
      countries: [],
      states: [],
      companySettings: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.getCountries();
    this.getCompanySettings();
  }

  /**
   * Toggled the clocking of the UI.
   */
  toggleBlocking() {
    this.setState({ blocking: !this.state.blocking });
  }

  /**
   * Set visibility texting toast pop up.
   * @param visible -> boolean value, true if popup needs to be visible.
   */
  enabledToast = (visible) => {
    this.setState({ showToast: visible });
  };

  /**
   * Extracts available business hours for a company.
   */
  getCountries() {
    this.props.client
      .query({
        query: gql`
          ${GET_COUNTRIES}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.listCountries &&
          response.data.listCountries.countries
        ) {
          this.setState({ countries: response.data.listCountries.countries });
        }
      })
      .catch((error) => {
        console.log("Error in getting countries: ", error);
      });
  }

  /**
   * Extracts states for the country.
   */
  getStates(country) {
    this.props.client
      .query({
        query: gql`
          ${GET_STATES}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: { country: country },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        if (response && response.data && response.data.listStates) {
          this.setState({ states: response.data.listStates });
        }
      })
      .catch((error) => {
        console.log("Error in getting states: ", error);
      });
  }

  /**
   * Handler for country changed drop-down
   * @param event
   * @param setFieldValue
   */
  countryChanged = (event, setFieldValue) => {
    setFieldValue("country", event.target.value);
    setFieldValue("state", "");
    this.getStates(event.target.value);
  };

  /**
   * Extracts available company settings.
   */
  getCompanySettings() {
    this.toggleBlocking();
    this.props.client
      .query({
        query: gql`
          ${GET_COMPANY_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: { id: parseInt(this.state.companyId, 10) },
        fetchPolicy: APPOLO_FETCH_POLICY,
      })
      .then((response) => {
        this.toggleBlocking();
        if (response && response.data && response.data.company) {
          this.setState({ companySettings: response.data.company });
          if (response.data.company.country) {
            this.getStates(response.data.company.country);
          }
        }
      })
      .catch((error) => {
        this.toggleBlocking();
        console.log("Error in getting states: ", error);
      });
  }

  /**
   * Updates company settings.
   */
  updateCompanySettings(values) {
    this.toggleBlocking();
    this.props.client
      .mutate({
        mutation: gql`
          ${UPDATE_COMPANY_SETTINGS}
        `,
        context: { headers: { AUTHORIZATION: this.state.token } },
        variables: this.processParams(values),
      })
      .then(() => {
        addToast({
          type: "success",
          message: "Company contact details updated successfully.",
        });
        this.toggleBlocking();
      })
      .catch(() => {
        this.setState({
          type: "error",
          message: "Failed to update company contact details.",
        });
        this.toggleBlocking();
      });
  }

  /**
   * Process the updated company settings.
   * @param values
   * @returns {{companyId: Number, address1: (*|string), address2: (*|string), country: (*|string|string), state, city: (*|string), zip, phone: (*|string), phoneExt: (RequestReview.extension|*|string)}}
   */
  processParams(values) {
    let params = {
      companyId: parseInt(this.state.companyId, 10),
      address1: values.street,
      address2: values.suite,
      country: values.country,
      state: values.state,
      city: values.city,
      zip: values.zip.toString(),
      phone: values.phone,
      phoneExt: values.extension,
    };
    return params;
  }

  render() {
    const { companySettings } = this.state;
    const self = this;
    return (
      <Formik
        initialValues={{
          street: companySettings ? companySettings.address1 : "",
          suite: companySettings ? companySettings.address2 : "",
          city: companySettings ? companySettings.city : "",
          state: companySettings ? companySettings.state : "",
          zip: companySettings ? companySettings.zip : "",
          country: companySettings ? companySettings.country : "",
          phone: companySettings
            ? companySettings.phone.match(/[A-Za-z]/)
              ? companySettings.phone
              : numberToPhoneNumberConversion(companySettings.phone)
            : "",
          extension: companySettings ? companySettings.phoneExt : "",
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          street: Yup.string().required("Field required"),
          suite: Yup.string(),
          city: Yup.string().required("Field required"),
          state: Yup.string().required("Field required"),
          zip: Yup.string().required("Field required").max(7),
          country: Yup.string().required("Field required"),
          phone: Yup.string()
            .required("Field required")
            .matches(PHONE_NUMBER_WITH_LETTER_EXPRESSION, "Invalid phone"),
        })}
        onSubmit={(values, actions) => {
          self.updateCompanySettings(values);
        }}
        render={({ errors, status, touched, values, setFieldValue }) => (
          <BlockUi tag="div" blocking={this.state.blocking}>
            <Form>
              <div id="contact-details">
                <div className="row">
                  <div className="col-lg-10 col-md-12">
                    <div className="row">
                      <div className="col-12">
                        <ProfileSettingTitleBar
                          title={"Contact Details"}
                          subTitle={
                            "This section contains your company contact details like address and contact details"
                          }
                        />
                      </div>
                    </div>
                    <div className="row offset-sm-1">
                      <div className="col-12 col-md-11">
                        <div className="row form-heading mt-3 ml-0">
                          Address
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <label className="input-label required-field">
                              Street
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="street"
                              className={
                                "form-control" +
                                (errors.street && touched.street
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="1234 Center Ave."
                            ></Field>
                            <ErrorMessage
                              name="street"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-6 col-md-6">
                            <label className="input-label">Suite</label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="suite"
                              className={
                                "form-control" +
                                (errors.suite && touched.suite
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Suite 300"
                            ></Field>
                            <ErrorMessage
                              name="suite"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-6 col-md-6">
                            <label className="input-label required-field">
                              City
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="city"
                              className={
                                "form-control" +
                                (errors.city && touched.city
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Berkeley"
                            ></Field>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-6 col-md-6">
                            <label className="input-label required-field">
                              Country
                            </label>
                            <br />
                            <Field
                              as="select"
                              name="country"
                              className={
                                "form-control" +
                                (errors.country && touched.country
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(event) =>
                                this.countryChanged(event, setFieldValue)
                              }
                            >
                              <option value="">Select Country</option>
                              {this.state.countries.map((country, index) => (
                                <option value={country} key={index}>
                                  {" "}
                                  {country}{" "}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-6 col-md-6">
                            <label className="input-label required-field">
                              State
                            </label>
                            <br />
                            <Field
                              component="select"
                              name="state"
                              className={
                                "form-control" +
                                (errors.state && touched.state
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={!values.country}
                            >
                              <option value="">Select State</option>
                              {this.state.states.map((state, index) => (
                                <option value={state.code} key={index}>
                                  {" "}
                                  {state.name}{" "}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-6 col-md-6">
                            <label className="input-label required-field">
                              ZIP/Postal Code
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="zip"
                              className={
                                "form-control" +
                                (errors.zip && touched.zip ? " is-invalid" : "")
                              }
                              placeholder="94707"
                            ></Field>
                            <ErrorMessage
                              name="zip"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row form-heading mt-4 ml-0">
                          Contact Details
                        </div>
                        <div className="row mt-4">
                          <div className="col-6 col-md-6">
                            <label className="input-label required-field">
                              Phone
                            </label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="phone"
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="(123) 555-2345"
                            ></Field>
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-6 col-md-6">
                            <label className="input-label">Extension</label>
                            <br />
                            <Field
                              component={StyleGuideForm.Control}
                              name="extension"
                              className="form-control"
                              placeholder="55"
                            ></Field>
                            <ErrorMessage
                              name="extension"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row mt-4 ml-0 mb-5">
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </BlockUi>
        )}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  companyId: user.userInfo.companyId,
});

export default connect(mapStateToProps)(ContactDetails);
