import React, { useEffect, useState } from "react";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import { Link, withRouter, useLocation, NavLink } from "react-router-dom";
import logo from "src/img/header/logo.png";
import { useLazyQuery, useQuery } from "react-apollo";
import { GET_ENTERPRISE_DETAILS } from "src/graphql/enterprise/queries";
import EnterpriseReporting from "src/assets/images/enterprise/enterprise-reporting.svg";
import campaignOverview from "src/assets/images/enterprise/prospect-campaign-overview.svg";
import brandingBudget from "src/assets/images/enterprise/branding-budget.svg";
import locations from "src/assets/images/enterprise/locations.svg";
import settingsIcon from "src/assets/images/enterprise/settings.svg";
import usersIcon from "src/assets/images/enterprise/users.svg";
import notificationsIcon from "src/assets/images/enterprise/notifications.svg";
import billingIcon from "src/assets/images/enterprise/billing.svg";
import enterpriseIcon from "src/assets/images/enterprise/enterprise.svg";
import overallProspectCampaignOverviewIcon from "src/assets/images/enterprise/overall_prospect_campaign_overview.svg";
import { useDispatch } from "react-redux";
import { setEnterpriseDetails } from "src/redux/userSlice";
import "./Sidebar.scss";

const Sidebar = ({ match }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); // Toggle state for sub-menu
  const [openSettings, setOpenSettings] = useState(
    location.pathname.includes("settings"),
  ); // Toggle state for sub-menu

  const [fetchEnterpriseDetails] = useLazyQuery(GET_ENTERPRISE_DETAILS, {
    onCompleted: ({ getEnterprise }) => {
      dispatch(setEnterpriseDetails(getEnterprise));
    },
  });

  useEffect(() => {
    setTimeout(() => {
      fetchEnterpriseDetails();
    }, 1000);
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("settings")) {
      setOpenSettings(false);
    }
    if (location.pathname.includes("key-metrics")) {
      setOpen(true);
    }
  }, [location.pathname]);

  const isCampaignOverviewActive = [
    "/key-metrics",
    "/branding-budget",
    "/campaign-usage-report",
  ].some((path) => location.pathname.includes(path));

  return (
    <Nav defaultActiveKey="/overview" className="flex-column p-3">
      <Navbar.Brand className=" pt-1 pb-4" href="#">
        <img
          src={logo}
          className="logo-image"
          height="40px"
          alt="Customer Lobby"
        />
      </Navbar.Brand>

      {/* <Nav.Link href="locations" className=" mb-2 rounded">
        <img src={EnterpriseReporting} />
        Enterprise Reporting
        <div className={`chevron collapsed`}></div>
      </Nav.Link> */}
      <div className="menu-container">
        <Accordion>
          <Card className="border-0">
            <Card.Header className="p-0">
              <Nav.Link
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                className=" w-100 text-left d-flex justify-content-between align-items-center"
              >
                <div
                  className={`d-flex align-items-center ${
                    isCampaignOverviewActive ? "text-white" : ""
                  }`}
                >
                  <img src={campaignOverview} className="mr-2" />
                  Campaign Overview
                </div>
                <div className={`chevron ${open ? "collapsed" : ""}`}></div>
              </Nav.Link>
            </Card.Header>
            <Accordion.Collapse
              eventKey="0"
              className="bg-dark-slate"
              in={open}
            >
              <Card.Body className="p-0">
                <Nav className="flex-column">
                  <NavLink
                    to={`${match.url}/key-metrics`}
                    className="pl-5"
                    activeClassName="active-link"
                  >
                    <img src={brandingBudget} className="mr-2" />
                    Key Metrics
                  </NavLink>
                  <NavLink
                    to={`${match.url}/branding-budget`}
                    className="pl-5"
                    activeClassName="active-link"
                  >
                    <img src={brandingBudget} className="mr-2" />
                    Branding Budget
                  </NavLink>
                  <NavLink
                    to={`${match.url}/campaign-usage-report`}
                    className="pl-5"
                    activeClassName="active-link"
                  >
                    <img
                      src={overallProspectCampaignOverviewIcon}
                      className="mr-2"
                    />
                    Campaign Usage Report
                  </NavLink>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <NavLink to={`${match.url}/locations`} activeClassName="active-link">
          <img src={locations} className="mr-2" />
          Locations
        </NavLink>
        <NavLink to={`${match.url}/users`} activeClassName="active-link">
          <img src={usersIcon} className="mr-2" />
          Users
        </NavLink>
        <Accordion>
          <Card className="border-0">
            <Card.Header className="p-0">
              <Nav.Link
                onClick={() => setOpenSettings(!openSettings)}
                aria-expanded={openSettings}
                className={`w-100 text-left d-flex justify-content-between align-items-center ${
                  location.pathname.includes("settings") ? "text-white" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <img src={settingsIcon} className="mr-2" />
                  Settings
                </div>
                <div
                  className={`chevron ${openSettings ? "collapsed" : ""}`}
                ></div>
              </Nav.Link>
            </Card.Header>
            <Accordion.Collapse
              eventKey="0"
              className="bg-dark-slate"
              in={openSettings}
            >
              <Card.Body className="p-0">
                <Nav className="flex-column">
                  <NavLink
                    to={`${match.url}/settings`}
                    className="  pl-5"
                    activeClassName="active-link"
                    isActive={(match, location) =>
                      location.pathname.includes("/settings") &&
                      !location.pathname.includes("/settings/")
                    }
                  >
                    <img src={enterpriseIcon} className="mr-2" />
                    Enterprise
                  </NavLink>
                  <NavLink
                    to={`${match.url}/settings/notifications`}
                    className=" mt-2  pl-5"
                    activeClassName="active-link"
                  >
                    <img src={notificationsIcon} className="mr-2" />
                    Notifications
                  </NavLink>
                  <NavLink
                    to={`${match.url}/settings/billing`}
                    className=" mt-2  pl-5"
                    activeClassName="active-link"
                  >
                    <img src={billingIcon} className="mr-2" />
                    Billing
                  </NavLink>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Nav>
  );
};

export default withRouter(Sidebar);
