import React, { useEffect } from "react";
import { useMutation } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import { PARTNER_LOGIN_MUTATION } from "src/graphql/auth/mutation";
import { useDispatch } from "react-redux";
import { setToastMessage } from "src/redux/toastSlice";

const ServiceFusionLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get("token");

  const [partnerLogin] = useMutation(PARTNER_LOGIN_MUTATION, {
    variables: {
      authToken: token,
    },
    onCompleted: ({ partnerIntegrationLogin }) => {
      if (partnerIntegrationLogin?.authenticated) {
        history.push(
          `/ghost-users/login-as/${partnerIntegrationLogin?.token}?id=${partnerIntegrationLogin?.companyId}&type=company&loginType=fusion`,
        );
      } else {
        dispatch(
          setToastMessage({
            type: "error",
            message: partnerIntegrationLogin?.errors,
          }),
        );
        history.push(`/company/login`);
      }
    },
    onError: (err) => {
      dispatch(
        setToastMessage({
          type: "error",
          message:
            err && err.graphQLErrors && err.graphQLErrors[0]
              ? err.graphQLErrors[0].message
              : "Something went wrong, please try again.",
        }),
      );
      history.push(`/company/login`);
    },
  });

  useEffect(() => {
    partnerLogin();
  }, []);
  return (
    <div className="page-loader-container">
      <div class="loader"></div>
    </div>
  );
};

export default ServiceFusionLogin;
