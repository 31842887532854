import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import usePersistentState from "src/utils/hooks/usePersistentState";
import { Row, Col, Spinner } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { MEMBERS_URLS } from "src/shared/Config";
import UrlUtil from "src/utils/UrlUtil";
import StringUtil from "src/utils/StringUtil";
import {
  REVIEW_RESULTS_FETCH_POLICY,
  REVIEWS_BASEURL,
  GOOGLE_REVIEWS_BASEURL,
} from "src/constants/ReviewResults";
import {
  QUERY_MINI_WIDGETS,
  QUERY_REVIEWS_META,
} from "src/graphql/reviews/results/queries";
// import local components
import MiniGridWidget from "./grid_widgets/MiniGridWidget";
import MediumGridWidget from "./grid_widgets/MediumGridWidget";
import DateFilter from "./date_filter/DateFilter";
import ReviewLink from "./review_link/ReviewLink";
import OverallReviewPerformance from "./charts/OverallReviewPerformance";
import OverallRating from "./charts/OverallRating";
import SentimentScore from "./charts/SentimentScore";
import PrivateFeedback from "./charts/PrivateFeedback";
import PublishedReviews from "./charts/PublishedReviews";
import PendingReviews from "./charts/PendingReviews";
import ResponseRate from "./charts/ResponseRate";
import ReviewDistributionBySource from "./charts/ReviewDistributionBySource";
import ReviewDistributionByRating from "./charts/ReviewDistributionByRating";
import ReviewDistributionByTopic from "./charts/ReviewDistributionByTopic";
import TrafficToReviewPages from "./charts/TrafficToReviewPages";
import PageViews from "./charts/PageViews";
import SentimentScoreTrend from "./charts/SentimentScoreTrend";
import InvitesVSReviews from "./charts/InvitesVSReviews";
import AverageRatingByLocation from "./charts/AverageRatingByLocation";
import ResponseRateByPlatform from "./charts/ResponseRateByPlatform";
import * as sessionHelper from "src/shared/SessionHelper";
import "./ReviewResults.scss";
import { useSelector } from "react-redux";

//variables
const LAZY_LOAD_MIN_HEIGHT = 200;

export const ReviewResults = () => {
  // variables
  const showCompanyId = useGetCompanyIdFromUrl();
  const filterKey = `resultsfilter-${showCompanyId}`;

  // state
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [spotlightReviewsUrl, setSpotlightReviewsUrl] = useState("");
  const [googleReviewsUrl, setGoogleReviewsUrl] = useState("");
  const [hasGoogleReviews, setHasGoogleReviews] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = usePersistentState(filterKey, {});
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const history = useHistory();

  // call apis
  const [
    getMiniWidgets,
    { loading: loadingMiniWidgets, data: dataMiniWidgets },
  ] = useLazyQuery(QUERY_MINI_WIDGETS, {
    variables: {
      companyId: showCompanyId,
      dateFrom: filter.value || "",
      dateBucket: filter.bucket || "",
    },
    fetchPolicy: REVIEW_RESULTS_FETCH_POLICY,
    onCompleted(data) {
      if (data && data.responseRate) {
        const { responseRate } = data;
        setToDate(responseRate.currentRange.to);
        setFromDate(responseRate.currentRange.from);
      }
    },
  });

  const { loading: loadingMeta, error: errorMeta } = useQuery(
    QUERY_REVIEWS_META,
    {
      variables: {
        companyId: parseInt(showCompanyId),
      },
      fetchPolicy: "cache-first",
      onCompleted(data) {
        if (data && data.filters) {
          const reviewFilters = data.filters.dateRange.map((filter, id) => ({
            ...filter,
            label: processFilterLabel(filter),
            id,
          }));

          setFilters(reviewFilters);
          // set default filter
          if (Object.keys(filter).length === 0) {
            setFilter(reviewFilters[1]);
          }
          getMiniWidgets();
        }
        if (data && data.company) {
          if (data.company.abbreviatedName) {
            setSpotlightReviewsUrl(
              UrlUtil.joinUrl(
                REVIEWS_BASEURL,
                showCompanyId,
                data.company.abbreviatedName,
              ),
            );
          }
          if (data.company.companyName) {
            setGoogleReviewsUrl(
              GOOGLE_REVIEWS_BASEURL +
                encodeURIComponent(
                  `${data.company.companyName} ${data.company.city} ${data.company.state} reviews`,
                ),
            );
          }
        }
        if (featureFlags.smartInviteWrite) {
          setHasGoogleReviews(featureFlags.smartInviteWrite);
        }
      },
      onError(e) {
        console.error(e);
      },
    },
  );

  // methods
  window.addEventListener("beforeunload", function () {
    sessionStorage.removeItem(filterKey);
  });
  // TODO: tech debt; needs to be handled by API
  const processFilterLabel = (filter) => {
    if (!/\(.*\)/.test(filter.label)) {
      return `${filter.label} (${StringUtil.capitalize(filter.bucket)}s)`;
    }
    return filter.label;
  };

  // checks
  if (!showCompanyId) {
    sessionHelper.removeCookies();
    sessionStorage.clear();
    window.location = MEMBERS_URLS.login;
    return null;
  }

  if (loadingMeta || !filters || filters.length === 0) {
    return (
      <div className="mt-4 text-center d-flex align-items-center justify-content-center">
        {errorMeta ? (
          <div>
            <div className="sg-text-danger">
              An error occurred while loading filters. Please check back in
              sometime.
            </div>
            <div>
              <a
                href=" "
                onClick={() => {
                  history.push(`/companies/${showCompanyId}/dashboard`);
                }}
              >
                Click here
              </a>{" "}
              to go back to dashboard
            </div>
          </div>
        ) : (
          <React.Fragment>
            <Spinner size="lg" animation="grow" className="mr-2" />
            Populating data...
          </React.Fragment>
        )}
      </div>
    );
  }

  // feature flag check
  if (!featureFlags.reviewRead) {
    return null;
  }

  // render
  return (
    <div className="review-results-container">
      {/* header */}
      <Row className="review-results-block">
        <Col lg={4} className="my-0 h2 text-dark font-weight-bold">
          Overview
        </Col>
        <Col lg={8} className="text-right">
          <ReviewLink
            isVisible={!!spotlightReviewsUrl}
            href={spotlightReviewsUrl}
            text="View CustomerLobby Reviews"
          />
          <ReviewLink
            isVisible={!!googleReviewsUrl}
            href={googleReviewsUrl}
            text="View Google Reviews"
          />
          <DateFilter filters={filters} filter={filter} setFilter={setFilter} />
        </Col>
      </Row>

      {/* overall performance */}
      <Row className="review-results-block">
        <Col xs={12}>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <OverallReviewPerformance
              companyId={showCompanyId}
              filter={filter}
            />
          </LazyLoad>
        </Col>
      </Row>

      {/* mini widgets */}
      <Row className="review-results-block">
        {/* overall rating */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <OverallRating
              loading={loadingMiniWidgets}
              companyId={showCompanyId}
              data={dataMiniWidgets ? dataMiniWidgets.overallRating : null}
              filter={filter}
            />
          </LazyLoad>
        </MiniGridWidget>

        {/* sentiment score */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <SentimentScore
              fromDate={fromDate}
              toDate={toDate}
              filter={filter}
              loading={loadingMiniWidgets}
              companyId={showCompanyId}
              data={dataMiniWidgets ? dataMiniWidgets.sentimentScore : null}
            />
          </LazyLoad>
        </MiniGridWidget>

        {/* private feedback */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <PrivateFeedback
              fromDate={fromDate}
              toDate={toDate}
              filter={filter}
              loading={loadingMiniWidgets}
              companyId={showCompanyId}
              data={dataMiniWidgets ? dataMiniWidgets.privateFeedback : null}
            />
          </LazyLoad>
        </MiniGridWidget>

        {/* published reviews */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <PublishedReviews
              fromDate={fromDate}
              toDate={toDate}
              filter={filter}
              loading={loadingMiniWidgets}
              companyId={showCompanyId}
              data={dataMiniWidgets ? dataMiniWidgets.publishedReviews : null}
            />
          </LazyLoad>
        </MiniGridWidget>

        {/* pending reviews */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <PendingReviews
              fromDate={fromDate}
              toDate={toDate}
              filter={filter}
              loading={loadingMiniWidgets}
              companyId={showCompanyId}
              data={dataMiniWidgets ? dataMiniWidgets.pendingReviews : null}
            />
          </LazyLoad>
        </MiniGridWidget>

        {/* response rate */}
        <MiniGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <ResponseRate
              companyId={showCompanyId}
              filter={filter}
              loading={loadingMiniWidgets}
              data={dataMiniWidgets ? dataMiniWidgets.responseRate : null}
            />
          </LazyLoad>
        </MiniGridWidget>
      </Row>

      <Row className="review-results-block pt-0">
        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <ReviewDistributionBySource
              companyId={showCompanyId}
              filter={filter}
              toDate={toDate}
              fromDate={fromDate}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <ReviewDistributionByRating
              companyId={showCompanyId}
              filter={filter}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <ReviewDistributionByTopic
              companyId={showCompanyId}
              filter={filter}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <TrafficToReviewPages
              companyId={showCompanyId}
              filter={filter}
              toDate={toDate}
              fromDate={fromDate}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <PageViews companyId={showCompanyId} filter={filter} />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <SentimentScoreTrend companyId={showCompanyId} filter={filter} />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <InvitesVSReviews
              companyId={showCompanyId}
              filter={filter}
              fromDate={fromDate}
              toDate={toDate}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <AverageRatingByLocation
              companyId={showCompanyId}
              filter={filter}
              toDate={toDate}
              fromDate={fromDate}
            />
          </LazyLoad>
        </MediumGridWidget>

        <MediumGridWidget>
          <LazyLoad height={LAZY_LOAD_MIN_HEIGHT}>
            <ResponseRateByPlatform
              hasGoogleReviews={hasGoogleReviews}
              companyId={showCompanyId}
              filter={filter}
            />
          </LazyLoad>
        </MediumGridWidget>
      </Row>
    </div>
  );
};

export default ReviewResults;
