import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "src/shared/styleguide/form/Form";
import Card from "src/shared/styleguide/card/Card";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import Button from "src/shared/styleguide/button/Button";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { CREATE_LOCATION_MUTATION } from "src/graphql/enterprise/mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_STATES,
} from "src/components/company/settings/graphql_queries/queries";
import { PHONE_NUMBER_WITH_LETTER_EXPRESSION } from "src/shared/Constants";
import { numberToPhoneNumberConversion } from "src/shared/Utils";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const initialValues = {
  companyName: "",
  companyDescription: "",
  categoryIds: "",
  isActive: true,
  website: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "US",
  companyUser: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    isManager: true,
    isActive: true,
  },
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required field"),
  website: Yup.string().url().required("Website is required field"),
  phone: Yup.string()
    .required("Phone is required field")
    .matches(PHONE_NUMBER_WITH_LETTER_EXPRESSION, "Invalid phone"),
  address1: Yup.string().required("Street is required field"),
  city: Yup.string().required("City is required field"),
  zip: Yup.string().required("ZIP is required field").max(7),
  companyUser: Yup.object().shape({
    firstName: Yup.string().required("First Name is required field"),
    lastName: Yup.string().required("Last Name is required field"),
    email: Yup.string().email().required("Please enter an email address."),
    password: Yup.string()
      .min(16, "Password must be at least 16 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character",
      )
      .required("Password is required field"),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "The password and confirmation do not match",
      )
      .required("Please re-enter password."),
  }),
});

const AddLocation = ({ history }) => {
  useDocumentTitle("Add Location");
  const [createNewLocation] = useMutation(CREATE_LOCATION_MUTATION);

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await createNewLocation({
        variables: values,
      });
      setSubmitting(false);
      history.push("locations");
    },
  });

  const [getStates, { data: statesList }] = useLazyQuery(GET_STATES, {
    onCompleted: ({ listStates }) => {
      // set the default value for state
      setFieldValue("state", listStates[0]?.name);
    },
  });

  const { data } = useQuery(GET_CATEGORIES, {
    onCompleted: ({ categories }) => {
      // set the default value for categoryIds field
      setFieldValue("categoryIds", parseInt(categories[0]?.id));
    },
  });
  const { data: countryList } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    getStates({
      variables: {
        country: values.country,
      },
    });
  }, [values.country]);

  return (
    <>
      <div className="page-title">Add Location</div>
      <Card className="mt-4 mb-4">
        <Row>
          <Col>
            <h1 className="form-heading">General</h1>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Form.Group controlId="companyName">
              <div className="d-flex">
                <Form.Label className="text-dark" required>
                  Name
                </Form.Label>
                <PopupInfo
                  message="Name"
                  icon={
                    <span>
                      <PopupIcon
                        iconSize={16}
                        props={{
                          className: "mb-1 ml-2",
                        }}
                      />
                    </span>
                  }
                />
              </div>
              <Form.Control
                placeholder="Name"
                onChange={handleChange}
                name="companyName"
                value={values.companyName}
              />
              {touched.companyName && errors.companyName ? (
                <div className="sg-text-danger my-2">{errors.companyName}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label className="text-dark">
                Description
                <PopupInfo
                  message="Description"
                  icon={
                    <span>
                      <PopupIcon
                        iconSize={16}
                        props={{
                          className: "mb-1 ml-2",
                        }}
                      />
                    </span>
                  }
                />
              </Form.Label>
              <Form.Control
                placeholder="Description"
                onChange={handleChange}
                name="companyDescription"
                value={values.companyDescription}
              />
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label className="text-dark">Category</Form.Label>
              <Form.Select
                onChange={(e) =>
                  setFieldValue("categoryIds", parseInt(e.value))
                }
                value={values.categoryIds}
                options={data?.categories?.map((categoryValue) => ({
                  value: categoryValue.id,
                  label: categoryValue.name,
                }))}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <Form.Group controlId="isActive">
              <Form.Label className="text-dark">Activate Location?</Form.Label>
              <Form.Select
                onChange={(e) => setFieldValue("isActive", e.value)}
                value={values.isActive}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
            </Form.Group>

            <h1 className="form-heading my-4">Contact</h1>

            <Form.Group controlId="website">
              <Form.Label className="text-dark" required>
                Website
              </Form.Label>
              <Form.Control
                placeholder="Website"
                onChange={handleChange}
                name="website"
                value={values.website}
              />
              {touched.website && errors.website ? (
                <div className="sg-text-danger my-2">{errors.website}</div>
              ) : null}
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label className="text-dark" required>
                Phone
              </Form.Label>
              <Form.Control
                placeholder="Phone"
                onChange={handleChange}
                name="phone"
                value={numberToPhoneNumberConversion(values.phone)}
              />
              {touched.phone && errors.phone ? (
                <div className="sg-text-danger my-2">{errors.phone}</div>
              ) : null}
            </Form.Group>

            <h1 className="form-heading my-4">Address</h1>

            <Form.Group controlId="street">
              <Form.Label className="text-dark" required>
                Street
              </Form.Label>
              <Form.Control
                placeholder="Street"
                onChange={handleChange}
                name="address1"
                value={values.address1}
              />
              {touched.address1 && errors.address1 ? (
                <div className="sg-text-danger my-2">{errors.address1}</div>
              ) : null}
            </Form.Group>

            <Form.Row>
              <Col xs={6}>
                <Form.Group controlId="suite">
                  <Form.Label className="text-dark">Suite</Form.Label>
                  <Form.Control
                    placeholder="Suite"
                    onChange={handleChange}
                    name="address2"
                    value={values.address2}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="city">
                  <Form.Label className="text-dark" required>
                    City
                  </Form.Label>
                  <Form.Control
                    placeholder="City"
                    onChange={handleChange}
                    name="city"
                    value={values.city}
                  />
                  {touched.city && errors.city ? (
                    <div className="sg-text-danger my-2">{errors.city}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="state">
                  <Form.Label className="text-dark" required>
                    State
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setFieldValue("state", e.value)}
                    value={values.state}
                    options={statesList?.listStates?.map((state) => ({
                      value: state.name,
                      label: state.name,
                    }))}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="zip">
                  <Form.Label className="text-dark" required>
                    ZIP
                  </Form.Label>
                  <Form.Control
                    placeholder="ZIP"
                    onChange={handleChange}
                    name="zip"
                    value={values.zip}
                  />
                  {touched.zip && errors.zip ? (
                    <div className="sg-text-danger my-2">{errors.zip}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="country">
                  <Form.Label className="text-dark">Country</Form.Label>
                  <Form.Select
                    onChange={(e) => setFieldValue("country", e.value)}
                    value={values.country}
                    options={countryList?.listCountries?.countries.map(
                      (countryVal) => ({
                        value: countryVal,
                        label: countryVal,
                      }),
                    )}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
            </Form.Row>

            <h1 className="form-heading my-4">User</h1>

            <Form.Row>
              <Col xs={6}>
                <Form.Group controlId="firstName">
                  <Form.Label className="text-dark" required>
                    First Name
                  </Form.Label>
                  <Form.Control
                    placeholder="First Name"
                    onChange={handleChange}
                    name="companyUser.firstName"
                    value={values?.companyUser?.firstName}
                  />
                  {touched?.companyUser?.firstName &&
                  errors?.companyUser?.firstName ? (
                    <div className="sg-text-danger my-2">
                      {errors.companyUser.firstName}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="lastName">
                  <Form.Label className="text-dark" required>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Last Name"
                    onChange={handleChange}
                    name="companyUser.lastName"
                    value={values.companyUser.lastName}
                  />
                  {touched?.companyUser?.lastName &&
                  errors?.companyUser?.lastName ? (
                    <div className="sg-text-danger my-2">
                      {errors.companyUser.lastName}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="email">
                  <Form.Label className="text-dark" required>
                    Email
                  </Form.Label>
                  <Form.Control
                    placeholder="Email"
                    onChange={handleChange}
                    name="companyUser.email"
                    value={values.companyUser.email}
                  />
                  {touched?.companyUser?.email && errors?.companyUser?.email ? (
                    <div className="sg-text-danger my-2">
                      {errors.companyUser.email}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="password">
                  <Form.Label className="text-dark" required>
                    Password
                  </Form.Label>
                  <Form.Control
                    placeholder="Password"
                    onChange={handleChange}
                    name="companyUser.password"
                    type="password"
                    value={values.companyUser.password}
                  />
                  {touched?.companyUser?.password &&
                  errors?.companyUser?.password ? (
                    <div className="sg-text-danger my-2">
                      {errors.companyUser.password}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="confirm">
                  <Form.Label className="text-dark" required>
                    Confirmation
                  </Form.Label>
                  <Form.Control
                    placeholder="Confirmation"
                    onChange={handleChange}
                    type="password"
                    name="companyUser.passwordConfirmation"
                    value={values.companyUser.passwordConfirmation}
                  />
                  {touched?.companyUser?.passwordConfirmation &&
                  errors?.companyUser?.passwordConfirmation ? (
                    <div className="sg-text-danger my-2">
                      {errors.companyUser.passwordConfirmation}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="user_country">
                  <Form.Label className="text-dark">Account Manager</Form.Label>
                  <Form.Select
                    value={values.companyUser.isManager}
                    onChange={(e) =>
                      setFieldValue("companyUser.isManager", e.value)
                    }
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="user_active">
                  <Form.Label className="text-dark">User Active</Form.Label>
                  <Form.Select
                    value={values.companyUser.isActive}
                    onChange={(e) =>
                      setFieldValue("companyUser.isActive", e.value)
                    }
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-between border-top">
            <Button variant="" className="mt-3">Cancel</Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="mt-3 px-5"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AddLocation;
