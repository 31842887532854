import React from "react";
import { useParams } from "react-router-dom";
import Card from "src/shared/styleguide/card/Card";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import { Row, Col } from "reactstrap";
import CustomersComponent from "../CustomersComponent";
import { useState, useEffect } from "react";
import { NO_CACHE } from "src/shared/Constants";
import {
  CONTACTS_WITH_METADATA,
  FILTER_CAMPAIGN_USAGE,
} from "src/graphql/customers_segments/customers/queries.js";
import debounce from "lodash.debounce";
import BlockUi from "react-block-ui";
import { useApolloClient } from "@apollo/react-hooks";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { numberFormatter } from "src/shared/Utils";

const SegmentsOverview = ({ data }) => {
  const client = useApolloClient();
  const showCompanyId = useGetCompanyIdFromUrl();
  const { filterId } = useParams();

  // state
  const [customersList, setCustomersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCustomersFound, setTotalCustomersFound] = useState(0);
  const [campaignStats, setCampaignStats] = useState({});

  // methods
  /**
   *
   * @param {*} mainContent -- Main content to be displayed on card
   * @param {*} bottomDetails -- Bottom details to be displayed below main content
   * @returns
   */
  const cardContent = (mainContent, bottomDetails) => {
    return (
      <>
        <Col className="card-container" md={4} sm={4}>
          <div className="main-content">{mainContent}</div>
          <div className="bottom-details">{bottomDetails}</div>
        </Col>
      </>
    );
  };

  /**
   * Fetch data from FILTER_CAMPAIGN_USAGE API with different filterId
   */
  const getCampaigns = (props = {}) => {
    client
      .query({
        query: FILTER_CAMPAIGN_USAGE,
        fetchPolicy: NO_CACHE,
        variables: { filterId: data.id ? data.id : parseInt(filterId) },
      })
      .then((res) => {
        if (res && res.data.filterCampaignUsage) {
          setCampaignStats(res.data.filterCampaignUsage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Fetch customers data from API with different query variables
   */
  const getCustomers = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      companyId: showCompanyId,
      metaData: data.metaData ? data.metaData : "",
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      rpp: 10,
      sortBy: props.sortBy ? props.sortBy : "created_at",
      sortOrder: props.sortOrder ? props.sortOrder : "desc",
      searchField: "name",
    };
    if (props.searchTerm) {
      queryVariables["searchTerm"] =
        props.searchTerm && props.searchTerm.length >= 3
          ? props.searchTerm.toLowerCase()
          : "";
    }

    client
      .query({
        query: CONTACTS_WITH_METADATA,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data) {
          setTotalCustomersFound(res.data.contactsWithMeta.total);
          setCustomersList(res.data.contactsWithMeta.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  useEffect(() => {
    getCampaigns();
    if (data.metaData) {
      getCustomers();
    }
  }, [data]);

  return (
    <div className="segment-overview">
      <div className="overview-title">Segment Overview</div>
      <Row>
        <Col md={4}>
          <Card
            className="overview-card"
            title={
              <React.Fragment>
                <span className="title">Segment Coverage</span>
                <PopupInfo message="This show the number and how much percentage of total customers the segment has." />
              </React.Fragment>
            }
          >
            <Row className="card-content">
              <Col md={12}>
                <div className="main-content">
                  {data.customersCount
                    ? numberFormatter(data.customersCount, 0)
                    : "0"}
                </div>
                <div className="bottom-details">
                  {`${
                    data.customerShare ? data.customerShare : 0
                  }% of total customers in this segment`}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            className="overview-card"
            title={
              <React.Fragment>
                <span className="title">Repeat Customers</span>
                <PopupInfo message="This shows the total number and percentage of repeat customers." />
              </React.Fragment>
            }
          >
            <Row className="card-content">
              <Col className="card-container" md={6} sm={6}>
                <div className="main-content">
                  {data.repeatCustomerRate && data.customersCount
                    ? numberFormatter(
                        (data.repeatCustomerRate * data.customersCount) / 100,
                        0,
                      )
                    : 0}
                </div>
                <div className="bottom-details">Customers</div>
              </Col>
              <Col className="card-container" md={6} sm={6}>
                <div className="main-content">
                  {data.repeatCustomerRate ? data.repeatCustomerRate : 0}%
                </div>
                <div className="bottom-details">Repeat Rate</div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            className="overview-card"
            title={
              <React.Fragment>
                <span className="title">Number of Campaigns</span>
                <PopupInfo message="This shows how many campaigns and communication channel was this segment used in." />
              </React.Fragment>
            }
          >
            <Row className="card-content">
              {cardContent(
                campaignStats.postcard
                  ? numberFormatter(campaignStats.postcard, 0)
                  : 0,
                "Postcard",
              )}
              {cardContent(
                campaignStats.text_message
                  ? numberFormatter(campaignStats.text_message, 0)
                  : 0,
                "Text",
              )}
              {cardContent(
                campaignStats.email
                  ? numberFormatter(campaignStats.email, 0)
                  : 0,
                "Email",
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="p-0" md={12}>
          <BlockUi loader={<React.Fragment />} blocking={loading}>
            <CustomersComponent
              totalCustomersFound={totalCustomersFound}
              customersList={customersList}
              getCustomers={getCustomers}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </BlockUi>
        </Col>
      </Row>
    </div>
  );
};

export default SegmentsOverview;
