import React, { useEffect, useRef, useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import searchIcon from "src/assets/images/common/searchIcon.svg";
import closeButton from "src/assets/images/enterprise/cross_input.svg";
import "./SearchComponent.scss";

const SearchComponent = ({ handleOnChange }) => {
  const inputRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleActivate = () => {
    setIsActive(true);
  };

  const handleDeactivate = () => {
    setSearchText("");
    setIsActive(false);
    handleOnChange("");
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    handleOnChange(e.target.value);
  };

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
    }
  }, [isActive]);

  return (
    <div className="search-container ml-2 mr-2">
      {!isActive ? (
        <div className="search-toggle" onClick={handleActivate}>
          <img width="15" src={searchIcon} className="mr-1" />
          <span>SEARCH</span>
        </div>
      ) : (
        <InputGroup>
          <FormControl
            value={searchText}
            ref={inputRef}
            onChange={handleInputChange}
            placeholder="Search"
          />
          <Button
            variant="outline-light"
            className="search-close-icon"
            onClick={handleDeactivate}
          >
            <img src={closeButton} />
          </Button>
        </InputGroup>
      )}
    </div>
  );
};

export default SearchComponent;
