import React, { useState } from "react";
import Modal from "src/shared/styleguide/modal/Modal";
import Button from "src/shared/styleguide/button/Button";
import PropTypes from "prop-types";
import pulseMIcon from "src/assets/images/settings/apps/pulsem.svg";
import customerLobbyIcon from "src/assets/images/settings/apps/customerlobby.svg";
import pulseMAccount from "src/assets/images/settings/apps/pulsem-account.svg";
import feedback from "src/assets/images/settings/apps/feedback.svg";
import downArrow from "src/assets/images/common/down_arrow.svg";
import upArrow from "src/assets/images/common/up_arrow.svg";
import GrayAppImg from "src/img/gray_app.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from "src/shared/styleguide/form/Form";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import { APP_INTEGRATIONS } from "src/shared/Constants";

const PulseMConnectionModal = ({
  setShowConnectionModal,
  showConnectionModal,
  updateConnectionStatus,
  company,
  pulseMAppIntegration,
}) => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [reviewDetailsExpanded, setReviewDetailsExpanded] = useState(false);

  // methods
  // Formik
  const formik = useFormik({
    initialValues: {
      locationId:
        pulseMAppIntegration && pulseMAppIntegration.metaData
          ? pulseMAppIntegration.metaData.location_id
          : "",
      apiKey:
        pulseMAppIntegration && pulseMAppIntegration.metaData
          ? pulseMAppIntegration.metaData.api_key
          : "",
    },
    validationSchema: Yup.object().shape({
      locationId: Yup.string().required("Please enter pulseM Location ID"),
      apiKey: Yup.string().required("Please enter pulseM API Key"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  /**
   * Action handler for connection to the pulseM.
   * @param {JSON} Input parameter from connection Form.
   */
  const saveChanges = ({ locationId, apiKey }) => {
    const params = {
      companyId: showCompanyId,
      shortName: APP_INTEGRATIONS.pulseM,
      status: "enabled",
      metaData: {
        locationId: locationId,
        apiKey: apiKey,
      },
    };
    updateConnectionStatus(params, true);
  };

  // render
  return (
    <Modal
      modalSize="lg"
      onHide={() => {
        setShowConnectionModal(false);
      }}
      header={
        <div className="w-100 d-flex align-items-center">
          <div className="flex-grow">Connect with Customer Lobby</div>
        </div>
      }
      body={
        <div className="connection-modal-contents">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-container">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={customerLobbyIcon}
                    alt="Customer Lobby"
                    className="mr-2 customerlobby-img"
                  />
                  <img src={pulseMIcon} alt="Pulse M" className="pulsem-img" />
                  <p className="my-3 connect-to-pulsem-text">
                    Connect to pulseM
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 px-0">
                  {/* Company Name section */}
                  <div className="company-details">
                    <span className="company-name-text pl-2">
                      {company ? company.companyName : ""}{" "}
                    </span>
                  </div>
                </div>
              </div>

              {/* Getting started section*/}
              <div className="row mb-4">
                <div className="col-12 getting-started-section">
                  <p className="getting-started-text mt-3">Getting Started</p>
                  <div className="d-flex align-items-center">
                    <img src={GrayAppImg} alt="" className="mr-3 img-style" />
                    <div className="description">
                      {" "}
                      To connect to pulseM you will need a pulseM Location ID
                      and API Key. Contact pulseM to obtain these details.{" "}
                      <a
                        href="https://pulsem.me/contact-us/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Contact PulseM
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <img
                      src={pulseMAccount}
                      alt=""
                      className="mr-3 img-style"
                    />
                    <div className="description">
                      {" "}
                      Your pulseM account will need to have at least two
                      approved reviews before creating postcard campaigns.{" "}
                    </div>
                  </div>
                </div>
              </div>

              {/* Review Details section */}
              <div className="row mb-4">
                <div className="col-12 review-details-section px-0">
                  <div className="label-style mb-1">
                    This gives Customer Lobby access to pulseM{" "}
                  </div>
                  <div className="d-flex company-details">
                    <img
                      src={feedback}
                      alt="Feedback"
                      className="mr-3 feedback-icon"
                    />
                    <div className="company-name-text flex-grow-1">
                      Review Details
                      {reviewDetailsExpanded && (
                        <ul className="pl-0 mt-3">
                          <li>Customer name</li>
                          <li>Review star rating</li>
                          <li>Review summary</li>
                          <li>Review date</li>
                        </ul>
                      )}
                    </div>
                    {!reviewDetailsExpanded ? (
                      <img
                        src={downArrow}
                        alt="Down Arrow"
                        className="arrow mt-2"
                        onClick={() => setReviewDetailsExpanded(true)}
                      />
                    ) : (
                      <img
                        src={upArrow}
                        alt="Up Arrow"
                        className="arrow mt-2"
                        onClick={() => setReviewDetailsExpanded(false)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  {/* form for connection*/}
                  <div id="connection-form">
                    <div className="row">
                      <div className="col-12 px-0">
                        <Form.Group controlId="locationId">
                          <Form.Label className="label-style mb-0">
                            Location ID <small className="text-danger">*</small>
                          </Form.Label>
                          <Form.Control
                            name="locationId"
                            value={formik.values.locationId}
                            isInvalid={formik.errors.locationId}
                            placeholder="Enter the pulseM Location ID"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "locationId",
                                e.target.value,
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.touched.locationId &&
                            formik.errors.locationId
                              ? formik.errors.locationId
                              : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-12 px-0">
                        <Form.Group controlId="apiKey">
                          <Form.Label className="label-style mb-0">
                            API Key <small className="text-danger">*</small>
                          </Form.Label>
                          <Form.Control
                            name="apiKey"
                            value={formik.values.apiKey}
                            isInvalid={formik.errors.apiKey}
                            placeholder="Enter the pulseM API Key"
                            onChange={(e) => {
                              formik.setFieldValue("apiKey", e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.touched.apiKey && formik.errors.apiKey
                              ? formik.errors.apiKey
                              : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mt-3">
              <div className="col-12 text-right">
                <Button
                  variant="outline-secondary"
                  className="mr-3"
                  onClick={() => setShowConnectionModal(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Connect
                </Button>
              </div>
            </div>
          </form>
        </div>
      }
      show={showConnectionModal}
    />
  );
};

PulseMConnectionModal.propTypes = {
  setShowConnectionModal: PropTypes.func.isRequired,
  showConnectionModal: PropTypes.bool.isRequired,
};

export default PulseMConnectionModal;
