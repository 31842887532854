import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_DIRECT_CONNECT_SETTINGS } from "src/graphql/direct_connect/mutations";
import { NO_CACHE } from "src/shared/Constants";
import { useSelector } from "react-redux";

const ServiceFusionCallback = () => {
  const { userInfo } = useSelector((state) => state.user);
  const companyId = userInfo?.companyId;

  const [updateDirectConnectSettings] = useMutation(
    UPDATE_DIRECT_CONNECT_SETTINGS,
  );

  const handleUpdate = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const redirectUri = window.location.origin + window.location.pathname;

    if (!code) {
      window.location.replace(
        `${document.location.origin}/companies/${companyId}/direct-connect-settings`,
      );
    }

    const res = await updateDirectConnectSettings({
      variables: {
        companyId,
        workflowSystem: "ServiceFusion",
        code,
        redirectUri,
      },
      fetchPolicy: NO_CACHE,
    });

    if (
      res &&
      !res.loading &&
      res.data &&
      res.data.updateDirectConnectSetting
    ) {
      window.location.replace(
        `${document.location.origin}/companies/${companyId}/direct-connect-settings?connected=true`,
      );
    }
  };

  useEffect(() => {
    handleUpdate();
  }, []);

  return null;
};

export default ServiceFusionCallback;
