import React, { Suspense } from "react";
import GetReviewsInviteForm from "./GetReviewsInviteForm";
import Button from "src/shared/styleguide/button/Button";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import {
  NoResultsFound,
  NoCustomersAdded,
  PhoneActionButton,
  EmailActionButton,
  PhoneActionButtonWithWait,
} from "./GetReviewsWidgets";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import ModalPopupRight from "src/shared/modal/ModalPopupRight";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/react-hooks";
import { NETWORK_ONLY } from "src/shared/Constants";
import { QUERY_INVITATIONS_SUMMARY } from "src/graphql/reviews/get_reviews/queries";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import format from "date-fns/format";
import parse from "date-fns/parse";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import UrlUtil from "src/utils/UrlUtil";
import debounce from "lodash.debounce";
import BlockUi from "react-block-ui";
import classnames from "classnames";
import UserInitials from "src/shared/styleguide/common/UserInitials";
import favoritesImg from "src/assets/images/reviews/favorites.png";
import StringUtil from "src/utils/StringUtil";
import { addToast } from "src/utils/ToastUtil";
import { useSelector } from "react-redux";

// lazy load
const UploadContact = React.lazy(() =>
  import("src/components/upload_contact/UploadContact"),
);
const OtherReviewOptions = React.lazy(() => import("./OtherReviewOptions"));

const GetReviewsContent = ({
  createCustomerCall,
  createEmailInvitation,
  filters,
  resetFilters,
  initialFilters,
}) => {
  // variables
  const showCompanyId = useGetCompanyIdFromUrl();
  const minSearchTermLength = 2;
  const perPage = 10;
  const headerItems = [
    { columnLabel: "NAME", columnValue: "name", sortable: true },
    { columnLabel: "EMAIL ADDRESS", columnValue: "email", sortable: true },
    { columnLabel: "PHONE NUMBER", columnValue: "phone", sortable: true },
    { columnLabel: "CREATED DATE", columnValue: "created_at", sortable: true },
    {
      columnLabel: "LAST SERVICE DATE",
      columnValue: "last_service_date",
      sortable: true,
    },
    {
      columnLabel: "LAST CONTACT",
      columnValue: "last_contacted_at",
      sortable: true,
    },
    {
      columnLabel: "ACTION",
      columnValue: "days_left_to_contact",
      sortable: true,
    },
  ];

  // state
  const client = useApolloClient();
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [loading, setLoading] = React.useState(false);
  const [otherReviewOptionsShown, setOtherReviewOptionsShown] =
    React.useState(false);
  const [uploadModalShown, setUploadModalShown] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("last_service_date");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [contacts, setContacts] = React.useState([]);
  const [filtersApplied, setFiltersApplied] = React.useState(false);

  // methods
  const handleSuccess = () => {
    addToast({
      message: "Customer Invited Successfully",
      type: "success",
    });
  };

  const handleErrorMessage = (message) => {
    addToast({ message: message, type: "error" });
  };

  const sendEmailInvitation = async (contact, props = {}) => {
    try {
      await createEmailInvitation({
        variables: { ...contact, companyId: showCompanyId },
        fetchPolicy: "no-cache",
      });
      handleSuccess();
      getInvites(props);
    } catch (e) {
      if (e.message === "GraphQL error: Contact was invited recently") {
        handleErrorMessage(e.message.split(":")[1]);
      }
    }
  };

  const customerCallInvite = async (contact, props = {}) => {
    try {
      await createCustomerCall({
        variables: { ...contact, companyId: showCompanyId },
        fetchPolicy: "no-cache",
      });
      handleSuccess();
      getInvites(props);
    } catch (e) {
      if (e.message === "GraphQL error: Contact was invited recently")
        handleErrorMessage(e.message.split(":")[1]);
    }
  };

  const getContactRow = (contact, props = {}) => {
    const customerUrl = UrlUtil.joinUrl(
      "companies",
      showCompanyId,
      "customers",
      contact.contactId,
    );
    return [
      {
        node: (
          <div className="d-flex align-items-center">
            <UserInitials
              firstName={contact.firstName}
              lastName={contact.lastName}
            />
            <a
              href={`/companies/${showCompanyId}/customers/${contact.contactId}`}
              target="_blank"
            >
              {contact.contactDisplayName
                ? StringUtil.highlightWithinWord(
                    contact.contactDisplayName,
                    filters.searchTerm,
                  )
                : "N/A"}
            </a>
          </div>
        ),
      },
      {
        node: contact.email
          ? StringUtil.highlightWithinWord(contact.email, filters.searchTerm)
          : "N/A",
      },
      {
        node: (
          <div>
            {contact.formattedPhoneDaytime ? (
              <div>
                {StringUtil.highlightWithinWord(
                  contact.formattedPhoneDaytime,
                  filters.searchTerm,
                  true,
                )}
              </div>
            ) : null}
            {contact.formattedPhoneMobile ? (
              <div className="text-black-50">
                {StringUtil.highlightWithinWord(
                  contact.formattedPhoneMobile,
                  filters.searchTerm,
                  true,
                )}{" "}
                (mob)
              </div>
            ) : null}
            {contact.formattedPhoneAlt ? (
              <div className="text-black-50">
                {StringUtil.highlightWithinWord(
                  contact.formattedPhoneAlt,
                  filters.searchTerm,
                  true,
                )}{" "}
                (alt.)
              </div>
            ) : null}
            {!contact.formattedPhoneDaytime &&
            !contact.formattedPhoneMobile &&
            !contact.formattedPhoneAlt
              ? "N/A"
              : ""}
          </div>
        ),
      },
      {
        node: (
          <span>
            {format(new Date(contact.createdAt), "MMM dd, yyyy")}{" "}
            {contact.sourceLabel ? (
              <span className="text-black-50">({contact.sourceLabel})</span>
            ) : null}
          </span>
        ),
      },
      {
        node: contact.lastServiceDate
          ? format(
              parse(contact.lastServiceDate, "yyyy-MM-dd", new Date()),
              "MMM dd, yyyy",
            )
          : "N/A",
      },
      {
        node: !contact.lastContactedAt ? (
          "N/A"
        ) : (
          <span>
            <span className="mr-2">
              {format(new Date(contact.lastContactedAt), "MMM dd, yyyy")}
            </span>
            {contact.lastContactedMethod &&
            contact.lastContactedMethod === "call" ? (
              <PopupInfo
                message="Contacted by Call"
                icon={
                  <span>
                    <PhoneActionButton />
                  </span>
                }
              />
            ) : contact.lastContactedMethod &&
              contact.lastContactedMethod === "email" ? (
              <PopupInfo
                message="Contacted by Email"
                icon={
                  <span>
                    <EmailActionButton />
                  </span>
                }
              />
            ) : contact.lastContactedMethod &&
              contact.lastContactedMethod === "review" ? (
              <PopupInfo
                message="Review received"
                icon={
                  <span>
                    <img
                      style={{ maxHeight: 18 }}
                      src={favoritesImg}
                      alt="star"
                    />
                  </span>
                }
              />
            ) : null}
          </span>
        ),
      },
      {
        node: (
          <div className="d-flex align-items-center justify-content-end">
            {contact.daysLeftToContact && contact.daysLeftToContact > 0 ? (
              <PopupInfo
                message={`Eligible for invitation on ${format(
                  new Date(contact.eligibleToContactOn),
                  "MMM dd, yyyy",
                )}`}
                icon={
                  <span
                    style={{
                      borderRadius: 4,
                      border: "1px solid #f47821",
                      whiteSpace: "nowrap",
                    }}
                    className="sg-text-warning px-2"
                  >
                    {contact.daysLeftToContact}d left
                  </span>
                }
              />
            ) : contact.callQueuedOn ? (
              <PopupInfo
                message={`Call queued on ${format(
                  new Date(contact.callQueuedOn),
                  "MMM dd, yyyy",
                )}`}
                icon={
                  <span
                    className={classnames({
                      "btn-disabled": true,
                    })}
                  >
                    <PhoneActionButtonWithWait />
                  </span>
                }
              />
            ) : (
              <PopupInfo
                message="Send Call Invite"
                icon={
                  <span
                    onClick={() => customerCallInvite(contact, props)}
                    className={classnames({
                      "btn-disabled": !contact.callable,
                      "cursor-pointer": contact.callable,
                    })}
                  >
                    <PhoneActionButton />
                  </span>
                }
              />
            )}
            <span className="mr-3" />
            {contact.daysLeftToContact &&
            contact.daysLeftToContact > 0 ? null : (
              <React.Fragment>
                <PopupInfo
                  message="Send Email Invite"
                  icon={
                    <span
                      onClick={() => sendEmailInvitation(contact, props)}
                      className={classnames({
                        "btn-disabled": !contact.emailable,
                        "cursor-pointer": contact.emailable,
                      })}
                    >
                      <EmailActionButton />
                    </span>
                  }
                />
                <span className="mr-3" />
              </React.Fragment>
            )}
            <DropdownButton
              variant="link"
              dropdownClassName="d-inline-block"
              dropShadow={true}
              buttonClassName="p-0"
              direction={false}
              alignRight={true}
              title={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="3.348"
                  height="15.064"
                  viewBox="0 0 3.348 15.064"
                  fill="#667587"
                >
                  <g transform="translate(-149.333 15.064) rotate(-90)">
                    <g transform="translate(0 149.333)">
                      <g transform="translate(0 0)">
                        <circle cx="1.674" cy="1.674" r="1.674" />
                        <circle
                          cx="1.674"
                          cy="1.674"
                          r="1.674"
                          transform="translate(5.858)"
                        />
                        <circle
                          cx="1.674"
                          cy="1.674"
                          r="1.674"
                          transform="translate(11.717)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              }
            >
              <Dropdown.Item
                target="_blank"
                href={`/companies/${showCompanyId}/customers/${contact.contactId}`}
              >
                View Customer Details
              </Dropdown.Item>
            </DropdownButton>
          </div>
        ),
      },
    ];
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getInvites({
      page: selected,
    });
  };

  const onSort = (columnValue, sortDirection) => {
    setSortBy(columnValue);
    setSortOrder(sortDirection);
    setCurrentPage(0);
    getInvites({
      page: 0,
      sortBy: columnValue,
      sortOrder: sortDirection,
    });
  };

  const getInvites = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      companyId: showCompanyId,
      missingEmailAddress: filters.missingEmailAddress,
      missingPhoneNumber: filters.missingPhoneNumber,
      limit: perPage,
      viewBy: filters.selectedViewBy.value,
      searchTerm:
        filters.searchTerm && filters.searchTerm.length > minSearchTermLength
          ? filters.searchTerm
          : "",
      sortBy: props.sortBy ? props.sortBy : sortBy ? sortBy : undefined,
      sortOrder: props.sortOrder
        ? props.sortOrder
        : sortOrder
        ? sortOrder
        : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
    };
    client
      .query({
        query: QUERY_INVITATIONS_SUMMARY,
        fetchPolicy: NETWORK_ONLY,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.invitationsSummary) {
          const { invitationsSummary } = res.data;
          // setup contacts
          setContacts(
            invitationsSummary.contacts.map((contact) =>
              getContactRow(contact, {
                ...queryVariables,
                page: queryVariables.page - 1,
              }),
            ),
          );
          // setup page
          setPageCount(Math.ceil(invitationsSummary.total / perPage));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  // effects
  React.useEffect(() => {
    setFiltersApplied(
      (filters.searchTerm !== initialFilters.searchTerm &&
        filters.searchTerm.length > minSearchTermLength) ||
        filters.selectedViewBy.value !== initialFilters.selectedViewBy.value ||
        filters.missingPhoneNumber !== initialFilters.missingPhoneNumber ||
        filters.missingEmailAddress !== initialFilters.missingEmailAddress,
    );
  }, [filters]);

  React.useEffect(() => {
    setCurrentPage(0);
    getInvites({
      page: 0,
    });
  }, [filters.searchTerm]);

  React.useEffect(() => {
    setCurrentPage(0);
    getInvites({
      page: 0,
    });
  }, [
    filters.selectedViewBy,
    filters.missingEmailAddress,
    filters.missingPhoneNumber,
  ]);

  // render
  return (
    <div className="content-view mb-4">
      {/* modal */}

      <Suspense fallback={<React.Fragment />}>
        <UploadContact show={uploadModalShown} setShow={setUploadModalShown} />
      </Suspense>

      <Suspense fallback={<React.Fragment />}>
        <ModalPopupRight
          isModalOpen={otherReviewOptionsShown}
          modalClose={() => {
            setOtherReviewOptionsShown(false);
          }}
          modalHeader="Other Review Options"
          modalBody={<OtherReviewOptions />}
        />
      </Suspense>

      {/* header */}
      <div className="my-0 mt-4 pb-2 h2 text-dark font-weight-bold">
        Review Requests
        {featureFlags.reviewWrite ? (
          <React.Fragment>
            <Button
              onClick={() => setUploadModalShown(true)}
              variant="primary"
              className="mr-2 float-right"
            >
              Upload Customers
            </Button>
            <Button
              onClick={() => {
                setOtherReviewOptionsShown(true);
              }}
              variant="primary"
              className="mr-2 float-right"
            >
              Review Options
            </Button>
          </React.Fragment>
        ) : null}
      </div>

      {/* form */}
      {featureFlags.reviewWrite ? (
        <GetReviewsInviteForm
          createCustomerCall={createCustomerCall}
          createEmailInvitation={createEmailInvitation}
        />
      ) : null}

      <div className="mb-2 d-flex align-items-center">
        Showing results for - {filters.selectedViewBy.labelString}
        {filters.searchTerm && filters.searchTerm.length > minSearchTermLength
          ? " having " + filters.searchTerm
          : ""}
        {filtersApplied ? (
          <Button
            variant="link"
            className="m-0 p-0 ml-2 font-weight-bold sg-text-info"
            onClick={() => resetFilters()}
            style={{
              fontWeight: "0.85rem",
            }}
          >
            Reset Filter
          </Button>
        ) : null}
      </div>

      {/* table */}
      {featureFlags.reviewRead ? (
        contacts.length === 0 && filtersApplied ? (
          <NoResultsFound />
        ) : contacts.length === 0 ? (
          <NoCustomersAdded />
        ) : (
          <BlockUi loader={<React.Fragment />} blocking={loading}>
            <div className="get-reviews-table-container mt-2 mb-0">
              <Table
                className="get-reviews-table mb-0"
                head={[
                  <SortableTableHeader
                    sortable={headerItems[0].sortable}
                    columnLabel={headerItems[0].columnLabel}
                    columnValue={headerItems[0].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />,
                  <SortableTableHeader
                    sortable={headerItems[1].sortable}
                    columnLabel={headerItems[1].columnLabel}
                    columnValue={headerItems[1].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />,
                  <SortableTableHeader
                    sortable={headerItems[2].sortable}
                    columnLabel={headerItems[2].columnLabel}
                    columnValue={headerItems[2].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />,
                  <div style={{ minWidth: 132 }}>
                    <SortableTableHeader
                      sortable={headerItems[3].sortable}
                      columnLabel={headerItems[3].columnLabel}
                      columnValue={headerItems[3].columnValue}
                      onSort={onSort}
                      currentSortValue={sortBy}
                      currentSortOrder={sortOrder}
                    />
                    <span className="ml-2">
                      <PopupInfo
                        placement="bottom"
                        message="This shows the date and the method used to pull in the customer data."
                      />
                    </span>
                  </div>,
                  <SortableTableHeader
                    sortable={headerItems[4].sortable}
                    columnLabel={headerItems[4].columnLabel}
                    columnValue={headerItems[4].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />,
                  <div style={{ minWidth: 140 }}>
                    <SortableTableHeader
                      sortable={headerItems[5].sortable}
                      columnLabel={headerItems[5].columnLabel}
                      columnValue={headerItems[5].columnValue}
                      onSort={onSort}
                      currentSortValue={sortBy}
                      currentSortOrder={sortOrder}
                    />
                    <span className="ml-2">
                      <PopupInfo
                        placement="bottom"
                        message="Last Contact date is based on Email Invitation, Call Invite or Review Received. And eligibility is calculated on Last Contact Date."
                      />
                    </span>
                  </div>,
                  <SortableTableHeader
                    sortable={headerItems[6].sortable}
                    columnLabel={headerItems[6].columnLabel}
                    columnValue={headerItems[6].columnValue}
                    onSort={onSort}
                    currentSortValue={sortBy}
                    currentSortOrder={sortOrder}
                  />,
                ]}
                body={contacts}
                hasPagination={true}
                pageCount={pageCount}
                onPagination={onPagination}
                currentPage={currentPage}
              />
            </div>{" "}
          </BlockUi>
        )
      ) : null}
    </div>
  );
};

GetReviewsContent.propTypes = {
  filters: PropTypes.object.isRequired,
  createCustomerCall: PropTypes.func.isRequired,
  createEmailInvitation: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
};

export default GetReviewsContent;
