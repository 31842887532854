import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { QUERY_EMAIL_SIGNATURE } from "src/graphql/settings/reviews/queries";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import SettingsCard from "../SettingsCard";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";

const EmailSignatureLink = () => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [emailSignature, setEmailSignature] = React.useState("");
  const [copied, setCopied] = React.useState(false);
  const [copyInterval, setCopyInterval] = React.useState(null);

  // call apis
  const { loading } = useQuery(QUERY_EMAIL_SIGNATURE, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    variables: {
      companyId: showCompanyId,
    },
    onCompleted(data) {
      populateData(data);
    },
  });

  // methods
  const populateData = (data) => {
    if (data && data.company) {
      const signature = generateEmailSignatureLink(data.company);
      setEmailSignature(signature);
    }
  };
  const generateEmailSignatureLink = (company) => {
    return `<a href="https://www.customerlobby.com/reviews/${company.id}/${company.abbreviatedName}"><img src="https://www.customerlobby.com/images/logos/logo4-customer-ffffff.png" border="0" /></a>`;
  };
  const setCopiedTemporarily = () => {
    setCopied(true);
    const interval = setTimeout(() => setCopied(false), 3000);
    setCopyInterval(interval);
  };

  // effects
  React.useEffect(() => {
    return () => {
      if (copyInterval) {
        clearInterval(copyInterval);
      }
    };
  });

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Email Signature Link"
      settingsDescription="Market your reputation with every email you send! Copy and paste the code below into your HTML email to include a link to your reviews in your emails."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34.91"
          height="36.65"
          viewBox="0 0 34.91 36.65"
        >
          <g transform="translate(-9.418 .4)">
            <g transform="translate(9.668)">
              <g>
                <path
                  d="M43.8 12.829l-4.421-3.693V3.9a.828.828 0 0 0-.855-.8h-6.441L29.5.943a4.109 4.109 0 0 0-5.248.008L21.669 3.1h-6.523a.828.828 0 0 0-.855.8v5.318l-4.343 3.611a.813.813 0 0 0-.28.633h.016v18.417A4.106 4.106 0 0 0 13.773 36h26.2a4.106 4.106 0 0 0 4.088-4.121V13.463h.016a.747.747 0 0 0-.277-.634zm-4.425-1.555l2.595 2.156-2.591 1.925zM25.3 2.21a2.47 2.47 0 0 1 3.15.008l1.05.882h-5.256zm-9.364 2.534h21.8V16.58l-9.369 6.975a2.505 2.505 0 0 1-2.969 0l-9.459-7.041zm-1.645 6.605v3.94l-2.509-1.867zm28.131 20.522a2.463 2.463 0 0 1-2.443 2.484H13.773a2.461 2.461 0 0 1-2.443-2.476V15.1l13.1 9.78a4.121 4.121 0 0 0 4.9 0l13.1-9.78z"
                  transform="translate(-9.668)"
                />
              </g>
            </g>
            <g transform="translate(20.954 11.324)">
              <g id="prefix__Group_2233" data-name="Group 2233">
                <path
                  d="M152.137 137.663H147.7a.823.823 0 0 0 0 1.645h4.442a.823.823 0 1 0 0-1.645z"
                  transform="translate(-146.873 -137.663)"
                />
              </g>
            </g>
            <g transform="translate(20.954 15.437)">
              <g id="prefix__Group_2235" data-name="Group 2235">
                <path
                  d="M157.9 187.663h-10.2a.823.823 0 0 0 0 1.645h10.2a.823.823 0 0 0 0-1.645z"
                  transform="translate(-146.873 -187.663)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <React.Fragment>
          <CopyToClipboard
            onCopy={setCopiedTemporarily}
            text={emailSignature || ""}
          >
            <span
              style={{
                color: "#428bca",
              }}
              className="text-right cursor-pointer"
            >
              {copied ? "Copied" : "Copy Link"}
            </span>
          </CopyToClipboard>
          <Card className="code-preview m-0 mt-1">
            <code className="code-preview-code">
              <div className="text-dark">{emailSignature}</div>
            </code>
          </Card>
        </React.Fragment>
      }
    />
  );
};

export default EmailSignatureLink;
