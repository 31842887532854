import React from "react";
import { Container } from "react-bootstrap";
import "src/components/partner/Terms.scss";

const Terms = () => {
  const host = window.location.origin;
  return (
    <Container>
      <div class="row" id="partner-terms">
        <div class="col-md-12">
          <section class="no-padding">
            <h1 className="terms-heading">Summary of Partnership Terms</h1>
            <h5>You Get:</h5>
            <ul>
              <li className="top-list">6 month free Reviews Account for your business.</li>
              <li className="top-list">
                Ongoing commissions equal to {"{monthlyFeePercentage}"} of the
                monthly fees {"{accountActivationFeePercentage placeholder}"}{" "}
                paid to Customer Lobby by the clients you sign up for a Reviews Account.
                If you elected not to receive a commission and instead elected to
                receive a discount on the monthly fee, the discounted monthly fee
                will be available to you when you convert your clients from free
                trials to paid accounts. {"{accountActivationFeePercentage <= 0}"} Potential clients who
              were contacted in the prior 60 days by a Customer Lobby sales
              person are not eligible for commission.
              </li>

              
            </ul>
            <h5>Your Clients Get:</h5>
            <ul>
              <li className="top-list">30 Day free trial (no credit card, no obligation).</li>
              <li className="top-list">2 Free customer call reviews.</li>
              <li className="top-list">$50 Discount on Account Activation Fee (typically $199).</li>
            </ul>
            <p>
              This Summary of Partnership Terms is qualified by the
              Partner/Customer Terms of Use below.
            </p>
            <h1 className="terms-heading"></h1>
          </section>
          <section>
            <h1 className="terms-heading">Partner/Customer Terms of Use</h1>
            <p>Last Updated: November 4, 2009</p>
            <p>The following are the terms and conditions for use of the</p>
            Customer Lobby, LLC ("Customer Lobby") Internet-based products and
            services ("Services") by registered customers of Customer Lobby.
            Please read these Partner/Customer Terms of Use ("Terms") carefully
            before accessing the Customer Lobby Web site or using the Services
            (the Services together with the Web site, the "Site"). By checking
            the box that you have read and agreed to these Customer Terms of Use
            ("Accepting the Terms") as required during the registration process,
            you are representing that you have read, understand and are
            consenting to be bound by these Terms in their entirety, and your
            act of Accepting the Terms will constitute your legally binding
            signature. Access to and use of the customer-only and partner-only
            features of the Site, is contingent upon your agreement to and
            continued compliance with these Terms. If you do not agree to all of
            the provisions in these Terms, do not check the box that you have
            read and agreed to these Terms, and you will not receive access to
            the customer-only features of the Site as a registered user. By
            Accepting the Terms and becoming a registered user you will be fully
            bound by these Terms.
            <p>
            After becoming a registered user and so long as your account has
            not been terminated, cancelled or deactivated, you, and any
            Additional Users to whom you give the privilege, will have the
            ability to add additional users to your account ("Additional
            Users"). By adding an Additional User, you hereby agree that such
            Additional Users shall be fully bound by these Terms.

            Your use of the Site as a user (as opposed to a Customer, as defined
            below) is governed by the public-use terms and conditions at the
            following link (the "General Terms"):
            </p>
            <a href={`${host}/index/terms-of-use`} target="_blank">
              {host}/index/terms-of-use{' '}
            </a>
            The General Terms are incorporated herein by this reference and, by
            clicking Accepting the Terms you are also representing that you have
            read, understand, and are agreeing to become bound by such General
            Terms in your use of the Site.
            <p></p>
            The Customer Lobby Site provides an online community for rating and
            reviewing businesses, services, products and locations, as well as a
            variety of other features and services to support and build that
            community. Customer Lobby reserves the right to change or modify any
            of these Terms, the General Terms, its privacy policy, the Site or
            any policy relating to the Site, at any time, by posting the new
            agreement or policy, as applicable, to the Customer Lobby website
            located at
            <a href={host} target="_blank">
              {" "}
              {host}
              {" "}
            </a>
            (or such other URL as Customer Lobby may provide). In the event of
            any change to these Terms or the General Terms, you will be notified
            by email that a change has occurred, and your continuing to use the
            features of the Customer Lobby Site (including but not limited to
            the management features, receipt of email notifications and
            maintaining a public link to a Customer Lobby review page)
            thereafter will bind you to any such changes.
            <ol>
              <li>
                <b>ACCOUNT</b>
                A person or entity can become a partner by
                completing the partner account set-up process on the Site or by
                being named as an additional user on the account of a current
                authorized partner (in either instance, a "Partner"). A person
                or entity can become a customer by completing the customer
                account set-up process on the Site or by being named as an
                additional user on the account of a current authorized customer
                (in either instance, a "Customer"). You must complete the set-up
                process by providing Customer Lobby with complete and accurate
                information as prompted by the registration form, including your
                e-mail address and password. You shall protect your password,
                and are solely responsible for any and all use that occurs under
                either of your accounts, including any usage by additional
                authorized users on your accounts or any use of your password or
                the passwords of your authorized users. You agree to notify
                Customer Lobby immediately of any unauthorized use of your
                accounts or any other breach of security.
              </li>
              <li>
                <b>CONTENT</b>
                All information, words, images, sounds, and other material on
                all pages of the Site ("Content," which shall be deemed to
                include, without limitation, all Customer Content as defined
                below), to the extent proprietary in nature, is the property of
                Customer Lobby. Customer Lobby takes no responsibility and
                assumes no liability for any message, profile, opinion,
                question, answer, posting, data, information, file, text, link,
                music, sound, image, video, photo, graphic, code, work of
                authorship or other material posted, stored, or uploaded or
                downloaded by a Customer or any third party to your review
                pages, including without limitation content provided by you,
                your customers and reviewers (including, but not limited to, any
                reviews transcribed and summarized by Customer Lobby from phone
                calls with your customers), and your Ask the Reviewer
                communications by questioners and your customers and reviewers
                (collectively, "Customer Content"), or for any loss or damage to
                or resulting from Customer Content. Customer Content is
                expressly limited to any Content derived from your account or
                posted on your account's review page, including but not limited
                to your published reviews, non-published reviews, Ask the
                Reviewer communications and suggestion box communications.
                <div class="uppercase-font">
                  as A PROVIDER OF INTERACTIVE SERVICES, CUSTOMER LOBBY IS NOT
                  LIABLE FOR ANY MISTAKES, DEFAMATION, SLANDER, LIBEL,
                  OMISSIONS, FALSEHOODS, OBSCENITY, PORNOGRAPHY OR PROFANITY IN
                  ANY CONTENT, NOR WILL CUSTOMER LOBBY BE LIABLE FOR ANY
                  STATEMENTS, REPRESENTATIONS OR ANY OTHER INFORMATION OR
                  CONTENT PROVIDED BY ITS USERS IN ANY PUBLIC FORUM, PERSONAL
                  HOME PAGE OR OTHER INTERACTIVE AREA, WHETHER OR NOT PART OF
                  THE SITE.
                </div>
                Although Customer Lobby has no obligation to screen, edit or
                monitor any of the Content posted to or distributed through the
                Site, Customer Lobby reserves the right, and has absolute
                discretion, to remove, screen or edit, with or without notice,
                any Content posted or stored on the Site, at any time and for
                any reason. You agree that an authorized user of your customer
                account will review the Customer Content relating to your
                account and will promptly notify Customer Lobby if any such
                Customer Content contains inaccurate information or otherwise
                does not meet the definition of a Valid Review. A "Valid Review"
                is a review that does not include any of the following: (a)
                Obscenities, discriminatory language, or other inappropriate
                comments (including any comments written in a language other
                than English) not suitable for this forum; (b) Advertisements,
                "spam" content, or references to other companies, reviewers,
                services, products, offers, or websites; (c) Email addresses,
                URL's, phone numbers, physical addresses, or other forms of
                contact information; (d) Reviews that: are not written by a
                customer of the company being reviewed; include pricing
                information; have been withdrawn by the reviewer; or that
                Customer Lobby believes, in its sole judgment, to be untrue; (e)
                Critical or spiteful comments about other reviews or their
                authors; (f) Computer viruses, scripts, tags, worms or other
                potentially damaging computer programs or files; or (g) Comments
                that may reasonably be considered to be defamatory, libelous,
                hateful, racially or religiously biased or offensive, unlawfully
                threatening or unlawfully harassing to any individual,
                partnership or corporation.
                <p>
                  On our Customers' behalf, Customer Lobby has secured a
                  nonexclusive, royalty-free, perpetual, transferable,
                  irrevocable and fully sublicensable right to use, reproduce,
                  modify, adapt, translate, distribute, publish, delete, edit,
                  create derivative works of and publicly display the Content
                  throughout the world in any media, now known or hereafter
                  devised. In addition, Customers have the right to display the
                  first name associated with the account of the user that
                  submitted such Content.
                </p>
                <p>
                  By posting or distributing Customer Content or other Content
                  to or through the Site, you (a) grant Customer Lobby, its
                  affiliates and the entity about which the Content is written a
                  nonexclusive, royalty-free, perpetual, transferable,
                  irrevocable and fully sublicensable right to use, reproduce,
                  modify, adapt, translate, distribute, publish, delete, edit,
                  create derivative works of and publicly display such Content
                  throughout the world in any media, now known or hereafter
                  devised; (b) grant Customer Lobby, its affiliates and
                  sublicensees the right to display the name, title and company
                  name associated with the account of the user that submitted
                  such Content if such user has agreed to display such
                  information publicly and, in instances when such user has not
                  agreed to display such information publicly, grant Customer
                  Lobby, its affiliates and sublicensees the right to display
                  the first name associated with the account of the user that
                  submitted such Content; (c) grant Customer Lobby, its
                  affiliates and sublicensees the right to reject or refuse to
                  post Content; (d) grant Customer Lobby, its affiliates and
                  sublicensees the right to contact you or any Content authors
                  via email or telephone; and (e) represent and warrant to
                  Customer Lobby that (i) you own and control all of the rights
                  to the Content that you post or otherwise distribute, or you
                  otherwise have the lawful right to post and distribute such
                  Content to or through the Site; (ii) such Content is accurate
                  and not misleading; and (iii) use and posting or other
                  transmission of such Content does not violate these Terms and
                  will not violate any rights of or cause injury to any person
                  or entity. You further grant Customer Lobby the right to
                  pursue at law any person or entity that violates your or
                  Customer Lobby's rights in the Content by a breach of these
                  Terms, which right is without the implication of any
                  obligation to do so.
                </p>
                <p>
                  Content submitted by any user will be considered public and
                  non-confidential and Customer Lobby may freely disclose or use
                  such Content without consent or notice. Customer Lobby shall
                  have no obligation to offer or provide you any payment or
                  other consideration for Content that you or any user submits.
                  Customer Lobby shall have no duty to attribute authorship of
                  Content. You specifically agree that Customer Lobby shall not
                  be responsible for unauthorized access to or alteration of any
                  Content, including without limitation the Customer Content.
                </p>
                <p>
                  Customer Lobby may provide Content to third-party web sites to
                  be published on such sites. Customer Lobby makes no
                  representations or assurances that any Content will be
                  published on any third-party web site; that any Content that
                  is published on any third-party web site will be accurately
                  reproduced; that any Content that is published on any
                  third-party web site will not be reproduced onto additional
                  web sites that have not been approved by Customer Lobby; or
                  that, if any Content is published on a third-party web site,
                  that such Content will remain on such site. Customer Lobby
                  may, at its sole discretion, seek to remove any Content from
                  any third-party web site at anytime including, but not limited
                  to, upon the termination of your account.
                </p>
                <p>
                  If it is determined that you retain moral rights (including
                  rights of attribution or integrity) in the Content, you hereby
                  declare that (a) you do not require that any personally
                  identifying information be used in connection with the
                  Content, or any derivative works of or upgrades or updates
                  thereto; (b) you have no objection to the publication, use,
                  modification, deletion and exploitation of the Content by
                  Customer Lobby or its licensees, successors and assigns; (c)
                  you forever waive and agree not to claim or assert any
                  entitlement to any and all moral rights of an author in any of
                  the Content; and (d) you forever release Customer Lobby, and
                  its licensees, successors and assigns, from any claims that
                  you could otherwise assert against Customer Lobby by virtue of
                  any such moral rights.
                </p>
              </li>
              <li>
                <b>PROPRIETARY RIGHTS</b>
                Except for the limited license granted below, Customer Lobby
                expressly reserves all right, title and interest in and to the
                Site, the Content (including without limitation the Customer
                Content), the Customer Lobby Technology (as defined below) and
                all other Customer Lobby IP (as defined below) and no rights
                therein are transferred to you by your access to the Site or the
                Customer Lobby IP. "Customer Lobby Technology" means all
                technology, inventions, know-how and related intellectual
                property owned or developed by Customer Lobby, including without
                limitation Customer Lobby-related software, code, script or API
                and all processing, algorithms and other software and technology
                used by Customer Lobby in the provision of the Site, together
                with any fixes, updates and upgrades of the foregoing. "Customer
                Lobby IP" means all intellectual property of Customer Lobby,
                including the Site, the Content, the Customer Lobby Technology,
                all trademarks, trade names, service marks, logos, trade dress
                and similar intellectual property rights of Customer Lobby, the
                Customer Lobby-provided logo and link, and any copyright,
                patent, trademark, other intellectual property right or federal
                or state right pertaining thereto, together with all
                derivatives, improvements, enhancements or extensions of any of
                the foregoing intellectual property conceived, reduced to
                practice or otherwise developed on or on behalf of Customer
                Lobby. Customer Lobby IP constitutes valuable assets of Customer
                Lobby. Unless explicitly stated herein, nothing in these Terms
                may be construed as conferring any license to Customer Lobby IP,
                whether by estoppel, implication or otherwise.
              </li>
              <li>
                <b>LICENSES</b>
                Subject to these Terms, Customer Lobby hereby grants to you a
                limited, revocable, non-exclusive, non-transferable license
                ("License") to access and use the Site and, to the extent
                necessary to your use of the Site, the Customer Lobby IP, in
                each case solely in accordance with these Terms and the
                applicable policies of Customer Lobby made available on the
                Customer Lobby Site.
                <p>
                  During the term of the License, Customer Lobby hereby grants
                  to you a limited, revocable, non-exclusive, non-transferable
                  license to include a link to the Site and an image from the
                  Site of the Customer Lobby logo on your web site solely for
                  the purpose of accessing the information available from the
                  Site.
                </p>
              </li>
              <li>
                <b>RESTRICTIONS ON USE</b>
                The Customer Lobby IP is to be used only to facilitate the
                exchange of opinions and information about the businesses,
                services, products and locations reviewed on the Site, and each
                person or entity supplying information to or using information
                from the Site shall only use the Customer Lobby IP for such
                purpose. You shall not: (a) use, or allow the use of, any
                Customer Lobby IP, except pursuant to the limited rights
                expressly granted in these Terms; (b) use the Customer Lobby IP
                other than for its intended purpose or, with respect to the
                Customer Lobby Technology that is a part thereof, in any manner
                that is inconsistent with user documentation, if any, made
                available to you by Customer Lobby; (c) attempt to decompile,
                reverse engineer, hack into or compromise any aspect of the
                Customer Lobby IP (including without limitation the Customer
                Lobby Technology), or attempt to access personal data of any
                other registered users; (d) use any data mining, robots or
                similar data gathering or extraction methods to collect
                information or data from the Customer Lobby IP; (e) remove,
                obscure or alter any legal notices, including notices of
                intellectual property rights appearing in or on the Customer
                Lobby IP or any materials delivered to you by Customer Lobby;
                (f) use, post, transmit or introduce any device, software or
                routine which interferes or attempts to interfere with the
                operation of the Customer Lobby IP; (g) reproduce, distribute,
                display, modify or make derivative uses of the Customer Lobby IP
                or the Site Content, or any portion thereof (except for any
                Content for which you have been given written permission),
                except as expressly provided herein; or (h) engage in any
                prohibited conduct, as detailed below.
                <p>
                  Recognizing the global nature of the Internet, you agree to
                  comply with all local rules regarding online conduct and
                  acceptable content. You will not use the Customer Lobby IP in
                  any way or for any purpose that would violate, or would have
                  the effect of violating, any applicable laws, rules or
                  regulations or any rights of any third parties, including
                  without limitation any law or right regarding the transmission
                  of technical data exported from the United States or the
                  country in which you reside, any laws affecting the
                  transmission or posting of content or affecting the privacy of
                  persons, or any law or right regarding any copyright, patent,
                  trademark, trade secret, music, image or other proprietary or
                  property right, false advertising, unfair competition,
                  defamation, invasion of privacy or rights of celebrity.
                </p>
                <p>
                  Except as expressly permitted herein, any use of any portion
                  of the Customer Lobby IP without the prior written permission
                  of Customer Lobby is strictly prohibited and will terminate
                  the License. Any such unauthorized use may also violate
                  applicable laws, including without limitation copyright and
                  trademark laws and applicable communications regulations and
                  statutes.
                </p>
              </li>
              <li>
                <b>NONDISCLOSURE OF CUSTOMER INFORMATION</b>
                In the normal course of using the Customer Lobby Site, you or
                your customers may input your customers' names, email addresses,
                locations or phone numbers ("Customer Information") into the
                Site. Unless your customer includes Customer Information in the
                body or subject line of a review of your business, we will not
                disclose your Customer Information to a third party with the
                exception of the first name, last initial and location of the
                reviewer.
                <p>
                  We reserve the right to disclose your Customer Information as
                  required by law; or to protect the rights, property, or safety
                  of Customer Lobby, our users, or others. Obviously, however,
                  this does not include selling, renting, sharing, or otherwise
                  disclosing Customer Information for commercial purposes. In
                  the event that we are legally compelled to disclose your
                  Customer Information to a third party, we will notify you
                  unless doing so would violate the law or a court order.
                </p>
              </li>
              <li>
                <b>PROHIBITED CONDUCT</b>
                You agree that you are responsible for your own conduct and
                Content while using the Customer Lobby IP and for any
                consequences thereof. You agree to use the Customer Lobby IP
                only for purposes that are legal, proper and in accordance with
                these Terms and any applicable policies or guidelines. By way of
                example, and not as a limitation, you agree not to post, email,
                or otherwise make available Content: (a) that is unlawful,
                harmful, threatening, abusive, harassing, defamatory, libelous,
                invasive of another's privacy, or is harmful to minors in any
                way; (b) that is pornographic or depicts a human being engaged
                in sexual conduct; (c) that harasses, degrades, intimidates or
                is hateful toward an individual or group of individuals on the
                basis of religion, gender, sexual orientation, race, ethnicity,
                age or disability; (d) that impersonates any person or entity,
                including, but not limited to, a Customer Lobby owner or
                employee, or falsely states or otherwise misrepresents your
                affiliation with a person or entity; (e) that includes personal
                or identifying information, beyond a first name, about another
                person without that person's explicit consent; (f) that is
                false, deceptive, misleading, deceitful, misinformative, or
                constitutes "bait and switch"; (g) that infringes any patent,
                trademark, trade secret, copyright or other proprietary rights
                of any party, or Content that you do not have a right to make
                available under any law or under contractual or fiduciary
                relationships; (h) that constitutes or contains "affiliate
                marketing," "link referral code," "junk mail," "spam," "chain
                letters," "pyramid schemes," or unsolicited commercial
                advertisement or any similar matter; (i)that constitutes or
                contains any form of advertising or solicitation if: posted in
                areas of the Customer Lobby Site which are not designated for
                such purposes (j) that communicates directly regarding services,
                products or commercial interests (via email or phone) to a
                Customer Lobby user who has not previously given you their email
                address and indicated in writing that it is all right to contact
                them about the same; (k) that advertises any illegal service or
                the sale of any items the sale of which is prohibited or
                restricted by any applicable law, including without limitation
                items services the sale of which is prohibited; (l) that
                contains software viruses or any other computer code, files or
                programs; (m) that disrupts the normal flow of dialogue with an
                excessive number of messages (flooding attack) to the Site, or
                that otherwise negatively affects other users' ability to use
                the Site; or (n) that employs misleading email addresses, or
                forged headers or otherwise manipulated identifiers in order to
                disguise the origin of Content transmitted through the Site.
                Additionally, you agree not to: (i) contact anyone who has asked
                not to be contacted; (ii) "stalk" or otherwise harass anyone;
                (iii) attempt to gain unauthorized access to Customer Lobby's
                computer systems or engage in any activity that disrupts,
                diminishes the quality of, interferes with the performance of,
                or impairs the functionality of, the Site; or (iv) use any form
                of automated device or computer program that enables the
                submission of postings on or through the Site without each
                posting being manually entered by the author thereof (an
                "automated posting device"), including without limitation the
                use of any such automated posting device to submit postings in
                bulk, or for automatic submission of postings at regular
                intervals.
                <p>
                  The above list is only representative of the conduct not
                  permitted under these Terms and is not exhaustive. Customer
                  Lobby does not screen the behavior of users and is not liable
                  for their actions or violations of these Terms. If you suspect
                  a user is in violation of these Terms, please contact Customer
                  Lobby at info@customerlobby.com. Any conduct deemed
                  inappropriate by Customer Lobby may result in suspension or
                  termination of your Customer Lobby account and License.
                </p>
              </li>
              <li>
                <b>THIRD PARTIES</b>
                The Site may contain links to third-party Web sites
                ("Third-Party Sites") and third-party content ("Third-Party
                Content"). You use links to Third-Party Sites, and any
                Third-Party Content therein, at your own risk. Customer Lobby
                does not monitor or have any control over, and makes no claim or
                representation regarding, Third-Party Content or Third-Party
                Sites. Customer Lobby provides these links only as a
                convenience, and a link to a Third-Party Site or Third-Party
                Content does not imply Customer Lobby's endorsement, adoption or
                sponsorship of, or affiliation with, such Third-Party Site or
                Third-Party Content. Customer Lobby accepts no responsibility
                for reviewing changes or updates to, or the quality, content,
                policies, nature or reliability of, Third-Party Content,
                Third-Party Sites or Web sites linking to the Site. When you
                leave the Site, our terms and policies no longer govern. You
                should review applicable terms and policies, including privacy
                and data-gathering practices, of any Third-Party Site, and
                should make whatever investigation you feel necessary or
                appropriate before proceeding with any transaction with any
                third party.
                <p>
                  Your interactions with other users of the Site or with
                  advertisers, including payment and delivery of goods or
                  services, or other terms, conditions, warranties or
                  representations associated with such dealings, are solely
                  between you and the other user or the advertiser. You agree
                  that Customer Lobby will not be responsible for any loss or
                  damage incurred as the result of any such dealings or with
                  respect to any third party's use or disclosure of personally
                  identifiable information supplied to such third party by you.
                  If there is a dispute between you and any third party
                  (including another user), Customer Lobby has the right, but
                  not the obligation, to participate in or monitor such dispute;
                  provided, however, that neither the possession nor the
                  exercise of such right shall be deemed to create any duty,
                  liability or obligation on Customer Lobby.
                </p>
              </li>
              <li>
                <b>FEES PAYABLE TO PARTNERS</b>
                Through your Partner account, you will have the ability to
                create a Free Account on behalf of your existing clients (a
                "Client's Free Account"). A Client's Free Account becomes a
                "Client's Paid Account" by entering a valid credit card and
                being charged a monthly membership fee. In each calendar month,
                you earn a commission equal to {"{monthlyFeePercentage}"} of the
                sum of your Client's Paid Account membership fees in that month{" "}
                {
                  "{accountActivationFeePercentage <= 0 All set-up fees or account activation fees are not included in the calculation of commissions.}"
                }{" "}
                Commissions are paid by Customer Lobby to its partners on the
                15th day of the month immediately following the month in which
                such Client's Paid Account membership fees are paid.
              </li>
              <li>
                <b>FEES PAYABLE TO CUSTOMER LOBBY</b>
                To continue your license as a Customer after the expiration of
                your Free Account, an amount equal to the then-current set-up
                fee (the "Set-Up Fee") plus the first month's membership fee
                (the "Membership Fee") (together, the "Fees") is due and
                payable. The current Fees can be found on the Site. Until your
                account is terminated, each month, on the monthly anniversary of
                your set-up date, the then-current Membership Fee is due and
                payable. From time to time, Customer Lobby may offer discounts
                on certain fees, but is not obligated to do so and is not
                obligated to offer all discounts to existing customers.
                <p>
                  Unless you have updated the credit card information on the
                  Site, the credit card with which you registered for the Site
                  will be charged on the date that any fee becomes due and
                  payable. You are responsible for maintaining and updating the
                  credit card information on file in your account to ensure that
                  your account is not deactivated.
                </p>
                <p>
                  Customer Lobby reserves the right to change its fee schedule
                  for use of the Site and the Customer Lobby IP in the future.
                  As a condition of your continued use of and access to the Site
                  following notice thereof, you agree to pay Customer Lobby
                  pursuant to the then-current fee schedule available on the
                  Site, and Customer Lobby reserves the right to deactivate your
                  access to the Site for failure to pay any such applicable fee
                  on or prior to the due date. Deactivation of your account does
                  not serve as account termination, and your financial
                  obligations to Customer Lobby in such a case under these Terms
                  would continue to accrue. If your account is deactivated, to
                  reactivate your account a reactivation fee (a "Reactivation
                  Fee") equal to the then-current Set-Up Fee will be charged to
                  your credit card.
                </p>
                <p>
                  Upon termination or cancellation of your Customer account, no
                  refund of any pre-paid Membership Fee will be issued. Any
                  refund of fees that is issued by Customer Lobby will be in the
                  form of a check or a credit to the credit card on file and
                  will be at Customer Lobby's sole discretion.
                </p>
              </li>
              <li>
                <b>FREE TRIAL ACCOUNT</b>
                After completing the free trial registration process (a "Free
                Account"), you may be entitled to a period of use of the Site
                with out cost to you. Customer Lobby reserves the right to
                terminate any Free Account at anytime and with out prior notice.
                Terminated accounts will have no access to data that was part of
                their account.
              </li>
              <li>
                <b>PRIVACY</b>
                Access to the Site may store a cookie on your computer. In
                connection with the provision of the Site, Customer Lobby may
                aggregate data derived from your use of the Site to compile
                aggregate statistics, metrics and general trend data about the
                Site for marketing and promotional purposes.
                <p>
                  You agree to not use the Site to track or collect personally
                  identifiable information of other users, nor will you
                  associate any data gathered with any personally identifying
                  information from any source as part of your use of the Site.
                </p>
                <p>
                  Customer Lobby is committed to protecting your privacy and the
                  information that Customer Lobby gathers. Customer Lobby's
                  complete privacy policy (the "Customer Lobby Privacy Policy"),
                  which is incorporated into this Agreement by reference, is
                  available at the following link:
                  <a href={`${host}/index/privacy-policy`} target="_blank">
                    {host}/index/privacy-policy
                  </a>
                  By Accepting the Terms, you represent and acknowledge that you
                  have read, understand and agree to the terms of the Customer
                  Lobby Privacy Policy.
                </p>
              </li>
              <li>
                <b>NOTIFICATION OF CLAIMS OF INFRINGEMENT</b>
                If you believe that your work has been copied in a way that
                constitutes copyright infringement, or your intellectual
                property rights have been otherwise violated, please notify
                Customer Lobby's agent for notice of claims of copyright or
                other intellectual property infringement ("Agent") at:
                info@customerlobby.com with "Attn: Copyright Agent" in the
                subject line.
                <p>
                  Please provide our Agent with the following notice ("Notice"):
                  (a) a description of the copyrighted work that you claim has
                  been infringed; (b) a description of the material on the Site
                  that you claim is infringing, with enough detail so that we
                  may locate it; (c) a statement by you that you have a
                  good-faith belief that the disputed use is not authorized by
                  the copyright owner, its agent, or the law; (d) a statement by
                  you declaring under penalty of perjury that (i) the above
                  information in your Notice is accurate, and (ii) that you are
                  the owner of the copyright interest involved or that you are
                  authorized to act on behalf of that owner; (e) your address,
                  telephone number, and email address; and (f) the physical or
                  electronic signature of the person authorized to act on behalf
                  of the owner of the copyright interest. Customer Lobby will
                  remove the infringing posting(s), subject to the procedures
                  outlined in the Digital Millennium Copyright Act ("DMCA")
                  (including without limitation the counter-notice and put-back
                  procedures).
                </p>
              </li>
              <li>
                <b>INDEMNIFICATION</b>
                You agree to indemnify, hold harmless and defend Customer Lobby,
                including its members, officers, directors, employees, agents
                and affiliates, at your expense, against any and all third-party
                claims, actions, proceedings, and suits brought against Customer
                Lobby or any of its members, officers, directors, employees,
                agents or affiliates, and all related liabilities, damages,
                settlements, penalties, fines, costs or expenses (including
                without limitation reasonable attorneys' fees) incurred by
                Customer Lobby or any of its officers, directors, employees,
                agents or affiliates, arising out of or relating to (a) your
                breach of any term or condition of these Terms, or (b) your use
                of the Site or any use of the site by your authorized user or
                misuse of your or your authorized users' passwords, or (c) any
                use by others of your Customer Content. In such a case, Customer
                Lobby will provide you with written notice of such claim, suit
                or action. You shall cooperate as fully as reasonably required
                in the defense of any claim. Customer Lobby reserves the right,
                at its own expense, to assume the exclusive defense and control
                of any matter subject to indemnification by you, and in such an
                event your obligation to indemnify and hold harmless Customer
                Lobby (and its members, officers, directors, employees, agents
                and affiliates) shall continue in full effect with the sole
                exception of costs of defense.
              </li>
              <li>
                <b>RELEASE</b>
                You hereby release Customer Lobby, and its members, officers,
                directors, employees, agents and affiliates, from any and all
                claims, demands, losses, damages, rights, and actions of any
                kind, including without limitation financial or non-financial
                loss, damage to your or your company's reputation, personal
                injuries, death, and property damage, that are either directly
                or indirectly related to or arise from (a) any interactions with
                other users or any Customer Lobby IP, (b) any incorrect or
                inaccurate Customer Content or other Content (including any
                information in profiles) posted on or through the Site, whether
                caused by users or by any of the equipment or programming
                associated with or utilized in the Site, (c) conduct of any user
                of the Site, or (d) removal of any Customer Content or other
                Content provided by you to the Site. You hereby waive California
                Civil Code section 1542, which states: "A general release does
                not extend to claims which the creditor does not know or suspect
                to exist in his or her favor at the time of executing the
                release, which if known by him or her must have materially
                affected his or her settlement with the debtor."
              </li>
              <li>
                <b>REPRESENTATIONS AND WARRANTIES; DISCLAIMERS</b>
                <div class="uppercase-font">
                  THE SITE AND ALL CUSTOMER LOBBY IP ARE PROVIDED "AS IS" AND
                  "AS AVAILABLE." THERE ARE NO WARRANTIES, CLAIMS OR
                  REPRESENTATIONS MADE BY CUSTOMER LOBBY (OR ANY MEMBER,
                  OFFICER, EMPLOYEE OR AGENT THEREOF), EITHER EXPRESS OR
                  IMPLIED, WITH RESPECT TO THE SITE OR ANY CUSTOMER LOBBY IP,
                  INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF QUALITY,
                  PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, TITLE OR
                  FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES
                  CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE
                  USAGE. THE INFORMATION AND SERVICES INCLUDED IN OR AVAILABLE
                  THROUGH THE SITE OR SERVICES MAY INCLUDE INACCURACIES OR
                  TYPOGRAPHICAL ERRORS. CUSTOMER LOBBY (OR ANY MEMBER, OFFICER,
                  EMPLOYEE OR AGENT THEREOF) DOES NOT WARRANT THAT (A) THE SITE
                  OR OTHER CUSTOMER LOBBY IP WILL MEET YOUR NEEDS, (B) THE SITE
                  OR OTHER CUSTOMER LOBBY IP WILL BE ERROR-FREE OR ACCESSIBLE AT
                  ALL TIMES, (C) DEFECTS WILL BE CORRECTED, (D) THE SITE OR THE
                  SERVER THAT MAKES THE SITE AVAILABLE ARE FREE OF VIRUSES OR
                  OTHER HARMFUL COMPONENTS, OR (E) THE USE OR THE RESULTS OF THE
                  USE OF THE SITE OR THE CONTENT MADE AVAILABLE AS PART OF THE
                  SITE WILL BE CORRECT, ACCURATE, TIMELY OR OTHERWISE RELIABLE.
                  YOU ASSUME ALLL RESPONSIBILITY FOR SELECTION AND USE OF
                  CONTENT AND ALL OTHER CUSTOMER LOBBY IP.
                </div>
                <p>
                  The foregoing exclusions and disclaimers are an essential part
                  of these terms and formed the basis for determining the price
                  charged for the services. Some states do not allow exclusion
                  of an implied warranty, so this disclaimer may not apply to
                  you.
                </p>
              </li>
              <li>
                <b>LIMITATIONS OF LIABILITY</b>
                <div class="uppercase-font">
                  TO THE MAXIMUM EXTENT ALLOWED BY LAW, UNDER NO CIRCUMSTANCES,
                  INCLUDING NEGLIGENCE, SHALL CUSTOMER LOBBY BE LIABLE TO YOU OR
                  ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL,
                  CONSEQUENTIAL OR OTHER INDIRECT DAMAGES, WHETHER OR NOT
                  FORESEEABLE, INCLUDING, WITHOUT LIMITATION, LOST REVENUES,
                  LOST PROFITS, LOST BUSINESS, OR LOST DATA, ARISING FROM YOUR
                  USE OF, OR ANY OTHER PARTY'S USE OF, OR YOUR OR ANY OTHER
                  PARTY'S INABILITY TO USE, THE SITE, THE CONTENT OR ANY OTHER
                  CUSTOMER LOBBY IP, WHETHER BASED ON A CLAIM OR ACTION OF
                  CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY OR OTHER
                  TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION,
                  OR OTHERWISE, EVEN IF CUSTOMER LOBBY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES.
                </div>
                <p>
                  Customer Lobby's cumulative liability to you or any other
                  party for any loss or damage resulting from any claims,
                  demands, or actions arising out of or relating to these Terms
                  shall not exceed one hundred dollars (US$100). Some states do
                  not allow the limitation or exclusion of liability for
                  incidental or consequential damages, so the above limitations
                  or exclusions may not apply to you.
                </p>
                <p class="uppercase-font">
                  THE LIMITATIONS ON DAMAGES HEREUNDER ARE PART OF THE TERMS
                  UNDER WHICH CUSTOMER LOBBY IS WILLING TO ALLOW CUSTOMERS TO
                  REGISTER AND BE INCLUDED ON THE SITE AS CONTEMPLATED HEREIN.
                  CUSTOMER ACKNOWLEDGES THAT THE SERVICES, SITE AND CONTENT
                  WOULD NOT BE PROVIDED UNDER THE SAME TERMS, INCLUDING WITHOUT
                  LIMITATION THE SAME LOW COST, TO CUSTOMER, IF CUSTOMER LOBBY
                  WERE RESPONSIBLE FOR CUSTOMER'S DAMAGES.
                </p>
              </li>
              <li>
                <b>INTERNET</b>
                Customer Lobby reserves the right to make the Site unavailable
                or to take it down without notice as may be required or desired
                to make modifications or for any other reason. In addition, any
                software, information, content, or material of any kind
                downloaded or otherwise obtained through the use of the Site is
                obtained at your own discretion and risk, and you shall be
                solely responsible for any damage to any computer or loss of
                data that results from such download or use of the Content or
                access to the Site. Portions of the Site utilize Internet
                connectivity to transmit and receive Web pages, Content and
                data. Customer Lobby may utilize this information to gather
                usage statistics. In addition, you are warned that the Internet
                is not a secure medium or environment, and thus all use of the
                Internet, including in connection with the Site and Content, is
                solely at your own risk. Customer Lobby is not responsible for
                any viruses, spam or other actions or results that may occur as
                a result of your use of the Internet, Software, Site or Content.
              </li>
              <li>
                <b>TERMINATION; MODIFICATION OF LICENSE AND SITE OFFERINGS</b>
                <p>You can terminate your Customer Lobby Customer or Partner
                  account at any time. Your Customer and Partner account will
                  automatically terminate upon the termination of the contract
                  between Customer Lobby and you and/or your parent company. Upon
                  termination of your account, the License to use the Site and the
                  other Customer Lobby IP, or any portion thereof, will terminate
                  simultaneously. Notwithstanding any provision of these Terms,
                  Customer Lobby reserves the right, in its sole discretion,
                  without any notice or liability to you, to (a) terminate your
                  License to use the Site, Customer account, Partner account, and
                  the other Customer Lobby IP, or any portion thereof; (b) block
                  or prevent your future access to and use of all or any portion
                  of the Site or the other Customer Lobby IP; (c) change, suspend
                  or discontinue any aspect of the Site or the other Customer
                  Lobby IP; and (d) impose limits on the Site and the other
                  Customer Lobby IP. Either party hereto may terminate the License
                  at any time and for any reason. In addition, the License will
                  terminate immediately, without notice, if you fail to comply
                  with these Terms.
                </p>
                <p>
                  Upon any termination of the License, you will discontinue use
                  of the Site and the other Customer Lobby IP and, if
                  applicable, delete all copies of the Customer Lobby IP from
                  your browser, Web servers, servers and any other location
                  where Customer Lobby IP has been saved. Upon termination of
                  the License, you will no longer have access to the Site or any
                  of the Content contained therein.
                </p>
                <p>
                  You understand and acknowledge that, unless and until the
                  Customer Lobby IP is deleted from your servers, Web servers,
                  Web sites, and browser, these Terms remain in effect.
                </p>
                <p>
                  All sections of these Terms will survive termination of the
                  License except.
                </p>
                <div class="uppercase-font">
                  ACCOUNT, LICENSES, FEES PAYABLE TO CUSTOMER LOBBY and PARTNER
                  FEES.
                </div>
              </li>
              <li>
                <b>MISCELLANEOUS</b>
                <p>These Terms represent the complete agreement between us
                  concerning its subject matter, and supersede all prior
                  agreements and representations between the parties hereto.
                </p>
                <p>
                  These Terms shall be governed by and construed under the laws
                  of the State of California without reference to its
                  conflict-of-law principles. In the event of any conflicts
                  between foreign law, rules and regulations and California law,
                  California law, rules and regulations shall prevail and
                  govern.
                </p>
                <p>
                  Each party hereto agrees to submit to the exclusive and
                  personal jurisdiction of the state and federal courts located
                  in San Francisco, California, with respect to any dispute
                  between the parties related to this Agreement, the Services or
                  the Customer Lobby IP (whether based in contract tort or any
                  other right). The United Nations Convention on Contracts for
                  the International Sale of Goods and the Uniform Computer
                  Information Transactions Act shall not apply to these Terms.
                </p>
                <p>
                  The Customer Lobby IP is controlled by U.S. Export
                  Regulations, and may be not be exported to or used by
                  embargoed countries or individuals.
                </p>
                <p>
                  Any notices to Customer Lobby must be sent via email addressed
                  to:
                  <a href="mailto:contact@customerlobby.com">
                    contact@customerlobby.com,
                  </a>
                  and are deemed given upon receipt. Notices to you will be sent
                  to your email address on record with Customer lobby, and are
                  also deemed given upon receipt.
                </p>
                <p>
                  If any provision of these Terms is held to be unenforceable
                  for any reason, such provision shall be reformed to the extent
                  necessary to make it enforceable to the maximum extent
                  permissible so as to effect the intent of the parties hereto,
                  and the remainder of these Terms shall continue in full force
                  and effect.
                </p>
                <p>
                  A waiver of any default is not a waiver of any subsequent
                  default.
                </p>
                <p>
                  These Terms shall be binding upon and inure to the benefit of
                  the respective successors and assigns of the parties hereto.
                </p>
                <p>
                  Anything contained on the Site inconsistent with or
                  conflicting with these Terms is superseded by these Terms, as
                  in effect at any given time.
                </p>
                <p class="uppercase-font">
                  YOU REPRESENT AND WARRANT THAT YOU ARE AT LEAST EIGHTEEN (18)
                  YEARS OF AGE AND THAT YOU HAVE THE RIGHT AND ABILITY TO ENTER
                  INTO THESE TERMS. YOU FURTHER REPRESENT THAT YOU ARE
                  AUTHORIZED TO ENTER INTO THESE TERMS, AND THAT THESE TERMS
                  CONSTITUTE YOUR VALID AND LEGALLY BINDING OBLIGATION.
                </p>
              </li>
              <li>
                <b>QUESTIONS AND COMMENTS</b>
                <p class="no-padding">
                  If you have any questions regarding these Terms or your use of
                  the Site, please submit them to us at
                  <a href="mailto:info@customerlobby.com">
                    {' '}info@customerlobby.com
                  </a>
                </p>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </Container>
  );
};

export default Terms;
