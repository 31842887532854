import React from "react";
import "src/components/reviews/manage_reviews/tabs/ManageTab/review-list/ReviewHeader.scss";
import "src/components/reviews/manage_reviews/tabs/private-tab/PrivateFeedbackTabComponent.scss";
import {
  getNameInitials,
  dateFormatter,
  contactPageUrl,
} from "src/shared/Utils.js";
import customerlobby_icon from "src/styles/assets/review-settings-icon/spotlight.svg";
import google_icon from "src/styles/assets/review-settings-icon/google-icon.svg";
import { DEFAULT_STAR_RATING_CONFIG } from "src/shared/Config";
import StarRatings from "react-star-ratings";
import {
  PLATFORM_TYPE,
  REVIEW_SOURCE_DISPLAY,
  NOT_AVAILABLE,
} from "src/shared/Constants.js";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
import "src/components/reviews/manage_reviews/tabs/private-tab/private-feedback-list/PrivateFeedbackTileComponentV2.scss";
import StringUtil from "src/utils/StringUtil";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const ManageInfoHelper = ({ img, data }) => {
  // checks
  if (!data) {
    return null;
  }

  // render
  return (
    <div style={{ marginTop: "10px" }} className="d-flex align-items-center">
      <div
        style={{ minWidth: "18px" }}
        className="d-flex justify-content-center align-items-center"
      >
        {img}
      </div>
      <div className="pflist-data-styles">{data}</div>
    </div>
  );
};

const ManageTileComponent = ({ searchTerm = "", clickEvent, reviewData }) => {
  const companyId = useGetCompanyIdFromUrl();
  // methods
  const getPlatformImage = (platform) => {
    switch (platform) {
      case PLATFORM_TYPE.CUSTOMER_LOBBY:
        return customerlobby_icon;
      case PLATFORM_TYPE.GOOGLE:
        return google_icon;
      default:
        return customerlobby_icon;
    }
  };

  const starRating = (totalScore) => {
    var val = Math.floor(totalScore * 5);
    return val;
  };

  const handleClickEvent = (e, review) => {
    if (e.target.id === "customer-link") return null;
    return clickEvent(review);
  };

  // render
  return (
    <div>
      {reviewData &&
        reviewData.map((review, index) => (
          <div
            onClick={(e) => handleClickEvent(e, review)}
            style={{ marginRight: "0px" }}
            className="row pvtile-container"
            key={index}
          >
            <div className="col-12 pr-lg-0">
              <div className="new-tile d-flex justify-content-between">
                <div className="pflist-left-container d-flex">
                  <div className="ellipse d-flex align-items-center">
                    <div className="name-initials w-100">
                      {review.contact
                        ? getNameInitials(review.contact.name)
                        : NOT_AVAILABLE}
                    </div>
                  </div>

                  <div
                    style={{ marginLeft: "14px" }}
                    className="pflist-contact-info-container d-flex flex-column"
                  >
                    <a
                      id="customer-link"
                      href={contactPageUrl(review.contact.contactId, companyId)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="name contact-page-link"
                    >
                      {review.contact
                        ? StringUtil.highlightWithinWord(
                            review.contact.name,
                            searchTerm,
                          )
                        : NOT_AVAILABLE}
                    </a>
                    <ManageInfoHelper
                      img={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.953"
                          height="12.199"
                          viewBox="0 0 15.953 12.199"
                        >
                          <g transform="translate(.1 -60.9)">
                            <g transform="translate(0 61)">
                              <path
                                fill="#667587"
                                stroke="#667587"
                                strokeWidth="0.2px"
                                d="M14.369 61H1.385A1.387 1.387 0 0 0 0 62.385v9.231A1.387 1.387 0 0 0 1.385 73h12.984a1.387 1.387 0 0 0 1.385-1.385v-9.23A1.387 1.387 0 0 0 14.369 61zm-.191.923l-6.272 6.272-6.326-6.272zM.923 71.424v-8.853l4.446 4.407zm.653.653l4.448-4.448 1.559 1.545a.462.462 0 0 0 .651 0l1.52-1.52 4.424 4.424zm13.255-.653L10.407 67l4.424-4.424z"
                                transform="translate(0 -61)"
                              />
                            </g>
                          </g>
                        </svg>
                      }
                      data={
                        review.contact
                          ? StringUtil.highlightWithinWord(
                              review.contact.email,
                              searchTerm,
                            )
                          : null
                      }
                    />
                    <ManageInfoHelper
                      img={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.037"
                          height="19"
                          viewBox="0 0 11.037 19"
                        >
                          <g transform="translate(-7.334)">
                            <path
                              fill="#667587"
                              d="M17.088 0H8.617a1.287 1.287 0 0 0-1.283 1.283v16.433A1.287 1.287 0 0 0 8.617 19h8.471a1.287 1.287 0 0 0 1.283-1.283V1.283A1.287 1.287 0 0 0 17.088 0zM11.5.925h2.71a.156.156 0 0 1 0 .311H11.5a.156.156 0 0 1 0-.311zm1.355 17.433a.642.642 0 1 1 .642-.642.642.642 0 0 1-.644.642zm4.626-1.733H8.227V2.035h9.251z"
                            />
                          </g>
                        </svg>
                      }
                      data={
                        review.contact
                          ? StringUtil.highlightWithinWord(
                              review.contact.formattedPhoneMobile,
                              searchTerm,
                            )
                          : null
                      }
                    />
                    <ManageInfoHelper
                      img={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.771"
                          height="16.76"
                          viewBox="0 0 16.771 16.76"
                        >
                          <g transform="translate(-.563 .35)">
                            <path
                              fill="#667587"
                              stroke="#667587"
                              strokeWidth="0.7px"
                              d="M4.154 10.676a17.039 17.039 0 0 0 5.667 4.439 8.821 8.821 0 0 0 3.172.938c.077 0 .15.007.226.007a2.626 2.626 0 0 0 2.037-.875.073.073 0 0 0 .013-.017 8.576 8.576 0 0 1 .642-.666c.156-.15.316-.306.469-.466a1.607 1.607 0 0 0-.007-2.393l-2-2a1.619 1.619 0 0 0-1.173-.538 1.673 1.673 0 0 0-1.185.536l-1.191 1.191c-.11-.063-.223-.12-.329-.173a4.115 4.115 0 0 1-.366-.2 12.609 12.609 0 0 1-3.01-2.742A7.261 7.261 0 0 1 6.1 6.093c.313-.283.606-.579.889-.869.1-.1.2-.206.306-.309a1.706 1.706 0 0 0 .552-1.2 1.693 1.693 0 0 0-.552-1.2L6.3 1.528c-.116-.116-.226-.23-.339-.346-.216-.227-.446-.46-.672-.67A1.659 1.659 0 0 0 4.117 0a1.708 1.708 0 0 0-1.184.516L1.688 1.76A2.557 2.557 0 0 0 .926 3.4a6.159 6.159 0 0 0 .462 2.66 15.489 15.489 0 0 0 2.766 4.616zM1.738 3.468a1.76 1.76 0 0 1 .529-1.132L3.5 1.1a.9.9 0 0 1 .617-.285.85.85 0 0 1 .6.29c.223.206.433.423.659.652.113.116.23.233.346.353l.992.992a.9.9 0 0 1 .313.622.9.9 0 0 1-.313.622c-.1.1-.206.21-.31.313-.309.313-.6.609-.919.892l-.017.017a.651.651 0 0 0-.166.739c0 .01.007.017.01.027a7.832 7.832 0 0 0 1.168 1.9 13.308 13.308 0 0 0 3.208 2.922 4.763 4.763 0 0 0 .439.24 4.114 4.114 0 0 1 .366.2l.037.02a.7.7 0 0 0 .323.083.706.706 0 0 0 .5-.226l1.245-1.245a.881.881 0 0 1 .609-.3.836.836 0 0 1 .589.3l2.007 2a.8.8 0 0 1-.01 1.255c-.14.15-.286.293-.443.443a9.584 9.584 0 0 0-.7.722 1.827 1.827 0 0 1-1.428.606c-.057 0-.116 0-.173-.007a8.014 8.014 0 0 1-2.869-.859 16.187 16.187 0 0 1-5.395-4.226 14.825 14.825 0 0 1-2.628-4.375 5.368 5.368 0 0 1-.419-2.319z"
                            />
                          </g>
                        </svg>
                      }
                      data={
                        review.contact
                          ? StringUtil.highlightWithinWord(
                              review.contact.formattedPhoneDaytime,
                              searchTerm,
                            )
                          : null
                      }
                    />
                    <ManageInfoHelper
                      img={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.771"
                          height="16.76"
                          viewBox="0 0 16.771 16.76"
                        >
                          <g transform="translate(-.563 .35)">
                            <path
                              fill="#667587"
                              stroke="#667587"
                              strokeWidth="0.7px"
                              d="M4.154 10.676a17.039 17.039 0 0 0 5.667 4.439 8.821 8.821 0 0 0 3.172.938c.077 0 .15.007.226.007a2.626 2.626 0 0 0 2.037-.875.073.073 0 0 0 .013-.017 8.576 8.576 0 0 1 .642-.666c.156-.15.316-.306.469-.466a1.607 1.607 0 0 0-.007-2.393l-2-2a1.619 1.619 0 0 0-1.173-.538 1.673 1.673 0 0 0-1.185.536l-1.191 1.191c-.11-.063-.223-.12-.329-.173a4.115 4.115 0 0 1-.366-.2 12.609 12.609 0 0 1-3.01-2.742A7.261 7.261 0 0 1 6.1 6.093c.313-.283.606-.579.889-.869.1-.1.2-.206.306-.309a1.706 1.706 0 0 0 .552-1.2 1.693 1.693 0 0 0-.552-1.2L6.3 1.528c-.116-.116-.226-.23-.339-.346-.216-.227-.446-.46-.672-.67A1.659 1.659 0 0 0 4.117 0a1.708 1.708 0 0 0-1.184.516L1.688 1.76A2.557 2.557 0 0 0 .926 3.4a6.159 6.159 0 0 0 .462 2.66 15.489 15.489 0 0 0 2.766 4.616zM1.738 3.468a1.76 1.76 0 0 1 .529-1.132L3.5 1.1a.9.9 0 0 1 .617-.285.85.85 0 0 1 .6.29c.223.206.433.423.659.652.113.116.23.233.346.353l.992.992a.9.9 0 0 1 .313.622.9.9 0 0 1-.313.622c-.1.1-.206.21-.31.313-.309.313-.6.609-.919.892l-.017.017a.651.651 0 0 0-.166.739c0 .01.007.017.01.027a7.832 7.832 0 0 0 1.168 1.9 13.308 13.308 0 0 0 3.208 2.922 4.763 4.763 0 0 0 .439.24 4.114 4.114 0 0 1 .366.2l.037.02a.7.7 0 0 0 .323.083.706.706 0 0 0 .5-.226l1.245-1.245a.881.881 0 0 1 .609-.3.836.836 0 0 1 .589.3l2.007 2a.8.8 0 0 1-.01 1.255c-.14.15-.286.293-.443.443a9.584 9.584 0 0 0-.7.722 1.827 1.827 0 0 1-1.428.606c-.057 0-.116 0-.173-.007a8.014 8.014 0 0 1-2.869-.859 16.187 16.187 0 0 1-5.395-4.226 14.825 14.825 0 0 1-2.628-4.375 5.368 5.368 0 0 1-.419-2.319z"
                            />
                          </g>
                        </svg>
                      }
                      data={
                        review.contact
                          ? StringUtil.highlightWithinWord(
                              review.contact.formattedPhoneAlt,
                              searchTerm,
                            )
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="pflist-right-container d-flex flex-column">
                  <div
                    style={{ width: "100%", marginBottom: "10px" }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex">
                      <StarRatings
                        rating={starRating(review.totalScore)}
                        numberOfStars={5}
                        starDimension={DEFAULT_STAR_RATING_CONFIG.dimension}
                        starSpacing={DEFAULT_STAR_RATING_CONFIG.spacing}
                        starEmptyColor={DEFAULT_STAR_RATING_CONFIG.emptyColor}
                        starRatedColor={DEFAULT_STAR_RATING_CONFIG.ratedColor}
                      />
                      <div className="after-rating">
                        <span className="dot">.</span>
                        <span className="review-type pt-sm-1">
                          {REVIEW_SOURCE_DISPLAY[review.source]}
                        </span>
                        <span className="dot">.</span>
                        <span className="review-type pt-sm-1">
                          {dateFormatter(review.reviewedAt)}
                        </span>
                      </div>
                    </div>

                    <img
                      src={getPlatformImage(review.platform)}
                      alt="review-logo"
                      className="manage-review-logo"
                    />
                  </div>

                  <div style={{ marginBottom: "5px" }} className="subject">
                    {review.summary}
                  </div>
                  <LinesEllipsisLoose
                    text={review.body}
                    maxLine="2"
                    ellipsis="..."
                    lineHeight="16"
                    style={{
                      width: 450,
                      maxWidth: 450,
                      minWidth: 450,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ManageTileComponent;
