import { createSlice } from "@reduxjs/toolkit";
import { loadCookie, removeCookies } from "src/shared/SessionHelper";
import { COOKIES } from "src/shared/Config";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    isAuthenticated: !!loadCookie(COOKIES.AUTH_TOKEN),
    token: loadCookie(COOKIES.AUTH_TOKEN),
    loading: true,
    acceptsTerms: false,
    enterpriseInfo: {},
  },
  reducers: {
    setUserDetails: (state, { payload }) => {
      state.userInfo = payload;
      state.isAuthenticated = true;
      state.loading = false;
    },
    setAuthToken: (state, { payload }) => {
      state.token = payload;
    },
    logout: (state) => {
      removeCookies();
      sessionStorage.clear();
      state.isAuthenticated = false;
      state.userInfo = {};
    },
    setAcceptTerms: (state, { payload }) => {
      state.acceptsTerms = payload;
    },
    setEnterpriseDetails: (state, { payload }) => {
      state.enterpriseInfo = payload;
    },
  },
});

export const {
  setUserDetails,
  setAcceptTerms,
  logout,
  setEnterpriseDetails,
  setAuthToken,
} = userSlice.actions;
export default userSlice.reducer;
