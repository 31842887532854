import { gql } from "apollo-boost";

export const ENTERPRISE_USER_INFO = gql`
  query getEnterpriseDetails($id: ID!) {
    getEnterpriseUser(id: $id) {
      adminUserId
      companyUserId
      email
      enterpriseId
      firstName
      id
      isActive
      isManager
      lastName
      userDisplayName
    }
  }
`;
export const ENTERPRISE_BRANDING_BUDGET = gql`
  query EnterpriseBrandingBudget(
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
  ) {
    enterpriseBrandingBudget(
      page: $page
      rpp: $rpp
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
    ) {
      data {
        companyName
        approvedCredits
        recommendedCredits
        city
        state
        zip
        companyId
      }
      totalCount
    }
  }
`;

export const ENTERPRISE_POSTCARD_RESULTS = gql`
  query EnterprisePostcardResults(
    $sortBy: String
    $rpp: Int
    $page: Int
    $sortOrder: String
    $searchTerm: String
    $fromDate: String
    $toDate: String
    $category: [String!]
  ) {
    enterprisePostcardResults(
      category: $category
      page: $page
      rpp: $rpp
      searchTerm: $searchTerm
      fromDate: $fromDate
      toDate: $toDate
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        companyId
        companyName
        responseRate
        revenueGenerated
        totalTransactions
        customersReached
      }
      totalCount
    }
  }
`;

export const ENTERPRISE_OVERALL_RESULT = gql`
  query enterpriseOverall {
    enterpriseOverallCampaignResults
  }
`;

export const ENTERPRISE_CAMPAIGN_USER_REPORT = gql`
  query CamapignUsage(
    $sortBy: String
    $rpp: Int
    $page: Int
    $sortOrder: String
    $campaignSearchTerm: String
    $companySearchTerm: String
    $category: [String!]
  ) {
    enterpriseCampaignUsage(
      category: $category
      campaignSearchTerm: $campaignSearchTerm
      companySearchTerm: $companySearchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      rpp: $rpp
      page: $page
    ) {
      data {
        companyId
        companyName
        campaignCount
      }
      totalCount
    }
  }
`;

export const ENTERPRISE_RECENT_CAMPAIGNS = gql`
  query listUpcomingCampaigns(
    $companyId: ID!
    $fromDate: String!
    $status: [String!]
    $toDate: String!
    $sortBy: String
    $sortOrder: String
  ) {
    listUpcomingCampaigns(
      companyId: $companyId
      fromDate: $fromDate
      status: $status
      toDate: $toDate
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      id
      mailingDate
      communicationCampaign {
        category
        channel
        followUpEmailEnabled
        name
        nextMailingDate
        upcomingMailingDate
      }
    }
  }
`;

export const ENTERPRISE_UNIQUE_CAMPAIGN_NAMES = gql`
  query enterpriseUniqCampaignNames(
    $companySearchTerm: String
    $campaignSearchTerm: String
    $category: [String!]
  ) {
    enterpriseUniqCampaignNames(
      companySearchTerm: $companySearchTerm
      campaignSearchTerm: $campaignSearchTerm
      category: $category
    ) {
      name
    }
  }
`;

export const ENTERPRISE_LOCATIONS = gql`
  query enterpriseGetLocations(
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
  ) {
    enterpriseLocations(
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      page: $page
      rpp: $rpp
    ) {
      totalCount
      data {
        companyId
        companyName
        lastReviewDate
        status
        reviewPlatform
        companyFeatureFlags
        directConnectDisabled
        directConnectStatus
        paymentMethod
        city
        state
        address1
        address2
        zip
      }
    }
  }
`;

export const GET_ENTERPRISE_DETAILS = gql`
  query enterprise {
    getEnterprise {
      name
      description
      reviewPageUrl
      enableReviewsPage
      phoneNumber
      address1
      address2
      city
      state
      zipcode
      country
      categoryIds
      website
      billingEmail
    }
  }
`;

export const ENTERPRISE_USERS = gql`
  query enterpriseUsers(
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
    $searchTerm: String
  ) {
    enterpriseUsers(
      page: $page
      rpp: $rpp
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
    ) {
      data {
        firstName
        lastName
        email
        isActive
        isManager
        userDisplayName
        id
      }
      totalCount
    }
  }
`;

export const GET_ENTERPRISE_USER = gql`
  query getEnterpriseUser($id: ID!) {
    getEnterpriseUser(id: $id) {
      firstName
      lastName
      email
      isActive
      isManager
      id
    }
  }
`;
export const GET_NOTIFICATION_EMAIL_USER_LIST = gql`
  query enterpriseNotificationEmailsList {
    enterpriseUsers(page: 1, sortBy: "first_name") {
      data {
        id
        firstName
        lastName
        email
      }
      notificationUser {
        id
        lastName
        firstName
        email
      }
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS_DATA = gql`
  query enterpriseSettings {
    enterpriseNotificationSettings {
      id
      categoryName
      deliverToEnterpriseUser
      deliverToCompanyUser
    }
  }
`;

export const ENTERPRISE_SUBSCRIPTION = gql`
  query EnterpriseSubscription {
    enterpriseSubscription {
      billing {
        address1
        address2
        city
        country
        countryLabel
        email
        firstName
        lastName
        state
        stateLabel
        zip
      }
      cardType
      enterpriseId
      expirationDate
      lastFour
      status
    }
  }
`;

export const ENTERPRISE_RECEIPTS = gql`
  query enterpriseReceipts(
    $rpp: Int
    $page: Int
    $sortBy: String
    $sortOrder: String
  ) {
    enterpriseReceipts(
      page: $page
      rpp: $rpp
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        id
        status
        url
        date
      }
      totalCount
    }
  }
`;
