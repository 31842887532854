import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient } from "react-apollo";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import { Row, Col, Button } from "react-bootstrap";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "src/shared/styleguide/button/Button";
import SearchComponent from "src/components/search_component/SearchComponent";
import NoRecordFound from "src/components/enterprise/NoRecordFound.jsx";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { LIST_PARTNER_USERS } from "src/graphql/partner/queries";
import "src/components/enterprise/KeyMetrics.scss";

export const HEADER_ITEMS = [
  {
    columnLabel: "Name",
    columnValue: "first_name",
    sortable: true,
    width: 350,
  },
  {
    columnLabel: "Email",
    columnValue: "email",
    sortable: true,
  },
  {
    columnLabel: "Designation",
    columnValue: "is_owner",
    sortable: true,
  },
  {
    columnLabel: "Status",
    columnValue: "is_active",
    sortable: true,
  },
  {
    columnLabel: "Actions",
    columnValue: "",
    sortable: false,
  },
];

const Users = () => {
  useDocumentTitle("Users");
  const perPage = 10;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataProvider, setDataProvider] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const [companyStatus, setCompanyStatus] = useState("active");
  const companyTypes = [
    { name: "ACTIVE", value: "active" },
    { name: "INACTIVE", value: "inactive" },
  ];

  const getPostcardCampaignRow = (data) => {
    const { email, isActive, isOwner, userDisplayName } = data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span className="text-primary">{userDisplayName}</span>
          </div>
        ),
      },
      {
        node: email,
      },
      {
        node: isOwner ? "Owner" : "Non-owner",
      },
      {
        node: isActive ? "Active" : "Inactive",
      },

      {
        node: <Button disabled>Manage</Button>,
      },
    ];
  };

  const getUsersListsData = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
    };

    if (props.searchTerm || filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm || props.searchTerm.toLowerCase() || "";
    }

    client
      .query({
        query: LIST_PARTNER_USERS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listPartnerUsers) {
          const companies = res.data.listPartnerUsers.data;
          const totalRecords = res.data.listPartnerUsers.totalCount;
          setDataProvider(companies);

          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getUsersListsData({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getUsersListsData({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy, companyStatus]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getUsersListsData(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  return (
    <BlockUi>
      <div className="page-title mb-5">Users</div>

      <Card
        title={
          <Row>
            <Col className="d-flex justify-content-end align-items-center key-metrics-filter">
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <div style={{ minWidth: values?.width || "auto" }}>
                <SortableTableHeader
                  sortable={values.sortable}
                  columnLabel={values.columnLabel}
                  columnValue={values.columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />
              </div>
            ))}
            body={dataProvider.map((data) => getPostcardCampaignRow(data))}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </BlockUi>
  );
};

export default Users;
