import React from "react";
import BlockUi from "react-block-ui";
import { stringConvertor, dateFormatter } from "src/shared/Utils";
import {
  getCampaignChannel,
  getChannelIcon,
} from "src/components/campaigns/manage/CampaignCardUtilities";
import "./RecentCampaigns.scss";

const RecentCampaigns = ({ recentCampaigns, modelLoading }) => {
  const capitalizeFirstLetter = (val) => {
    if (val === "email_blast") {
      return "Email Blast";
    } else if (val === "drip") {
      return "Over Time";
    } else if (val === "sms_blast") {
      return "SMS Blast";
    } else {
      return stringConvertor(val);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="row recent-popover-header">
        <span className="col-sm-3 row-header">Campaign</span>
        <span className="col-sm-3 row-header">Channel</span>
        <span className="col-sm-3 row-header">Campaign Type</span>
        <span className="col-sm-3 row-header">Mailing/Schedule Date</span>
      </div>
      <BlockUi
        blocking={modelLoading}
        loader={
          <div className="data-loading-text mb-2">
            Your data is being generated...
          </div>
        }
      >
        {recentCampaigns.length > 0
          ? recentCampaigns.map((item) => {
              return (
                <div
                  style={{ marginBottom: 5, marginTop: 5 }}
                  key={item?.communicationCampaign?.name}
                  className="row"
                >
                  <span className="col-sm-3 campaign-name sub-rows">
                    {item?.communicationCampaign?.name}
                  </span>
                  <span className="col-sm-3 sub-rows row-text">
                    <img
                      src={getChannelIcon(item?.communicationCampaign?.channel)}
                      alt={`${getCampaignChannel(
                        item?.communicationCampaign?.channel,
                      )}`}
                    />
                  </span>
                  <span className="col-sm-3 sub-rows row-text">
                    {capitalizeFirstLetter(
                      item?.communicationCampaign?.category,
                    )}
                  </span>
                  <span className="col-sm-3 sub-rows row-text">
                    {dateFormatter(
                      item?.mailingDate,
                    )}
                  </span>
                </div>
              );
            })
          : !modelLoading && (
              <div className="text-center mt-2">No result found</div>
            )}
      </BlockUi>
    </div>
  );
};

export default RecentCampaigns;
