import React, { useEffect, useState, useContext } from "react";
import "src/components/settings/widgets/review_widgets/StaticButtonsBody.scss";
import LightBlueCard from "src/components/settings/widgets/review_widgets/LightBlueCard.jsx";
import { copyAppointmentButtonV2 } from "src/utils/CopyToClipboardUtil.js";
import RequestAnAppointment from "src/components/settings/widgets/review_widgets/RequestAnAppointment.jsx";
import { useApolloClient } from "@apollo/react-hooks";
import { QUERY_APPOINTMENT_URL } from "src/graphql/appointments/queries";
import SettingsContext from "src/shared/context/SettingsContext";
import { NO_CACHE } from "src/shared/Constants";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const AppointmentWidgetBody = ({ handleToast, widgetSettings }) => {
  const companyId = useGetCompanyIdFromUrl();
  const client = useApolloClient();
  const settingsContext = useContext(SettingsContext);
  const [appointmentLink, setAppointmentLink] = useState("");
  const [copied, setCopied] = React.useState(false);

  // methods
  /**
   * Fetch the appointment link
   */
  const fetchAppointmentLink = () => {
    client
      .query({
        query: QUERY_APPOINTMENT_URL,
        variables: {
          companyId: companyId,
        },
        fetchPolicy: NO_CACHE,
      })
      .then((res) => {
        if (res.data && res.data.appointment) {
          setAppointmentLink(
            settingsContext.showAppointment
              ? `${res.data.appointment.url}?s=widget`
              : res.data.appointment.url,
          );
        }
      })
      .catch((err) => {
        // do nothing
      });
  };

  useEffect(() => {
    fetchAppointmentLink();
  }, []);

  const handleDynamicButtonClick = (callback) => {
    handleToast();
    callback({
      companyId,
      abbreviatedName: widgetSettings.data.company.abbreviatedName,
      companyName: widgetSettings.data.company.companyName,
      appointmentLink: appointmentLink,
    });
    setCopiedTemporarily();
  };

  const setCopiedTemporarily = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  // render
  return (
    <div>
      <LightBlueCard />
      <div
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        className="buttons-container"
      >
        <div
          style={{ marginBottom: "0px", height: "158px" }}
          className="button-outer-container"
        >
          <div className="button-container-appointments">
            <RequestAnAppointment />
          </div>
          <div
            onClick={() => handleDynamicButtonClick(copyAppointmentButtonV2)}
            className="copy-code"
          >
            {copied ? "Copied" : "Copy Code"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentWidgetBody;
