import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFoundPage from "src/shared/errorpages/NotFoundPage";
import TwitterConnectionSettings from "src/components/settings/reviews/tabs/TwitterConnectSettings";
import FacebookConnectionSettings from "src/components/settings/reviews/tabs/FacebookConnectSettings";

const Routes = ({ routes }) => {
  return (
    // <BrowserRouter>
    <Switch>
      {/* Authentication routes */}
      <Route
        path="/auth/_cb/twitter"
        render={() => <TwitterConnectionSettings />}
      />
      <Route
        path="/auth/_cb/facebook"
        render={() => <FacebookConnectionSettings />}
      />
      {routes.map((route) => (
        <Route key={route.id} {...route} />
      ))}
      {/* <Route render={() => <NotFoundPage />} /> */}
    </Switch>
    // </BrowserRouter>
  );
};

export default Routes;
