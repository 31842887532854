import { gql } from "apollo-boost";

const CREATE_EMAIL_INVITATION = gql`
  mutation createEmailInvitation(
    $contactId: ID
    $companyId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phoneMobile: String
    $phoneDaytime: String
    $phoneDaytimeExt: String
  ) {
    createEmailInvitation(
      contactId: $contactId
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneMobile: $phoneMobile
      phoneDaytime: $phoneDaytime
      phoneDaytimeExt: $phoneDaytimeExt
    ) {
      contactId
      status
      emailKey
      attempts
      maxAttempts
    }
  }
`;

const CREATE_CUSTOMER_CALL = gql`
  mutation createCustomerCall(
    $contactId: ID
    $companyId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phoneMobile: String
    $phoneDaytime: String
    $phoneDaytimeExt: String
  ) {
    createCustomerCall(
      contactId: $contactId
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneMobile: $phoneMobile
      phoneDaytime: $phoneDaytime
      phoneDaytimeExt: $phoneDaytimeExt
    ) {
      companyId
      contactId
      customerCallCreditId
      adminUserId
      status
      attempts
      notes
      manuallyQueued
    }
  }
`;

export const PURCHASE_CREDITS_MUTATION = gql`
  mutation purchaseCustomerCallCredits($creditCount: Int!) {
    purchaseCustomerCallCredits(creditCount: $creditCount)
  }
`;

export { CREATE_EMAIL_INVITATION, CREATE_CUSTOMER_CALL };
