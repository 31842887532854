import React from "react";
import LightBlueCard from "src/components/settings/widgets/review_widgets/LightBlueCard.jsx";
import "src/components/settings/widgets/review_widgets/StaticButtonsBody.scss";
import {
  copyStaticButtonOne,
  copyStaticButtonTwo,
  copyStaticButtonThree,
  copyStaticButtonFour,
} from "src/utils/CopyToClipboardUtil";
import CustomerReviewsCircle from "src/assets/images/widgets/read_customer_circle.png";
import CustomerReviewsSquare from "src/assets/images/widgets/read_customer_widget.png";
import ClientReviewsSquare from "src/assets/images/widgets/read_client_widget.png";
import ClientReviewsCircle from "src/assets/images/widgets/read_client_circle.png";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const StaticButtonsBody = ({ handleToast, widgetSettings }) => {
  const companyId = useGetCompanyIdFromUrl();
  const [copiedOne, setCopiedOne] = React.useState(false);
  const [copiedTwo, setCopiedTwo] = React.useState(false);
  const [copiedThree, setCopiedThree] = React.useState(false);
  const [copiedFour, setCopiedFour] = React.useState(false);

  const handleStaticButtonClick = (callback, flag) => {
    handleToast();
    callback({
      companyId,
      abbreviatedName: widgetSettings.data.company.abbreviatedName,
      companyName: widgetSettings.data.company.companyName,
    });
    switch (flag) {
      case "one":
        setCopiedOne(true);
        setTimeout(() => setCopiedOne(false), 3000);
        break;
      case "two":
        setCopiedTwo(true);
        setTimeout(() => setCopiedTwo(false), 3000);
        break;
      case "three":
        setCopiedThree(true);
        setTimeout(() => setCopiedThree(false), 3000);
        break;
      case "four":
        setCopiedFour(true);
        setTimeout(() => setCopiedFour(false), 3000);
        break;
    }
  };

  const handleCompanyName = () => {
    if (widgetSettings.data && !widgetSettings.loading) {
      return widgetSettings.data.company.companyName;
    }

    return "";
  };

  return (
    <div>
      <LightBlueCard />
      <div style={{ marginBottom: "-31px" }} className="buttons-container">
        <div className="button-outer-container">
          <div className="button-container">
            <img
              className="widget-img"
              alt={`${handleCompanyName()}`}
              src={CustomerReviewsCircle}
              style={{ border: "none" }}
            />
          </div>
          <div
            onClick={() => handleStaticButtonClick(copyStaticButtonOne, "one")}
            className="copy-code"
          >
            {copiedOne ? "Copied" : "Copy Code"}
          </div>
        </div>

        <div className="button-outer-container">
          <div className="button-container">
            <img
              className="widget-img"
              alt={`${handleCompanyName()}`}
              src={ClientReviewsCircle}
              style={{ border: "none" }}
            />
          </div>
          <div
            onClick={() => handleStaticButtonClick(copyStaticButtonTwo, "two")}
            className="copy-code"
          >
            {copiedTwo ? "Copied" : "Copy Code"}
          </div>
        </div>

        <div className="button-outer-container">
          <div className="button-container">
            <img
              className="widget-img"
              alt={`${handleCompanyName()}`}
              src={CustomerReviewsSquare}
              style={{ border: "none" }}
            />
          </div>
          <div
            onClick={() =>
              handleStaticButtonClick(copyStaticButtonThree, "three")
            }
            className="copy-code"
          >
            {copiedThree ? "Copied" : "Copy Code"}
          </div>
        </div>

        <div className="button-outer-container">
          <div className="button-container">
            <img
              className="widget-img"
              alt={`${handleCompanyName()}`}
              src={ClientReviewsSquare}
              style={{ border: "none" }}
            />
          </div>
          <div
            onClick={() =>
              handleStaticButtonClick(copyStaticButtonFour, "four")
            }
            className="copy-code"
          >
            {copiedFour ? "Copied" : "Copy Code"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticButtonsBody;
