import channelIconEmail from "src/assets/images/common/email.svg";
import channelIconMessage from "src/assets/images/common/message.svg";
import channelIconPostcard from "src/assets/images/common/postcard.svg";
import channelIconPostcardFollowup from "src/assets/images/common/followup.svg";
import channelIconError from "src/assets/images/alerts/error.svg";
import growMyBusiness from "src/assets/images/prospect_marketing/journey/grow-my-business.svg";
import targetNeighborhood from "src/assets/images/prospect_marketing/journey/target-neighborhood.svg";
import uploadContact from "src/assets/images/prospect_marketing/journey/upload-contact.svg";
import { formatDate, stringConvertor, dateFormatter } from "src/shared/Utils";
import { DATE_FORMATS } from "src/shared/Constants";
import * as config from "src/shared/Config";
import { store } from "src/redux/store";
import {
  REPEAT_INTERVAL_OPTIONS_POSTCARD,
  REPEAT_INTERVAL_OPTIONS_EMAIL,
} from "src/shared/Constants";

export const CAMPAIGN_STATUS = {
  ACTIVE: "active",
  SCHEDULED: "scheduled",
  APPROVED: "approved",
  INACTIVE: "inactive",
  DRAFT: "draft",
  PAUSED: "paused",
  SCHEDULED_PROCESS: "scheduled_in_progress",
  DONE: "done",
};

export const getBadgeType = (status = "") => {
  const cleanStatus = status.toLowerCase();
  switch (cleanStatus) {
    case CAMPAIGN_STATUS.SCHEDULED:
      return "info";
    case CAMPAIGN_STATUS.SCHEDULED_PROCESS:
      return "info";
    case CAMPAIGN_STATUS.APPROVED:
      return "info";
    case CAMPAIGN_STATUS.ACTIVE:
      return "success";
    case CAMPAIGN_STATUS.INACTIVE:
      return "default";
    case CAMPAIGN_STATUS.DRAFT:
      return "warning";
    default:
      return "default";
  }
};

export const getCampaignChannel = (channel = "") => {
  const cleanChannel = channel.toLowerCase();

  switch (cleanChannel) {
    case "text_message":
      return "Text";
    case CHANNEL.POSTCARD_FOLLOWUP:
      return "Postcard Followup";
    default:
      return stringConvertor(cleanChannel);
  }
};

export const getCampaignType = (type = "") => {
  const cleanType = type.toLowerCase();

  switch (cleanType) {
    case "sms_blast":
      return "SMS Blast";
    case "sms_drip":
      return "SMS Drip";
    case "drip":
      return "Over Time";
    default:
      return stringConvertor(type);
  }
};

export const CHANNEL = {
  EMAIL: "email",
  POSTCARD: "postcard",
  TEXT: "text_message",
  POSTCARD_FOLLOWUP: "postcard_followup",
};

export const getChannelIcon = (channel = "") => {
  const cleanChannel = channel.toLowerCase();

  switch (cleanChannel) {
    case CHANNEL.EMAIL:
      return channelIconEmail;
    case CHANNEL.POSTCARD:
      return channelIconPostcard;
    case CHANNEL.TEXT:
      return channelIconMessage;
    case CHANNEL.POSTCARD_FOLLOWUP:
      return channelIconPostcardFollowup;
    default:
      return channelIconError;
  }
};

export const getProspectTypelIcon = (channel = "") => {
  const cleanChannel = channel?.toLowerCase();

  switch (cleanChannel) {
    case "filters":
      return growMyBusiness;
    case "neighbourhood":
      return targetNeighborhood;
    case "csv_upload":
      return uploadContact;

    default:
      return channelIconError;
  }
};

export const goToEdit = ({ id, channel = "", history }) => {
  const lowerCaseChannel = (channel || "").toLowerCase();
  let path;
  const {
    userInfo: { companyId },
  } = store.getState()["user"];

  if (lowerCaseChannel === CHANNEL.EMAIL) {
    path = `/companies/${companyId}/email-campaigns/${id}?step=3`;
  } else if (lowerCaseChannel === CHANNEL.TEXT) {
    path = `/companies/${companyId}/text-campaigns/${id}?step=3`;
  } else {
    path = `/companies/${companyId}/postcard-campaigns/${id}?step=4`;
  }
  history.push(path);
  return;
};

export const getUpcomingCampaignDate = ({
  category,
  channel,
  createdAt,
  mailingDate,
  resumeDate,
  scheduledAt,
  status,
  upcomingMailingDate,
  updatedAt,
}) => {
  const cleanChannel = channel.toLowerCase();
  if (category === "branding" && status === "paused") {
    return {
      label: "Resume on",
      dateStr: resumeDate ? formatDate(resumeDate, "lll") : "N/A",
    };
  } else if (category === "branding" || category === "drip") {
    return {
      label: "Mailing",
      dateStr: mailingDate ? formatDate(mailingDate, "lll") : "N/A",
    };
  } else if (cleanChannel === CHANNEL.POSTCARD) {
    return {
      label: "Mailing",
      dateStr: upcomingMailingDate
        ? formatDate(upcomingMailingDate, "ll")
        : "N/A",
    };
  } else if (
    cleanChannel === CHANNEL.EMAIL ||
    cleanChannel === CHANNEL.TEXT ||
    category === "blast"
  ) {
    return {
      label: "Scheduled on",
      dateStr: scheduledAt ? formatDate(scheduledAt, "lll") : "N/A",
    };
  } else {
    return {
      label: updatedAt > createdAt ? "Modified on" : "Created on",
      dateStr:
        updatedAt > createdAt
          ? formatDate(updatedAt, "lll")
          : formatDate(createdAt, "lll"),
    };
  }
};

export const getCampaignStatusMessage = (status, frequency) => {
  switch (status) {
    case "draft":
      return "This campaign is in the draft state and not approved";
    case "scheduled":
      return "This campaign is approved and scheduled to go on the selected date";
    case "active":
      if (frequency === "Once") {
        return "This campaign is finished and no longer sending but the results are currently being tracked and collected";
      } else {
        return "This campaign has atleast one iteration sent with future iterations to be sent";
      }
    case "done":
      return "The tracking of this campaign is completed";
    case "inactive":
      return "This campaign had atleast one iteration sent and the future iterations have been unapproved";
    case "paused":
      return "This campaign had atleast one iteration sent and the future iterations have been paused";
  }
};

export const flagColor = "#f47821";

export const getFrequencyLabelByValue = (value) => {
  const data = REPEAT_INTERVAL_OPTIONS_POSTCARD.find(
    (val) => val.value === value,
  );
  return data?.label;
};

export const getFrequencyLabelByUnit = (unit) => {
  const data = REPEAT_INTERVAL_OPTIONS_EMAIL.find((val) => val.unit === unit);
  return data?.label;
};
export const getDateDetails = ({
  status,
  channel,
  category,
  frequency,
  approved,
  createdAt,
  updatedAt,
  resumeDate,
  unapprovedAt,
  approvedAt,
}) => {
  let date;
  let campaignDateStatus = "";
  switch (status) {
    case CAMPAIGN_STATUS.DRAFT:
      date = dateFormatter(createdAt, DATE_FORMATS.lll);
      campaignDateStatus = "Created on";
      break;
    case CAMPAIGN_STATUS.SCHEDULED:
      date = dateFormatter(
        approvedAt ? approvedAt : createdAt,
        DATE_FORMATS.lll,
      );
      campaignDateStatus = "Approved on";
      break;
    case CAMPAIGN_STATUS.ACTIVE:
      if (approvedAt || unapprovedAt) {
        if (
          (channel === "postcard" || category === "email_blast") &&
          frequency !== "Once"
        ) {
          date = approved
            ? dateFormatter(approvedAt, DATE_FORMATS.lll)
            : dateFormatter(unapprovedAt, DATE_FORMATS.lll);
          campaignDateStatus = approved ? "Approved on" : "Unapproved on";
        } else {
          date = dateFormatter(approvedAt, DATE_FORMATS.lll);
          campaignDateStatus = "Approved on";
        }
      } else {
        date = dateFormatter(createdAt, DATE_FORMATS.lll);
        campaignDateStatus = "Created on";
      }
      break;
    case CAMPAIGN_STATUS.INACTIVE:
      if (approvedAt || unapprovedAt) {
        if (
          (channel === "postcard" || category === "email_blast") &&
          frequency !== "Once"
        ) {
          date = approved
            ? dateFormatter(approvedAt, DATE_FORMATS.lll)
            : dateFormatter(unapprovedAt, DATE_FORMATS.lll);
          campaignDateStatus = approved ? "Approved on" : "Unapproved on";
        } else {
          date = dateFormatter(approvedAt, DATE_FORMATS.lll);
          campaignDateStatus = "Approved on";
        }
      } else {
        date = dateFormatter(createdAt, DATE_FORMATS.lll);
        campaignDateStatus = "Created on";
      }

      break;
    case CAMPAIGN_STATUS.PAUSED:
      date = dateFormatter(resumeDate, DATE_FORMATS.lll);
      campaignDateStatus = "Paused till";
      break;
    case CAMPAIGN_STATUS.DONE:
      if (approvedAt) {
        date = dateFormatter(approvedAt, DATE_FORMATS.lll);
        campaignDateStatus = "Approved on";
      } else {
        date = dateFormatter(createdAt, DATE_FORMATS.lll);
        campaignDateStatus = "Created on";
      }
      break;
    case CAMPAIGN_STATUS.SCHEDULED_PROCESS:
      if (approvedAt) {
        date = dateFormatter(approvedAt, DATE_FORMATS.lll);
        campaignDateStatus = "Approved on";
      } else {
        date = dateFormatter(createdAt, DATE_FORMATS.lll);
        campaignDateStatus = "Created on";
      }
      break;
    default:
      date = dateFormatter(createdAt, DATE_FORMATS.lll);
      campaignDateStatus = "Created on";
      break;
  }

  return {
    date: date,
    dateStr: campaignDateStatus,
  };
};
