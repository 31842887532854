import { gql } from "apollo-boost";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String, $password: String, $type: String) {
    login(email: $email, password: $password, type: $type) {
      authenticated
      companyId
      companyUserId
      firstName
      lastName
      token
      errors
      enterpriseId
      enterpriseUserId
      expired
      partnerAccountId
      partnerUserId
    }
  }
`;
export const LOGIN_AS_GHOST_MUTATION = gql`
  mutation GhostLogin($token: String!, $type: String!, $id: ID!) {
    ghostLogin(token: $token, type: $type, id: $id) {
      authenticated
      companyId
      companyUserId
      enterpriseId
      enterpriseUserId
      errors
      lastName
      firstName
      success
      token
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!, $type: String) {
    resetPassword(email: $email, type: $type) {
      emailNotFound
      success
    }
  }
`;
export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $token: String!
    $type: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      token: $token
      type: $type
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
      errors
    }
  }
`;

export const PARTNER_LOGIN_MUTATION = gql`
  mutation partnerLogin($authToken: String!) {
    partnerIntegrationLogin(
      partnerName: "service-fusion"
      authToken: $authToken
    ) {
      token
      companyId
      errors
      authenticated
    }
  }
`;

export const LOGIN_AS_PARTNER_GHOST_MUTATION = gql`
  mutation PartnerGhostLogin($token: String!) {
    partnerGhostLogin(token: $token) {
      authenticated
      companyId
      companyUserId
      enterpriseId
      enterpriseUserId
      errors
      lastName
      firstName
      success
      token
    }
  }
`;
