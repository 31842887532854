import React, { useCallback, useEffect, useState } from "react";
import Table, { SortableTableHeader } from "src/shared/styleguide/table/Table";
import BlockUi from "react-block-ui";
import Card from "src/shared/styleguide/card/Card";
import { useApolloClient, useQuery } from "react-apollo";
import NumberUtil from "src/utils/NumberUtil";
import { NO_CACHE } from "src/shared/Constants";
import debounce from "lodash.debounce";
import downloadIcon from "src/assets/images/common/download.svg";
import {
  ENTERPRISE_POSTCARD_RESULTS,
  ENTERPRISE_OVERALL_RESULT,
} from "src/graphql/enterprise/queries";
import MiniCard from "src/shared/styleguide/card/MiniCard";
import filterIcon from "src/assets/images/enterprise/filter.svg";
import postcardEmailIcon from "src/assets/images/enterprise/postcard_email.svg";
import textIcon from "src/assets/images/enterprise/text.svg";
import emailIcon from "src/assets/images/enterprise/email.svg";
import averageResponseRateIcon from "src/assets/images/enterprise/average_response_rate.svg";
import overallCustomerIcon from "src/assets/images/enterprise/overall_unique_customer_reached.svg";
import informationIcon from "src/assets/images/alerts/information_gray.svg";
import ReadMoreNotes from "src/shared/styleguide/common/ReadMoreNotes";
import format from "date-fns/format";
import { Row, Col } from "react-bootstrap";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "src/shared/styleguide/button/Button";
import SearchComponent from "src/components/search_component/SearchComponent";
import { QUERY_CAMPAIGN_METADATA } from "src/graphql/campaigns/manage/queries";
import FilterModal from "./FilterModal";
import CsvUtil from "src/utils/CsvUtil";
import NoRecordFound from "./NoRecordFound";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import { rearrangedArrayData } from "src/shared/Utils";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import "./KeyMetrics.scss";

export const HEADER_ITEMS = [
  {
    columnLabel: "ACCOUNT NAME",
    columnValue: "company_name",
    sortable: true,
    width: 350,
  },
  {
    columnLabel: "SUM OF TOTAL CUSTOMERS REACHED",
    columnValue: "customers_reached",
    sortable: false,
  },
  {
    columnLabel: "AVERAGE OF RESPONSE RATE",
    columnValue: "response_rate",
    sortable: true,
  },
  {
    columnLabel: "TRANSACTIONS",
    columnValue: "total_transactions",
    sortable: true,
  },
  {
    columnLabel: "SUM OF IMPACTED REVENUE",
    columnValue: "revenue_generated",
    sortable: true,
  },
];

const KeyMetrics = () => {
  useDocumentTitle("Key Metrics");
  const perPage = 10;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataProvider, setDataProvider] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState();
  const [showFilterModal, setShowFilterModal] = useState();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    from: null,
    to: null,
  });
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: {
      value: "",
    },
    sortOrder: {
      value: "",
    },
  });

  const [campaignTypeValue, setCampaignTypeValue] = useState("all");
  const campaignTypes = [
    { name: "All", value: "all" },
    { name: "Branding", value: "branding" },
    { name: "Over Time", value: "drip" },
    { name: "Blast", value: "blast" },
  ];

  const { data } = useQuery(ENTERPRISE_OVERALL_RESULT);

  const getCampaignInfo = (companyId) => {
    setCampaignInfo();
    setInfoLoading(true);
    client
      .query({
        query: QUERY_CAMPAIGN_METADATA,
        fetchPolicy: NO_CACHE,
        variables: { companyId, channel: "postcard" },
      })
      .then(({ data: { campaignMetadata } }) => {
        setCampaignInfo(campaignMetadata);
        setInfoLoading(false);
      });
  };

  const getPostcardCampaignRow = (data) => {
    const {
      companyName,
      revenueGenerated,
      responseRate,
      totalTransactions,
      companyId,
      customersReached,
    } = data;
    return [
      {
        node: (
          <div className="d-flex align-items-center customer-name">
            <span className="text-primary">{companyName}</span>
            <ReadMoreNotes
              icon={
                <img
                  className="ml-1"
                  src={informationIcon}
                  onClick={() => getCampaignInfo(companyId)}
                />
              }
              title="Your Status"
              placement="right"
              threshold={400}
              minWidth={280}
              rootClose={true}
              className="key-metrics-popover"
              content={
                <div className="d-flex flex-column">
                  <span>
                    Number of Active Campaign :{" "}
                    <b>{campaignInfo?.status_counts?.active || 0}</b>
                  </span>
                  <span>
                    Number Of Inactive Campaign :{" "}
                    <b>{campaignInfo?.status_counts?.inactive || 0}</b>
                  </span>
                  <span>
                    Number Of Paused Campaign :{" "}
                    <b>{campaignInfo?.status_counts?.paused || 0}</b>
                  </span>
                  <span>
                    Number Of Done Campaign :{" "}
                    <b>{campaignInfo?.status_counts?.done || 0}</b>
                  </span>
                  <span>
                    Number Of Campaign : <b>{campaignInfo?.total_count || 0}</b>
                  </span>
                  <span>
                    Number Of Upcoming Campaign :{" "}
                    <b>{campaignInfo?.status_counts?.scheduled || 0}</b>
                  </span>
                  <span>
                    Unapproved Campaigns :{" "}
                    <b>{campaignInfo?.unapproved_count || 0}</b>
                  </span>
                </div>
              }
            />
          </div>
        ),
      },
      {
        node: NumberUtil.toHumanReadableNumber(customersReached),
      },
      {
        node: `${responseRate ? `${responseRate?.toFixed(2)}%` : 0}`,
      },
      {
        node: NumberUtil.toHumanReadableNumber(totalTransactions),
      },
      {
        node: <span>{NumberUtil.toUSD(revenueGenerated)}</span>,
      },
    ];
  };

  const getPostcardsData = debounce((props = {}) => {
    setLoading(true);
    const queryVariables = {
      rpp: perPage,
      sortBy: props.sortBy ? props.sortBy.value : undefined,
      sortOrder: props.sortOrder ? props.sortOrder.value : undefined,
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      category: props.campaignTypeValue
        ? [props.campaignTypeValue]
        : campaignTypeValue === "all"
          ? []
          : [campaignTypeValue],
      fromDate: dateFilter.from ? format(dateFilter.from, "yyyy/MM/dd") : "",
      toDate: dateFilter.to ? format(dateFilter.to, "yyyy/MM/dd") : "",
    };

    if (props.searchTerm || filters.searchTerm) {
      queryVariables["searchTerm"] =
        filters.searchTerm || props.searchTerm.toLowerCase() || "";
    }

    client
      .query({
        query: ENTERPRISE_POSTCARD_RESULTS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.enterprisePostcardResults) {
          const brandingBudget = res.data.enterprisePostcardResults.data;
          const totalRecords = res.data.enterprisePostcardResults.totalCount;
          setDataProvider(brandingBudget);

          setPageCount(Math.ceil(totalRecords / perPage));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  const onSort = (columnValue, sortDirection) => {
    setCurrentPage(0);
    setFilters({
      ...filters,
      sortBy: { value: columnValue },
      sortOrder: { value: sortDirection },
    });
  };

  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
    getPostcardsData({
      page: selected,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  useEffect(() => {
    setCurrentPage(0);
    getPostcardsData({
      page: 0,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  }, [filters.sortBy, campaignTypeValue]);

  const getSearchResults = (params) => {
    setCurrentPage(0);
    getPostcardsData(params);
  };
  const debounced = useCallback(debounce(getSearchResults, 500), []);
  const searchRecord = (searchTerm) => {
    debounced({
      page: 0,
      searchTerm,
      campaignTypeValue: campaignTypeValue === "all" ? "" : campaignTypeValue,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const applyDateFilter = () => {
    setIsFilterApplied(true);
    setCurrentPage(0);
    setShowFilterModal(false);
    getPostcardsData({
      page: 0,
      campaignTypeValue: campaignTypeValue === "all" ? "" : campaignTypeValue,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const resetFilter = () => {
    setIsFilterApplied(false);
    setDateFilter({
      from: null,
      to: null,
    });
    setFilters({
      searchTerm: "",
      sortBy: {
        value: "",
      },
      sortOrder: {
        value: "",
      },
    });
    // close the search input box
    // close the search input box
    const searchClose = document.querySelector(".search-close-icon")
    if (searchClose) {
      searchClose.click()
    }
  };

  const downloadCSV = () => {
    const queryVariables = {
      rpp: 0,
      sortBy: filters.sortBy ? filters.sortBy.value : undefined,
      sortOrder: filters.sortOrder ? filters.sortOrder.value : undefined,
      category: campaignTypeValue === "all" ? [] : [campaignTypeValue],
      fromDate: dateFilter.from ? format(dateFilter.from, "yyyy/MM/dd") : "",
      toDate: dateFilter.to ? format(dateFilter.to, "yyyy/MM/dd") : "",
    };

    if (filters.searchTerm) {
      queryVariables["searchTerm"] = filters.searchTerm.toLowerCase() || "";
    }
    client
      .query({
        query: ENTERPRISE_POSTCARD_RESULTS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then(({ data: { enterprisePostcardResults } }) => {
        const order = [
          "companyName",
          "customersReached",
          "responseRate",
          "totalTransactions",
          "revenueGenerated",
        ];

        const labels = [
          "ACCOUNT NAME",
          "SUM OF TOTAL CUSTOMERS REACHED",
          "AVERAGE OF RESPONSE RATE (%)",
          "TRANSACTIONS",
          "SUM OF IMPACTED REVENUE ($)",
        ];

        const rearrangedArray = rearrangedArrayData(
          enterprisePostcardResults?.data,
          order,
        );

        CsvUtil.downloadCSVFile(rearrangedArray, labels, "Key Metrics");
      });
  };

  return (
    <BlockUi blocking={infoLoading}>
      <div className="page-title">Key Metrics</div>

      <Row className="mt-4 mb-5 key-metrics">
        <Col sm={4}>
          <MiniCard
            height="170px"
            title="Overall Communication Sent"
            popupInfoMsg="Overall Communication Sent"
            cardBodyStyle={{ padding: "0 2rem" }}
          >
            <Row>
              <Col>
                <div className="d-flex flex-column text-center align-items-center">
                  <img src={postcardEmailIcon} className="card-icon" />
                  <span className="card-item-title">
                    {NumberUtil.toHumanReadableNumber(
                      data?.enterpriseOverallCampaignResults
                        ?.communications_sent?.postcard || 0,
                    )}
                  </span>
                  <span className="card-sub-title">
                    Postcard + Follow up Email
                  </span>
                </div>
              </Col>
              <Col>
                <div className="d-flex flex-column text-center align-items-center">
                  <img src={textIcon} className="card-icon" />
                  <span className="card-item-title">
                    {NumberUtil.toHumanReadableNumber(
                      data?.enterpriseOverallCampaignResults
                        ?.communications_sent?.text || 0,
                    )}
                  </span>
                  <span className="card-sub-title">Text</span>
                </div>
              </Col>
              <Col>
                <div className="d-flex flex-column text-center align-items-center">
                  <img src={emailIcon} className="card-icon" />
                  <span className="card-item-title">
                    {data?.enterpriseOverallCampaignResults?.communications_sent
                      ?.email || 0}
                  </span>
                  <span className="card-sub-title">Email</span>
                </div>
              </Col>
            </Row>
          </MiniCard>
        </Col>
        <Col sm={4}>
          <MiniCard
            height="170px"
            title="Overall Average Response Rate (Conversion)"
            popupInfoMsg="Overall Average Response Rate (Conversion)"
            cardBodyStyle={{ padding: "0 1rem" }}
          >
            <Row>
              <Col>
                <div className="d-flex flex-column text-center align-items-center">
                  <img src={averageResponseRateIcon} className="card-icon" />
                  <span className="card-item-title">
                    {data
                      ? `${data?.enterpriseOverallCampaignResults?.average_response_rate?.toFixed(
                        2,
                      )} %`
                      : "-"}
                  </span>
                  <span className="card-sub-title">Average Response Rate</span>
                </div>
              </Col>
            </Row>
          </MiniCard>
        </Col>
        <Col sm={4}>
          <MiniCard
            height="170px"
            title="Overall Unique Customers Reached (Across All Locations)"
            popupInfoMsg="Overall Unique Customers Reached (Across All Locations)"
            cardBodyStyle={{ padding: "0 1rem" }}
          >
            <Row>
              <Col>
                <div className="d-flex flex-column text-center align-items-center">
                  <img src={overallCustomerIcon} className="card-icon" />
                  <span className="card-item-title">
                    {NumberUtil.toK(
                      data?.enterpriseOverallCampaignResults
                        ?.unique_customers_count,
                      1,
                      true,
                    )}
                  </span>
                </div>
              </Col>
            </Row>
          </MiniCard>
        </Col>
      </Row>

      <Card
        title={
          <Row>
            <Col className="table-heading">
              Overall Account Performance for all Postcard Campaigns{" "}
              <PopupInfo
                placement="top"
                message="Overall Account Performance for all Postcard Campaigns"
                iconSize={16}
              />
              {isFilterApplied && (dateFilter.from || dateFilter.to) ? (
                <span
                  className="reset-filter text-primary"
                  onClick={resetFilter}
                >
                  Reset Filters
                </span>
              ) : null}
            </Col>
            <Col className="d-flex justify-content-end align-items-center key-metrics-filter">
              <ToggleButtonGroup
                type="radio"
                name="campaignTypeValue"
                defaultValue="all"
                className="mr-2"
              >
                {campaignTypes.map((radio, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      id={`radio-${index}`}
                      type="radio"
                      variant={
                        radio.value == campaignTypeValue
                          ? "primary"
                          : "secondary"
                      }
                      name="radio"
                      value={radio.value}
                      checked={campaignTypeValue === radio.value}
                      onChange={(e) =>
                        setCampaignTypeValue(e.currentTarget.value)
                      }
                      className="cursor-pointer"
                    >
                      {radio.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
              <SearchComponent
                handleOnChange={(value) => {
                  setFilters({ ...filters, searchTerm: value });
                  searchRecord(value);
                }}
              />
              <div className="filter-button">
                {isFilterApplied && <span className="filter-badge" />}
                <img
                  width="15"
                  src={filterIcon}
                  className="mr-1 cursor-pointer"
                />
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterModal(true);
                  }}
                  className="cursor-pointer"
                >
                  FILTER
                </span>
                {showFilterModal && (
                  <FilterModal
                    setDateFilter={setDateFilter}
                    closeModal={() => setShowFilterModal(false)}
                    dateFilter={dateFilter}
                    submit={applyDateFilter}
                  />
                )}
              </div>
              <img
                className="ml-2 cursor-pointer"
                onClick={downloadCSV}
                height="15"
                width="30"
                src={downloadIcon}
              />
            </Col>
          </Row>
        }
      >
        <BlockUi loader={<React.Fragment />} blocking={loading}>
          <Table
            className="customer-table mb-0"
            loading={loading}
            head={HEADER_ITEMS.map((values) => (
              <div style={{ minWidth: values?.width || "auto" }}>
                <SortableTableHeader
                  sortable={values.sortable}
                  columnLabel={values.columnLabel}
                  columnValue={values.columnValue}
                  onSort={onSort}
                  currentSortValue={filters.sortBy.value}
                  currentSortOrder={filters.sortOrder.value}
                />
              </div>
            ))}
            body={dataProvider.map((data) => getPostcardCampaignRow(data))}
            hasPagination={true}
            pageCount={pageCount}
            onPagination={onPagination}
            currentPage={currentPage}
            noData={<NoRecordFound />}
          />
        </BlockUi>
      </Card>
    </BlockUi>
  );
};

export default KeyMetrics;
