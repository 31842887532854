import React, { useContext } from "react";
import Form from "src/shared/styleguide/form/Form";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import EllipsisV from "src/styles/assets/ellipsis-v.svg";
import Table from "src/shared/styleguide/table/Table";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import {
  QUERY_FEATURE_FLAGS,
  QUERY_SMART_INVITES,
} from "src/graphql/settings/reviews/queries";
import {
  MUTATION_CREATE_SMART_INVITES,
  MUTATION_DELETE_REVIEW_DESTINATION,
} from "src/graphql/settings/reviews/mutations";
import { getCompanyFeatureFlags } from "src/shared/SessionHelper";
import SettingsCard from "../SettingsCard";
import SmartInviteAddSite from "./SmartInviteAddSite";
import swal from "sweetalert";
import * as Yup from "yup";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import { addToast } from "src/utils/ToastUtil";
import SettingsContext from "src/shared/context/SettingsContext";
import { COMPANY_USER, ffConstants } from "src/shared/Constants";
import { useSelector } from "react-redux";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";

const SmartInviteSettings = () => {
  // variables
  const perPage = 10;
  const {
    userInfo: { companyUserId: showUserId },
  } = useSelector((state) => state.user);
  const showCompanyId = useGetCompanyIdFromUrl();

  // state
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [enabled, setEnabled] = React.useState(false);
  const [destinations, setDestinations] = React.useState([]);
  const [editDestination, setEditDestination] = React.useState();
  const [tableBody, setTableBody] = React.useState([[]]);
  const [loading, setLoading] = React.useState(true);
  const [fetchingDestinations, setFetchingDestinations] = React.useState(false);
  const [fetchingFeatureFlags, setFetchingFeatureFlags] = React.useState(false);
  const client = useApolloClient();
  const settingsContext = useContext(SettingsContext);

  // call apis
  const [updateEnabled] = useMutation(MUTATION_CREATE_SMART_INVITES);
  const [removeDestination] = useMutation(MUTATION_DELETE_REVIEW_DESTINATION);

  // methods
  const onPagination = ({ selected }) => {
    setCurrentPage(selected);
  };
  const fetchDestinations = (
    props = { updateLoader: true, fetchPolicy: undefined },
  ) => {
    if (props.updateLoader) {
      setFetchingDestinations(true);
    }

    client
      .query({
        query: QUERY_SMART_INVITES,
        fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
        variables: {
          companyId: showCompanyId,
        },
        fetchPolicy: props.fetchPolicy,
      })
      .then((res) => {
        if (res && res.data) {
          populateData(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (props.updateLoader) {
          setFetchingDestinations(false);
        }
      });
  };

  const fetchFeatureFlags = (
    props = { updateLoader: true, fetchPolicy: undefined },
  ) => {
    if (props.updateLoader) {
      setFetchingFeatureFlags(true);
    }

    client
      .query({
        query: QUERY_FEATURE_FLAGS,
        fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
        variables: {
          companyId: showCompanyId,
        },
        fetchPolicy: props.fetchPolicy,
      })
      .then((res) => {
        if (res && res.data) {
          populateData(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (props.updateLoader) {
          setFetchingFeatureFlags(false);
        }
      });
  };

  const populateData = (data) => {
    if (data && data.companyFeatureFlags) {
      const companyFeatureFlags = data.companyFeatureFlags;
      const SmartInviteWrite = getCompanyFeatureFlags(
        ffConstants.smartInvite,
        ffConstants.write,
        companyFeatureFlags,
      );
      setEnabled(SmartInviteWrite);
    }

    if (data && data.reviewDestinationsList) {
      const destinations = data.reviewDestinationsList.reviewDestinations;
      setDestinations(destinations);
      setPageCount(Math.ceil(destinations.length / perPage));
      setCurrentPage(0);
    }
  };
  const saveEnabled = (enabled) => {
    updateEnabled({
      variables: {
        companyId: showCompanyId,
        component: "SmartInvite",
        status: enabled,
        userId: showUserId,
        userType: COMPANY_USER,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Invitation settings updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Invitation settings not updated.",
        });
      });
  };
  const deleteDestination = (id) => {
    swal({
      title: "Are you sure?",
      className: "custom-email-template",
      text: "Do you really want to delete? This action cannot be undone.",
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Delete",
          value: "delete",
        },
        cancel: true,
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
    }).then((confirm) => {
      if (confirm === "delete") {
        removeDestination({
          variables: {
            companyId: showCompanyId,
            id,
          },
        })
          .then(() => {
            fetchDestinations({
              updateLoader: false,
              fetchPolicy: "network-only",
            });
          })
          .catch(() => {});
      }
    });
  };

  // effects
  React.useEffect(() => {
    fetchDestinations();
    fetchFeatureFlags();
  }, []);

  React.useEffect(() => {
    if (!fetchingFeatureFlags && !fetchingDestinations) {
      setLoading(false);
    }
  }, [fetchingFeatureFlags, fetchingDestinations]);

  React.useEffect(() => {
    setTableBody(
      destinations
        .slice(currentPage * perPage, (currentPage + 1) * perPage)
        .map((src) => [
          { node: src.displayName, props: { style: { minWidth: 120 } } },
          {
            node: (
              <div className="text-center">
                {Yup.string().url().isValidSync(src.url) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="12.182"
                    viewBox="0 0 16 12.182"
                    fill="#313541"
                  >
                    <g transform="translate(0 -53.55)">
                      <g transform="translate(0 53.55)">
                        <path
                          d="M5.091 63.186l-3.818-3.818L0 60.641l5.091 5.091L16 54.823l-1.273-1.273z"
                          transform="translate(0 -53.55)"
                        />
                      </g>
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="12"
                    viewBox="0 0 25 24.999"
                    fill="#313541"
                  >
                    <g transform="translate(0 -0.001)">
                      <path
                        d="M14.711,12.5,24.542,2.67A1.563,1.563,0,1,0,22.331.459L12.5,10.29,2.669.459A1.563,1.563,0,0,0,.458,2.67L10.29,12.5.458,22.332a1.563,1.563,0,1,0,2.211,2.21L12.5,14.711l9.831,9.831a1.563,1.563,0,1,0,2.211-2.211Z"
                        transform="translate(0)"
                      />
                    </g>
                  </svg>
                )}
              </div>
            ),
            props: {
              style: {
                minWidth: 140,
              },
            },
          },
          {
            node: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={src.url}
                title={src.url}
                className="sg-text-info truncate-overflow"
              >
                {src.url}
              </a>
            ),
            props: {
              style: {
                width: "72%",
              },
            },
          },
          {
            node: (
              <DropdownButton
                variant="link"
                drop="up"
                direction={false}
                title={<img src={EllipsisV} alt="Ellipsis" />}
              >
                <Dropdown.Item
                  href="#/edit"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    setEditDestination({
                      id: src.id,
                      url: src.url,
                      gmailOnly: src.metaData ? src.metaData.gmailOnly : false,
                      connectionStatus: src.metaData
                        ? src.metaData.connectionStatus === "connected"
                        : false,
                      accessToken: src.metaData
                        ? src.metaData.accessToken
                        : false,
                      refreshToken: src.metaData
                        ? src.metaData.refreshToken
                        : false,
                      displayName: src.displayName,
                    });
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/delete"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    deleteDestination(src.id);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            ),
            props: {
              style: {
                width: 40,
              },
            },
          },
        ]),
    );
  }, [destinations, currentPage]);

  // feature flag check
  if (!featureFlags.reviewWrite) {
    return null;
  }

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Smart Invite"
      settingsDescription="Enabling Smart Invite will prompt your customers to leave reviews at any or all of the additional review sites below. Click on “+ Add Site” button to add a custom link or pick from an existing integration option (like Google), to be added to your company’s review page."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35.1"
          height="36.6"
          viewBox="0 0 35.1 36.6"
        >
          <g transform="translate(-10.367 .3)">
            <g transform="translate(11.048 22.496)">
              <g>
                <path
                  d="M49.531 331.6L35.1 320.668a3.5 3.5 0 0 0-4.281 0L16.39 331.6a.75.75 0 0 0 .9 1.2l14.431-10.94a2.021 2.021 0 0 1 2.469 0l14.436 10.94a.75.75 0 1 0 .9-1.2z"
                  transform="translate(-16.092 -319.936)"
                />
              </g>
            </g>
            <g transform="translate(29.088 12.268)">
              <g>
                <path
                  d="M288.513 174.774a.749.749 0 0 0-1.05-.145l-14.513 10.983a.75.75 0 0 0 .9 1.2l14.513-10.983a.749.749 0 0 0 .15-1.055z"
                  transform="translate(-272.653 -174.476)"
                />
              </g>
            </g>
            <g transform="translate(10.734 12.266)">
              <g>
                <path
                  d="M27.333 185.586L12.82 174.6a.75.75 0 0 0-.9 1.2l14.512 10.983a.75.75 0 1 0 .9-1.2z"
                  transform="translate(-11.618 -174.451)"
                />
              </g>
            </g>
            <g transform="translate(10.667 9.499)">
              <g>
                <path
                  d="M44.358 137.325l-2.458-2.051a.75.75 0 0 0-.962 1.151l2.46 2.051a.748.748 0 0 1 .271.577v20.3a.752.752 0 0 1-.75.75h-30a.752.752 0 0 1-.75-.75v-20.3a.749.749 0 0 1 .27-.577l2.46-2.051a.75.75 0 1 0-.96-1.152l-2.463 2.052a2.252 2.252 0 0 0-.807 1.728v20.3a2.253 2.253 0 0 0 2.25 2.25h30a2.253 2.253 0 0 0 2.25-2.25v-20.3a2.246 2.246 0 0 0-.811-1.728z"
                  transform="translate(-10.667 -135.098)"
                />
              </g>
            </g>
            <g transform="translate(13.667)">
              <g>
                <path
                  d="M81.083 0h-27a.75.75 0 0 0-.75.75v14.382a.75.75 0 1 0 1.5 0V1.5h25.5v13.639a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-.75-.75z"
                  transform="translate(-53.333)"
                />
              </g>
            </g>
            <g transform="translate(27.167 6)">
              <g>
                <path
                  d="M246.083 85.333a.75.75 0 0 0-.75.75v9a.75.75 0 0 0 1.5 0v-9a.75.75 0 0 0-.75-.75z"
                  transform="translate(-245.333 -85.333)"
                />
              </g>
            </g>
            <g transform="translate(22.667 10.5)">
              <g>
                <path
                  d="M191.083 149.333h-9a.75.75 0 1 0 0 1.5h9a.75.75 0 1 0 0-1.5z"
                  transform="translate(-181.333 -149.333)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <div>
          {/* 1. Status */}
          <div className="d-flex lex-row mt-4 align-items-center">
            <div className="d-flex flex-row align-items-center">
              <span className="text-dark mr-2 mt-1">Status</span>
            </div>
            <Form.Group
              style={{ marginLeft: 48 }}
              className="mb-0"
              controlId="smartInvitesStatus"
            >
              <Form.Check
                custom
                checked={enabled}
                onChange={() => {
                  settingsContext.setSmartInviteEnabled(!enabled);
                  setEnabled(!enabled);
                  saveEnabled(!enabled);
                }}
                type="switch"
                label={<React.Fragment />}
                className="text-dark custom-switch-lg"
              />
            </Form.Group>
          </div>

          {enabled ? (
            <div className="mt-4">
              <div className="mb-4">
                <div className="float-right">
                  <SmartInviteAddSite
                    destinations={destinations}
                    fetchDestinations={fetchDestinations}
                    fetchingDestinations={fetchingDestinations}
                    setEditDestination={setEditDestination}
                    editDestination={editDestination}
                  />
                </div>
                <div className="h3 text-dark mb-0">
                  Add the site name and URL to your review page below.
                </div>
              </div>
              <Table
                head={[
                  "SOURCE",
                  <div className="text-center">VERIFIED?</div>,
                  "REVIEW SITE",
                  "",
                ]}
                body={tableBody}
                hasPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                onPagination={onPagination}
              />
            </div>
          ) : null}
        </div>
      }
    />
  );
};

export default SmartInviteSettings;
