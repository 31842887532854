import { gql } from "apollo-boost";

export const UPDATE_PARTNER_DETAILS = gql`
  mutation updatePartner(
    $partnerAccountName: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $country: String
    $phone: String
    $website: String
  ) {
    updatePartnerAccount(
      partnerAccountName: $partnerAccountName
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      country: $country
      phone: $phone
      website: $website
    ) {
      partnerAccountName
    }
  }
`;

export const UPDATE_PARTNER_USER = gql`
  mutation updatePartnerUser(
    $partnerUserId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $passwordConfirmation: String
  ) {
    updatePartnerUser(
      partnerUserId: $partnerUserId
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      companyUserId
      firstName
      lastName
      email
      isActive
      isOwner
      userDisplayName
    }
  }
`;
