import React, { useEffect, useState } from "react";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import logo from "src/img/header/logo.png";
import locations from "src/assets/images/enterprise/locations.svg";
import usersIcon from "src/assets/images/enterprise/users.svg";
import settingsIcon from "src/assets/images/enterprise/settings.svg";
import resultsIcon from "src/assets/images/header/review_results.svg";
import termsIcon from "src/assets/images/header/manage_reviews.svg";
import enterpriseIcon from "src/assets/images/enterprise/enterprise.svg";
import "src/components/enterprise/Sidebar.scss";

const Sidebar = ({ match }) => {
  const location = useLocation();
  const path = location.pathname;
  const matches =
    path.includes("edit") || path.includes("users") || path.includes("terms");
  const [openSettings, setOpenSettings] = useState(matches); // Toggle state for sub-menu

  useEffect(() => {
    if (!matches) {
      setOpenSettings(false);
    }
  }, [path]);
  return (
    <Nav defaultActiveKey="/overview" className="flex-column p-3">
      <Navbar.Brand className=" pt-1 pb-4" href="#">
        <img
          src={logo}
          className="logo-image"
          height="40px"
          alt="Customer Lobby"
        />
      </Navbar.Brand>
      <div className="menu-container">
        <NavLink to={`${match.url}/results`} activeClassName="active-link">
          <img src={resultsIcon} className="mr-2" />
          Results
        </NavLink>
        <NavLink to={`${match.url}/companies`} activeClassName="active-link">
          <img src={locations} className="mr-2" />
          Companies
        </NavLink>
        <Accordion>
          <Card className="border-0">
            <Card.Header className="p-0">
              <Nav.Link
                onClick={() => setOpenSettings(!openSettings)}
                aria-expanded={openSettings}
                className={`w-100 text-left d-flex justify-content-between align-items-center ${
                  matches ? "text-white" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <img src={settingsIcon} className="mr-2" />
                  Settings
                </div>
                <div
                  className={`chevron ${openSettings ? "collapsed" : ""}`}
                ></div>
              </Nav.Link>
            </Card.Header>
            <Accordion.Collapse
              eventKey="0"
              className="bg-dark-slate"
              in={openSettings}
            >
              <Card.Body className="p-0">
                <Nav className="flex-column">
                  <NavLink
                    to={`${match.url}/edit`}
                    className="  pl-5"
                    activeClassName="active-link"
                    isActive={(match, location) =>
                      location.pathname.includes("/edit") &&
                      !location.pathname.includes("/edit/")
                    }
                  >
                    <img src={enterpriseIcon} className="mr-2" />
                    Partner
                  </NavLink>
                  <NavLink
                    to={`${match.url}/users`}
                    className=" mt-2  pl-5"
                    activeClassName="active-link"
                  >
                    <img src={usersIcon} className="mr-2" />
                    Users
                  </NavLink>
                  <NavLink
                    to={`${match.url}/terms`}
                    className=" mt-2  pl-5"
                    activeClassName="active-link"
                  >
                    <img src={termsIcon} className="mr-2" />
                    Terms
                  </NavLink>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Nav>
  );
};

export default withRouter(Sidebar);
