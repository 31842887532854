import React, { useState } from "react";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  QUERY_SUGGESTION_TOPICS,
  QUERY_DEFAULT_SUGGESTION_TOPICS,
} from "src/graphql/settings/reviews/queries";
import {
  MUTATION_SUGGESTION_TOPICS,
  MUTATION_SET_SUGGESTION_TOPIC,
} from "src/graphql/settings/reviews/mutations";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import SettingsCard from "src/components/settings/reviews/SettingsCard";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import { addToast } from "src/utils/ToastUtil";

const PrivateFeedbackSettings = () => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [enabled, setEnabled] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const formik = useFormik({
    initialValues: {
      question1: null,
      question2: null,
      question3: null,
    },
    validationSchema: Yup.object().shape({
      question1: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
      question2: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
      question3: Yup.object()
        .typeError("Invalid option")
        .required("Field required"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  // call apis
  const { loading, refetch } = useQuery(QUERY_SUGGESTION_TOPICS, {
    variables: {
      companyId: showCompanyId,
    },
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    onCompleted(data) {
      populateData(data);
    },
  });
  useQuery(QUERY_DEFAULT_SUGGESTION_TOPICS, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    onCompleted(data) {
      if (data && data.defaultSuggestionTopics) {
        setFeedbackQuestions(
          data.defaultSuggestionTopics
            .sort(({ displayOrder: da, displayOrder: db }) => da - db)
            .map((topic) => ({
              ...topic,
              value: topic.topicId,
              label: topic.topic,
              suggestionTopicId: topic,
              suggestionTopic: topic.topic,
            })),
        );
      }
    },
  });
  const [saveSettings] = useMutation(MUTATION_SUGGESTION_TOPICS);
  const [setSuggestionTopicEnabled, { loading: savingSuggestionTopicEnabled }] =
    useMutation(MUTATION_SET_SUGGESTION_TOPIC);

  // methods
  const populateData = (data) => {
    if (data && data.company) {
      setEnabled(!!data.company.acceptContactSuggestions);
    }
    if (data && data.company && data.company.suggestionTopics) {
      const questions = data.company.suggestionTopics.map((topic) => ({
        ...topic,
        value: topic.suggestionTopicId,
        label: topic.suggestionTopic,
      }));
      formik.resetForm({
        values: {
          question1: questions[0],
          question2: questions[1],
          question3: questions[2],
        },
      });
    }
  };

  const saveChanges = ({ question1, question2, question3 }) => {
    const suggestionTopics = [question1, question2, question3]
      .filter((topic) => !!topic)
      .map((topic, index) => ({
        suggestionTopic: topic.suggestionTopic,
        currentOrder: index + 1,
      }));

    saveSettings({
      variables: {
        companyId: showCompanyId,
        suggestionTopics,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Feedback Settings updated",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Feedback Settings not updated",
        });
      });
  };

  const saveSuggestionTopicEnabled = (acceptContactSuggestions) => {
    setSuggestionTopicEnabled({
      variables: {
        companyId: showCompanyId,
        acceptContactSuggestions,
      },
    })
      .then(() => {
        if (acceptContactSuggestions) {
          refetch()
            .then((res) => res.data)
            .then(populateData);
        }
        addToast({
          type: "success",
          message: "Feedback Settings updated",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Feedback Settings  not updated",
        });
      });
  };

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Private Feedback"
      settingsDescription="This section enables you to change the questions for private feedback. If you change the status to OFF the Private Feedback questions will not be shown to reviewers."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38.234"
          height="36.363"
          viewBox="0 0 38.234 36.363"
        >
          <g transform="translate(.45 -20.883)">
            <g transform="translate(0 21.333)">
              <g>
                <circle cx=".75" cy=".75" r=".75" transform="translate(3 3)" />
                <circle cx=".75" cy=".75" r=".75" transform="translate(6 3)" />
                <circle cx=".75" cy=".75" r=".75" transform="translate(9 3)" />
                <path
                  d="M107.416 214.833h16.5a.75.75 0 0 0 0-1.5h-16.5a.75.75 0 0 0 0 1.5z"
                  transform="translate(-99.166 -199.833)"
                />
                <path
                  d="M116.416 341.333h-9a.75.75 0 1 0 0 1.5h9a.75.75 0 1 0 0-1.5z"
                  transform="translate(-99.166 -318.833)"
                />
                <path
                  d="M33.129 30.333c-.043 0-.086.011-.129.013v-6.013a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v27a3 3 0 0 0 3 3h27a3 3 0 0 0 3-3v-13.94l2.159-2.159a2.871 2.871 0 0 0-2.03-4.9zm-31.629-6a1.5 1.5 0 0 1 1.5-1.5h27a1.5 1.5 0 0 1 1.5 1.5v3h-30zm30 27a1.5 1.5 0 0 1-1.5 1.5H3a1.5 1.5 0 0 1-1.5-1.5v-22.5h30v2.012a2.821 2.821 0 0 0-.4.329l-8.16 8.159H8.25a.75.75 0 0 0 0 1.5h13.485l-.72 3.6a.75.75 0 0 0 .735.9.766.766 0 0 0 .146-.015l3.749-.75a.745.745 0 0 0 .384-.205l5.471-5.47zm-6.371-8.191l-2.423.485.485-2.426L30 34.393l1.94 1.94zm8.971-8.968l-1.1 1.1-1.94-1.94 1.1-1.1a1.372 1.372 0 0 1 1.939 1.94z"
                  transform="translate(0 -21.333)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <React.Fragment>
          {/* status */}
          <div className="d-flex flex-row mt-4 align-items-center">
            <div className="text-dark mt-1 mr-4">Status</div>
            <Form.Group className="mb-0" controlId="privateFeedbackEnabled">
              <Form.Check
                custom
                checked={enabled}
                onChange={() => {
                  if (!savingSuggestionTopicEnabled) {
                    setEnabled(!enabled);
                    saveSuggestionTopicEnabled(!enabled);
                  }
                }}
                type="switch"
                label={<React.Fragment />}
                className="text-dark custom-switch-lg"
              />
            </Form.Group>
          </div>

          {enabled ? (
            <form className="suggestion-topics" onSubmit={formik.handleSubmit}>
              {/* Question 1 */}
              <div className="d-flex flex-row mt-4 align-items-center">
                <Form.Group
                  className="mb-0"
                  style={{ width: 280 }}
                  controlId="question1"
                >
                  <Form.Label className="text-dark small">
                    Question 1<span className="ml-2 text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="question1"
                    value={formik.values.question1}
                    onChange={(val) => formik.setFieldValue("question1", val)}
                    placeholder="Select a Question"
                    options={feedbackQuestions}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </div>

              {/* Question 2 */}
              <div className="d-flex flex-row mt-2 align-items-center">
                <Form.Group
                  className="mb-0"
                  style={{ width: 280 }}
                  controlId="question2"
                >
                  <Form.Label className="text-dark small">
                    Question 2<span className="ml-2 text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="question"
                    value={formik.values.question2}
                    onChange={(val) => formik.setFieldValue("question2", val)}
                    placeholder="Select a Question"
                    options={feedbackQuestions}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </div>

              {/* Question 3 */}
              <div className="d-flex flex-row mt-2 align-items-center">
                <Form.Group
                  className="mb-0"
                  style={{ width: 280 }}
                  controlId="question3"
                >
                  <Form.Label className="text-dark small">
                    Question 3<span className="ml-2 text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="question3"
                    value={formik.values.question3}
                    onChange={(val) => formik.setFieldValue("question3", val)}
                    placeholder="Select a Question"
                    options={feedbackQuestions}
                    menuBuffer={30}
                    searchable={false}
                    clearable={false}
                  />
                </Form.Group>
              </div>

              <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  className="mr-2"
                  disabled={!formik.dirty || !formik.isValid}
                >
                  Save Changes
                </Button>
              </div>
            </form>
          ) : null}
        </React.Fragment>
      }
    />
  );
};

export default PrivateFeedbackSettings;
