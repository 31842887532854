import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import AboutCard from "./AboutCard";
import CustomerInformationCard from "./CustomerInformationCard";
import CommPreferencesCard from "./CommPreferencesCard";
import { commPreferencesShaper, initialCustomerData } from "./customerUtils";
import { GET_CONTACT } from "src/graphql/customers_segments/customers/queries.js";
import { NO_CACHE } from "src/shared/Constants.js";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import "./CustomerContent.scss";

const CustomerContent = () => {
  // state
  const companyId = useGetCompanyIdFromUrl();
  const { customerId } = useParams();
  const history = useHistory();
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [commPreferences, setCommPreferences] = useState(
    commPreferencesShaper(),
  );
  const [customerInfoDisabled, setCustomerInfoDisabled] = useState(true);

  // methods
  const invalidRequest = () => {
    history.push(`/companies/${companyId}/customers`);
  };

  // call apis
  useQuery(GET_CONTACT, {
    variables: { contactId: customerId },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      if (data && data.contact) {
        if (data.contact.companyId !== companyId) {
          invalidRequest();
        }
        setCustomerData(data.contact);
        setCommPreferences(commPreferencesShaper(data.contact));
        setCustomerInfoDisabled(
          data.contact.source !== "csv_upload" &&
            data.contact.source !== "manual_input",
        );
      }
    },
    onError: () => {
      invalidRequest();
    },
  });

  // render
  return (
    <React.Fragment>
      <div className="customer-container">
        <div className="customer-block">
          <Row>
            <Col sm={12} className="customer-about-card">
              <AboutCard customerData={customerData} />
            </Col>
            <Col sm={12} className="customer-communication-preferences-card">
              <CommPreferencesCard
                customerData={customerData}
                customerId={customerId}
                commPreferences={commPreferences}
                setCommPreferences={setCommPreferences}
              />
            </Col>
            <Col sm={12} className="customer-information-card">
              <CustomerInformationCard
                customerData={customerData}
                customerInfoDisabled={customerInfoDisabled}
                customerId={customerId}
                setCustomerData={setCustomerData}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerContent;
