import { number as CardValidator } from "card-validator";

export default class CardUtil {
  /**
   * validate a card number
   * @param {number} cardNumber  card number
   */
  static validateCard(cardNumber) {
    const cardNumberStr = (cardNumber === undefined || cardNumber === null
      ? ""
      : cardNumber
    ).toString();
    const result = CardValidator(cardNumberStr);
    if (result && result.card && result.card.type === "american-express") {
      result.card.type = "amex";
    }
    return result;
  }
}
