import React from "react";
import { Route, Switch } from "react-router";
import Users from "./Users";
import AddEditUser from "./AddEditUser";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const EnterpriseUser = ({ match }) => {
  useDocumentTitle("Users");
  return (
    <Switch>
      <Route path={`${match.path}/`} exact component={Users} />
      <Route path={`${match.path}/add-user`} component={AddEditUser} />
      <Route path={`${match.path}/edit-user/:id`} component={AddEditUser} />
    </Switch>
  );
};

export default EnterpriseUser;
