import React, { useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import classnames from "classnames";
import isEqual from "lodash.isequal";
import { Accordion, Col, Row, Button } from "react-bootstrap";
import AccordionToggle from "src/shared/styleguide/accordiontoggle/AccordionToggle";
import Form from "src/shared/styleguide/form/Form";
import { formatDate } from "src/shared/Utils";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import InfoIconWhite from "src/assets/images/common/information_white.svg";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import { PopupInfo } from "src/shared/styleguide/common/PopupInfo";
import { clearFilterStorage } from "./SegmentLocalStorageHelper.js";
import WindowUtil from "src/utils/WindowUtil";
import {
  initialState,
  optionsServiceContractStatus,
  RELATIVE_NEXT,
  RELATIVE_PREV,
  RELATIVE_NEXT_MONTH,
  NEXT_MONTH_STARTING,
  NEXT_MONTH_ENDING,
  PAGE_LENGTH,
  optionsHasMailingAddress,
  optionsHasMobileNumber,
  optionsHasEmailAddress,
} from "./SegmentConstants";
import {
  GET_CITIES,
  GET_COMPANY_STATES,
  GET_ZIPCODES,
} from "src/graphql/customers_segments/segment/queries.js";
import { NO_CACHE } from "src/shared/Constants";
import {
  getTouchedData,
  inputValueFormatter,
  inputValueSanitizer,
  locationShaper,
  populateFormState,
  shapeForSubmit,
  validateRange,
} from "./SegmentUtils";
import "./SegmentContent.scss";

const SegmentInclude = ({
  activeCard,
  activeSection,
  companyFilterMetrics,
  customFiltersList,
  fetchedFormData,
  fetchedMetaData,
  formik,
  formState,
  setActiveSection,
  setRawCitiesData,
  setRawStatesData,
  setRawZipCodesData,
  setSelectMulti,
  setShowUploadAdvancedFilterModal,
  setSubmitData,
  filterId,
  sourceOption,
  jobTypes,
}) => {
  // constants
  const client = useApolloClient();
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const [ExpandAll, setExpandAll] = React.useState(false);
  const [disableContractInputs, setDisableContractInputs] =
    React.useState(false);
  const [allStates, setAllStates] = React.useState([]);
  const [stateData, setStateData] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [citiesList, setCitiesList] = React.useState([]);
  const [zipCodeData, setZipCodeData] = React.useState([]);
  const [currentPagePerPopover, setCurrentPagePerPopover] = useState({
    city: 0,
    state: 0,
    zip: 0,
    job_type: 0,
    service_contract_type: 0,
  });
  const [paginatedData, setPaginatedData] = React.useState({
    city: [],
    state: [],
    zip: [],
    job_type: [],
    service_contract_type: [],
  });

  // Transaction States
  const [sinceLastTxnUnit, setSinceLastTxnUnit] = React.useState(
    !isEqual(formik.values.months, formState.months) ? "months" : "days",
  );

  // methods
  const readMoreContent = ({ include, type, items, key }) => {
    const onPagination = (event) => {
      setCurrentPagePerPopover({
        ...currentPagePerPopover,
        [key]: event.selected,
      });
      setPaginatedData({
        ...paginatedData,
        [key]: formik.values[key].slice(
          event.selected * PAGE_LENGTH,
          event.selected * PAGE_LENGTH + PAGE_LENGTH,
        ),
      });
    };

    return (
      <React.Fragment>
        <Row className="read-more-show-all-container container-fluid d-flex">
          {paginatedData[key].map((item, index) => (
            <Col xs={6} key={`${key.replace(/\s/g, "")}-${index}`}>
              <div className="value">
                {item.label}
                <span
                  className="Select-value-icon cursor-pointer"
                  onClick={() => {
                    setSelectMulti({
                      include: include,
                      type: type,
                      items: items.filter((el) => el.value !== item.value),
                      key: key,
                    });
                  }}
                >
                  ×
                </span>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} className="paginator-container">
            <PaginatorComponent
              pageCount={items.length / PAGE_LENGTH}
              pageChangeHandler={onPagination}
              currentPage={currentPagePerPopover[key]}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const relativeDateChangeHandler = (e, which) => {
    if (e.value === "next" && which === "start") {
      const newObj = {
        ...formik.values.contract_start_date,
        start: 0,
        end: 30,
      };
      formik.setFieldTouched(
        "contract_start_date",
        !isEqual(newObj, initialState.contract_start_date),
      );
      formik.setFieldValue("contract_start_date", newObj);
    } else if (e.value === "prev" && which === "start") {
      const newObj = {
        ...formik.values.contract_start_date,
        start: -30,
        end: 0,
      };

      formik.setFieldTouched(
        "contract_start_date",
        !isEqual(newObj, initialState.contract_start_date),
      );
      formik.setFieldValue("contract_start_date", newObj);
    } else if (e.value === "next_month" && which === "start") {
      const newObj = {
        ...formik.values.contract_start_date,
        start: NEXT_MONTH_STARTING,
        end: NEXT_MONTH_ENDING,
      };

      formik.setFieldTouched(
        "contract_start_date",
        !isEqual(newObj, initialState.contract_start_date),
      );
      formik.setFieldValue("contract_start_date", newObj);
    } else if (e.value === "next" && which === "end") {
      const newObj = {
        ...formik.values.contract_end_date,
        start: 0,
        end: 30,
      };
      formik.setFieldTouched(
        "contract_end_date",
        !isEqual(newObj, initialState.contract_end_date),
      );
      formik.setFieldValue("contract_end_date", newObj);
    } else if (e.value === "prev" && which === "end") {
      const newObj = {
        ...formik.values.contract_end_date,
        start: -30,
        end: 0,
      };
      formik.setFieldTouched(
        "contract_end_date",
        !isEqual(newObj, initialState.contract_end_date),
      );
      formik.setFieldValue("contract_end_date", newObj);
    } else if (e.value === "next_month" && which === "end") {
      const newObj = {
        ...formik.values.contract_end_date,
        start: NEXT_MONTH_ENDING,
        end: NEXT_MONTH_STARTING,
      };
      formik.setFieldTouched(
        "contract_end_date",
        !isEqual(newObj, initialState.contract_end_date),
      );
      formik.setFieldValue("contract_end_date", newObj);
    }
  };

  // API Calls
  /**
   * Fetch data from API with different query variables
   */

  const updateExcludeValue = (type, value = formik.values[type]) => {
    const newExcludeValue = {
      min:
        parseInt(formik.values[`not_${type}`].min) ||
        0 >= parseInt(value.min) ||
        0
          ? ""
          : formik.values[`not_${type}`].min,
      max:
        parseInt(formik.values[`not_${type}`].max) ||
        0 >= parseInt(value.max) ||
        0
          ? ""
          : formik.values[`not_${type}`].max,
      rMin: Math.max(parseInt(value.min) + 1),
      rMax: Math.min(parseInt(value.max) - 1),
    };

    formik.setFieldValue(`not_${type}`, newExcludeValue);
  };

  // Get Location Data
  useQuery(GET_COMPANY_STATES, {
    variables: {
      companyId: showCompanyId,
    },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      if (data && data.listCompanyStates) {
        // form states  data
        setStateData(locationShaper(data.listCompanyStates));
        setStatesList(Object.keys(data.listCompanyStates));
        setAllStates(Object.keys(data.listCompanyStates));
        // add states data for display reference
        setRawStatesData(data.listCompanyStates);
      }
    },
  });

  const getCities = () => {
    const statesArray = !isEqual(statesList, ["any"]) ? statesList : allStates;
    const queryVariables = {
      companyId: showCompanyId,
      states: statesArray,
    };
    client
      .query({
        query: GET_CITIES,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listCities) {
          // form states data
          setCityData(locationShaper(res.data.listCities));
          setCitiesList(Object.keys(res.data.listCities));
          // add states data for display reference
          setRawCitiesData(res.data.listCities);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getZipCodes = () => {
    const citiesDataList = cityData.map((item) => item.value);
    const statesDataList = stateData.map((item) => item.value);
    const citiesArray = !isEqual(citiesList, ["any"])
      ? citiesList
      : citiesDataList.slice(1, citiesDataList.length);

    const statesArray = !isEqual(statesList, ["any"])
      ? statesList
      : statesDataList.slice(1, statesDataList.length);

    const queryVariables = {
      companyId: showCompanyId,
      cities: citiesArray,
      states: statesArray,
    };
    client
      .query({
        query: GET_ZIPCODES,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listZipcodes) {
          // form states  data
          setZipCodeData(locationShaper(res.data.listZipcodes));
          // add states data for display reference
          setRawZipCodesData(res.data.listZipcodes);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // effects
  React.useEffect(() => {
    if (!filterId && !fetchedFormData) {
      setSubmitData(shapeForSubmit(formik));
    }
  }, [formik.values]);

  React.useEffect(() => {
    setDisableContractInputs(
      isEqual(formik.values.in_contract, [optionsServiceContractStatus[2]]),
    );
  }, [formik.values.in_contract]);

  React.useEffect(() => {
    const cityStart = currentPagePerPopover.city * PAGE_LENGTH;
    const stateStart = currentPagePerPopover.state * PAGE_LENGTH;
    const zipStart = currentPagePerPopover.zip * PAGE_LENGTH;
    const jobStart = currentPagePerPopover.job_type * PAGE_LENGTH;
    const servicecontracttype =
      currentPagePerPopover.service_contract_type * PAGE_LENGTH;

    setPaginatedData({
      city: formik.values.city.slice(cityStart, cityStart + PAGE_LENGTH),
      state: formik.values.state.slice(stateStart, stateStart + PAGE_LENGTH),
      zip: formik.values.zip.slice(zipStart, zipStart + PAGE_LENGTH),
      job_type: formik.values.job_type.slice(jobStart, jobStart + PAGE_LENGTH),
      service_contract_type: formik.values.service_contract_type.slice(
        servicecontracttype,
        servicecontracttype + PAGE_LENGTH,
      ),
    });
  }, [
    formik.values.city,
    formik.values.state,
    formik.values.zip,
    formik.values.job_type,
    formik.values.service_contract_type,
  ]);

  React.useEffect(() => {
    const states = formik.values.state.map((item) => item.value);
    setStatesList(states);
  }, [formik.values.state]);

  React.useEffect(() => {
    const cities = formik.values.city.map((item) => item.value);
    setCitiesList(cities);
  }, [formik.values.city]);

  React.useEffect(() => {
    getCities();
  }, [statesList]);

  React.useEffect(() => {
    getZipCodes();
  }, [citiesList]);

  // render
  return (
    <React.Fragment>
      <div className="criteria-form-card">
        <div className="criteria-form-card-header active">Include Criteria</div>
        <div className="criteria-form-card-body">
          <div className="expand-controls">
            <span
              onClick={() => {
                setActiveSection(null);
                setExpandAll(true);
              }}
            >
              Expand All
            </span>
            <span
              onClick={() => {
                setActiveSection(null);
                setExpandAll(false);
              }}
            >
              Collapse All
            </span>
            <span
              onClick={() => {
                if (fetchedFormData) {
                  formik.setValues({ ...fetchedFormData });
                  if (fetchedMetaData) {
                    // Clear all touched items
                    Object.keys(formik.touched).map((item) => {
                      formik.setFieldTouched(item, false);
                    });
                    // Set original touched items
                    getTouchedData(fetchedMetaData).map((item) => {
                      formik.setFieldTouched(item, true);
                    });
                  }
                } else {
                  formik.resetForm();
                  const initialState = populateFormState(
                    formik.initialValues,
                    companyFilterMetrics,
                  );

                  formik.setValues({
                    ...formik.initialValues,
                    ...initialState,
                  });
                }
                clearFilterStorage();
              }}
            >
              Clear All
            </span>
          </div>
          <Accordion
            activeKey={
              ExpandAll
                ? "3"
                : activeSection === "customer_details"
                ? "1"
                : null
            }
            className={classnames("my-2 form-accordion", {
              "segment-section-active":
                activeSection === "customer_details" &&
                activeCard === "include",
            })}
          >
            <div
              className="form-toggle-target "
              onClick={() => {
                setExpandAll(false);
                setActiveSection(
                  activeSection === "customer_details"
                    ? null
                    : "customer_details",
                );
              }}
            >
              <div className="form-section-title-container">
                <div className="form-section-title">Customer Details</div>
              </div>
              <AccordionToggle
                eventKey={ExpandAll ? "3" : "1"}
              ></AccordionToggle>
            </div>
            <Accordion.Collapse eventKey={ExpandAll ? "3" : "1"}>
              <div>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.commercial,
                      })}
                    >
                      Type
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={"This filters by customer type"}
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      className="mb-3"
                      clearable={false}
                      disabled={
                        !companyFilterMetrics.resident_status ||
                        companyFilterMetrics.resident_status.length <= 1
                      }
                      onChange={(item) => {
                        formik.setFieldTouched(
                          "commercial",
                          !isEqual([item], initialState["commercial"]),
                        );
                        formik.setFieldValue("commercial", [item]);
                      }}
                      options={companyFilterMetrics.resident_status}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.commercial[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.source,
                      })}
                    >
                      Source
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by source from how the customer contact was added"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelect
                      clearable={false}
                      disabled={
                        !companyFilterMetrics.sources ||
                        companyFilterMetrics.sources.length <= 1
                      }
                      onChange={(items) =>
                        setSelectMulti({
                          include: true,
                          type: "customer_details",
                          items: items,
                          key: "source",
                        })
                      }
                      options={sourceOption}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.source}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.in_contract,
                      })}
                    >
                      Service Contract Status
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by customer's service contract status"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      className="mb-3"
                      clearable={false}
                      disabled={
                        !optionsServiceContractStatus ||
                        optionsServiceContractStatus.length <= 1
                      }
                      onChange={(item) => {
                        formik.setFieldTouched(
                          "in_contract",
                          !isEqual([item], initialState["in_contract"]),
                        );
                        formik.setFieldValue("in_contract", [item]);
                      }}
                      options={optionsServiceContractStatus}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.in_contract[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched":
                          formik.touched.service_contract_type,
                      })}
                    >
                      Service Contract Type
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by customer's service contract type"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelectFilter
                      name="Service Contract Type"
                      allPagination={
                        !!companyFilterMetrics.service_contract_types &&
                        companyFilterMetrics.service_contract_types.length > 1
                      }
                      disabled={
                        disableContractInputs ||
                        !companyFilterMetrics.service_contract_types ||
                        companyFilterMetrics.service_contract_types.length <= 1
                      }
                      clearable={false}
                      onBlur={() => {
                        if (formik.values.service_contract_type.length < 1) {
                          formik.setFieldValue(
                            "service_contract_type",
                            initialState.service_contract_type,
                          );
                        }
                      }}
                      onChange={(items) =>
                        setSelectMulti({
                          include: true,
                          type: "customer_details",
                          items: items,
                          key: "service_contract_type",
                        })
                      }
                      readMoreContent={readMoreContent({
                        include: true,
                        type: "customer_details",
                        items: formik.values.service_contract_type,
                        key: "service_contract_type",
                      })}
                      options={companyFilterMetrics.service_contract_types}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.service_contract_type}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.contract_start_date,
                      })}
                    >
                      Service Contract Start Date
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by date range for service contract start date"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      disabled={disableContractInputs}
                      placeholder="All"
                      value={formik.values.contract_start_date.type}
                      options={[
                        {
                          value: "range",
                          label: "Date Range",
                        },
                        {
                          value: "relative",
                          label: "Relative Date Picker",
                        },
                      ]}
                      onChange={(e) => {
                        const initialObj = initialState.contract_start_date;
                        const newObj = {
                          type: e.value,
                          ...(e.value === "range"
                            ? {
                                start: initialObj.start,
                                end: initialObj.end,
                              }
                            : {
                                start: -30,
                                end: 0,
                              }),
                        };
                        formik.setFieldTouched(
                          "contract_start_date",
                          !isEqual(newObj, initialObj),
                        );
                        formik.setFieldValue("contract_start_date", newObj);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={{ span: 6, offset: 5 }}>
                    {formik.values.contract_start_date.type === "range" ? (
                      <Row className="pt-3">
                        <Col xl={6}>
                          <Form.DatePicker
                            disabled={disableContractInputs}
                            placeholderText="Select date"
                            minDate={null}
                            maxDate={
                              formik.values.contract_start_date.end || null
                            }
                            selected={formik.values.contract_start_date.start}
                            onChange={(val) => {
                              const newObj = {
                                ...formik.values.contract_start_date,
                                start: val,
                              };
                              formik.setFieldTouched(
                                "contract_start_date",
                                !isEqual(
                                  newObj,
                                  initialState.contract_start_date,
                                ),
                              );
                              formik.setFieldValue(
                                "contract_start_date",
                                newObj,
                              );
                            }}
                            useWeekdaysShort={false}
                            locale="en-gb"
                          />
                        </Col>
                        <Col xl={6}>
                          <Form.DatePicker
                            disabled={disableContractInputs}
                            placeholderText="Select date"
                            minDate={
                              formik.values.contract_start_date.start || null
                            }
                            maxDate={null}
                            selected={formik.values.contract_start_date.end}
                            onChange={(val) => {
                              const newObj = {
                                ...formik.values.contract_start_date,
                                end: val,
                              };
                              formik.setFieldTouched(
                                "contract_start_date",
                                !isEqual(
                                  newObj,
                                  initialState.contract_start_date,
                                ),
                              );
                              formik.setFieldValue(
                                "contract_start_date",
                                newObj,
                              );
                            }}
                            useWeekdaysShort={false}
                            locale="en-gb"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row className="pt-3">
                        <Col xl={12}>
                          <Form.Select
                            disabled={disableContractInputs}
                            value={
                              formik.values.contract_start_date.start === -30
                                ? RELATIVE_PREV
                                : formik.values.contract_start_date.start === 0
                                ? RELATIVE_NEXT
                                : RELATIVE_NEXT_MONTH
                            }
                            onChange={(e) => {
                              relativeDateChangeHandler(e, "start");
                            }}
                            placeholder="All"
                            options={[
                              RELATIVE_PREV,
                              RELATIVE_NEXT,
                              RELATIVE_NEXT_MONTH,
                            ]}
                            menuBuffer={30}
                            searchable={false}
                            clearable={false}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.contract_end_date,
                      })}
                    >
                      Service Contract End Date
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by date range for service contract end date"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      disabled={disableContractInputs}
                      placeholder={""}
                      value={formik.values.contract_end_date.type}
                      options={[
                        {
                          value: "range",
                          label: "Date Range",
                        },
                        {
                          value: "relative",
                          label: "Relative Date Picker",
                        },
                      ]}
                      onChange={(e) => {
                        const initialObj = initialState.contract_end_date;
                        const newObj = {
                          type: e.value,
                          ...(e.value === "range"
                            ? {
                                start: initialObj.start,
                                end: initialObj.end,
                              }
                            : {
                                start: -30,
                                end: 0,
                              }),
                        };
                        formik.setFieldTouched(
                          "contract_end_date",
                          !isEqual(newObj, initialObj),
                        );
                        formik.setFieldValue("contract_end_date", newObj);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col xl={{ span: 6, offset: 5 }}>
                    {formik.values.contract_end_date.type === "range" ? (
                      <Row>
                        <Col xl={6}>
                          <Form.DatePicker
                            disabled={disableContractInputs}
                            placeholderText="Select date"
                            minDate={null}
                            maxDate={
                              formik.values.contract_end_date.end || null
                            }
                            selected={formik.values.contract_end_date.start}
                            onChange={(val) => {
                              const newObj = {
                                ...formik.values.contract_end_date,
                                start: val,
                              };
                              formik.setFieldTouched(
                                "contract_end_date",
                                !isEqual(
                                  newObj,
                                  initialState.contract_end_date,
                                ),
                              );
                              formik.setFieldValue("contract_end_date", newObj);
                            }}
                            useWeekdaysShort={false}
                            locale="en-gb"
                          />
                        </Col>
                        <Col xl={6}>
                          <Form.DatePicker
                            disabled={disableContractInputs}
                            placeholderText="Select date"
                            minDate={
                              formik.values.contract_end_date.start || null
                            }
                            maxDate={null}
                            selected={formik.values.contract_end_date.end}
                            onChange={(val) => {
                              const newObj = {
                                ...formik.values.contract_end_date,
                                end: val,
                              };
                              formik.setFieldTouched(
                                "contract_end_date",
                                !isEqual(
                                  newObj,
                                  initialState.contract_end_date,
                                ),
                              );
                              formik.setFieldValue("contract_end_date", newObj);
                            }}
                            useWeekdaysShort={false}
                            locale="en-gb"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xl={12}>
                          <Form.Select
                            disabled={disableContractInputs}
                            value={
                              formik.values.contract_end_date.start === -30
                                ? RELATIVE_PREV
                                : formik.values.contract_end_date.start === 0
                                ? RELATIVE_NEXT
                                : RELATIVE_NEXT_MONTH
                            }
                            onChange={(e) => {
                              relativeDateChangeHandler(e, "end");
                            }}
                            placeholder="All"
                            options={[
                              RELATIVE_PREV,
                              RELATIVE_NEXT,
                              RELATIVE_NEXT_MONTH,
                            ]}
                            menuBuffer={30}
                            searchable={false}
                            clearable={false}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.state,
                      })}
                    >
                      State
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          <span>
                            This filters by customer’s state or province. This
                            shows the number of customers per state or province
                            which have good data.{" "}
                            <i>
                              Note - these are customer contacts which have
                              first names.
                            </i>
                          </span>
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelectFilter
                      disabled={
                        formik.touched.zip ||
                        formik.touched.city ||
                        stateData.length <= 1
                      }
                      allPagination={stateData.length > 1}
                      clearable={false}
                      name="State"
                      onBlur={() => {
                        if (formik.values.state.length < 1) {
                          formik.setFieldValue("state", initialState.state);
                        }
                      }}
                      onChange={(items) => {
                        setSelectMulti({
                          include: true,
                          type: "customer_details",
                          items: items,
                          key: "state",
                        });
                      }}
                      readMoreContent={readMoreContent({
                        include: true,
                        type: "customer_details",
                        items: formik.values.state,
                        key: "state",
                      })}
                      options={stateData}
                      placeholder=""
                      searchable={true}
                      value={formik.values.state}
                    />
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.city,
                      })}
                    >
                      City
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          <span>
                            This filters by customer's city. This shows the
                            number of customers per city which have good data.{" "}
                            <i>
                              Note - these are customer contacts which have
                              first names.
                            </i>
                          </span>
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelectFilter
                      clearable={false}
                      allPagination={cityData.length > 1}
                      disabled={formik.touched.zip || cityData.length <= 1}
                      name="City"
                      onBlur={() => {
                        if (formik.values.city.length < 1) {
                          formik.setFieldValue("city", initialState.city);
                        }
                      }}
                      onChange={(items) =>
                        setSelectMulti({
                          include: true,
                          type: "customer_details",
                          items: items,
                          key: "city",
                        })
                      }
                      options={cityData}
                      placeholder=""
                      readMoreContent={readMoreContent({
                        include: true,
                        type: "customer_details",
                        items: formik.values.city,
                        key: "city",
                      })}
                      searchable={true}
                      value={formik.values.city}
                    />
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.zip,
                      })}
                    >
                      ZIP
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          <span>
                            This filters by customer's ZIP or postal code. This
                            shows the number of customers per ZIP or postal code
                            which have good data.{" "}
                            <i>
                              Note - these are customer contacts which have
                              first names.
                            </i>
                          </span>
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelectFilter
                      clearable={true}
                      allPagination={zipCodeData.length > 1}
                      disabled={zipCodeData.length <= 1}
                      onBlur={() => {
                        if (formik.values.zip.length < 1) {
                          formik.setFieldValue("zip", initialState.zip);
                        }
                      }}
                      onChange={(items) =>
                        setSelectMulti({
                          include: true,
                          type: "customer_details",
                          items: items,
                          key: "zip",
                        })
                      }
                      readMoreContent={readMoreContent({
                        include: true,
                        type: "customer_details",
                        items: formik.values.zip,
                        key: "zip",
                      })}
                      name="ZIP Code"
                      options={zipCodeData}
                      placeholder=""
                      searchable={true}
                      value={formik.values.zip}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.has_mailing_address,
                      })}
                    >
                      Has Mailing Address
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters customers for whom you have a mailing address"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      className="mb-3"
                      clearable={false}
                      onChange={(item) => {
                        formik.setFieldTouched(
                          "has_mailing_address",
                          !isEqual([item], initialState["has_mailing_address"]),
                        );
                        formik.setFieldValue("has_mailing_address", [item]);
                      }}
                      options={optionsHasMailingAddress}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.has_mailing_address[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.has_mobile_number,
                      })}
                    >
                      Has Mobile Number
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters customers for whom you have a mobile number"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      className="mb-3"
                      clearable={false}
                      onChange={(item) => {
                        formik.setFieldTouched(
                          "has_mobile_number",
                          !isEqual([item], initialState["has_mobile_number"]),
                        );
                        formik.setFieldValue("has_mobile_number", [item]);
                      }}
                      options={optionsHasMobileNumber}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.has_mobile_number[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.has_email_address,
                      })}
                    >
                      Has Email Address
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters customers for whom you have an email address"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Select
                      className="mb-3"
                      clearable={false}
                      onChange={(item) => {
                        formik.setFieldTouched(
                          "has_email_address",
                          !isEqual([item], initialState["has_email_address"]),
                        );
                        formik.setFieldValue("has_email_address", [item]);
                      }}
                      options={optionsHasEmailAddress}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.has_email_address[0]}
                    />
                  </Col>
                </Row>
              </div>
            </Accordion.Collapse>
          </Accordion>
          <Accordion
            activeKey={
              ExpandAll ? "3" : activeSection === "transactions" ? "2" : null
            }
            className={classnames("mt-2 mb-3 form-accordion", {
              "segment-section-active":
                activeSection === "transactions" && activeCard === "include",
            })}
          >
            <div
              className="form-toggle-target"
              onClick={() => {
                setExpandAll(false);
                setActiveSection(
                  activeSection === "transactions" ? null : "transactions",
                );
              }}
            >
              <div className="form-section-title-container">
                <div className="form-section-title">Transactions</div>
              </div>
              <AccordionToggle
                eventKey={ExpandAll ? "3" : "2"}
              ></AccordionToggle>
            </div>
            <Accordion.Collapse eventKey={ExpandAll ? "3" : "2"}>
              <div>
                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.job_type,
                      })}
                    >
                      Job Type
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={"This filters by a transaction's job type"}
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.MultiSelectFilter
                      clearable={false}
                      name="Job Type"
                      allPagination={!!jobTypes && jobTypes.length > 1}
                      disabled={!jobTypes || jobTypes.length <= 1}
                      onBlur={() => {
                        if (formik.values.job_type.length < 1) {
                          formik.setFieldValue(
                            "job_type",
                            initialState.job_type,
                          );
                        }
                      }}
                      onChange={(items) => {
                        setSelectMulti({
                          include: true,
                          type: "transactions",
                          items: items,
                          key: "job_type",
                        });
                      }}
                      readMoreContent={readMoreContent({
                        include: true,
                        type: "transactions",
                        items: formik.values.job_type,
                        key: "job_type",
                      })}
                      options={jobTypes}
                      placeholder="All"
                      searchable={false}
                      value={formik.values.job_type}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.count,
                      })}
                    >
                      Number of transactions
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by the number of transactions a customer has had with you"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.count,
                          min: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "count",
                          !isEqual(newObj, formState.count),
                        );
                        formik.setFieldValue("count", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "count",
                          "min",
                          companyFilterMetrics.min_transaction_count ||
                            initialState.count.min,
                          formState,
                        );
                        updateExcludeValue("count");
                      }}
                      placeholder="Min"
                      value={
                        formik.values.count.min !== "any"
                          ? inputValueFormatter(formik.values.count.min)
                          : inputValueFormatter(
                              parseInt(
                                companyFilterMetrics.min_transaction_count ||
                                  initialState.count.min,
                              ),
                            )
                      }
                    />
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.count,
                          max: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "count",
                          !isEqual(newObj, formState.count),
                        );
                        formik.setFieldValue("count", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "count",
                          "max",
                          companyFilterMetrics.max_transaction_count ||
                            initialState.count.max,
                          formState,
                        );
                        updateExcludeValue("count");
                      }}
                      placeholder="Max"
                      value={
                        formik.values.count.max !== "any"
                          ? inputValueFormatter(formik.values.count.max)
                          : inputValueFormatter(
                              parseInt(
                                companyFilterMetrics.max_transaction_count ||
                                  initialState.count.max,
                              ),
                            )
                      }
                    />
                  </Col>
                  <Col xl={{ span: 6, offset: 5 }}>
                    <div className="segment-slider-container">
                      <Form.MultiRange
                        min={
                          parseInt(
                            companyFilterMetrics.min_transaction_count,
                          ) || initialState.count.min
                        }
                        max={
                          parseInt(
                            companyFilterMetrics.max_transaction_count,
                          ) || initialState.count.max
                        }
                        value={{
                          min:
                            (formik.values.count.min ||
                              formik.values.count.min === 0 ||
                              formik.values.count.min === "0") &&
                            formik.values.count.min !== "any"
                              ? formik.values.count.min
                              : parseInt(
                                  companyFilterMetrics.min_transaction_count,
                                ),
                          max:
                            formik.values.count.max &&
                            formik.values.count.max !== "any"
                              ? formik.values.count.max
                              : parseInt(
                                  companyFilterMetrics.max_transaction_count,
                                ),
                        }}
                        onChange={(value) => {
                          formik.setFieldTouched(
                            "count",
                            !isEqual(value, formState.count),
                          );
                          formik.setFieldValue("count", value);
                          updateExcludeValue("count", value);
                        }}
                        formattedValueNeeded={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched":
                          formik.touched.months || formik.touched.days,
                      })}
                    >
                      Time since last transaction
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={`	
                        This filters by ${
                          sinceLastTxnUnit === "days" ? "days" : "months"
                        } since your last transaction with the customer`}
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Form.Group
                      className="time-since-last-transaction-type"
                      controlId="timeSinceLastTransactionType1"
                    >
                      <Form.Check
                        custom
                        checked={sinceLastTxnUnit === "days"}
                        onChange={(e) => {
                          setSinceLastTxnUnit("days");
                          formik.setFieldValue(
                            "months",
                            formState.months || initialState.months,
                          );
                          formik.setFieldTouched("months", false);
                        }}
                        type="radio"
                        label="Days"
                        className="text-dark"
                      />
                    </Form.Group>

                    <Form.Group
                      className="time-since-last-transaction-type"
                      controlId="timeSinceLastTransactionType2"
                    >
                      <Form.Check
                        custom
                        checked={sinceLastTxnUnit === "months"}
                        onChange={() => {
                          setSinceLastTxnUnit("months");
                          formik.setFieldValue(
                            "days",
                            formState.days || initialState.days,
                          );
                          formik.setFieldTouched("days", false);
                        }}
                        type="radio"
                        label="Months"
                        className="text-dark"
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6}></Col>
                  <Col xl={{ span: 3, offset: 5 }}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values[sinceLastTxnUnit],
                          min: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          sinceLastTxnUnit,
                          !isEqual(newObj, formState[sinceLastTxnUnit]),
                        );
                        formik.setFieldValue(sinceLastTxnUnit, newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          sinceLastTxnUnit,
                          "min",
                          formState[sinceLastTxnUnit].min ||
                            initialState[sinceLastTxnUnit].min ||
                            formState,
                        );
                      }}
                      placeholder="Min"
                      value={
                        formik.values[sinceLastTxnUnit].min !== "any"
                          ? inputValueFormatter(
                              formik.values[sinceLastTxnUnit].min,
                            )
                          : inputValueFormatter(
                              parseInt(formState[sinceLastTxnUnit].min) ||
                                parseInt(initialState[sinceLastTxnUnit].min),
                            )
                      }
                    />
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values[sinceLastTxnUnit],
                          max: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          sinceLastTxnUnit,
                          !isEqual(newObj, formState[sinceLastTxnUnit]),
                        );
                        formik.setFieldValue(sinceLastTxnUnit, newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          sinceLastTxnUnit,
                          "max",
                          formState[sinceLastTxnUnit].max ||
                            initialState[sinceLastTxnUnit].max ||
                            formState,
                        );
                      }}
                      placeholder="Max"
                      value={
                        formik.values[sinceLastTxnUnit].max !== "any"
                          ? inputValueFormatter(
                              formik.values[sinceLastTxnUnit].max,
                            )
                          : inputValueFormatter(
                              parseInt(formState[sinceLastTxnUnit].max) ||
                                parseInt(initialState[sinceLastTxnUnit].max),
                            )
                      }
                    />
                  </Col>
                  <Col xl={{ span: 6, offset: 5 }}>
                    <div className="segment-slider-container">
                      <Form.MultiRange
                        min={
                          parseInt(formState[sinceLastTxnUnit].min) ||
                          parseInt(initialState[sinceLastTxnUnit].min)
                        }
                        max={
                          parseInt(formState[sinceLastTxnUnit].max) ||
                          parseInt(initialState[sinceLastTxnUnit].max)
                        }
                        value={{
                          min:
                            formik.values[sinceLastTxnUnit].min !== "any"
                              ? formik.values[sinceLastTxnUnit].min
                              : formState[sinceLastTxnUnit].min ||
                                initialState[sinceLastTxnUnit].min,
                          max:
                            formik.values[sinceLastTxnUnit].max !== "any"
                              ? formik.values[sinceLastTxnUnit].max
                              : formState[sinceLastTxnUnit].max ||
                                initialState[sinceLastTxnUnit].max,
                        }}
                        onChange={(value) => {
                          formik.setFieldTouched(
                            sinceLastTxnUnit,
                            !isEqual(value, formState[sinceLastTxnUnit]),
                          );
                          formik.setFieldValue(sinceLastTxnUnit, value);
                        }}
                        formattedValueNeeded={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.amount,
                      })}
                    >
                      Total Dollars spent
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by total dollars spent by the customer"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.amount,
                          min: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "amount",
                          !isEqual(newObj, formState.amount),
                        );
                        formik.setFieldValue("amount", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "amount",
                          "min",
                          companyFilterMetrics.min_transaction_amount ||
                            formState.amount.min ||
                            initialState.amount.min,
                          formState,
                        );
                        updateExcludeValue("amount");
                      }}
                      placeholder="Min"
                      value={
                        formik.values.amount.min !== "any"
                          ? inputValueFormatter(formik.values.amount.min)
                          : inputValueFormatter(
                              parseInt(
                                companyFilterMetrics.min_transaction_amount,
                              ) || initialState.amount.min,
                            )
                      }
                    />
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.amount,
                          max: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "amount",
                          !isEqual(newObj, formState.amount),
                        );
                        formik.setFieldValue("amount", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "amount",
                          "max",
                          companyFilterMetrics.max_transaction_amount ||
                            formState.amount.max ||
                            initialState.amount.max,
                          formState,
                        );
                        updateExcludeValue("amount");
                      }}
                      placeholder="Max"
                      value={
                        formik.values.amount.max !== "any"
                          ? inputValueFormatter(formik.values.amount.max)
                          : inputValueFormatter(
                              parseInt(
                                companyFilterMetrics.max_transaction_amount,
                              ) || initialState.amount.max,
                            )
                      }
                    />
                  </Col>
                  <Col xl={{ span: 6, offset: 5 }}>
                    <div className="segment-slider-container">
                      <Form.MultiRange
                        min={
                          parseInt(
                            companyFilterMetrics.min_transaction_amount,
                          ) ||
                          formState.amount.min ||
                          initialState.amount.min
                        }
                        max={
                          parseInt(
                            companyFilterMetrics.max_transaction_amount,
                          ) ||
                          formState.amount.max ||
                          initialState.amount.max
                        }
                        value={{
                          min:
                            formik.values.amount.min &&
                            formik.values.amount.min !== "any"
                              ? formik.values.amount.min
                              : formState.amount.min || initialState.amount.min,
                          max:
                            formik.values.amount.max &&
                            formik.values.amount.max !== "any"
                              ? formik.values.amount.max
                              : formState.amount.max || initialState.amount.max,
                        }}
                        onChange={(value) => {
                          formik.setFieldTouched(
                            "amount",
                            !isEqual(value, initialState.amount),
                          );
                          formik.setFieldValue("amount", value);
                          updateExcludeValue("amount", value);
                        }}
                        formattedValueNeeded={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.amount_per_txn,
                      })}
                    >
                      Dollar spent per transaction
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by dollars spent per transaction by the customer"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.amount_per_txn,
                          min: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "amount_per_txn",
                          !isEqual(newObj, formState.amount_per_txn),
                        );
                        formik.setFieldValue("amount_per_txn", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "amount_per_txn",
                          "min",
                          companyFilterMetrics.min_amount_per_transaction ||
                            formState.amount_per_txn.min ||
                            initialState.amount_per_txn.min,
                          formState,
                        );
                      }}
                      placeholder="Min"
                      value={inputValueFormatter(
                        formik.values.amount_per_txn.min,
                      )}
                    />
                  </Col>
                  <Col xl={3}>
                    <Form.Control
                      onChange={(e) => {
                        const newObj = {
                          ...formik.values.amount_per_txn,
                          max: inputValueSanitizer(e.target.value),
                        };
                        formik.setFieldTouched(
                          "amount_per_txn",
                          !isEqual(newObj, formState.amount_per_txn),
                        );
                        formik.setFieldValue("amount_per_txn", newObj);
                      }}
                      onBlur={() => {
                        validateRange(
                          formik,
                          "amount_per_txn",
                          "max",
                          companyFilterMetrics.max_amount_per_transaction ||
                            formState.amount_per_txn.ma ||
                            initialState.amount_per_txn.max,
                          formState,
                        );
                      }}
                      placeholder="Max"
                      value={inputValueFormatter(
                        formik.values.amount_per_txn.max,
                      )}
                    />
                  </Col>
                  <Col xl={{ span: 6, offset: 5 }}>
                    <div className="segment-slider-container">
                      <Form.MultiRange
                        min={
                          parseInt(
                            companyFilterMetrics.min_amount_per_transaction,
                          ) ||
                          formState.amount_per_txn.min ||
                          initialState.amount_per_txn.min
                        }
                        max={parseInt(
                          companyFilterMetrics.max_amount_per_transaction ||
                            formState.amount_per_txn.ma ||
                            initialState.amount_per_txn.max,
                        )}
                        value={{
                          min:
                            formik.values.amount_per_txn.min === "0" ||
                            formik.values.amount_per_txn.min === 0
                              ? 0
                              : formik.values.amount_per_txn.min ||
                                formState.amount_per_txn.min ||
                                initialState.amount_per_txn.min,
                          max:
                            formik.values.amount_per_txn.max ||
                            formState.amount_per_txn.max ||
                            initialState.amount_per_txn.max,
                        }}
                        onChange={(value) => {
                          formik.setFieldTouched(
                            "amount_per_txn",
                            !isEqual(value, formState.amount_per_txn),
                          );
                          formik.setFieldValue("amount_per_txn", value);
                        }}
                        formattedValueNeeded={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col xl={5} lg={6}>
                    <Form.Label
                      className={classnames("text-dark", {
                        "criteria-touched": formik.touched.txn_date,
                      })}
                    >
                      Transaction Date Range
                      <PopupInfo
                        tooltipClassName="create-segement-tooltip"
                        iconClassName="create-segement-tooltip-icon"
                        placement="top"
                        message={
                          "This filters by date range for transactions with the customer"
                        }
                      />
                    </Form.Label>
                  </Col>
                  <Col xl={6}>
                    <Row>
                      <Col xl={6}>
                        <Form.DatePicker
                          placeholderText="Select date"
                          minDate={
                            new Date(companyFilterMetrics.min_transacted_at)
                          }
                          maxDate={
                            formik.values.txn_date.max ||
                            new Date(companyFilterMetrics.max_transacted_at)
                          }
                          selected={formik.values.txn_date.min}
                          onChange={(val) => {
                            const newObj = {
                              ...formik.values.txn_date,
                              min: val,
                            };
                            formik.setFieldTouched(
                              "txn_date",
                              !isEqual(newObj, formState.txn_date),
                            );
                            formik.setFieldValue("txn_date", newObj);
                          }}
                          useWeekdaysShort={false}
                          locale="en-gb"
                        />
                      </Col>
                      <Col xl={6}>
                        <Form.DatePicker
                          placeholderText="Select date"
                          minDate={
                            formik.values.txn_date.min ||
                            new Date(companyFilterMetrics.min_transacted_at)
                          }
                          maxDate={
                            new Date(companyFilterMetrics.max_transacted_at)
                          }
                          selected={formik.values.txn_date.max}
                          onChange={(val) => {
                            const newObj = {
                              ...formik.values.txn_date,
                              max: val,
                            };
                            formik.setFieldTouched(
                              "txn_date",
                              !isEqual(newObj, formState.amount_per_txn),
                            );
                            formik.setFieldValue("txn_date", newObj);
                          }}
                          useWeekdaysShort={false}
                          locale="en-gb"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Accordion.Collapse>
          </Accordion>
          <div className="criteria-form-card mb-5">
            <div className="criteria-form-card-header active">
              Advanced Filter
              <PopupInfo
                tooltipClassName="create-segement-tooltip"
                placement="top"
                message={
                  "This is the Custom Filter that Customer Lobby members used prior to September 2022, so it may look familiar to you if you've been with us a while"
                }
                icon={
                  <img
                    src={InfoIconWhite}
                    className="create-segement-tooltip-icon"
                  />
                }
              />
            </div>
            <div className="criteria-form-card-body advanced-filter py-3">
              {customFiltersList.map((item, i) => {
                const nameInfo = item.label;
                const dateInfo =
                  new Date(item.updatedAt) > new Date(item.createdAt)
                    ? `(Updated on ${formatDate(item.updatedAt, "D MMM YYYY")})`
                    : `(Created on ${formatDate(
                        item.createdAt,
                        "D MMM YYYY",
                      )})`;
                const newItemLabel = `${nameInfo} ${dateInfo}`;

                const isSelected = formik.values.custom_filter_ids.some(
                  (filter) => filter && filter.value === item.value,
                );

                return (
                  <Form.Group
                    controlId={`formBasicCheckbox${i}`}
                    key={`advanced-filter-${i}`}
                  >
                    <Form.Check
                      custom
                      checked={isSelected}
                      onChange={() => {
                        const newItem = {
                          value: item.value,
                        };
                        let newFilters = [...formik.values.custom_filter_ids];
                        if (!isSelected) {
                          newFilters.push(newItem);
                        } else {
                          newFilters = newFilters.filter((el) => {
                            if (el && item) {
                              return el.value !== item.value;
                            }
                            return true;
                          });
                        }
                        formik.setFieldTouched(
                          "custom_filter_ids",
                          newFilters.length > 0,
                        );
                        formik.setFieldValue("custom_filter_ids", newFilters);
                      }}
                      type="checkbox"
                      label={
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            WindowUtil.openTab(item.outputUrl);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {newItemLabel}
                        </span>
                      }
                      className="text-dark"
                    />
                  </Form.Group>
                );
              })}
              <Button
                variant="outline-primary"
                size="lg"
                className="mb-2"
                onClick={() => setShowUploadAdvancedFilterModal(true)}
              >
                Add New Advanced Filter
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SegmentInclude.propTypes = {
  activeCard: PropTypes.string.isRequired,
  activeSection: PropTypes.string,
  companyFilterMetrics: PropTypes.object.isRequired,
  customFiltersList: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setSelectMulti: PropTypes.func.isRequired,
  setShowUploadAdvancedFilterModal: PropTypes.func.isRequired,
  setSubmitData: PropTypes.func.isRequired,
};

export default SegmentInclude;
