import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import customerLobbyIcon from "src/assets/images/common/cl-logo-header.png";
import serviceMonsterIcon from "src/assets/images/common/service_monster_logo.png";
import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_USER_DETAILS } from "src/graphql/auth/queries";
import { useHistory } from "react-router-dom";
import { COOKIES } from "src/shared/Config";
import { loadCookie, removeCookies } from "src/shared/SessionHelper";
import { setUserDetails } from "src/redux/userSlice";
import { useParams } from "react-router-dom";
import StringUtil from "src/utils/StringUtil";
import { setToastMessage } from "src/redux/toastSlice";
import { CREATE_SERVICE_MONSTER_CONNECTION } from "src/graphql/direct_connect/mutations";
import "./Auth.scss";

const AuthLayout = ({ title, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isExpired = history.location.pathname.includes(
    "update-expired-password",
  );
  const isServiceMonster =
    history.location.pathname.includes("service-monster");
  const { type } = useParams();

  const [createServiceMonsterConnection] = useMutation(
    CREATE_SERVICE_MONSTER_CONNECTION,
    {
      onCompleted: () => {
        dispatch(
          setToastMessage({
            type: "success",
            message:
              "Your Service Monster account has been successfully linked.",
          }),
        );
      },
      onError: (err) => {
        dispatch(
          setToastMessage({
            type: "error",
            message:
              err && err.graphQLErrors && err.graphQLErrors[0]
                ? err.graphQLErrors[0].message
                : "Something went wrong. Please try again",
          }),
        );
      },
    },
  );

  const [fetchUserInfo] = useLazyQuery(GET_USER_DETAILS, {
    onCompleted: async (data) => {
      if (data?.getAuthentication?.authenticated) {
        const { getAuthentication } = data;

        await dispatch(setUserDetails(getAuthentication));

        const { companyId } = getAuthentication;
        if (isServiceMonster) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const requestId = urlParams.get("requestID");
          await createServiceMonsterConnection({
            variables: {
              companyId,
              requestId,
            },
          });
          history.push(`/companies/${companyId}/direct-connect-settings`);
        } else if (getAuthentication?.expired) {
          history.push(`/${type}/update-expired-password`);
        } else if (history.location.pathname.includes("enterprise")) {
          history.push(`/enterprise/dashboard`);
        } else {
          history.push(`/companies/${companyId}/dashboard`);
        }
      }
    },
    onError: () => {
      removeCookies();
    },
  });

  useEffect(() => {
    const autToken = loadCookie(COOKIES.AUTH_TOKEN);
    if (autToken) {
      fetchUserInfo({
        variables: {
          token: autToken,
        },
      });
    }
    document.body.classList.add("auth-body");
    return () => {
      document.body.classList.remove("auth-body");
    };
  }, []);

  return (
    <div className="auth-container">
      <Container className="auth-wrapper">
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <div className="auth-card">
              <div className="text-center mb-5 mt-3">
                {isServiceMonster ? (
                  <div className="service-monster-logo">
                    <img src={customerLobbyIcon} />
                    <i className="fa fa-exchange fa-2x"></i>
                    <img src={serviceMonsterIcon} width="184px" />
                  </div>
                ) : (
                  <img src={customerLobbyIcon} className="logo mb-4" />
                )}
                <h1 className="mt-4">{title}</h1>
                <span>
                  {isExpired ? (
                    <p>
                      Your password has expired. You must update your password{" "}
                      <br /> before you can log in
                    </p>
                  ) : (
                    `${StringUtil.capitalize(type)} Account`
                  )}
                </span>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthLayout;
