import React from "react";
import ReactHighcharts from "react-highcharts";

const PieChart = ({ colors, data, labelFormatter }) => {
  const config = {
    colors,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "<br/><b>{point.percentage:.2f}%</b>",
      percentageDecimals: 1,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    legend: {
      labelFormatter,
      symbolRadius: 0,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data,
      },
    ],
  };
  return <ReactHighcharts config={config} />;
};

export default PieChart;
