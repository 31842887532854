import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "src/shared/styleguide/card/Card";
import BlockUi from "react-block-ui";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import BillingPreferences from "./BillingPreferences";
import BillingTransactions from "./BillingTransactions";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";

const Billing = () => {
  useDocumentTitle("Billing");
  return (
    <BlockUi>
      <div className="page-title">Settings</div>
      <Card className="mt-4 mb-4 billing-card">
        <Row>
          <Col>
            <div
              style={{ lineHeight: 0 }}
              className="font-weight-bold h2 text-dark m-0 d-flex align-items-center settings-card-name"
            >
              Payment Details
              <span className="ml-2">
                <PopupInfo
                  placement="top"
                  message="Payment Details"
                  iconSize={16}
                />
              </span>
            </div>
            <div className="mt-1">Update payment and billing information.</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <BillingPreferences />
          </Col>
        </Row>
        <BillingTransactions />
      </Card>
    </BlockUi>
  );
};

export default Billing;
