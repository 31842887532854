import React, { useEffect, useRef } from "react";
import calendarIcon from "src/assets/images/enterprise/calendar.svg";
import Button from "src/shared/styleguide/button/Button";
import Form from "src/shared/styleguide/form/Form";
import { Row, Col } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./FilterModal.scss";

const validationSchema = Yup.object({
  from: Yup.date().nullable(),
  to: Yup.date()
    .nullable()
    .when("from", (from, schema) => {
      return from
        ? schema.min(from, "To Date must be greater than or equal to From Date")
        : schema;
    }),
});

const FilterModal = ({ closeModal, setDateFilter, dateFilter, submit }) => {
  const { handleSubmit, errors, setFieldValue } = useFormik({
    initialValues: {
      from: dateFilter.from,
      to: dateFilter.to,
    },
    validationSchema,
    onSubmit: () => {
      submit();
    },
  });

  const myDivRef = useRef(null);

  // Function to handle the close logic
  const handleClickOutside = (event) => {
    // Check if the click happened outside the referenced div
    if (myDivRef.current && !myDivRef.current.contains(event.target)) {
      if (!event.target.className?.includes("datepicker")) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    // Add click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={myDivRef} className="filter-modal">
      <h1 className="filter-pane-header mb-0">
        Filter
        <span className="pull-right cursor-pointer" onClick={closeModal}>
          <svg
            version="1.1"
            viewBox="0 0 100 100"
            style={{
              width: 16,
              height: 16,
              strokeWidth: 12,
              stroke: "#d6d3d3",
            }}
          >
            <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
          </svg>
        </span>
      </h1>

      <Row className="filter-pane-content p-4">
        <Col sm={12} className="mb-0">
          <Form.Label className="text-dark">
            <span className="mr-2">Choose Date Range</span>
          </Form.Label>
        </Col>
        <Col sm={6} className="mb-0">
          <Form.Group
            controlId="lastTransactionDateStart"
            style={{
              background: `url(${calendarIcon}) no-repeat 20px 20px`,
              backgroundPosition: "right 10px center",
            }}
          >
            <Form.DatePicker
              dateFormat="MMM dd, yyyy"
              selected={dateFilter.from}
              onChange={(date) => {
                setDateFilter({
                  ...dateFilter,
                  from: date,
                });
                setFieldValue("from", date);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={6} className="mb-0">
          <Form.Group
            controlId="lastTransactionDateEnd"
            style={{
              background: `url(${calendarIcon}) no-repeat 20px 20px`,
              backgroundPosition: "right 10px center",
            }}
          >
            <Form.DatePicker
              dateFormat="MMM dd, yyyy"
              selected={dateFilter.to}
              onChange={(date) => {
                setDateFilter({
                  ...dateFilter,
                  to: date,
                });
                setFieldValue("to", date);
              }}
            />
          </Form.Group>
        </Col>
        {errors.to && (
          <Col sm={12}>
            <div className="sg-text-danger my-2">{errors.to}</div>
          </Col>
        )}
        <Col className="filter-pane-footer">
          <Button variant="outline-secondary mr-2" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FilterModal;
