import React from "react";
import Button from "src/shared/styleguide/button/Button";
import { QUERY_TWITTER_PROFILE } from "src/graphql/settings/reviews/queries";
import {
  MUTATION_CREATE_TWITTER_PROFILE,
  MUTATION_RESET_TWITTER,
} from "src/graphql/settings/reviews/mutations";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { useGetCompanyIdFromUrl } from "src/utils/useGetCompanyIdFromUrl";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import FullPageOverlay from "src/shared/styleguide/overlay/FullPageOverlay";
import TwitterProfileInformation from "./TwitterProfileInformation";
import ConnectTwitterButton from "./ConnectTwitterButton";
import {
  REVIEW_SETTINGS_FETCH_POLICY,
  CLOSE_WINDOW_AFTER,
} from "src/constants/ReviewSettings";
import GraphQLUtil from "src/utils/GraphQLUtil";
import { addToast } from "src/utils/ToastUtil";

const TwitterConnectionSettings = ({ loading, disconnectModal }) => {
  // state
  const showCompanyId = useGetCompanyIdFromUrl();
  const client = useApolloClient();
  const location = useLocation();
  const [hasToken, setHasToken] = React.useState();
  const [verifier, setVerifier] = React.useState();
  const [twitterAuthCreds, setTwitterAuthCreds] = React.useState({});
  const [reset, setReset] = React.useState(false);
  const [connected, setConnected] = React.useState(false);

  // call apis
  const { loading: loadingProfile, data: twitterData } = useQuery(
    QUERY_TWITTER_PROFILE,
    {
      fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
      variables: {
        companyId: showCompanyId,
      },
      onCompleted(data) {
        populateData(data);
      },
    },
  );
  const [createProfile] = useMutation(MUTATION_CREATE_TWITTER_PROFILE);
  const [resetProfile] = useMutation(MUTATION_RESET_TWITTER);

  // methods
  const refetch = () => {
    return client.query({
      query: QUERY_TWITTER_PROFILE,
      fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
      variables: {
        companyId: showCompanyId,
      },
    });
  };
  const populateData = (data) => {
    if (
      data &&
      data.socialNetworkProfiles &&
      data.socialNetworkProfiles.length > 0
    ) {
      setConnected(true);
    } else {
      setConnected(false);
    }
  };
  const authHandler = ({ secret, token, verifier }) => {
    if (secret && token && verifier) {
      setReset(false);
      createProfile({
        variables: {
          companyId: showCompanyId,
          secret,
          token,
          verifier,
        },
      })
        .then(() => {
          setTimeout(() => {
            refetch().then((res) => {
              populateData(res ? res.data : null);
            });
          }, CLOSE_WINDOW_AFTER);
          addToast({
            type: "success",
            message: "Twitter is connected",
          });
        })
        .catch((err) => {
          const errMsg = GraphQLUtil.getError(err);
          let twitterErr = "";
          if (errMsg && /choose another/i.test(errMsg)) {
            twitterErr = (
              <span>
                This Twitter Account is currently being used. Please choose
                another Twitter account to link.{" "}
                <u>
                  <a
                    className="text-white"
                    href={`/companies/${showCompanyId}/help-center`}
                  >
                    Click here
                  </a>
                </u>{" "}
                to contact our support team.
              </span>
            );
          }
          addToast({
            type: "error",
            persist: twitterErr ? true : false,
            message: twitterErr || "Twitter not connected.",
          });
        });
    }
  };
  const disconnect = () => {
    setReset(true);
    resetProfile({
      variables: {
        companyId: showCompanyId,
      },
    })
      .then((res) => {
        refetch().then((res) => {
          populateData(res ? res.data : null);
        });
      })
      .catch((err) => {});
  };
  const closePopup = (timeout = CLOSE_WINDOW_AFTER) => {
    setTimeout(() => {
      window.close();
    }, timeout);
  };

  // effects
  React.useEffect(() => {
    window.addEventListener(
      "message",
      ({ data }) => {
        if (data && data.data && data.data.oauthVerifier) {
          setVerifier(data.data.oauthVerifier);
        } else {
          refetch().then((res) => {
            populateData(res ? res.data : null);
          });
        }
      },
      false,
    );

    const params = new URLSearchParams(location.search);
    if (params.has("oauth_token")) {
      const oauthToken = params.get("oauth_token");
      const oauthVerifier = params.get("oauth_verifier");
      setHasToken(true);
      window.opener.postMessage(
        {
          type: "authorized",
          data: {
            oauthToken,
            oauthVerifier,
          },
        },
        window.origin,
      );
      closePopup();
    }
    if (params.has("denied")) {
      setHasToken(true);
      closePopup(0);
    }
  }, []);

  React.useEffect(() => {
    if (verifier) {
      authHandler({ ...twitterAuthCreds, verifier });
    }
  }, [verifier]);

  // checks
  if (loading || loadingProfile) {
    return <React.Fragment />;
  }

  if (hasToken) {
    return (
      <FullPageOverlay>
        <h2>Logging in...</h2>
      </FullPageOverlay>
    );
  }

  // render
  return (
    <div className="mt-4">
      {!connected ||
      reset ||
      !twitterData ||
      !twitterData.socialNetworkProfiles ||
      twitterData.socialNetworkProfiles.length === 0 ? (
        <div>
          <div className="mb-4">
            Automatically tweet your new review by linking your Twitter account
            to your Customer Lobby account.
          </div>
          <ConnectTwitterButton setTwitterAuthCreds={setTwitterAuthCreds} />
        </div>
      ) : (
        <React.Fragment>
          <TwitterProfileInformation
            twitterProfile={twitterData.socialNetworkProfiles[0]}
          />
          <div>
            <div className="my-3">
              <p>Click below to disconnect your twitter profile.</p>
              <Button
                onClick={() =>
                  disconnectModal(
                    disconnect,
                    "This will disconnect your Customer Lobby account from Twitter. And no more posts will be made via Customer Lobby.",
                  )
                }
              >
                Disconnect
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

TwitterConnectionSettings.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  disconnectModal: PropTypes.func.isRequired,
};

export default TwitterConnectionSettings;
